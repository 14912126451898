import { Department, Request } from "@dh-critical-path/api-types";
import { useDepartmentsTasksStatsQuery } from "../../../queries";
import { TasksStatsCards, TasksStatsEntityLink } from "../../Tasks/components/TasksStatsCards";

type DepartmentsTasksStatsCardsProps = {
  header: (count: number) => React.ReactElement;
  filter: Request.Departments.GetDepartmentsTasksStats;
  entityLink: TasksStatsEntityLink<Department>;
  showHeaderAsFallback?: boolean;
};

export function DepartmentsTasksStatsCards({
  header,
  filter,
  entityLink,
  showHeaderAsFallback = false,
}: DepartmentsTasksStatsCardsProps) {
  const { data } = useDepartmentsTasksStatsQuery(filter);

  if (!data || !data.length) {
    return showHeaderAsFallback ? header(data?.length ?? 0) : null;
  }

  return (
    <>
      {header(data?.length ?? 0)}
      <TasksStatsCards items={data} entityLink={entityLink} />
    </>
  );
}
