import { Project } from "@dh-critical-path/api-types";
import { Attachments, AttachmentsPanel } from "../../components/form/Attachments";
import { PageContainer } from "../../components/page";
import { useProjectAttachments } from "../../hooks/useAttachments";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

type ProjectAttachmentsPageProps = {
  project: Project;
};

function ProjectAttachmentsPage({ project }: ProjectAttachmentsPageProps) {
  useDocumentTitle(`${project.name} - Attachments`);

  const { attachments, isUploading, handleUpload, handleDelete } = useProjectAttachments(
    project.id
  );

  return (
    <PageContainer>
      <AttachmentsPanel>
        <Attachments
          onUpload={handleUpload}
          onDelete={handleDelete}
          attachments={attachments}
          isUploading={isUploading}
          isSearchable
          isRemovable
          confirmNotifications
        />
      </AttachmentsPanel>
    </PageContainer>
  );
}

export default ProjectAttachmentsPage;
