import { PropsWithChildren } from "react";
import HeaderHoc from "../../../hoc/header/HeaderHoc";

export function UsersPageLayout({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <HeaderHoc />
      <div>{children}</div>
    </>
  );
}
