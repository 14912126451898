import { Task, TasksComparisonResult, TemplateTask } from "@dh-critical-path/api-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import Button from "../../components/button/Button";
import {
  SlideOverBody,
  SlideOverButtons,
  SlideOverContent,
  SlideOverHeader,
  SlideOverTitle,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useConfirmModal } from "../../hooks/useModal";
import { useTaskUpdateFromTemplate } from "../../hooks/useTask";
import { useCompareTemplateTasks } from "../../queries";
import { isTask } from "../../utils/misc";
import { fullName, processTypeText } from "../../utils/text";
import { useSlideOverNavigate } from "../SlideOvers";
import {
  ComparisonTableHeadingRow,
  ComparisonTableRowContainer,
  ComparisonTableRowDivider,
  ComparisonTableRowValueField,
} from "./components/TemplateTasksComparisonTable";

type TemplateTaskComparisonSlideOverProps = {
  templateProjectId: number;
  projectId: number;
  templateTaskId: number;
  taskId: number;
};

type TemplateTaskComparisonProps = {
  result: TasksComparisonResult;
};

type FormattedDateStringProps = {
  date: string | null | undefined;
};

function joinBy<T extends { [key in X]: string }, X extends keyof T>(items: T[], field: X) {
  return items.map((item) => item[field]).join(", ");
}

function joinByName(items: { name: string }[]) {
  return joinBy(items, "name");
}

function resolveTaskAssignee(task: Task | TemplateTask) {
  const user = task.assignedToUser;
  const title = isTask(task) ? task.assignedToTitle : task.assignedToTemplateTitle;

  return user ? fullName(user) : title?.name;
}

function resolveTaskSupporter(task: Task | TemplateTask) {
  const users = task.supportedByUsers;
  const titles = isTask(task) ? task.supportedByTitles : task.supportedByTemplateTitles;

  return joinByName([...users.map((user) => ({ name: fullName(user) })), ...titles]);
}

function FormattedDateString({ date }: FormattedDateStringProps) {
  return date ? <FormattedDate value={date} year="numeric" month="short" day="2-digit" /> : <>?</>;
}

function TemplateTaskComparisonHeaderActions({ result }: TemplateTaskComparisonProps) {
  const slideOverNavigate = useSlideOverNavigate();
  const { modal, openModal } = useConfirmModal();
  const { handleUpdate, isLoading, isSuccess } = useTaskUpdateFromTemplate(
    result.template_task,
    result.task
  );

  return (
    <SlideOverButtons>
      {isSuccess ? (
        <Button variant="secondary" disabled={isLoading} onClick={() => slideOverNavigate()}>
          <FormattedMessage id="modal.close" />
        </Button>
      ) : (
        <>
          <Button variant="secondary" disabled={isLoading} onClick={() => slideOverNavigate()}>
            <FormattedMessage id="modal.discard" />
          </Button>
          <Button
            type="button"
            disabled={isLoading}
            loading={isLoading}
            onClick={() =>
              openModal(() => handleUpdate(), {
                description: "Are you sure you want to apply the changes?",
              })
            }
          >
            <FormattedMessage id="modal.apply" />
          </Button>
        </>
      )}
      {modal}
    </SlideOverButtons>
  );
}

function TemplateTaskComparisonHeader(props: TemplateTaskComparisonProps) {
  return (
    <SlideOverHeader>
      <SlideOverTitle>
        <FormattedMessage id="templates.tasks.modal.heading.comparison" />
      </SlideOverTitle>
      <TemplateTaskComparisonHeaderActions {...props} />
    </SlideOverHeader>
  );
}

function TemplateTaskComparisonContent({ result }: TemplateTaskComparisonProps) {
  if (!result.template_task?.templateProject || !result.task?.project) {
    return null;
  }
  // TODO: split into smaller components

  return (
    <SlideOverContent>
      <SlideOverBody width="wide" spacing="none">
        <div>
          <ComparisonTableHeadingRow
            templateProject={result.template_task?.templateProject}
            project={result.task?.project}
            isSticky={false}
          />
          {/* Name */}
          <ComparisonTableRowContainer variant={result.is_name_changed ? "changed" : "equal"}>
            <ComparisonTableRowValueField label="Task Name" value={result.template_task.name} />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField label="Task Name" value={result.task.name} />
          </ComparisonTableRowContainer>
          {/* Description */}
          <ComparisonTableRowContainer
            variant={result.is_description_changed ? "changed" : "equal"}
          >
            <ComparisonTableRowValueField
              label="Task Description"
              value={result.template_task.description}
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField
              label="Task Description"
              value={result.task.description}
            />
          </ComparisonTableRowContainer>
          {/* Department */}
          <ComparisonTableRowContainer
            variant={result.is_template_department_id_changed ? "changed" : "equal"}
          >
            <ComparisonTableRowValueField
              label="Responsible Department/Team"
              value={result.template_task.templateDepartment?.name}
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField
              label="Responsible Department/Team"
              value={result.task.department?.name}
            />
          </ComparisonTableRowContainer>
          {/* Assignee */}
          <ComparisonTableRowContainer
            variant={
              result.is_assignee_user_id_changed || result.is_assignee_template_title_id_changed
                ? "changed"
                : "equal"
            }
          >
            <ComparisonTableRowValueField
              label="Assigned To"
              value={resolveTaskAssignee(result.template_task)}
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField
              label="Assigned To"
              value={resolveTaskAssignee(result.task)}
            />
          </ComparisonTableRowContainer>
          {/* Supporters */}
          <ComparisonTableRowContainer
            variant={
              result.is_supporter_user_ids_changed || result.is_supporter_template_title_ids_changed
                ? "changed"
                : "equal"
            }
          >
            <ComparisonTableRowValueField
              label="Supporter"
              value={resolveTaskSupporter(result.template_task)}
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField
              label="Supporter"
              value={resolveTaskSupporter(result.task)}
            />
          </ComparisonTableRowContainer>
          {/* Tags */}
          <ComparisonTableRowContainer variant={result.is_tag_ids_changed ? "changed" : "equal"}>
            <ComparisonTableRowValueField
              label="Tags"
              value={joinByName(result.template_task.tags)}
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField label="Tags" value={joinByName(result.task.tags)} />
          </ComparisonTableRowContainer>
          {/* Dependencies */}
          <ComparisonTableRowContainer variant="base">
            <ComparisonTableRowValueField
              label="Dependent on"
              value={joinByName(result.template_task.dependsOn)}
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField
              label="Dependent on"
              value={joinByName(result.task.dependsOn)}
            />
          </ComparisonTableRowContainer>
          {/* Is Milestone */}
          <ComparisonTableRowContainer variant={result.is_milestone_changed ? "changed" : "equal"}>
            <ComparisonTableRowValueField
              label="Is Milestone"
              value={result.template_task.is_milestone ? "Yes" : "No"}
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField
              label="Is Milestone"
              value={result.task.is_milestone ? "Yes" : "No"}
            />
          </ComparisonTableRowContainer>
          {/* Attachments */}
          <ComparisonTableRowContainer
            variant={result.is_attachment_ids_changed ? "changed" : "equal"}
          >
            <ComparisonTableRowValueField
              label="Attachments"
              value={joinBy(result.template_task.attachments, "original_filename")}
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField
              label="Attachments"
              value={joinBy(result.task.attachments, "original_filename")}
            />
          </ComparisonTableRowContainer>
          {/* Duration */}
          <ComparisonTableRowContainer variant={result.is_duration_changed ? "changed" : "equal"}>
            <ComparisonTableRowValueField label="Duration" value={result.template_task.duration} />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField label="Duration" value={result.task.duration} />
          </ComparisonTableRowContainer>
          {/* Due Date Offset */}
          <ComparisonTableRowContainer
            variant={result.is_due_date_offset_changed ? "changed" : "equal"}
          >
            <ComparisonTableRowValueField
              label={processTypeText(
                result.template_task.templateProject,
                "Days Prior Opening",
                "Days Prior Closure",
                "Days Prior Re-Opening",
                "Days Prior Transition"
              )}
              value={result.template_task.due_date_offset}
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField
              label={processTypeText(
                result.task.project,
                "Days Prior Opening",
                "Days Prior Closure",
                "Days Prior Re-Opening",
                "Days Prior Transition"
              )}
              value={result.task.due_date_offset}
            />
          </ComparisonTableRowContainer>
          {/* Dates */}
          <ComparisonTableRowContainer
            variant={
              result.is_start_date_changed || result.is_due_date_changed ? "changed" : "equal"
            }
          >
            <ComparisonTableRowValueField
              label="Dates"
              value={
                <>
                  <FormattedDateString date={result.computed_start_date} />
                  {" - "}
                  <FormattedDateString date={result.computed_due_date} />
                </>
              }
            />
            <ComparisonTableRowDivider />
            <ComparisonTableRowValueField
              label="Dates"
              value={
                <>
                  <FormattedDateString date={result.task.start_date} />
                  {" - "}
                  <FormattedDateString date={result.task.due_date} />
                </>
              }
            />
          </ComparisonTableRowContainer>
        </div>
      </SlideOverBody>
    </SlideOverContent>
  );
}

function TemplateTaskComparison(props: TemplateTaskComparisonProps) {
  return (
    <>
      <TemplateTaskComparisonHeader {...props} />
      <TemplateTaskComparisonContent {...props} />
    </>
  );
}

function TemplateTaskComparisonNotFound() {
  return (
    <SlideOverContent>
      <SlideOverBody>
        <FormattedMessage id="modal.error.default" />
      </SlideOverBody>
    </SlideOverContent>
  );
}

export function TemplateTaskComparisonSlideOver(props: TemplateTaskComparisonSlideOverProps) {
  const result = useCompareTemplateTasks({
    template_project_id: props.templateProjectId,
    project_id: props.projectId,
    template_task_id: props.templateTaskId,
    task_id: props.taskId,
  });

  return (
    <SlideOverWithLoading
      result={result}
      render={([result]) =>
        Boolean(result?.template_task && result?.task) ? (
          <TemplateTaskComparison result={result} />
        ) : (
          <TemplateTaskComparisonNotFound />
        )
      }
    />
  );
}
