import classnames from "classnames";
import React, { InputHTMLAttributes } from "react";
import { FormElementProps, ValidationText } from ".";
import { nextId } from "../../utils/nextId";
import { styled } from "../../utils/styled";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement>, FormElementProps {
  //
}

interface CheckboxStyledProps extends InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean;
}

const CheckboxStyled = styled<HTMLInputElement, CheckboxStyledProps>(
  ({ disabled, invalid, className, ...props }) => (
    <input
      {...props}
      type="checkbox"
      disabled={disabled}
      className={classnames(
        "checkbox h-4 w-4 rounded text-beige focus:outline-none focus:ring-offset-0 focus:ring-transparent cursor-pointer",
        {
          "opacity-60": disabled,
          "border-stone-400 focus:border-2 focus:border-beige bg-white hover:border-beige":
            !invalid,
          "border-red-300": invalid,
        },
        className
      )}
    />
  )
);

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { id = nextId("checkbox"), label, invalid, invalidText, disabled, required, ...props },
  ref
) {
  return (
    <div className="relative flex items-start">
      <CheckboxStyled {...props} id={id} invalid={invalid} disabled={disabled} ref={ref} />
      <div className="ml-2 text-base leading-tight text-black select-none">
        {label && (
          <label htmlFor={id} className="cursor-pointer inline-block -mt-px">
            {label}
          </label>
        )}
        {invalid && invalidText && (
          <ValidationText className={classnames(label ? "mt-1" : "-mt-0.5")}>
            {invalidText}
          </ValidationText>
        )}
      </div>
    </div>
  );
});

export default Checkbox;
