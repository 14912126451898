import { Request, Response } from "@dh-critical-path/api-types";
import axios from "axios";
import { stringify } from "qs";

export function getTask(taskId: number) {
  return axios.get<Response.Tasks.GetTask>(`/api/tasks/${taskId}`).then(({ data }) => data);
}

export function createTask(params: Request.Tasks.UpdateTask) {
  return axios.post<Response.Tasks.CreateTask>(`/api/tasks`, params).then(({ data }) => data);
}

export function createTaskFromTemplate(params: Request.Tasks.CreateTaskFromTemplate) {
  return axios
    .post<Response.Tasks.CreateTaskFromTemplate>(`/api/tasks/from-template`, params)
    .then(({ data }) => data);
}

export function updateTask(params: Request.Tasks.UpdateTask) {
  return axios.patch<Response.Tasks.UpdateTask>(`/api/tasks/${params.task_id}`, params);
}

export function updateTasksByFilter(params: Request.Tasks.UpdateTasksByFilter) {
  return axios.patch<Response.Tasks.UpdateTasksByFilter>(`/api/tasks`, params);
}

export function pushTasksUpdatesToJiraByFilter(
  params: Request.Tasks.PushTasksUpdatesToJiraByFilter
) {
  return axios.post<Response.Tasks.UpdateTasksByFilter>(`/api/tasks/jira/push-updates`, params);
}

export function updateTaskFromTemplate(params: Request.Tasks.UpdateTaskFromTemplate) {
  return axios.patch<Response.Tasks.UpdateTaskFromTemplate>(
    `/api/tasks/${params.task_id}/from-template`,
    params
  );
}

export function deleteTask(taskId: number) {
  return axios.delete<Response.Tasks.DeleteTask>(`/api/tasks/${taskId}`);
}

export function restoreTask(taskId: number) {
  return axios.post<Response.Tasks.DeleteTask>(`/api/tasks/${taskId}/restore`);
}

export function getTasks(params: Request.Tasks.GetTasks) {
  return axios
    .get<Response.Tasks.GetTasks>("/api/tasks", {
      params,
      paramsSerializer: (params) => stringify(params),
    })
    .then(({ data }) => data);
}

export function getTasksForGantt(params: Request.Tasks.GetTasksForGantt) {
  return axios
    .get<Response.Tasks.GetTasksForGantt>("/api/tasks/gantt", { params })
    .then(({ data }) => data);
}

export function getTasksForFilter(params: Request.Tasks.GetTasksForFilter) {
  return axios
    .get<Response.Tasks.GetTasksForFilter>("/api/tasks/filter", { params })
    .then(({ data }) => data);
}

export function getTaskAssigneesForFilter(params: Request.Tasks.GetTaskAssigneesForFilter) {
  return axios
    .get<Response.Tasks.GetTaskAssigneesForFilter>("/api/tasks/assignees/filter", { params })
    .then(({ data }) => data);
}

export function getTaskStatusesForFilter() {
  return axios
    .get<Response.Tasks.GetTaskStatusesForFilter>("/api/tasks/statuses/filter")
    .then(({ data }) => data);
}
