import { Comment, Task, TemplateTask, User } from "@dh-critical-path/api-types";
import { CommentSource } from "@dh-critical-path/shared";
import React, {
  PropsWithChildren,
  createRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from "react";
import { UseFormReturn } from "react-hook-form";
import { ActionButton, Button } from "../../../../components/button";
import { Textarea } from "../../../../components/form";
import { TextareaProps } from "../../../../components/form/Textarea";
import { FormattedDateTime } from "../../../../components/formatting";
import JiraIcon from "../../../../components/icons/JiraIcon";
import { ProfileAvatar, ProfileAvatarPlaceholder } from "../../../../components/profile";
import { useAuth } from "../../../../hooks/useAuth";
import { CommentFormFields, useCommentForm, useComments } from "../../../../hooks/useComments";
import { useConfirmModal } from "../../../../hooks/useModal";

type TabCommentsProps = {
  task: Task | TemplateTask;
};

type CommentFormProps = {
  user: User;
  form: UseFormReturn<CommentFormFields>;
  canDiscard: boolean;
  isEditing: boolean;
  isSubmitting: boolean;
  isContentChanged: boolean;
  onSubmit: (data: CommentFormFields) => void;
  onDiscard: () => void;
};

type CommentListProps = {
  user: User;
  comments: Comment[];
  onEdit: (comment: Comment) => void;
  onDelete: (comment: Comment) => void;
};

type CommentListItemProps = {
  user: User;
  comment: Comment;
  onEdit: (comment: Comment) => void;
  onDelete: (comment: Comment) => void;
};

type CommentListItemContentProps = {
  comment: Comment;
};

interface CommentFormInputProps extends TextareaProps {
  alwaysExpanded: boolean;
}

const CommentFormInput = forwardRef<HTMLTextAreaElement, CommentFormInputProps>(
  ({ alwaysExpanded, ...props }, ref) => {
    const [expanded, setExpanded] = useState(false);

    const visibleRows = alwaysExpanded || expanded ? 5 : 1;

    return (
      <Textarea
        {...props}
        ref={ref}
        onFocus={() => setExpanded(true)}
        onBlur={() => setExpanded(false)}
        rows={visibleRows}
      />
    );
  }
);

function CommentForm({
  user,
  form,
  isEditing,
  isSubmitting,
  isContentChanged,
  canDiscard,
  onSubmit,
  onDiscard,
}: CommentFormProps) {
  const content = form.watch("content");
  const isEmpty = content.trim().length === 0;
  const alwaysExpanded = !isEmpty;

  const submitButtonRef = createRef<HTMLButtonElement>();

  function handleKeyDown(event: React.KeyboardEvent<HTMLFormElement>) {
    if (event.metaKey && event.key === "Enter") {
      submitButtonRef.current?.click();
    }
  }

  return (
    <form onKeyDown={handleKeyDown} className="flex" onSubmit={form.handleSubmit(onSubmit)}>
      <ProfileAvatar user={user} className="mr-3 m-2" />
      <div className="space-y-3 flex-1">
        <CommentFormInput
          {...form.register("content")}
          placeholder="Enter new comment"
          alwaysExpanded={alwaysExpanded || isContentChanged}
          invalid={Boolean(form.formState.errors.content)}
          invalidText={form.formState.errors.content?.message}
        />
        <div className="flex justify-end space-x-3">
          {canDiscard && (
            <Button variant="secondary" onClick={onDiscard}>
              Discard
            </Button>
          )}
          <Button
            ref={submitButtonRef}
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {isEditing ? "Save" : "Comment"}
          </Button>
        </div>
      </div>
    </form>
  );
}

function CommentJiraLink({ comment, children }: PropsWithChildren<CommentListItemContentProps>) {
  return (
    <a
      href={comment.jiraLink}
      target="_blank"
      rel="noreferrer"
      className="inline-flex items-center py-1 px-2 bg-beige-100 hover:bg-beige-400 rounded-md leading-tight whitespace-nowrap"
    >
      <span className="pr-1">{children}</span>
      <JiraIcon />
      <span className="pl-1">Jira</span>
    </a>
  );
}

function CommentListItemContent({ comment }: CommentListItemContentProps) {
  const lines = comment.content.split("\n");
  const limit = 7;

  if (comment.source === CommentSource.Jira && comment.jiraLink) {
    return (
      <>
        <div className="content">
          {lines.length > limit
            ? lines.slice(0, limit).join("\n").trimEnd() + "…"
            : comment.content}
        </div>
        <div className="mt-2">
          <CommentJiraLink comment={comment}>
            View {lines.length > limit ? "full" : ""} comment in
          </CommentJiraLink>
        </div>
      </>
    );
  } else {
    return <div className="content">{comment.content}</div>;
  }
}

function CommentListItem({ user, comment, onEdit, onDelete }: CommentListItemProps) {
  return (
    <div className="flex flex-none group border-b border-stone-100 last:border-0 last:pb-0 first:pt-0 py-6">
      {comment.createdByUser ? (
        <ProfileAvatar user={comment.createdByUser as User} className="mr-3" />
      ) : (
        <ProfileAvatarPlaceholder
          tooltipText={comment.source === CommentSource.Jira ? "Jira" : undefined}
          className="mr-3"
        />
      )}
      <div className="relative w-full">
        <div className="flex items-center justify-between w-full mb-1">
          <p className="text-iron text-sm">
            <FormattedDateTime date={new Date(comment.created_at)} />
          </p>
          {comment.source === CommentSource.ProPlan && user.id === comment.createdByUser?.id && (
            <div className="flex md:invisible group-hover:visible items-center space-x-3 pl-3">
              <ActionButton variant="edit" onClick={() => onEdit(comment)} />
              <ActionButton variant="delete" onClick={() => onDelete(comment)} />
            </div>
          )}
        </div>
        <CommentListItemContent comment={comment} />
      </div>
    </div>
  );
}

function CommentList({ user, comments, onEdit, onDelete }: CommentListProps) {
  return (
    <div>
      {comments.map((comment) => (
        <CommentListItem
          key={comment.id}
          user={user}
          comment={comment}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}

const discardCommentContentProps = {
  title: "Discard comment?",
};

const deleteCommentProps = {
  title: "Delete comment?",
};

export function TabComments({ task }: TabCommentsProps) {
  const { user } = useAuth();
  const { comments } = useComments(task);
  const {
    form,
    editingComment,
    isContentChanged,
    isSubmitting,
    handleSubmit,
    handleEdit,
    handleDelete,
    handleDiscard,
  } = useCommentForm(task);
  const { modal, openModal } = useConfirmModal();

  const handleEditWithConfirmation = useCallback(
    (comment: Comment) => {
      if (isContentChanged) {
        openModal(() => handleEdit(comment), discardCommentContentProps);
      } else {
        handleEdit(comment);
      }
    },
    [handleEdit, isContentChanged, openModal]
  );

  const handleDiscardWithConfirmation = useCallback(() => {
    if (isContentChanged) {
      openModal(() => handleDiscard(), discardCommentContentProps);
    } else {
      handleDiscard();
    }
  }, [handleDiscard, isContentChanged, openModal]);

  const handleDeleteWithConfirmation = useCallback(
    (comment: Comment) => {
      openModal(() => handleDelete(comment), deleteCommentProps);
    },
    [handleDelete, openModal]
  );

  return useMemo(() => {
    if (!user) {
      return null;
    }

    return (
      <div className="space-y-8">
        <CommentForm
          user={user}
          form={form}
          canDiscard={isContentChanged || Boolean(editingComment)}
          isContentChanged={isContentChanged}
          isEditing={Boolean(editingComment)}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          onDiscard={handleDiscardWithConfirmation}
        />
        <CommentList
          user={user}
          comments={comments}
          onEdit={handleEditWithConfirmation}
          onDelete={handleDeleteWithConfirmation}
        />
        {modal}
      </div>
    );
  }, [
    user,
    form,
    modal,
    isContentChanged,
    editingComment,
    isSubmitting,
    handleSubmit,
    handleDiscardWithConfirmation,
    comments,
    handleEditWithConfirmation,
    handleDeleteWithConfirmation,
  ]);
}
