import { User } from "@dh-critical-path/api-types";
import { prefixUserId } from "@dh-critical-path/shared";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useMutation } from "react-query";
import { impersonate } from "../../api";
import { ActionButton, Button } from "../../components/button";
import { Badge } from "../../components/common";
import {
  SlideOverButtons,
  SlideOverContent,
  SlideOverHeader,
  SlideOverSidebar,
  SlideOverSidebarField,
  SlideOverTitle,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useActionWithConfirmation } from "../../components/modals";
import { userRoleBadgeColor } from "../../enums";
import { AdminOnly, useAuth } from "../../hooks/useAuth";
import { useUserRemove } from "../../hooks/useUser";
import { useTasksQuery, useTemplateTasksQuery, useUserQuery } from "../../queries";
import { useActionCompleted } from "../../utils/hooks";
import { fullName } from "../../utils/text";
import { useSlideOverNavigate } from "../SlideOvers";
import { useNavigateToUserEdit } from "../utils/navigation";
import { AffectedTasksDetails } from "./components/AffectedTasksDetails";

type UserSlideOverProps = {
  userId: number;
};

type UserViewProps = {
  user: User;
};

export function useUserRemovingConfirmationDialog(handleRemove: (user: User) => Promise<any>) {
  const { modal, handleWithConfirmation } = useActionWithConfirmation(
    handleRemove,
    async (user) => ({
      description: <UserRemovingConfirmationText user={user} />,
    })
  );

  return { modal, handleRemoveWithConfirmation: handleWithConfirmation };
}

function UserRemovingConfirmationText({ user }: { user: User }) {
  const tasksQuery = useTasksQuery({
    assignee_ids: [prefixUserId(user.id)],
    limit: 1,
  });

  const templateTasksQuery = useTemplateTasksQuery({
    assignee_ids: [prefixUserId(user.id)],
    limit: 1,
  });

  return (
    <div>
      <p>Do you really want to remove this user?</p>
      <AffectedTasksDetails
        totalTasks={tasksQuery.data?.meta.total ?? 0}
        totalTemplateTasks={templateTasksQuery.data?.meta.total ?? 0}
      />
    </div>
  );
}

function UserDeleteAction({ user }: UserViewProps) {
  const navigate = useSlideOverNavigate();

  const { handleRemove, isLoading } = useUserRemove();

  const handleRemoveAndRedirect = useActionCompleted(handleRemove, () => navigate());

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useUserRemovingConfirmationDialog(handleRemoveAndRedirect);

  return (
    <>
      <ActionButton
        variant="delete"
        size="lg"
        rounding="base"
        onClick={() => handleRemoveWithConfirmation(user)}
        loading={isLoading}
      />
      {confirmModal}
    </>
  );
}

function UserEditAction({ user }: UserViewProps) {
  const navigateToEdit = useNavigateToUserEdit();

  return (
    <Button variant="secondary" onClick={() => navigateToEdit(user)}>
      <FormattedMessage id="modal.edit" />
    </Button>
  );
}

function UserImpersonateAction({ user }: UserViewProps) {
  const auth = useAuth();

  const { mutateAsync, isLoading, isSuccess } = useMutation(impersonate);

  async function handleImpersonate() {
    auth.login(await mutateAsync(user.id));
    window.location.href = "/";
  }

  return (
    <Button
      variant="secondary"
      onClick={handleImpersonate}
      loading={isLoading || isSuccess}
      disabled={isLoading || isSuccess}
    >
      Login as
    </Button>
  );
}

function UserSlideOverSidebarFields({ user }: UserViewProps) {
  return (
    <>
      <SlideOverSidebarField label="First Name" value={user.first_name} />
      <SlideOverSidebarField label="Last Name" value={user.last_name} />
      <SlideOverSidebarField label="Email" value={user.email} />
      <SlideOverSidebarField
        label="Role"
        value={
          user.sso_role ? (
            <Badge size="small" color={userRoleBadgeColor(user.sso_role)} rounded>
              {user.sso_role}
            </Badge>
          ) : null
        }
      />
      <SlideOverSidebarField
        label="Last Login Date"
        value={
          user.last_logged_in_at ? (
            <FormattedDate
              value={user.last_logged_in_at}
              year="numeric"
              month="short"
              day="2-digit"
            />
          ) : null
        }
      />
    </>
  );
}

function UserView({ user }: UserViewProps) {
  return (
    <>
      <SlideOverHeader>
        <SlideOverTitle>{fullName(user)}</SlideOverTitle>
        <SlideOverButtons>
          <UserDeleteAction user={user} />
          <UserEditAction user={user} />
          <AdminOnly render={() => <UserImpersonateAction user={user} />} />
        </SlideOverButtons>
      </SlideOverHeader>
      <SlideOverContent>
        <SlideOverSidebar>
          <UserSlideOverSidebarFields user={user} />
        </SlideOverSidebar>
      </SlideOverContent>
    </>
  );
}

export function UserSlideOver({ userId }: UserSlideOverProps) {
  const result = useUserQuery(userId);

  return <SlideOverWithLoading result={result} render={(user) => <UserView user={user} />} />;
}
