import { SsoUserRole } from "@dh-critical-path/shared";
import { useIntl } from "react-intl";
import { ControlledInput, ControlledSelect } from "../../../../components/form/controlled";
import { userRoleBackgroundColor } from "../../../../enums";

export function EmailField() {
  const { formatMessage } = useIntl();

  return (
    <ControlledInput
      name="email"
      label={formatMessage({ id: "users.form.email" })}
      placeholder={formatMessage({ id: "users.form.email.placeholder" })}
      required
    />
  );
}

export function SsoRoleField() {
  const { formatMessage } = useIntl();

  return (
    <ControlledSelect
      name="sso_role"
      label={formatMessage({ id: "users.form.sso-role" })}
      placeholder={formatMessage({ id: "users.form.sso-role.placeholder" })}
      options={[
        {
          id: SsoUserRole.ADMINISTRATOR,
          name: "Administrator",
          color: userRoleBackgroundColor(SsoUserRole.ADMINISTRATOR),
        },
        {
          id: SsoUserRole.LOCAL_DEPARTMENT,
          name: "Local Department",
          color: userRoleBackgroundColor(SsoUserRole.LOCAL_DEPARTMENT),
        },
        {
          id: SsoUserRole.GLOBAL_DEPARTMENT,
          name: "Global Department",
          color: userRoleBackgroundColor(SsoUserRole.GLOBAL_DEPARTMENT),
        },
      ]}
      isClearable
    />
  );
}
