import {
  Department,
  Project,
  Request,
  Task,
  TemplateDepartment,
  TemplateProject,
  TemplateTask,
  TemplateTitle,
  Title,
  User,
} from "@dh-critical-path/api-types";
import { useNavigate } from "react-router";
import { RouteKey, slideOverRoute } from "../SlideOvers";

export function projectLinkFactory() {
  return function (project: Project) {
    return `/projects/${project.id}`;
  };
}

export function projectDepartmentLinkFactory(project: Project) {
  return function (department: Department) {
    return `/projects/${project.id}/departments/${department.id}`;
  };
}

export function departmentLinkFactory() {
  return function (department: Department) {
    return `/projects/departments/${department.id}`;
  };
}

export function templateProjectLinkFactory() {
  return function (project: Pick<TemplateProject, "id">) {
    return `/templates/${project.id}`;
  };
}

export function templateDepartmentLinkFactory() {
  return function (department: Pick<TemplateDepartment, "id">) {
    return `/templates/departments/${department.id}`;
  };
}

export function templateProjectDepartmentLinkFactory(project: Pick<TemplateProject, "id">) {
  return function (department: Pick<TemplateDepartment, "id">) {
    return `/templates/${project.id}/departments/${department.id}`;
  };
}

export function useNavigateToProjects() {
  const navigate = useNavigate();

  return function () {
    navigate(`/projects`);
  };
}

export function useNavigateToProject() {
  const navigate = useNavigate();

  return function (project: Pick<Project, "id">) {
    navigate(`/projects/${project.id}`);
  };
}

export function useNavigateToProjectCreate() {
  const navigate = useNavigate();

  return function () {
    navigate(slideOverRoute(RouteKey.ProjectCreate, {}));
  };
}

export function useNavigateToProjectEdit() {
  const navigate = useNavigate();

  return function (project: Pick<Project, "id">) {
    navigate(
      slideOverRoute(RouteKey.ProjectEdit, {
        projectId: project.id,
      })
    );
  };
}

export function useNavigateToTask() {
  const navigate = useNavigate();

  return function (task: Pick<Task, "id">) {
    navigate(
      slideOverRoute(RouteKey.Task, {
        taskId: task.id,
      })
    );
  };
}

export function useNavigateToTaskCreate() {
  const navigate = useNavigate();

  return function (project: Pick<Project, "id">, department?: Pick<Department, "id">) {
    navigate(
      slideOverRoute(RouteKey.TaskCreate, {
        projectId: project.id,
        departmentId: department?.id,
      })
    );
  };
}

export function useNavigateToTaskEdit() {
  const navigate = useNavigate();

  return function (task: Pick<Task, "id">) {
    navigate(
      slideOverRoute(RouteKey.TaskEdit, {
        taskId: task.id,
      })
    );
  };
}

export function useNavigateToTasksMassUpdate() {
  const navigate = useNavigate();

  return function (filter: Request.Tasks.GetTasks) {
    navigate(
      slideOverRoute(RouteKey.TasksMassUpdate, {
        filter: Buffer.from(JSON.stringify(filter)).toString("base64"),
      })
    );
  };
}

export function useNavigateToProjectDepartments() {
  const navigate = useNavigate();

  return function (department: Department) {
    navigate(
      department.project_id ? `/projects/${department.project_id}/departments` : `/projects`
    );
  };
}

export function useNavigateToDepartment() {
  const navigate = useNavigate();

  return function (department: Department) {
    navigate(
      department.project_id
        ? `/projects/${department.project_id}/departments/${department.id}`
        : `/projects/departments/${department.id}`
    );
  };
}

export function useNavigateToDepartmentCreate() {
  const navigate = useNavigate();

  return function (project?: Project) {
    navigate(slideOverRoute(RouteKey.DepartmentCreate, { projectId: project?.id }));
  };
}

export function useNavigateToDepartmentEdit() {
  const navigate = useNavigate();

  return function (department: Department) {
    navigate(
      slideOverRoute(RouteKey.DepartmentEdit, {
        departmentId: department.id,
      })
    );
  };
}

export function useNavigateToDepartmentMembersAdd() {
  const navigate = useNavigate();

  return function (department: Department) {
    navigate(
      slideOverRoute(RouteKey.DepartmentMembersAdd, {
        departmentId: department.id,
      })
    );
  };
}

export function useNavigateToTitleCreate() {
  const navigate = useNavigate();

  return function (department: Pick<Department, "id">) {
    navigate(
      slideOverRoute(RouteKey.TitleCreate, {
        departmentId: department.id,
      })
    );
  };
}

export function useNavigateToTitleEdit() {
  const navigate = useNavigate();

  return function (title: Pick<Title, "id">) {
    navigate(
      slideOverRoute(RouteKey.TitleEdit, {
        titleId: title.id,
      })
    );
  };
}

export function useNavigateToTemplateProjects() {
  const navigate = useNavigate();

  return function () {
    navigate(`/templates`);
  };
}

export function useNavigateToTemplateDepartments() {
  const navigate = useNavigate();

  return function (department: Pick<TemplateDepartment, "id" | "template_project_id">) {
    navigate(
      department.template_project_id
        ? `/templates/${department.template_project_id}/departments`
        : `/templates/departments`
    );
  };
}

export function useNavigateToTemplateDepartment() {
  const navigate = useNavigate();

  return function (department: Pick<TemplateDepartment, "id" | "template_project_id">) {
    navigate(
      department.template_project_id
        ? `/templates/${department.template_project_id}/departments/${department.id}`
        : `/templates/departments/${department.id}`
    );
  };
}

export function useNavigateToTemplateDepartmentCreate() {
  const navigate = useNavigate();

  return function (project?: Pick<TemplateProject, "id">) {
    navigate(slideOverRoute(RouteKey.TemplateDepartmentCreate, { templateProjectId: project?.id }));
  };
}

export function useNavigateToTemplateDepartmentEdit() {
  const navigate = useNavigate();

  return function (department: Pick<TemplateDepartment, "id">) {
    navigate(
      slideOverRoute(RouteKey.TemplateDepartmentEdit, {
        templateDepartmentId: department.id,
      })
    );
  };
}

export function useNavigateToTemplateDepartmentMembersAdd() {
  const navigate = useNavigate();

  return function (department: Pick<TemplateDepartment, "id">) {
    navigate(
      slideOverRoute(RouteKey.TemplateDepartmentMembersAdd, {
        templateDepartmentId: department.id,
      })
    );
  };
}

export function useNavigateToTemplateTitleCreate() {
  const navigate = useNavigate();

  return function (department: Pick<TemplateDepartment, "id">) {
    navigate(
      slideOverRoute(RouteKey.TemplateTitleCreate, {
        templateDepartmentId: department.id,
      })
    );
  };
}

export function useNavigateToTemplateTitleEdit() {
  const navigate = useNavigate();

  return function (title: Pick<TemplateTitle, "id">) {
    navigate(
      slideOverRoute(RouteKey.TemplateTitleEdit, {
        templateTitleId: title.id,
      })
    );
  };
}

export function useNavigateToTemplateTaskCreate() {
  const navigate = useNavigate();

  return function (
    project: Pick<TemplateProject, "id">,
    department?: Pick<TemplateDepartment, "id">
  ) {
    navigate(
      slideOverRoute(RouteKey.TemplateTaskCreate, {
        templateProjectId: project.id,
        templateDepartmentId: department?.id,
      })
    );
  };
}

export function useNavigateToTemplateTaskEdit() {
  const navigate = useNavigate();

  return function (task: Pick<TemplateTask, "id">) {
    navigate(
      slideOverRoute(RouteKey.TemplateTaskEdit, {
        templateTaskId: task.id,
      })
    );
  };
}

export function useNavigateToTemplateTask() {
  const navigate = useNavigate();

  return function (task: Pick<TemplateTask, "id">) {
    navigate(
      slideOverRoute(RouteKey.TemplateTask, {
        templateTaskId: task.id,
      })
    );
  };
}

export function useNavigateToTemplateProject() {
  const navigate = useNavigate();

  return function (project: Pick<TemplateProject, "id">) {
    navigate(`/templates/${project.id}`);
  };
}

export function useNavigateToTemplateProjectCreate() {
  const navigate = useNavigate();

  return function () {
    navigate(slideOverRoute(RouteKey.TemplateProjectCreate, {}));
  };
}

export function useNavigateToTemplateProjectEdit() {
  const navigate = useNavigate();

  return function (project: Pick<TemplateProject, "id">) {
    navigate(
      slideOverRoute(RouteKey.TemplateProjectEdit, {
        templateProjectId: project.id,
      })
    );
  };
}

export function useNavigateToTemplateTasksToProjectTasksChanges() {
  const navigate = useNavigate();

  return function (template: Pick<TemplateProject, "id">, project: Pick<Project, "id">) {
    navigate(`/templates/${template.id}/compare/${project.id}`);
  };
}

export function useNavigateToUser() {
  const navigate = useNavigate();

  return function (user: Pick<User, "id">) {
    navigate(
      slideOverRoute(RouteKey.User, {
        userId: user.id,
      })
    );
  };
}

export function useNavigateToUserAdd() {
  const navigate = useNavigate();

  return function () {
    navigate(slideOverRoute(RouteKey.UserAdd, {}));
  };
}

export function useNavigateToUserEdit() {
  const navigate = useNavigate();

  return function (user: Pick<User, "id">) {
    navigate(
      slideOverRoute(RouteKey.UserEdit, {
        userId: user.id,
      })
    );
  };
}
