import { Project } from "@dh-critical-path/api-types";
import { ResourceIdPrefix, prefixResourceId } from "@dh-critical-path/shared";
import { FormattedMessage } from "react-intl";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useAuth } from "../../hooks/useAuth";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { DepartmentsTasksStatsCards } from "../Departments/components/DepartmentsTasksStatsCards";
import { TasksGroup } from "../Tasks/components/TasksGroup";
import { projectDepartmentLinkFactory } from "../utils/navigation";
import { ProjectDatesPanel } from "./components/ProjectDates";
import { ProjectHeader } from "./components/ProjectHeader";
import { ProjectKeyValuesPanel } from "./components/ProjectKeyValues";
import { ProjectTasksStats } from "./components/ProjectTasksStats";

type ProjectOverviewPageProps = {
  project: Project;
};

function ProjectOverviewPage({ project }: ProjectOverviewPageProps) {
  useDocumentTitle(`${project.name} - Overview`);

  const { user } = useAuth();

  return (
    <PageContainer>
      {/* overall status */}
      <Layout.Column width={12}>
        {/* header */}
        <ProjectHeader project={project} />
        {/* status */}
        <Layout.Spacer space={4}>
          <PageSectionHeader>
            <FormattedMessage id="headings.project.overall-status" />
          </PageSectionHeader>
          <ProjectTasksStats project={project} />
        </Layout.Spacer>
      </Layout.Column>
      {/* key values & completion date */}
      <Layout.Column width={12}>
        <Layout.Spacer space={4}>
          <PageSectionHeader>
            <FormattedMessage id="headings.project.information" />
          </PageSectionHeader>
          <PageContainer type="grid">
            <ProjectKeyValuesPanel project={project} />
            <ProjectDatesPanel project={project} />
          </PageContainer>
        </Layout.Spacer>
      </Layout.Column>
      {/* tasks */}
      <Layout.Column width={12}>
        <Layout.Spacer space={8}>
          <TasksGroup
            filter={{
              project_ids: [project.id],
              assignee_ids: [prefixResourceId(user!.id, ResourceIdPrefix.USER)],
            }}
            header={(count) => (
              <PageSectionHeader>
                <FormattedMessage id="headings.my-tasks" values={{ count }} />
              </PageSectionHeader>
            )}
          />
          <TasksGroup
            filter={{ project_ids: [project.id], is_overdue: true }}
            header={(count) => (
              <PageSectionHeader isDanger>
                <FormattedMessage id="headings.tasks.overdue" values={{ count }} />
              </PageSectionHeader>
            )}
          />
        </Layout.Spacer>
      </Layout.Column>
      {/* departments */}
      <Layout.Column width={12}>
        <Layout.Spacer space={4}>
          <DepartmentsTasksStatsCards
            filter={{ project_ids: [project.id], is_global: true }}
            entityLink={projectDepartmentLinkFactory(project)}
            header={(count) => (
              <PageSectionHeader>
                <FormattedMessage id="headings.project.departments-global" values={{ count }} />
              </PageSectionHeader>
            )}
          />
          <DepartmentsTasksStatsCards
            filter={{ project_ids: [project.id], is_local: true }}
            entityLink={projectDepartmentLinkFactory(project)}
            header={(count) => (
              <PageSectionHeader>
                <FormattedMessage id="headings.project.departments-local" values={{ count }} />
              </PageSectionHeader>
            )}
          />
        </Layout.Spacer>
      </Layout.Column>
    </PageContainer>
  );
}

export default ProjectOverviewPage;
