import { Task, TemplateTask } from "@dh-critical-path/api-types";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { NavigationTab, NavigationTabs } from "../../../components/common";
import { SlideOverBody } from "../../../components/form/SlideOver";
import JiraIcon from "../../../components/icons/JiraIcon";
import { AdminOnly } from "../../../hooks/useAuth";
import { isTask } from "../../../utils/misc";
import { RouteKey, slideOverRoute } from "../../SlideOvers";
import { TabComments } from "./tabs/TabComments";
import { TabOverview } from "./tabs/TabOverview";

interface TaskSlideOverTabsProps {
  task: Task | TemplateTask;
}

enum TaskTab {
  Overview = "Overview",
  Comments = "Comments",
  Activity = "Activity",
}

function resolveCurrentTab(searchParams: URLSearchParams): TaskTab {
  return (searchParams.get("tab") as TaskTab) ?? TaskTab.Overview;
}

function cloneSearchParamsWithoutTab(searchParams: URLSearchParams): URLSearchParams {
  const clonedSearchParams = new URLSearchParams(searchParams.toString());

  clonedSearchParams.delete("tab");

  return clonedSearchParams;
}

function useTaskTabs(task: Task | TemplateTask): [TaskTab, React.ReactNode] {
  const [searchParams] = useSearchParams();

  const currentTab = resolveCurrentTab(searchParams);

  const tabs = useMemo(() => {
    const searchStringWithoutTab = cloneSearchParamsWithoutTab(searchParams).toString();

    return (
      <NavigationTabs>
        <NavigationTab
          to={`?${searchStringWithoutTab}&tab=${TaskTab.Overview}`}
          isActive={currentTab === TaskTab.Overview}
          data-cy="task-navigation-overview-link"
        >
          Overview
        </NavigationTab>
        <NavigationTab
          to={`?${searchStringWithoutTab}&tab=${TaskTab.Comments}`}
          isActive={currentTab === TaskTab.Comments}
          data-cy="task-navigation-comments-link"
        >
          Comments ({task.meta.comments_count})
        </NavigationTab>
        {/* TODO: not implemented */}
        {/* <NavigationTab
          to={`?${searchStringWithoutTab}&tab=${TaskTab.Activity}`}
          isActive={currentTab === TaskTab.Activity}
          data-cy="task-navigation-activity-link"
        >
          Activity
        </NavigationTab> */}
        {isTask(task) && task.jiraLink && (
          <NavigationTab
            icon={<JiraIcon />}
            to={task.jiraLink}
            target="_blank"
            data-cy="task-navigation-jira-link"
          >
            {task.jira_key}
          </NavigationTab>
        )}
        <AdminOnly
          render={() => (
            <>
              {isTask(task) && task.template_task_id && task.project.template_project_id && (
                <NavigationTab
                  to={`/templates/${task.project.template_project_id}/tasks/${slideOverRoute(
                    RouteKey.TemplateTask,
                    { templateTaskId: task.template_task_id }
                  )}`}
                  target="_blank"
                  data-cy="task-navigation-template"
                >
                  Template
                </NavigationTab>
              )}
            </>
          )}
        />
      </NavigationTabs>
    );
  }, [searchParams, task, currentTab]);

  return [currentTab, tabs];
}

export const TaskSlideOverTabs: React.VFC<TaskSlideOverTabsProps> = ({ task }) => {
  const [currentTab, tabs] = useTaskTabs(task);

  return (
    <SlideOverBody>
      {tabs}
      {currentTab === TaskTab.Overview && <TabOverview task={task} />}
      {currentTab === TaskTab.Comments && <TabComments task={task} />}
      {/* TODO: not implemented */}
      {/* {currentTab === TaskTab.Activity && <TabActivity task={task} />} */}
    </SlideOverBody>
  );
};
