import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { Button } from "../../components/button";
import { PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import {
  templateDepartmentLinkFactory,
  useNavigateToTemplateDepartmentCreate,
} from "../utils/navigation";
import { TemplateDepartmentsTasksStatsCards } from "./components/TemplateDepartmentsTasksStatsCards";
import { TemplateProjectsPageLayout } from "./components/TemplateProjectsPageLayout";

export function TemplateDepartmentsPage() {
  useDocumentTitle("Global Template Departments");

  const navigateToCreationView = useNavigateToTemplateDepartmentCreate();

  return (
    <TemplateProjectsPageLayout title={<FormattedMessage id="navigation.departments" />}>
      <PageContainer>
        <TemplateDepartmentsTasksStatsCards
          filter={{ is_global: true }}
          entityLink={templateDepartmentLinkFactory()}
          header={(count) => (
            <PageSectionHeader
              buttons={
                <Button icon={PlusIcon} onClick={() => navigateToCreationView()}>
                  <FormattedMessage id="departments.create" />
                </Button>
              }
            >
              <FormattedMessage id="headings.departments.global" values={{ count }} />
            </PageSectionHeader>
          )}
          showHeaderAsFallback={true}
        />
      </PageContainer>
    </TemplateProjectsPageLayout>
  );
}
