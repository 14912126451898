import { XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import React, { HTMLAttributes, InputHTMLAttributes } from "react";
import { FormElementProps, Label, ValidationText } from ".";
import { nextId } from "../../utils/nextId";
import { styled } from "../../utils/styled";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, FormElementProps {
  leading?: "base" | "none";
  iconLeft?: React.ElementType;
  iconRight?: React.ElementType;
  isClearable?: boolean;
}

interface IconProps {
  icon: React.ElementType;
  align: "left" | "right";
}

const InputStyled = styled<HTMLInputElement, InputProps>(
  ({ disabled, invalid, className, type = "text", leading, iconLeft, iconRight, ...props }) => (
    <input
      {...props}
      autoComplete="off"
      type={type}
      disabled={disabled}
      className={classnames(
        "input shadow-sm block w-full text-base text-black rounded-md placeholder-stone",
        {
          "opacity-60 bg-gray-100": disabled,
          "border-stone-400 focus:ring-beige focus:border-beige hover:border-beige": !invalid,
          "border-red-300 text-red-600 focus:ring-0 focus:border-red-300": invalid,
          "leading-none border-none focus:ring-2": leading === "none",
          "pl-10": iconLeft,
          "pr-16": iconRight,
        },
        className
      )}
    />
  )
);

const Icon = styled<HTMLElement, IconProps>(({ icon: Component, align, ...props }) => (
  <div
    className={classnames("w-10 h-full flex items-center text-stone absolute top-0", {
      "left-0.5 justify-center": align === "left",
      "right-0": align === "right",
    })}
  >
    <Component {...props} />
  </div>
));

const ClearButton: React.VFC<HTMLAttributes<HTMLButtonElement>> = ({ ...props }) => {
  return (
    <button
      {...props}
      type="button"
      className="input-clear-button z-10 h-full flex items-center absolute top-0 right-3 hover:opacity-50"
    >
      <XIcon className="w-5 h-5 text-stone" />
    </button>
  );
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id = nextId("input"),
      label,
      invalid,
      invalidText,
      iconLeft,
      iconRight,
      disabled,
      type,
      required,
      isClearable,
      ...props
    },
    ref
  ) => {
    const clearInputHandler = () => {
      if (!props.onChange) {
        return;
      }
      props.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
    };
    return (
      <div>
        {label && (
          <Label htmlFor={id} disabled={disabled} required={required} className="mb-1">
            {label}
          </Label>
        )}
        <div className="relative">
          {iconLeft && (
            <label htmlFor={id}>
              <Icon icon={iconLeft} align="left" />
            </label>
          )}
          <InputStyled
            {...props}
            ref={ref}
            id={id}
            type={type}
            invalid={invalid}
            disabled={disabled}
            iconLeft={iconLeft}
            iconRight={iconRight}
          />
          {isClearable && <ClearButton onClick={clearInputHandler} />}
          {iconRight && (
            <label htmlFor={id}>
              <Icon icon={iconRight} align="right" />
            </label>
          )}
        </div>
        {invalid && invalidText && <ValidationText className="mt-1">{invalidText}</ValidationText>}
      </div>
    );
  }
);

export default Input;
