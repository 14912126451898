import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
} from "../../components/form/SlideOver";
import { useTitleCreateForm } from "../../hooks/useTitle";
import { useSlideOverNavigate } from "../SlideOvers";
import { TitleForm } from "./components/forms/TitleForm";

type TitleCreateSlideOverProps = {
  departmentId: number;
};

type TitleCreateFormProps = {
  departmentId: number;
};

export function TitleCreateForm({ departmentId }: TitleCreateFormProps) {
  const slideOverNavigate = useSlideOverNavigate();

  const { methods, handleSubmit } = useTitleCreateForm(departmentId, () => {
    slideOverNavigate();
  });

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="titles.modal.heading.create" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <TitleForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TitleCreateSlideOver({ departmentId }: TitleCreateSlideOverProps) {
  return <TitleCreateForm departmentId={departmentId} />;
}
