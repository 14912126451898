import { CustomElement, Descendant, Text } from "slate";

function encodeSpecialCharacters(text: string) {
  return text.replace(/([*_<>/#~])/g, "\\$1");
}

function decodeSpecialCharacters(text: string) {
  return text.replace(/(\\([*_<>/#~]))/g, "$2");
}

export function serialize(value: Descendant[]): string {
  return serializeRecursive(value).replace(/\n\n$/gs, "\n");
}

function serializeRecursive(value: Descendant[]): string {
  return value
    .map((node) => {
      if (Text.isText(node)) {
        const escapedText = encodeSpecialCharacters(node.text);

        if (node.bold) {
          return `**${escapedText}**`;
        } else if (node.italic) {
          return `_${escapedText}_`;
        } else if (node.underline) {
          return `<ins>${escapedText}</ins>`;
        } else if (node.strikethrough) {
          return `~~${escapedText}~~`;
        } else {
          return escapedText;
        }
      }

      let children = serializeRecursive(node.children);

      switch (node.type) {
        case "paragraph":
          return `${children}\n`;
        case "check-list-item":
          return `- [${node.checked ? "x" : " "}] ${children}\n`;
        case "bulleted-list":
          return `${children}`;
        case "list-item":
          return `- ${children}\n`;
        case "link":
          return `[${children}](${node.url})`;
        default:
          return children;
      }
    })
    .join("");
}

export function deserialize(markdown: string): Descendant[] {
  const nodes: Descendant[] = [];
  const lines = markdown.split("\n");

  const checkListItemRe = /^-\s\[(x|\s)\]\s(.*?)$/;
  const bulletListItemRe = /^-\s(.*?)$/;

  let matches;

  const readBulletedList = (i: number) => {
    const children: Descendant[] = [];

    for (let matches; i < lines.length; i++) {
      if ((matches = lines[i].match(bulletListItemRe))) {
        children.push({
          type: "list-item",
          children: deserializeLine(matches[1]),
        });
      } else {
        break;
      }
    }

    return children;
  };

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    if ((matches = line.match(checkListItemRe))) {
      const [, checked, text] = matches;
      nodes.push({
        type: "check-list-item",
        checked: checked === "x",
        children: deserializeLine(text),
      });
    } else if ((matches = line.match(bulletListItemRe))) {
      const children = readBulletedList(i);
      i += children.length - 1;
      nodes.push({ type: "bulleted-list", children });
    } else {
      nodes.push({ type: "paragraph", children: deserializeLine(line) });
    }
  }

  return nodes.map((node: Descendant) => {
    deserializeSpecialCharacters(node);
    return node;
  });
}

function deserializeSpecialCharacters(node: Descendant) {
  if ((node as CustomElement).children) {
    (node as CustomElement).children.forEach(deserializeSpecialCharacters);
  }

  if ((node as Text).text) {
    (node as Text).text = decodeSpecialCharacters((node as Text).text);
  }
}

function deserializeLine(line: string): Descendant[] {
  const nodes: Descendant[] = [];
  const boldRe = /^\*\*(.*?)\*\*/;
  const italicRe = /^_(.*?)_/;
  const underlinedRe = /^<ins>(.*?)<\/ins>/;
  const strikethroughRe = /^~~(.*?)~~/;
  const linkRe = /^\[(.*?)\]\((.*?)\)/;

  let matches;
  let text: Descendant = { text: "" };

  const flushNode = (node: Descendant) => {
    if ((text as Text).text) {
      nodes.push(text);
      text = { text: "" };
    }
    nodes.push(node);
  };

  for (let i = 0; i < line.length; i++) {
    const substring = line.substring(i);
    if ((matches = substring.match(boldRe))) {
      i += matches[0].length - 1;
      flushNode({ text: matches[1], bold: true });
    } else if ((matches = substring.match(italicRe))) {
      i += matches[0].length - 1;
      flushNode({ text: matches[1], italic: true });
    } else if ((matches = substring.match(underlinedRe))) {
      i += matches[0].length - 1;
      flushNode({ text: matches[1], underline: true });
    } else if ((matches = substring.match(strikethroughRe))) {
      i += matches[0].length - 1;
      flushNode({ text: matches[1], strikethrough: true });
    } else if ((matches = substring.match(linkRe))) {
      i += matches[0].length - 1;
      flushNode({ type: "link", url: matches[2], children: deserializeLine(matches[1]) });
    } else {
      text.text += line.charAt(i);
    }
  }

  if (text.text) {
    nodes.push(text);
  }

  return nodes.length ? nodes : [{ text: "" }];
}
