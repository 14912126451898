import { TemplateTask } from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { ActionButton, Button } from "../../components/button";
import { Hash } from "../../components/common";
import {
  SlideOverContent,
  SlideOverHeader,
  SlideOverSidebar,
  SlideOverSidebarField,
  SlideOverSidebarProfileListValue,
  SlideOverSidebarProfileValue,
  SlideOverSidebarTagsValue,
  SlideOverSidebarTaskListValue,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import {
  useTaskOrTemplateTaskRemovingConfirmationDialog,
  useTaskOrTemplateTaskRemovingErrorHandling,
  useTaskOrTemplateTaskRestoringConfirmationDialog,
} from "../../components/modals";
import {
  useTemplateTaskRemoveAction,
  useTemplateTaskRestoreAction,
} from "../../hooks/useTemplateTask";
import { useTemplateTaskQuery } from "../../queries";
import { processTypeText } from "../../utils/text";
import { RouteKey, slideOverRoute } from "../SlideOvers";
import { TaskSlideOverTabs } from "../Tasks/components/TaskSlideOverTabs";
import { useNavigateToTemplateTaskEdit } from "../utils/navigation";

type TemplateTaskSlideOverProps = {
  templateTaskId: number;
};

type TemplateTaskViewProps = {
  task: TemplateTask;
};

function TemplateTaskViewDeleteAction({ task }: TemplateTaskViewProps) {
  const { handleRemove, isLoading } = useTemplateTaskRemoveAction();

  const { modal: errorModal, handleRemoveWithErrorHandling } =
    useTaskOrTemplateTaskRemovingErrorHandling(handleRemove);

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useTaskOrTemplateTaskRemovingConfirmationDialog(handleRemoveWithErrorHandling);

  if (task.deleted_at) {
    return null;
  }

  return (
    <>
      <ActionButton
        variant="delete"
        size="lg"
        rounding="base"
        onClick={() => handleRemoveWithConfirmation(task)}
        loading={isLoading}
      />
      {confirmModal}
      {errorModal}
    </>
  );
}

function TemplateTaskViewRestoreAction({ task }: TemplateTaskViewProps) {
  const { handleRestore, isLoading } = useTemplateTaskRestoreAction();

  const { modal, handleRestoreWithConfirmation } =
    useTaskOrTemplateTaskRestoringConfirmationDialog(handleRestore);

  if (!task.deleted_at) {
    return null;
  }

  return (
    <>
      <ActionButton
        variant="restore"
        size="lg"
        rounding="base"
        onClick={() => handleRestoreWithConfirmation(task)}
        loading={isLoading}
      />
      {modal}
    </>
  );
}

function TemplateTaskViewEditAction({ task }: TemplateTaskViewProps) {
  const navigateToTemplateTaskEdit = useNavigateToTemplateTaskEdit();

  if (task.deleted_at) {
    return null;
  }

  return (
    <Button variant="secondary" onClick={() => navigateToTemplateTaskEdit(task)}>
      <FormattedMessage id="modal.edit" />
    </Button>
  );
}

function TemplateTaskViewActions({ task }: TemplateTaskViewProps) {
  return (
    <div className="flex space-x-8">
      <TemplateTaskViewDeleteAction task={task} />
      <TemplateTaskViewRestoreAction task={task} />
      <TemplateTaskViewEditAction task={task} />
    </div>
  );
}

function TemplateTaskViewHeader({ task }: TemplateTaskViewProps) {
  return (
    <SlideOverHeader>
      <div className="flex flex-col">
        <span className="text-sm font-bold text-iron-400 mb-1 md:mb-0">
          Template: {task.templateProject.name}
          &nbsp;
          <Hash>{task.templateProject.id}</Hash>
        </span>
        <h3 className="text-black text-xl font-medium">
          {task.name}
          &nbsp;
          <Hash>
            {task.templateProject.id}-{task.id}
          </Hash>
        </h3>
      </div>
      <TemplateTaskViewActions task={task} />
    </SlideOverHeader>
  );
}

function TemplateTaskViewContentSidebar({ task }: TemplateTaskViewProps) {
  return (
    <SlideOverSidebar>
      <SlideOverSidebarField
        label="Responsible Department/Team"
        value={task.templateDepartment?.name}
      />
      {Boolean(task.assignedToUser || task.assignedToTemplateTitle) && (
        <SlideOverSidebarField
          label="Assigned To"
          value={
            <SlideOverSidebarProfileValue
              user={task.assignedToUser}
              title={task.assignedToTemplateTitle}
            />
          }
        />
      )}
      {Boolean(task.supportedByUsers.length || task.supportedByTemplateTitles.length) && (
        <SlideOverSidebarField
          label="Supporters"
          value={
            <SlideOverSidebarProfileListValue
              users={task.supportedByUsers}
              titles={task.supportedByTemplateTitles}
            />
          }
        />
      )}
      {Boolean(task.tags.length) && (
        <SlideOverSidebarField
          label="Tags"
          value={
            <SlideOverSidebarTagsValue
              tags={task.tags}
              to={(tag) => `/templates/${task.template_project_id}/tasks?tag=${tag.id}`}
            />
          }
        />
      )}
      {Boolean(task.dependsOn.length) && (
        <SlideOverSidebarField
          label="Dependent on"
          value={
            <SlideOverSidebarTaskListValue
              tasks={task.dependsOn}
              to={(task) => slideOverRoute(RouteKey.TemplateTask, { templateTaskId: task.id })}
            />
          }
        />
      )}
      {Boolean(task.dependencyFor.length) && (
        <SlideOverSidebarField
          label="Dependency for"
          value={
            <SlideOverSidebarTaskListValue
              tasks={task.dependencyFor}
              to={(task) => slideOverRoute(RouteKey.TemplateTask, { templateTaskId: task.id })}
            />
          }
        />
      )}
      <SlideOverSidebarField label="Duration (days)" value={task.duration} />
      <SlideOverSidebarField
        label={processTypeText(
          task.templateProject,
          "Days Prior Opening",
          "Days Prior Closure",
          "Days Prior Re-Opening",
          "Days Prior Transition"
        )}
        value={task.due_date_offset}
      />
    </SlideOverSidebar>
  );
}

function TemplateTaskViewContent({ task }: TemplateTaskViewProps) {
  return (
    <SlideOverContent>
      <TaskSlideOverTabs task={task} />
      <TemplateTaskViewContentSidebar task={task} />
    </SlideOverContent>
  );
}

function TemplateTaskView({ task }: TemplateTaskViewProps) {
  return (
    <>
      <TemplateTaskViewHeader task={task} />
      <TemplateTaskViewContent task={task} />
    </>
  );
}

export function TemplateTaskSlideOver({ templateTaskId }: TemplateTaskSlideOverProps) {
  const result = useTemplateTaskQuery(templateTaskId);

  return (
    <SlideOverWithLoading result={result} render={(task) => <TemplateTaskView task={task} />} />
  );
}
