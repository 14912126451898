import { useQuery } from "react-query";
import { getUser, getUsers, getUsersForFilter } from "../api";

export const usersKeys = {
  allUsers: () => ["users"],
  user: (...args: Parameters<typeof getUser>) => ["users", ...args],
  users: (...args: Parameters<typeof getUsers>) => ["users", ...args],
  usersForFilter: (...args: Parameters<typeof getUsersForFilter>) => ["users:filter", ...args],
};

export function useUserQuery(...args: Parameters<typeof getUser>) {
  return useQuery(usersKeys.user(...args), () => getUser(...args));
}

export function useUsersQuery(...args: Parameters<typeof getUsers>) {
  return useQuery(usersKeys.users(...args), () => getUsers(...args));
}

export function useUsersForFilterQuery(...args: Parameters<typeof getUsersForFilter>) {
  return useQuery(usersKeys.usersForFilter(...args), () => getUsersForFilter(...args));
}
