import { useCallback, useMemo, useRef, useState } from "react";
import { ConfirmModal, DangerModal, SuccessModal } from "../components/common";

export type ConfirmModalProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  confirmButtonText?: React.ReactNode;
  cancelButtonText?: React.ReactNode;
};

export type DangerModalProps = {
  title: React.ReactNode;
  description: React.ReactNode;
};

export type SuccessModalProps = {
  title: React.ReactNode;
  description: React.ReactNode;
};

const defaultConfirmModalProps: ConfirmModalProps = {
  title: "Confirm action",
  description: "Are you sure?",
};

const defaultDangerModalProps: DangerModalProps = {
  title: "Error",
  description: "Something went wrong",
};

const defaultSuccessModalProps: SuccessModalProps = {
  title: "Done",
  description: "The action was successfully completed",
};

export function useConfirmModal() {
  const [props, setProps] = useState<ConfirmModalProps>(defaultConfirmModalProps);
  const [open, setOpen] = useState(false);

  const confirmCallbackRef = useRef<Function>();
  const cancelCallbackRef = useRef<Function>();

  const openModal = useCallback(
    (
      onConfirm: () => void,
      onCancel: ((() => void) | undefined) | Partial<ConfirmModalProps> = {},
      props: Partial<ConfirmModalProps> = {}
    ) => {
      props = typeof onCancel === "function" ? props : onCancel;
      onCancel = typeof onCancel === "function" ? onCancel : undefined;
      confirmCallbackRef.current = onConfirm;
      cancelCallbackRef.current = onCancel;
      setProps((previousProps) => ({ ...previousProps, ...props }));
      setOpen(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    cancelCallbackRef.current?.();
    confirmCallbackRef.current = undefined;
    cancelCallbackRef.current = undefined;
    setOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    confirmCallbackRef.current?.();
    confirmCallbackRef.current = undefined;
    cancelCallbackRef.current = undefined;
    setOpen(false);
  }, []);

  const modal = useMemo(() => {
    return <ConfirmModal {...props} open={open} onClose={closeModal} onConfirm={handleConfirm} />;
  }, [props, open, closeModal, handleConfirm]);

  return { modal, openModal };
}

export function useDangerModal() {
  const [props, setProps] = useState<DangerModalProps>(defaultDangerModalProps);
  const [open, setOpen] = useState(false);

  const closeCallbackRef = useRef<Function>();

  const openModal = useCallback(
    (onClose: (() => void) | Partial<DangerModalProps>, props: Partial<DangerModalProps> = {}) => {
      props = typeof onClose === "function" ? props : onClose;
      closeCallbackRef.current = typeof onClose === "function" ? onClose : undefined;
      setProps((previousProps) => ({ ...previousProps, ...props }));
      setOpen(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    closeCallbackRef.current?.();
    closeCallbackRef.current = undefined;
    setOpen(false);
  }, []);

  const modal = useMemo(
    () => <DangerModal {...props} open={open} onClose={closeModal} />,
    [props, open, closeModal]
  );

  return { modal, openModal };
}

export function useSuccessModal() {
  const [props, setProps] = useState<SuccessModalProps>(defaultSuccessModalProps);
  const [open, setOpen] = useState(false);

  const closeCallbackRef = useRef<Function>();

  const openModal = useCallback(
    (
      onClose: (() => void) | Partial<SuccessModalProps>,
      props: Partial<SuccessModalProps> = {}
    ) => {
      props = typeof onClose === "function" ? props : onClose;
      closeCallbackRef.current = typeof onClose === "function" ? onClose : undefined;
      setProps((previousProps) => ({ ...previousProps, ...props }));
      setOpen(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    closeCallbackRef.current?.();
    closeCallbackRef.current = undefined;
    setOpen(false);
  }, []);

  const modal = useMemo(
    () => <SuccessModal {...props} open={open} onClose={closeModal} />,
    [props, open, closeModal]
  );

  return { modal, openModal };
}

export function useConfirmModalPromise() {
  const { modal, openModal } = useConfirmModal();

  const confirmAction = useCallback(
    (props: Partial<ConfirmModalProps> = {}) => {
      return new Promise<boolean>((resolve) => {
        openModal(
          () => resolve(true),
          () => resolve(false),
          props
        );
      });
    },
    [openModal]
  );

  return { modal, confirmAction };
}

export function useDangerModalPromise() {
  const { modal, openModal: openModalBase } = useDangerModal();

  const openModal = useCallback(
    (props: Partial<DangerModalProps> = {}) => {
      return new Promise<boolean>((resolve) => {
        openModalBase(() => resolve(true), props);
      });
    },
    [openModalBase]
  );

  return { modal, openModal };
}

export function useSuccessModalPromise() {
  const { modal, openModal: openModalBase } = useSuccessModal();

  const openModal = useCallback(
    (props: Partial<SuccessModalProps> = {}) => {
      return new Promise<boolean>((resolve) => {
        openModalBase(() => resolve(true), props);
      });
    },
    [openModalBase]
  );

  return { modal, openModal };
}
