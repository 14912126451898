import { Project } from "@dh-critical-path/api-types";
import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { Button } from "../../components/button";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { AdminOnly, useAuth } from "../../hooks/useAuth";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { DepartmentsTasksStatsCards } from "../Departments/components/DepartmentsTasksStatsCards";
import { projectDepartmentLinkFactory, useNavigateToDepartmentCreate } from "../utils/navigation";

type ProjectDepartmentsPageProps = {
  project: Project;
};

function ProjectDepartmentsPage({ project }: ProjectDepartmentsPageProps) {
  useDocumentTitle(`${project.name} - Departments`);

  const { user } = useAuth();

  const navigateToCreationView = useNavigateToDepartmentCreate();

  return (
    <PageContainer>
      <Layout.Column width={12}>
        <Layout.Spacer space={8}>
          <DepartmentsTasksStatsCards
            filter={{ project_ids: [project.id], assignee_user_ids: [user!.id] }}
            entityLink={projectDepartmentLinkFactory(project)}
            header={(count) => (
              <PageSectionHeader>
                <FormattedMessage id="headings.my-departments" values={{ count }} />
              </PageSectionHeader>
            )}
          />
          <DepartmentsTasksStatsCards
            filter={{ project_ids: [project.id], is_local: true }}
            entityLink={projectDepartmentLinkFactory(project)}
            header={(count) => (
              <PageSectionHeader
                buttons={
                  <AdminOnly
                    render={() => (
                      <Button icon={PlusIcon} onClick={() => navigateToCreationView(project)}>
                        <FormattedMessage id="departments.create-local" />
                      </Button>
                    )}
                  />
                }
              >
                <FormattedMessage id="headings.project.departments-local" values={{ count }} />
              </PageSectionHeader>
            )}
            showHeaderAsFallback={true}
          />
        </Layout.Spacer>
      </Layout.Column>
    </PageContainer>
  );
}

export default ProjectDepartmentsPage;
