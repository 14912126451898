import { TemplateProject } from "@dh-critical-path/api-types";
import { PageContainer } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

type TemplateProjectActivityPageProps = {
  project: TemplateProject;
};

export function TemplateProjectActivityPage({ project }: TemplateProjectActivityPageProps) {
  useDocumentTitle(`${project.name} - Activity`);

  return <PageContainer>Work in progress</PageContainer>;
}
