import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { Button } from "../../components/button";
import { PageContainer, PageSectionHeader } from "../../components/page";
import { AdminOnly } from "../../hooks/useAuth";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useProjectsTasksStatsQuery } from "../../queries";
import { ErrorFallback } from "../components/ErrorFallback";
import { projectLinkFactory, useNavigateToProjectCreate } from "../utils/navigation";
import { ProjectsPageLayout } from "./components/ProjectsPageLayout";
import { ProjectsTasksStatsCards } from "./components/ProjectsTasksStatsCards";

export function ProjectsPage() {
  useDocumentTitle("My Projects");

  const navigateToCreationView = useNavigateToProjectCreate();
  const filter = {};
  const { error } = useProjectsTasksStatsQuery(filter);

  if (error) {
    return <ErrorFallback error={error} />;
  }

  return (
    <ProjectsPageLayout title={<FormattedMessage id="navigation.my-projects" />}>
      <PageContainer>
        <ProjectsTasksStatsCards
          header={(count) => (
            <PageSectionHeader
              buttons={
                <AdminOnly
                  render={() => (
                    <Button icon={PlusIcon} onClick={() => navigateToCreationView()}>
                      <FormattedMessage id="projects.create" />
                    </Button>
                  )}
                />
              }
            >
              <FormattedMessage id="headings.projects.ongoing" values={{ count }} />
            </PageSectionHeader>
          )}
          filter={filter}
          entityLink={projectLinkFactory()}
        />
      </PageContainer>
    </ProjectsPageLayout>
  );
}
