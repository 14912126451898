import { TemplateDepartment } from "@dh-critical-path/api-types";
import { keepTruthy } from "@dh-critical-path/shared";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { ActionButton, Button } from "../../../components/button";
import { Hash } from "../../../components/common";
import { useActionWithConfirmation } from "../../../components/modals";
import { PageSectionHeader } from "../../../components/page";
import { useTemplateDepartmentRemove } from "../../../hooks/useTemplateDepartment";
import { useTasksQuery, useTemplateTasksQuery } from "../../../queries";
import { useActionCompleted } from "../../../utils/hooks";
import {
  useNavigateToTemplateDepartmentEdit,
  useNavigateToTemplateDepartments,
} from "../../utils/navigation";
import { AffectedTasksDetails } from "./AffectedTasksDetails";

type TemplateDepartmentPageSectionHeaderProps = {
  department: TemplateDepartment;
};

export function useTemplateDepartmentRemovingConfirmationDialog(
  handleRemove: (department: TemplateDepartment) => Promise<any>
) {
  const { modal, handleWithConfirmation } = useActionWithConfirmation(
    handleRemove,
    async (department) => ({
      description: <TemplateDepartmentRemovingConfirmationText department={department} />,
    })
  );

  return { modal, handleRemoveWithConfirmation: handleWithConfirmation };
}

function TemplateDepartmentRemovingConfirmationText({
  department,
}: {
  department: TemplateDepartment;
}) {
  const params = useParams<"id" | "departmentId">();

  const projectId = params.departmentId ? Number(params.id) : null;

  const tasksQuery = useTasksQuery({
    template_department_ids: [department.id],
    limit: 1,
  });

  const templateTasksQuery = useTemplateTasksQuery({
    template_department_ids: [department.id],
    template_project_ids: keepTruthy([projectId]),
    limit: 1,
  });

  return (
    <div>
      <p>Do you really want to remove this department?</p>
      <AffectedTasksDetails
        totalTasks={tasksQuery.data?.meta.total ?? 0}
        totalTemplateTasks={templateTasksQuery.data?.meta.total ?? 0}
        templateDepartmentId={department.id}
        templateProjectId={projectId}
      />
    </div>
  );
}

function TemplateDepartmentEditButton({ department }: TemplateDepartmentPageSectionHeaderProps) {
  const navigateToEditView = useNavigateToTemplateDepartmentEdit();

  return (
    <Button variant="secondary" onClick={() => navigateToEditView(department)}>
      <FormattedMessage id="headings.departments.edit" />
    </Button>
  );
}

function TemplateDepartmentDeleteButton({ department }: TemplateDepartmentPageSectionHeaderProps) {
  const { handleRemove, isLoading } = useTemplateDepartmentRemove();

  const navigateToDepartments = useNavigateToTemplateDepartments();

  const handleRemoveAndRedirect = useActionCompleted(handleRemove, navigateToDepartments);

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useTemplateDepartmentRemovingConfirmationDialog(handleRemoveAndRedirect);

  return (
    <>
      <ActionButton
        variant="delete"
        size="lg"
        rounding="base"
        onClick={() => handleRemoveWithConfirmation(department)}
        loading={isLoading}
      >
        <FormattedMessage id="headings.departments.edit" />
      </ActionButton>
      {confirmModal}
    </>
  );
}

function TemplateDepartmentHeaderActions({ department }: TemplateDepartmentPageSectionHeaderProps) {
  return (
    <>
      <TemplateDepartmentEditButton department={department} />
      <TemplateDepartmentDeleteButton department={department} />
    </>
  );
}

export function TemplateDepartmentHeader({ department }: TemplateDepartmentPageSectionHeaderProps) {
  return (
    <PageSectionHeader
      className="mb-2"
      buttons={<TemplateDepartmentHeaderActions department={department} />}
    >
      <span className="pr-1 uppercase">{department.name}</span>
      <Hash>{department.id}</Hash>
    </PageSectionHeader>
  );
}
