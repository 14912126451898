import { TemplateDepartment, User } from "@dh-critical-path/api-types";
import { keepTruthy, prefixUserId } from "@dh-critical-path/shared";
import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { ActionButton, Button } from "../../../components/button";
import { useActionWithConfirmation } from "../../../components/modals";
import { PageSectionHeader } from "../../../components/page";
import { Panel, PanelFooter } from "../../../components/panel";
import { ProfileRow } from "../../../components/profile";
import { useTemplateDepartmentMemberRemove } from "../../../hooks/useTemplateDepartment";
import {
  useTasksQuery,
  useTemplateDepartmentMembersQuery,
  useTemplateTasksQuery,
} from "../../../queries";
import { fullName } from "../../../utils/text";
import { useNavigateToTemplateDepartmentMembersAdd } from "../../utils/navigation";
import { AffectedTasksDetails } from "./AffectedTasksDetails";

type TemplateDepartmentMembersPanelProps = {
  department: TemplateDepartment;
};

type TemplateDepartmentMembersProps = {
  department: TemplateDepartment;
};

type TemplateDepartmentMemberProps = {
  department: TemplateDepartment;
  member: User;
};

export function useDepartmentMemberRemovingConfirmationDialog(
  handleRemove: (department: TemplateDepartment, member: User) => Promise<any>
) {
  const { modal, handleWithConfirmation } = useActionWithConfirmation(
    handleRemove,
    async (department, member) => ({
      description: (
        <TemplateDepartmentMemberRemovingConfirmation department={department} member={member} />
      ),
    })
  );

  return { modal, handleRemoveWithConfirmation: handleWithConfirmation };
}

function TemplateDepartmentMemberRemovingConfirmation({
  department,
  member,
}: {
  department: TemplateDepartment;
  member: User;
}) {
  const params = useParams<"id" | "departmentId">();

  const projectId = params.departmentId ? Number(params.id) : null;

  const tasksQuery = useTasksQuery({
    template_department_ids: [department.id],
    assignee_ids: [prefixUserId(member.id)],
    limit: 1,
  });

  const templateTasksQuery = useTemplateTasksQuery({
    template_department_ids: [department.id],
    template_project_ids: keepTruthy([projectId]),
    assignee_ids: [prefixUserId(member.id)],
    limit: 1,
  });

  return (
    <div>
      <p>
        Do you really want to remove {fullName(member)} from {department.name}
      </p>
      <AffectedTasksDetails
        totalTasks={tasksQuery.data?.meta.total ?? 0}
        totalTemplateTasks={templateTasksQuery.data?.meta.total ?? 0}
        templateDepartmentId={department.id}
        templateProjectId={projectId}
        assigneeId={prefixUserId(member.id)}
      />
    </div>
  );
}

function TemplateDepartmentMemberRemoveAction({
  department,
  member,
}: TemplateDepartmentMemberProps) {
  const { handleRemove, isLoading } = useTemplateDepartmentMemberRemove();

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useDepartmentMemberRemovingConfirmationDialog(handleRemove);

  return (
    <>
      <ActionButton
        variant="delete"
        onClick={() => handleRemoveWithConfirmation(department, member)}
        loading={isLoading}
      />
      {confirmModal}
    </>
  );
}

function TemplateDepartmentMemberActions({ department, member }: TemplateDepartmentMemberProps) {
  return (
    <div className="flex md:invisible group-hover:visible items-center space-x-3 pl-3">
      <TemplateDepartmentMemberRemoveAction department={department} member={member} />
    </div>
  );
}

function TemplateDepartmentMember({ department, member }: TemplateDepartmentMemberProps) {
  return (
    <div className="flex w-full justify-between items-start md:items-center relative group mb-4 last:mb-0">
      <ProfileRow user={member} />
      <TemplateDepartmentMemberActions department={department} member={member} />
    </div>
  );
}

function TemplateDepartmentMembersEmpty() {
  return (
    <div className="py-6 text-center">
      <FormattedMessage id="department.members.empty" />
    </div>
  );
}

function TemplateDepartmentMembers({ department }: TemplateDepartmentMembersProps) {
  const { data } = useTemplateDepartmentMembersQuery(department.id);

  if (!data || !data.length) {
    return <TemplateDepartmentMembersEmpty />;
  }

  return (
    <>
      {data.map((member) => (
        <TemplateDepartmentMember key={member.id} department={department} member={member} />
      ))}
    </>
  );
}

export function TemplateDepartmentMembersPanel({
  department,
}: TemplateDepartmentMembersPanelProps) {
  return (
    <Panel>
      <TemplateDepartmentMembers department={department} />
      <TemplateDepartmentMembersPanelFooter department={department} />
    </Panel>
  );
}

function TemplateDepartmentMembersPanelFooter({ department }: TemplateDepartmentMembersPanelProps) {
  const navigateToMembersAdd = useNavigateToTemplateDepartmentMembersAdd();

  return (
    <PanelFooter>
      <Button
        icon={() => <PlusIcon className="w-4" />}
        onClick={() => navigateToMembersAdd(department)}
      >
        <FormattedMessage id="department.members.add" />
      </Button>
    </PanelFooter>
  );
}

export function TemplateDepartmentMembersPanelHeader({
  department,
}: TemplateDepartmentMembersPanelProps) {
  const { data } = useTemplateDepartmentMembersQuery(department.id);

  return (
    <PageSectionHeader>
      <FormattedMessage id="headings.departments.members" values={{ count: data?.length ?? 0 }} />
    </PageSectionHeader>
  );
}
