import { SsoUserRole } from "@dh-critical-path/shared";
import { BadgeColor } from "../components/common/Badge";

type TextColor = `text-${string}`;

type BackgroundColor = `bg-${string}`;

// TODO: get rid of this enum, user StatusType from @dh-critical-path/shared

export enum Status {
  completed = "completed",
  in_progress = "in_progress",
  overdue = "overdue",
  todo = "todo",
  backlog = "backlog",
  canceled = "canceled",
  on_hold = "on_hold",
}

export enum StatusColor {
  completed = "green-500",
  in_progress = "yellow-300",
  overdue = "red-500",
  todo = "blue-300",
  backlog = "stone-600",
  canceled = "stone-600",
  on_hold = "stone-600",
}

export enum StatusName {
  completed = "Completed",
  in_progress = "In Progress",
  overdue = "Overdue",
  todo = "Todo",
  backlog = "Backlog",
  canceled = "Canceled",
  on_hold = "On Hold",
}

export enum StatusBadgeColor {
  completed = "green",
  in_progress = "yellow",
  overdue = "red",
  todo = "blue",
  backlog = "gray",
  canceled = "gray",
  on_hold = "gray",
}

export function backgroundClassName(status: Status): BackgroundColor {
  return `bg-${StatusColor[status]}`;
}

export function textClassName(status: Status): TextColor {
  return `text-${StatusColor[status]}`;
}

export function statusName(status: Status): string {
  return StatusName[status];
}

export function badgeTextColor(status: Status): BadgeColor {
  return StatusBadgeColor[status];
}

export function userRoleBadgeColor(role: SsoUserRole): BadgeColor {
  return role === SsoUserRole.ADMINISTRATOR
    ? "red"
    : role === SsoUserRole.GLOBAL_DEPARTMENT
    ? "green"
    : "blue";
}

export function userRoleBackgroundColor(role: SsoUserRole): BackgroundColor {
  return role === SsoUserRole.ADMINISTRATOR
    ? "bg-red-500"
    : role === SsoUserRole.GLOBAL_DEPARTMENT
    ? "bg-green-500"
    : "bg-blue-300";
}
