import classnames from "classnames";
import React, { HTMLAttributes } from "react";

export type BadgeColor =
  | "gray"
  | "red"
  | "yellow"
  | "green"
  | "blue"
  | "indigo"
  | "purple"
  | "pink";

interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  size?: "base" | "small";
  rounded?: boolean;
  color: BadgeColor;
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ children, size = "base", rounded, color, className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={classnames(
        `inline-flex items-center justify-center rounded leading-none bg-${color}-100 text-${color}-800`,
        {
          "h-6 px-2.5 text-sm": size === "base",
          "h-5 px-2 text-xs": size === "small",
          "rounded-full": rounded,
        },
        className
      )}
    >
      {children}
    </div>
  )
);

export default Badge;
