import { FormattedMessage } from "react-intl";
import { NavigationTab } from "../../../components/common";
import { PageHeaderTabs } from "../../../components/page";

export function TemplateProjectsTabs() {
  return (
    <PageHeaderTabs>
      <NavigationTab to="/templates" end>
        <FormattedMessage id="navigation.templates" />
      </NavigationTab>
      <NavigationTab to="/templates/departments">
        <FormattedMessage id="navigation.departments" />
      </NavigationTab>
    </PageHeaderTabs>
  );
}
