import { ProcessType } from "@dh-critical-path/shared";
import { PropsWithChildren } from "react";
import { FieldArrayWithId, useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { ActionButton, Button } from "../../../../components/button";
import { SortHandle, SortableList, SortableListItem } from "../../../../components/common";
import {
  ControlledCheckbox,
  ControlledInput,
  ControlledMarkdown,
  ControlledSelect,
} from "../../../../components/form/controlled";
import { TemplateProjectUpdateFields } from "../../../../hooks/useTemplateProject";
import { nextIdNumeric } from "../../../../utils/nextId";

type KeyFieldProps = {
  index: number;
  field: FieldArrayWithId<TemplateProjectUpdateFields, "keys">;
  onRemove: (index: number) => void;
};

function KeyFieldListColumns({ children }: PropsWithChildren<{}>) {
  return <div className="flex gap-4">{children}</div>;
}

function DragHandleColumn({ children }: PropsWithChildren<{}>) {
  return <div className="flex-none w-4">{children}</div>;
}

function NameColumn({ children }: PropsWithChildren<{}>) {
  return <div className="flex-1">{children}</div>;
}

function ActionsColumn({ children }: PropsWithChildren<{}>) {
  return <div className="flex-none w-10">{children}</div>;
}

function KeyField({ index, field, onRemove }: KeyFieldProps) {
  const { formatMessage } = useIntl();

  return (
    <SortableListItem sortableListItemId={String(field.list_id)} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} className="py-4">
          <KeyFieldListColumns>
            <DragHandleColumn>
              <div className="mt-4">
                <SortHandle {...provided.dragHandleProps} />
              </div>
            </DragHandleColumn>
            <NameColumn>
              <div className="space-y-2">
                <ControlledInput
                  name={`keys.${index}.name`}
                  placeholder={formatMessage({
                    id: "templates.projects.form.keys.name.placeholder",
                  })}
                />
                <ControlledCheckbox name={`keys.${index}.is_required`} label="Required" />
              </div>
            </NameColumn>
            <ActionsColumn>
              <div className="mt-3">
                <ActionButton variant="delete" rounding="base" onClick={() => onRemove(index)} />
              </div>
            </ActionsColumn>
          </KeyFieldListColumns>
        </div>
      )}
    </SortableListItem>
  );
}

function KeyFieldList() {
  const { control } = useFormContext<TemplateProjectUpdateFields>();
  const { fields, remove, append, move } = useFieldArray({ control, name: "keys" });

  function handleAppend() {
    append({
      list_id: nextIdNumeric(),
      name: "",
      sort_order: fields.length,
      is_required: false,
    });
  }

  return (
    <div>
      <div>
        <FormattedMessage id="templates.projects.form.keys" />
      </div>
      <SortableList sortableId="KeyFieldList" onOrderChange={move}>
        {(provided) => (
          <div ref={provided.innerRef}>
            {fields.map((field, index) => (
              <KeyField key={field.list_id} index={index} field={field} onRemove={remove} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </SortableList>
      <div className="flex justify-end">
        <Button onClick={handleAppend}>
          <FormattedMessage id="templates.projects.form.keys.append" />
        </Button>
      </div>
    </div>
  );
}

function ProcessTypeField() {
  const { formatMessage } = useIntl();

  const { watch } = useFormContext<TemplateProjectUpdateFields>();

  const id = watch("id");

  return (
    <ControlledSelect
      name="process_type"
      label={formatMessage({ id: "templates.projects.form.process-type" })}
      placeholder={formatMessage({ id: "templates.projects.form.process-type.placeholder" })}
      options={[
        { id: ProcessType.OPENING, name: "Opening" },
        { id: ProcessType.CLOSURE, name: "Closure" },
        { id: ProcessType.RE_OPENING, name: "Re-Opening" },
        { id: ProcessType.TRANSITION, name: "Transition" },
      ]}
      isDisabled={Boolean(id)}
    />
  );
}

export function TemplateProjectForm() {
  const { formatMessage } = useIntl();

  return (
    <>
      <ControlledInput
        name="name"
        label={formatMessage({ id: "templates.projects.form.name" })}
        placeholder={formatMessage({ id: "templates.projects.form.name.placeholder" })}
        required
      />
      <ControlledMarkdown
        name="description"
        label={formatMessage({ id: "templates.projects.form.description" })}
        placeholder={formatMessage({ id: "templates.projects.form.description.placeholder" })}
        required
      />
      <ProcessTypeField />
    </>
  );
}

export function TemplateProjectSidebarForm() {
  return (
    <>
      <KeyFieldList />
    </>
  );
}
