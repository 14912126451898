import { Request, Response } from "@dh-critical-path/api-types";
import axios from "axios";

export function trackEvent(params: Request.Tracking.TrackEvent) {
  return axios.post<Response.Tracking.TrackEvent>(`/api/tracking`, params);
}

export function getMetabaseToken() {
  return axios
    .get<Response.Tracking.GetMetabaseToken>(`/api/tracking/metabase-token`)
    .then(({ data }) => data);
}
