import React, { HTMLAttributes } from "react";
import { FormattedDate, FormattedTime } from "react-intl";

interface FormattedDateTimeProps extends HTMLAttributes<HTMLSpanElement> {
  date: Date;
}

const FormattedDateTime = React.forwardRef<HTMLSpanElement, FormattedDateTimeProps>(
  ({ date, ...props }, ref) => {
    return (
      <span {...props} ref={ref}>
        <FormattedTime value={date} />
        <span>,&nbsp;</span>
        <FormattedDate value={date} day="2-digit" />
        <span>&nbsp;</span>
        <FormattedDate value={date} month="short" />
      </span>
    );
  }
);

export default FormattedDateTime;
