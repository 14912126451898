import classNames from "classnames";
import { NavLink, NavLinkProps } from "react-router-dom";
import { styled } from "../../utils/styled";

const HeaderLink = styled<HTMLAnchorElement, NavLinkProps>(({ children, ...props }) => (
  <NavLink
    {...props}
    className={(isActive) =>
      classNames("px-3 py-1.5 md:py-2 rounded-md text-base focus:ring focus:ring-beige-600", {
        "bg-beige-100 text-black": isActive.isActive,
        "hover:text-beige-400 text-white": !isActive.isActive,
      })
    }
  >
    {children}
  </NavLink>
));

export default HeaderLink;
