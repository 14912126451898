import { Comment, Task, TemplateTask } from "@dh-critical-path/api-types";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createComment,
  deleteComment,
  getTaskComments,
  getTemplateTaskComments,
  updateComment,
} from "../api/comments";
import { commentsKeys, tasksKeys } from "../queries";
import { validationErrorsInterceptor } from "../utils/form";
import { isTask } from "../utils/misc";

export type CommentFormFields = {
  content: string;
};

export type CommentableEntity = Task | TemplateTask;

function commentsQueryKey(task: CommentableEntity) {
  return isTask(task)
    ? commentsKeys.taskComments(task.id)
    : commentsKeys.templateTaskComments(task.id);
}

export function useComments(task: CommentableEntity) {
  const queryAction = isTask(task)
    ? () => getTaskComments(task.id)
    : () => getTemplateTaskComments(task.id);

  const query = useQuery(commentsQueryKey(task), queryAction);

  const comments = query.data || [];

  const isLoading = query.isLoading;

  return { comments, isLoading };
}

export function useCommentForm(task: CommentableEntity) {
  const [editingComment, setEditingComment] = useState<Comment | null>(null);
  const [isContentChanged, setIsContentChanged] = useState(false);

  const queryClient = useQueryClient();
  const { mutate: mutateCreate, isLoading: isCreating } = useMutation(createComment);
  const { mutate: mutateUpdate, isLoading: isUpdating } = useMutation(updateComment);
  const { mutate: mutateDelete, isLoading: isDeleting } = useMutation(deleteComment);

  const form = useForm<CommentFormFields>({
    defaultValues: {
      content: "",
    },
  });

  const content = form.watch("content");

  useEffect(() => {
    if (editingComment) {
      setIsContentChanged(content !== editingComment.content);
    } else {
      setIsContentChanged(content.trim().length > 0);
    }
  }, [content, editingComment, setIsContentChanged]);

  useEffect(() => {
    if (editingComment) {
      form.setValue("content", editingComment.content);
    }
  }, [form, editingComment]);

  const handleEdit = useCallback((comment: Comment) => {
    setEditingComment(comment);
  }, []);

  const resetForm = useCallback(() => {
    form.reset();
    setEditingComment(null);
  }, [form]);

  const updateTaskCommentsCount = useCallback(() => {
    queryClient.invalidateQueries(tasksKeys.task(task.id));
    queryClient.invalidateQueries(tasksKeys.allTasks());
  }, [queryClient, task]);

  const handleCreateSuccess = useCallback(
    (newComment: Comment) => {
      queryClient.setQueryData<Comment[]>(commentsQueryKey(task), (comments) => [
        newComment,
        ...(comments || []),
      ]);

      resetForm();
      updateTaskCommentsCount();
    },
    [queryClient, task, resetForm, updateTaskCommentsCount]
  );

  const handleUpdateSuccess = useCallback(
    (newComment: Comment) => {
      if (!editingComment) {
        return;
      }

      queryClient.setQueryData<Comment[]>(commentsQueryKey(task), (comments) =>
        (comments || []).map((existingComment) =>
          existingComment.id === editingComment.id ? newComment : existingComment
        )
      );

      resetForm();
    },
    [queryClient, task, editingComment, resetForm]
  );

  const handleDeleteSuccess = useCallback(
    (deletedComment: Comment) => {
      queryClient.setQueryData<Comment[]>(commentsQueryKey(task), (comments) =>
        (comments || []).filter((existingComment) => existingComment.id !== deletedComment.id)
      );

      if (editingComment?.id === deletedComment.id) {
        resetForm();
      }

      updateTaskCommentsCount();
    },
    [queryClient, task, editingComment, resetForm, updateTaskCommentsCount]
  );

  const handleCreate = useCallback(
    ({ content }: CommentFormFields) => {
      const payload = isTask(task)
        ? { task_id: task.id, content }
        : { template_task_id: task.id, content };

      mutateCreate(payload, {
        onSuccess: handleCreateSuccess,
        onError: validationErrorsInterceptor(form),
      });
    },
    [mutateCreate, task, form, handleCreateSuccess]
  );

  const handleUpdate = useCallback(
    ({ content }: CommentFormFields) => {
      mutateUpdate(
        { comment_id: editingComment!.id, content },
        { onSuccess: handleUpdateSuccess, onError: validationErrorsInterceptor(form) }
      );
    },
    [mutateUpdate, editingComment, form, handleUpdateSuccess]
  );

  const handleSubmit = useCallback(
    (data: CommentFormFields) => {
      if (editingComment) {
        handleUpdate(data);
      } else {
        handleCreate(data);
      }
    },
    [editingComment, handleUpdate, handleCreate]
  );

  const handleDelete = useCallback(
    (comment: Comment) => {
      mutateDelete({ comment_id: comment.id }, { onSuccess: () => handleDeleteSuccess(comment) });
    },
    [mutateDelete, handleDeleteSuccess]
  );

  const handleDiscard = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const isSubmitting = isCreating || isUpdating;

  return {
    form,
    editingComment,
    isContentChanged,
    isSubmitting,
    isDeleting,
    handleSubmit,
    handleDiscard,
    handleEdit,
    handleDelete,
  };
}
