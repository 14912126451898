import { Department as DepartmentType } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useDepartmentUpdateForm } from "../../hooks/useDepartment";
import { useDepartmentQuery } from "../../queries";
import { useNavigateToDepartment } from "../utils/navigation";
import { DepartmentForm } from "./components/forms/DepartmentForm";

type DepartmentEditSlideOverProps = {
  departmentId: number;
};

type DepartmentEditFormProps = {
  department: DepartmentType;
};

function DepartmentEditForm({ department }: DepartmentEditFormProps) {
  const navigate = useNavigateToDepartment();

  const { methods, handleSubmit } = useDepartmentUpdateForm(department, navigate);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="departments.modal.heading.edit" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <DepartmentForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function DepartmentEditSlideOver({ departmentId }: DepartmentEditSlideOverProps) {
  const result = useDepartmentQuery(departmentId);

  return (
    <SlideOverWithLoading
      result={result}
      render={(department) => <DepartmentEditForm department={department} />}
    />
  );
}
