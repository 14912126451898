import { Task, TemplateTask } from "@dh-critical-path/api-types";
import { AttachmentOwner } from "@dh-critical-path/shared";
import { useQueryClient } from "react-query";
import { updateTask, updateTemplateTask } from "../../../../api";
import { AttachmentList } from "../../../../components/form/Attachments";
import { Renderer } from "../../../../components/form/markdown-editor";
import { useAttachments } from "../../../../hooks/useAttachments";
import { tasksKeys, templateKeys } from "../../../../queries";
import { isTask } from "../../../../utils/misc";

type TabOverviewProps = {
  task: Task | TemplateTask;
};

export function useDescriptionUpdate(task: Task | TemplateTask) {
  const queryClient = useQueryClient();

  return async function (description: string) {
    try {
      // TODO: refactor?
      if (task.deleted_at) {
        return;
      }

      if (isTask(task)) {
        await updateTask({ task_id: task.id, description });
        queryClient.invalidateQueries(tasksKeys.task(task.id));
      } else {
        await updateTemplateTask({ template_task_id: task.id, description });
        queryClient.invalidateQueries(templateKeys.templateTask(task.id));
      }
    } catch (e) {
      console.error(e);
    }
  };
}

export function TabOverview({ task }: TabOverviewProps) {
  const { attachments } = useAttachments(
    isTask(task) ? AttachmentOwner.TASK : AttachmentOwner.TEMPLATE_TASK,
    task.id
  );

  const handleUpdate = useDescriptionUpdate(task);

  return (
    <div>
      <h4 className="text-iron mb-3">Description</h4>
      <div className="mb-3">
        <Renderer value={task.description} onChange={handleUpdate} />
      </div>
      {attachments.length > 0 && (
        <div>
          <h4 className="text-iron">Attachments ({attachments.length})</h4>
          <AttachmentList attachments={attachments} />
        </div>
      )}
    </div>
  );
}
