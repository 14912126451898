import { Request, Response } from "@dh-critical-path/api-types";
import axios from "axios";

export function getUsersForFilter(params: Request.Users.GetUsersForFilter) {
  return axios
    .get<Response.Users.GetUsersForFilter>("/api/users/filter", { params })
    .then(({ data }) => data);
}

export function getUser(id: number) {
  return axios.get<Response.Users.GetUser>(`/api/users/${id}`).then(({ data }) => data);
}

export function getUsers(params: Request.Users.GetUsers) {
  return axios.get<Response.Users.GetUsers>(`/api/users`, { params }).then(({ data }) => data);
}

export function addUser(params: Request.Users.AddUser) {
  return axios.post<Response.Users.AddUser>(`/api/users`, params).then(({ data }) => data);
}

export function updateUser({ id, ...params }: Request.Users.UpdateUser) {
  return axios
    .patch<Response.Users.UpdateUser>(`/api/users/${id}`, params)
    .then(({ data }) => data);
}

export function deleteUser({ id }: Request.Users.DeleteUser) {
  return axios.delete<Response.Users.DeleteUser>(`/api/users/${id}`);
}
