import { User } from "@dh-critical-path/api-types";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import { trackEvent } from "../api";
import { useAuth } from "./useAuth";

let eventsQueue: Parameters<typeof trackEvent>[] = [];

function enqueueEvent(user: User | undefined, ...args: Parameters<typeof trackEvent>) {
  eventsQueue.unshift(args);

  if (user) {
    let args;
    while ((args = eventsQueue.pop())) {
      trackEvent(...args);
    }
  }
}

export function useTrackEvent() {
  const { user } = useAuth();

  return useCallback(
    (event: string) => {
      enqueueEvent(user, { event, url: window.location.href });
    },
    [user]
  );
}

export function useTrackLocation() {
  const trackEvent = useTrackEvent();

  const location = useLocation();

  useEffect(() => {
    trackEvent("pageview");
  }, [location, trackEvent]);
}
