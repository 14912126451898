import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../components/button";
import { PageSectionHeaderButtonGroup } from "../../../components/page/PageSectionHeader";
import { useNavigateToUserAdd } from "../../utils/navigation";

function UserAddAction() {
  const navigateToUserAdd = useNavigateToUserAdd();

  return (
    <Button icon={PlusIcon} onClick={() => navigateToUserAdd()}>
      <FormattedMessage id="users.add" />
    </Button>
  );
}

export function UsersActions() {
  return (
    <PageSectionHeaderButtonGroup>
      <UserAddAction />
    </PageSectionHeaderButtonGroup>
  );
}
