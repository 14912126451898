import classnames from "classnames";
import React, { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  size?: "base" | "lg";
  variant?: "base" | "primary" | "secondary" | "danger" | "text";
}

const SpinnerOverlay = styled<HTMLDivElement, SpinnerProps>(
  ({ children, variant, className, ...props }) => (
    <div
      {...props}
      className={classnames(
        `absolute inset-0 flex items-center justify-center z-20 pointer-events-none`,
        {
          "bg-white": variant === "base" || variant === "secondary",
          "bg-transparent": variant === "text",
          "bg-violet-800": variant === "primary",
          "bg-red-400": variant === "danger",
        },
        className
      )}
    >
      {children}
    </div>
  )
);

const SpinnerBody = styled<HTMLDivElement, SpinnerProps>(({ variant, size }) => (
  <div
    style={{ borderBottomColor: "transparent", borderLeftColor: "transparent" }}
    className={classnames(`rounded-full relative border-solid flex-none animate-spin z-10`, {
      "border-white": variant === "primary" || variant === "danger",
      "border-violet": variant === "base" || variant === "secondary",
      "border h-4 w-4": size === "base",
      "border-4 w-12 h-12": size === "lg",
    })}
  ></div>
));

const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
  ({ size = "base", variant = "base", className, ...props }, ref) => (
    <SpinnerOverlay {...props} ref={ref} variant={variant}>
      <SpinnerBody variant={variant} size={size} />
    </SpinnerOverlay>
  )
);

export default Spinner;
