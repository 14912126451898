import { styled } from "../../utils/styled";
import { NavigationTabs } from "../common";

const PageHeaderTabs = styled<HTMLDivElement>(({ children, ...props }) => (
  <NavigationTabs {...props} className="-mx-4 px-4 mt-2 md:mt-0">
    {children}
  </NavigationTabs>
));

export default PageHeaderTabs;
