import { Project, Request } from "@dh-critical-path/api-types";
import { useProjectsTasksStatsQuery } from "../../../queries";
import { TasksStatsCards, TasksStatsEntityLink } from "../../Tasks/components/TasksStatsCards";

type ProjectsTasksStatsCardsProps = {
  header?: (count: number) => React.ReactElement;
  filter: Request.Projects.GetProjectsTasksStats;
  entityLink: TasksStatsEntityLink<Project>;
};

export function ProjectsTasksStatsCards({
  header,
  filter,
  entityLink,
}: ProjectsTasksStatsCardsProps) {
  const { data } = useProjectsTasksStatsQuery(filter);

  if (!data) {
    return null;
  } else if (!data.length) {
    return <p>You don't have access to any project</p>;
  }

  return (
    <>
      {header && header(data.length)}
      <TasksStatsCards items={data} entityLink={entityLink} />
    </>
  );
}
