import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, PropsWithChildren, forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import { Button, CloseButton } from "../button";
import ModalIcon from "../icons/ModalIcon";

type ModalType = "danger" | "success";

type ModalProps = {
  type?: ModalType;
  open: boolean;
  content?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  buttons: React.ReactNode;
  onClose: () => void;
};

type ConfirmModalProps = Pick<ModalProps, "open" | "onClose"> & {
  title: React.ReactNode;
  description: React.ReactNode;
  onConfirm: () => void;
  confirmButtonText?: React.ReactNode;
  cancelButtonText?: React.ReactNode;
};

type SuccessModalProps = Pick<ModalProps, "open" | "onClose"> & {
  title: React.ReactNode;
  description: React.ReactNode;
  buttonText?: React.ReactNode;
};

type DangerModalProps = Pick<ModalProps, "open" | "onClose"> & {
  title: React.ReactNode;
  description: React.ReactNode;
  buttonText?: React.ReactNode;
};

function ContentCenteringTrick() {
  return (
    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
      &#8203;
    </span>
  );
}

const ModalWrapper = forwardRef<HTMLDivElement, PropsWithChildren<Pick<ModalProps, "onClose">>>(
  function ModalWrapper({ children, ...props }, ref) {
    return (
      <Dialog {...props} ref={ref} as="div" className="fixed z-30 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {children}
        </div>
      </Dialog>
    );
  }
);

const ModalOverlay = forwardRef<HTMLDivElement>(function ModalOverlay(props, ref) {
  return (
    <Dialog.Overlay
      {...props}
      ref={ref}
      className="fixed inset-0 bg-stone-100 bg-opacity-75 transition-opacity"
    />
  );
});

const ModalBodyContainer = forwardRef<HTMLDivElement, PropsWithChildren<{}>>(
  function ModalBodyContainer({ children }, ref) {
    return (
      <div
        ref={ref}
        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full sm:p-6"
      >
        {children}
      </div>
    );
  }
);

function ModalButtonsContainer({ children }: PropsWithChildren<{}>) {
  return (
    <div className="mt-4 flex flex-col sm:flex-row sm:justify-end space-y-4 sm:space-y-0 sm:space-x-4">
      {children}
    </div>
  );
}

function ModalCloseButton(props: Parameters<typeof CloseButton>[0]) {
  return <CloseButton {...props} className="hidden sm:block absolute top-0 right-0 mt-4 mr-4" />;
}

function ModalBody({ children }: PropsWithChildren<{}>) {
  return <div className="sm:flex sm:items-start">{children}</div>;
}

function ModalContent({ children }: PropsWithChildren<{}>) {
  return <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">{children}</div>;
}

export function ModalDescription({ children }: PropsWithChildren<{}>) {
  return <div className="text-base leading-tight text-iron mt-2">{children}</div>;
}

export function ModalTitle({ children }: PropsWithChildren<{}>) {
  return (
    <Dialog.Title as="h3" className="text-xl leading-normal font-medium text-black">
      {children}
    </Dialog.Title>
  );
}

export function ConfirmModal({
  onClose,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  ...props
}: ConfirmModalProps) {
  return (
    <Modal
      {...props}
      type="danger"
      onClose={onClose}
      buttons={
        <>
          <Button variant="secondary" onClick={onClose}>
            {cancelButtonText ?? <FormattedMessage id="modal.cancel" />}
          </Button>
          <Button variant="danger" onClick={onConfirm}>
            {confirmButtonText ?? <FormattedMessage id="modal.confirm" />}
          </Button>
        </>
      }
    />
  );
}

export function SuccessModal({ onClose, buttonText, ...props }: SuccessModalProps) {
  return (
    <Modal
      {...props}
      type="success"
      onClose={onClose}
      buttons={
        <Button variant="primary" onClick={onClose}>
          {buttonText ?? <FormattedMessage id="modal.ok" />}
        </Button>
      }
    />
  );
}

export function DangerModal({ onClose, buttonText, ...props }: DangerModalProps) {
  return (
    <Modal
      {...props}
      type="danger"
      onClose={onClose}
      buttons={
        <Button variant="primary" onClick={onClose}>
          {buttonText ?? <FormattedMessage id="modal.ok" />}
        </Button>
      }
    />
  );
}

export function Modal({ open, onClose, type, content, title, description, buttons }: ModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <ModalWrapper onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ModalOverlay />
        </Transition.Child>
        <ContentCenteringTrick />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <ModalBodyContainer>
            <ModalCloseButton onClick={onClose} />
            <ModalBody>
              {type && <ModalIcon className="mx-auto sm:mx-0" type={type} />}
              <ModalContent>
                {content ?? (
                  <>
                    <ModalTitle>{title}</ModalTitle>
                    <ModalDescription>{description}</ModalDescription>
                  </>
                )}
              </ModalContent>
            </ModalBody>
            <ModalButtonsContainer>{buttons}</ModalButtonsContainer>
          </ModalBodyContainer>
        </Transition.Child>
      </ModalWrapper>
    </Transition.Root>
  );
}

export default Modal;
