import { TemplateTask } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverBody,
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useTemplateTaskUpdateForm } from "../../hooks/useTemplateTask";
import { useTemplateTaskQuery } from "../../queries";
import { useNavigateToTemplateTask } from "../utils/navigation";
import { TemplateTaskMainForm, TemplateTaskSidebarForm } from "./components/forms/TemplateTaskForm";

type TemplateTaskEditSlideOverProps = {
  templateTaskId: number;
};

type TemplateTaskEditFormProps = {
  task: TemplateTask;
};

export function TemplateTaskEditForm({ task }: TemplateTaskEditFormProps) {
  const navigateToTemplateTask = useNavigateToTemplateTask();

  const { methods, handleSubmit } = useTemplateTaskUpdateForm(task, (task) => {
    navigateToTemplateTask(task);
  });

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader
          submitText={<FormattedMessage id="modal.save" />}
          onDiscard={() => navigateToTemplateTask(task)}
        >
          <FormattedMessage id="tasks.modal.heading.edit" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverBody>
            <TemplateTaskMainForm />
          </SlideOverBody>
          <SlideOverSidebar>
            <TemplateTaskSidebarForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TemplateTaskEditSlideOver({ templateTaskId }: TemplateTaskEditSlideOverProps) {
  const result = useTemplateTaskQuery(templateTaskId);

  return (
    <SlideOverWithLoading result={result} render={(task) => <TemplateTaskEditForm task={task} />} />
  );
}
