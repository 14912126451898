import { Task } from "@dh-critical-path/api-types";
import { OrderDirection, TasksOrderColumn } from "@dh-critical-path/shared";
import { ChatAlt2Icon, DocumentTextIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Badge } from "../../../components/common";
import {
  Table,
  TableDivider,
  TableHeadCell,
  TableHeadCellSortable,
  TableHeadRow,
  TableRow,
} from "../../../components/table";
import {
  TaskAvatar,
  TaskCell,
  TaskDepartment,
  TaskDependenciesPerDepartments,
  TaskLabels,
  TaskName,
} from "../../../components/table/tasks";
import { Status, badgeTextColor } from "../../../enums";
import { TasksFilterFormFields } from "../../../hooks/useTasksFilter";

type TasksTableProps = {
  tasks: Task[];
  headRenderer?: React.ReactNode;
};

type TaskRowProps = {
  task: Task;
};

type TaskColumnStatusCounterProps = {
  icon: React.ElementType;
  count: number;
};

function TasksTableHeadRow() {
  return (
    <TableHeadRow>
      <TableHeadCell className="text-left uppercase">
        <FormattedMessage id="table.tasks.name" />
      </TableHeadCell>
      <TableHeadCell className="hidden sm:table-cell text-right uppercase">
        <FormattedMessage id="table.tasks.start-date" />
      </TableHeadCell>
      <TableHeadCell className="hidden sm:table-cell text-right uppercase">
        <FormattedMessage id="table.tasks.due-date" />
      </TableHeadCell>
      <TableHeadCell className="hidden sm:table-cell text-right uppercase">
        <FormattedMessage id="table.tasks.status" />
      </TableHeadCell>
    </TableHeadRow>
  );
}

export function TasksTableHeadRowSortable() {
  const { watch, setValue } = useFormContext<TasksFilterFormFields>();
  const orderColumn = watch("order_column");
  const orderDirection = watch("order_direction");

  function handleSort(orderColumn: TasksOrderColumn, orderDirection: OrderDirection) {
    setValue("order_column", orderColumn);
    setValue("order_direction", orderDirection);
  }

  return (
    <TableHeadRow>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="name"
        className="text-left uppercase"
      >
        <FormattedMessage id="table.tasks.name" />
      </TableHeadCellSortable>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="start_date"
        className="hidden sm:table-cell text-right uppercase"
      >
        <FormattedMessage id="table.tasks.start-date" />
      </TableHeadCellSortable>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="due_date"
        className="hidden sm:table-cell text-right uppercase"
      >
        <FormattedMessage id="table.tasks.due-date" />
      </TableHeadCellSortable>
      <TableHeadCell className="hidden sm:table-cell text-right uppercase">
        <FormattedMessage id="table.tasks.status" />
      </TableHeadCell>
    </TableHeadRow>
  );
}

function TaskColumnName({ task }: TaskRowProps) {
  return (
    <TaskCell task={task} className="relative p-3 pb-1 sm:pb-3 w-full">
      <TaskLabels task={task} />
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0">
        <TaskAvatar task={task} />
        <div>
          <TaskDepartment task={task} link="filter" />
          <TaskName task={task} />
          <TaskDependenciesPerDepartments task={task} />
        </div>
      </div>
    </TaskCell>
  );
}

function TaskColumnStartDate({ task }: TaskRowProps) {
  return (
    <TaskCell task={task} className="p-1 px-3 sm:p-3 sm:text-right sm:min-w-26 whitespace-nowrap">
      {!task.is_milestone && task.start_date && (
        <span className="text-xs block">
          <span className="sm:hidden">
            <FormattedMessage id="table.tasks.start-date" />:
          </span>
          &nbsp;
          <FormattedDate value={task.start_date} year="numeric" month="short" day="2-digit" />
        </span>
      )}
    </TaskCell>
  );
}

function TaskColumnDueDate({ task }: TaskRowProps) {
  return (
    <TaskCell task={task} className="p-1 px-3 sm:p-3 sm:text-right sm:min-w-26 whitespace-nowrap">
      {task.due_date && (
        <span className="text-xs block">
          <span className="sm:hidden">
            <FormattedMessage id="table.tasks.due-date" />:
          </span>
          &nbsp;
          <FormattedDate value={task.due_date} year="numeric" month="short" day="2-digit" />
        </span>
      )}
    </TaskCell>
  );
}

function TaskColumnStatusCounter({ icon: Component, count }: TaskColumnStatusCounterProps) {
  return (
    <div className="inline-flex items-center flex-none text-stone-400">
      <Component className="w-4 fill-current" />
      <span className="pl-1 text-iron text-xs">{count}</span>
    </div>
  );
}

function TaskColumnStatus({ task }: TaskRowProps) {
  return (
    <TaskCell task={task} className="p-3 sm:text-right pt-1 sm:pt-3">
      <div className="inline-flex sm:flex-col items-end sm:space-x-4 sm:space-y-3">
        <Badge
          size="small"
          rounded
          className="absolute right-3 top-4 sm:static"
          color={badgeTextColor(task.taskStatus.status_type as unknown as Status)}
        >
          {task.taskStatus.name}
        </Badge>
        <div className="flex items-center flex-none space-x-3">
          <TaskColumnStatusCounter icon={ChatAlt2Icon} count={task.meta.comments_count} />
          <TaskColumnStatusCounter icon={DocumentTextIcon} count={task.meta.attachments_count} />
        </div>
      </div>
    </TaskCell>
  );
}

function TaskRow({ task }: TaskRowProps) {
  return (
    <TableRow>
      <TaskColumnName task={task} />
      <TaskColumnStartDate task={task} />
      <TaskColumnDueDate task={task} />
      <TaskColumnStatus task={task} />
    </TableRow>
  );
}

function TasksEmptyRow() {
  return (
    <tr>
      <td colSpan={4} className="text-base font-bold text-center py-6 bg-white">
        <FormattedMessage id="table.tasks.empty" />
      </td>
    </tr>
  );
}

export function TasksTable({ tasks, headRenderer = <TasksTableHeadRow /> }: TasksTableProps) {
  return (
    <Table>
      {headRenderer}
      {tasks.map((task, index) => (
        <Fragment key={task.id}>
          <TaskRow task={task} />
          {index + 1 !== tasks.length && <TableDivider />}
        </Fragment>
      ))}
      {tasks.length === 0 && <TasksEmptyRow />}
    </Table>
  );
}
