import {
  TemplateProject,
  TemplateProjectKey as TemplateProjectKeyType,
} from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { PageContainer, PageSectionHeader } from "../../../components/page";
import { Panel } from "../../../components/panel";
import { useTemplateProjectKeysQuery } from "../../../queries";

type TemplateProjectKeysPanelProps = {
  project: TemplateProject;
};

type TemplateProjectKeysProps = {
  project: TemplateProject;
};

type TemplateProjectKeyProps = {
  projectKey: TemplateProjectKeyType;
};

function TemplateProjectKey({ projectKey }: TemplateProjectKeyProps) {
  return (
    <div className="text-base space-y-1 leading-tight whitespace-pre-line break-words">
      <p className="text-black">{projectKey.name}</p>
    </div>
  );
}

function TemplateProjectKeysEmpty() {
  return (
    <div>
      <FormattedMessage id="templates.projects.form.keys.empty" />
    </div>
  );
}

function TemplateProjectKeys({ project }: TemplateProjectKeysProps) {
  const { data } = useTemplateProjectKeysQuery(project.id);

  if (!data) {
    return null;
  }

  if (!data.length) {
    return <TemplateProjectKeysEmpty />;
  }

  return (
    <PageContainer type="grid">
      {data.map((key) => (
        <TemplateProjectKey key={key.id} projectKey={key} />
      ))}
    </PageContainer>
  );
}

export function TemplateProjectKeysPanel({ project }: TemplateProjectKeysPanelProps) {
  return (
    <Panel>
      <TemplateProjectKeys project={project} />
    </Panel>
  );
}

export function TemplateProjectKeysPanelHeader({ project }: TemplateProjectKeysPanelProps) {
  const { data } = useTemplateProjectKeysQuery(project.id);

  return (
    <PageSectionHeader>
      <FormattedMessage id="headings.templates.keys" values={{ count: data?.length ?? 0 }} />
    </PageSectionHeader>
  );
}
