import { useQuery } from "react-query";
import { getTitle, getTitles } from "../api";

export const titlesKeys = {
  all: () => ["titles"],
  templateTitle: (...args: Parameters<typeof getTitle>) => ["titles", ...args],
  templateTitles: (...args: Parameters<typeof getTitles>) => ["titles", ...args],
};

export function useTitleQuery(id: number) {
  return useQuery(titlesKeys.templateTitle(id), () => {
    return getTitle(id);
  });
}

export function useTitlesQuery(...args: Parameters<typeof getTitles>) {
  return useQuery(titlesKeys.templateTitles(...args), () => {
    return getTitles(...args);
  });
}
