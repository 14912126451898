import { Department as DepartmentType } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useDepartmentMembersAddForm } from "../../hooks/useDepartment";
import { useDepartmentQuery } from "../../queries";
import { useNavigateToDepartment } from "../utils/navigation";
import { DepartmentMembersForm } from "./components/forms/DepartmentMembersForm";

type DepartmentMembersAddSlideOverProps = {
  departmentId: number;
};

type DepartmentMembersAddFormProps = {
  department: DepartmentType;
};

function DepartmentMembersAddForm({ department }: DepartmentMembersAddFormProps) {
  const navigate = useNavigateToDepartment();

  const { methods, handleSubmit } = useDepartmentMembersAddForm(department, navigate);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="departments.modal.heading.add-member" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <DepartmentMembersForm department={department} />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function DepartmentMembersAddSlideOver({
  departmentId,
}: DepartmentMembersAddSlideOverProps) {
  const result = useDepartmentQuery(departmentId);

  return (
    <SlideOverWithLoading
      result={result}
      render={(department) => <DepartmentMembersAddForm department={department} />}
    />
  );
}
