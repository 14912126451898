import { TemplateDepartment } from "@dh-critical-path/api-types";
import { useIntl } from "react-intl";
import { ControlledSelect } from "../../../../components/form/controlled";
import { useUsersForFilterQuery } from "../../../../queries";

type TemplateDepartmentMembersFormProps = {
  department: TemplateDepartment;
};

export function TemplateDepartmentMembersForm({ department }: TemplateDepartmentMembersFormProps) {
  const { formatMessage } = useIntl();

  const { data } = useUsersForFilterQuery({ excluding_template_department_ids: [department.id] });

  return (
    <>
      <ControlledSelect
        name="member_ids"
        label={formatMessage({ id: "department.form.member_ids" })}
        placeholder={formatMessage({ id: "department.form.member_ids.placeholder" })}
        options={data?.map((user) => ({ ...user, user }))}
        showHash
        isMulti
        required
      />
    </>
  );
}
