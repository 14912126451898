import { TemplateProject } from "@dh-critical-path/api-types";
import { Attachments, AttachmentsPanel } from "../../components/form/Attachments";
import { PageContainer } from "../../components/page";
import { useTemplateProjectAttachments } from "../../hooks/useAttachments";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

type TemplateProjectAttachmentsPageProps = {
  project: TemplateProject;
};

export function TemplateProjectAttachmentsPage({ project }: TemplateProjectAttachmentsPageProps) {
  useDocumentTitle(`${project.name} - Attachments`);

  const { attachments, isUploading, handleUpload, handleDelete } = useTemplateProjectAttachments(
    project.id
  );

  return (
    <PageContainer>
      <AttachmentsPanel>
        <Attachments
          onUpload={handleUpload}
          onDelete={handleDelete}
          attachments={attachments}
          isUploading={isUploading}
          isSearchable
          isRemovable
        />
      </AttachmentsPanel>
    </PageContainer>
  );
}
