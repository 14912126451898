import { Request, TemplateProject } from "@dh-critical-path/api-types";
import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Button } from "../../components/button";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useTemplateDepartmentQuery } from "../../queries";
import { useNavigateToTemplateTaskCreate } from "../utils/navigation";
import { TemplateTasksGroup } from "./components/TempalteTasksGroup";
import { TemplateDepartmentHeader } from "./components/TemplateDepartmentHeader";
import {
  TemplateDepartmentMembersPanel,
  TemplateDepartmentMembersPanelHeader,
} from "./components/TemplateDepartmentMembers";
import { TemplateTitlesPanel, TemplateTitlesPanelHeader } from "./components/TemplateTitles";

type TemplateProjectDepartmentPageProps = {
  project: TemplateProject;
};

export function TemplateProjectDepartmentPage({ project }: TemplateProjectDepartmentPageProps) {
  const params = useParams<"departmentId">();

  const navigateToTemplateTaskCreate = useNavigateToTemplateTaskCreate();

  const { data } = useTemplateDepartmentQuery(Number(params.departmentId));

  useDocumentTitle(data ? `${project.name} - ${data.name}` : project.name);

  if (!data) {
    return null;
  }

  const filter: Request.TemplateTasks.GetTemplateTasks = {
    template_project_ids: [project.id],
    template_department_ids: [data.id],
  };

  return (
    <PageContainer>
      <Layout.Container>
        {/* header */}
        <Layout.Column width={12}>
          <TemplateDepartmentHeader department={data} />
        </Layout.Column>
        {/* members column */}
        <Layout.Column lgWidth={4}>
          <Layout.Spacer space={8}>
            <Layout.Spacer space={4}>
              <TemplateDepartmentMembersPanelHeader department={data} />
              <TemplateDepartmentMembersPanel department={data} />
            </Layout.Spacer>
            <Layout.Spacer space={4}>
              <TemplateTitlesPanelHeader department={data} />
              <TemplateTitlesPanel department={data} />
            </Layout.Spacer>
          </Layout.Spacer>
        </Layout.Column>
        {/* tasks column */}
        <Layout.Column lgWidth={8}>
          <Layout.Spacer space={8}>
            {/* tasks */}
            <TemplateTasksGroup
              filter={{ ...filter }}
              header={(count) => (
                <PageSectionHeader
                  buttons={
                    <Button
                      icon={PlusIcon}
                      onClick={() => navigateToTemplateTaskCreate(project, data)}
                    >
                      <FormattedMessage id="tasks.create" />
                    </Button>
                  }
                >
                  <FormattedMessage id="headings.tasks" values={{ count }} />
                </PageSectionHeader>
              )}
            />
          </Layout.Spacer>
        </Layout.Column>
      </Layout.Container>
    </PageContainer>
  );
}
