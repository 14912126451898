import { Request, TemplateDepartment } from "@dh-critical-path/api-types";
import { useTemplateDepartmentsTasksStatsQuery } from "../../../queries";
import { TemplateTasksStatsCards, TemplateTasksStatsEntityLink } from "./TemplateTasksStatsCards";

type TemplateDepartmentsTasksStatsCardsProps = {
  header: (count: number) => React.ReactElement;
  filter: Request.TemplateDepartments.GetTemplateDepartmentsTasksStats;
  entityLink: TemplateTasksStatsEntityLink<TemplateDepartment>;
  showHeaderAsFallback?: boolean;
};

export function TemplateDepartmentsTasksStatsCards({
  header,
  filter,
  entityLink,
  showHeaderAsFallback = false,
}: TemplateDepartmentsTasksStatsCardsProps) {
  const { data } = useTemplateDepartmentsTasksStatsQuery(filter);

  if (!data || !data.length) {
    return showHeaderAsFallback ? header(data?.length ?? 0) : null;
  }

  return (
    <>
      {header(data?.length ?? 0)}
      <TemplateTasksStatsCards items={data} entityLink={entityLink} />
    </>
  );
}
