import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes, useParams } from "react-router";
import { getProject } from "../../api";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { projectsKeys } from "../../queries";
import { ErrorFallback } from "../components/ErrorFallback";
import ProjectActivityPage from "./ProjectActivityPage";
import ProjectAttachmentsPage from "./ProjectAttachmentsPage";
import ProjectDepartmentPage from "./ProjectDepartmentPage";
import ProjectDepartmentsPage from "./ProjectDepartmentsPage";
import ProjectOverviewPage from "./ProjectOverviewPage";
import ProjectTasksPage from "./ProjectTasksPage";
import ProjectTimelinePage from "./ProjectTimelinePage";
import { ProjectPageLayout } from "./components/ProjectPageLayout";

function useProjectId(): number {
  const params = useParams<"id">();
  const [projectId, setProjectId] = useState(Number(params.id!));

  useEffect(() => {
    setProjectId(Number(params.id));
  }, [params.id]);

  return projectId;
}

export function ProjectRoutes() {
  const projectId = useProjectId();

  const { data, error } = useQuery(projectsKeys.project(projectId), () => getProject(projectId));

  useDocumentTitle(data?.name);

  if (error) {
    return <ErrorFallback error={error} />;
  } else if (!data) {
    return null;
  }

  return (
    <ProjectPageLayout project={data}>
      <Routes>
        <Route path="/" element={<ProjectOverviewPage project={data} />} />
        <Route path="/tasks" element={<ProjectTasksPage project={data} />} />
        <Route path="/departments" element={<ProjectDepartmentsPage project={data} />} />
        <Route
          path="/departments/:departmentId"
          element={<ProjectDepartmentPage project={data} />}
        />
        <Route path="/attachments" element={<ProjectAttachmentsPage project={data} />} />
        <Route path="/activity" element={<ProjectActivityPage project={data} />} />
        <Route path="/timeline" element={<ProjectTimelinePage project={data} />} />
      </Routes>
    </ProjectPageLayout>
  );
}
