import classnames from "classnames";
import React, { ButtonHTMLAttributes } from "react";
import { styled } from "../../utils/styled";
import Spinner from "../common/Spinner";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "danger" | "text";
  icon?: React.ElementType;
  loading?: boolean;
}

interface ButtonIconProps {
  icon: React.ElementType;
}

const ButtonIcon = styled<HTMLSpanElement, ButtonIconProps>(({ icon: Component }) => (
  <span className="fill-current flex flex-none items-center justify-center h-4 w-4 mr-2">
    <Component className="w-4" />
  </span>
));

const ButtonStyled = styled<HTMLButtonElement, ButtonProps>(
  ({ variant, disabled, className, loading, children, ...props }) => (
    <button
      {...props}
      disabled={disabled}
      className={classnames(
        "relative inline-flex items-center justify-center leading-tight whitespace-nowrap focus:outline-none overflow-hidden",
        {
          "h-7.5 px-4 py-1.5 text-sm shadow-sm rounded-md font-bold focus:ring": variant !== "text",
          "text-iron hover:text-black": variant === "text",
          "opacity-50 pointer-events-none": disabled,
          "pointer-events-none": loading,
          "text-white bg-violet hover:bg-violet-800 focus:ring-violet-600": variant === "primary",
          "bg-white border border-iron-100 text-iron hover:bg-beige-100 focus:ring-beige":
            variant === "secondary",
          "text-white bg-red-500 hover:bg-red-600 focus:ring-red-300": variant === "danger",
        },
        className
      )}
    >
      {children}
    </button>
  )
);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { variant = "primary", icon, type = "button", disabled, className, loading, children, ...props },
  ref
) {
  return (
    <ButtonStyled
      {...props}
      type={type}
      variant={variant}
      ref={ref}
      loading={loading}
      disabled={disabled}
      className={className}
    >
      {icon && <ButtonIcon icon={icon} />}
      {loading && <Spinner variant={variant} />}
      {children}
    </ButtonStyled>
  );
});

export default Button;
