import { Request, Response } from "@dh-critical-path/api-types";
import axios from "axios";
import { stringify } from "qs";

export function getTemplateProjectsTasksStats(
  params: Request.TemplateProjects.GetTemplateProjectsTasksStats
) {
  return axios
    .get<Response.TemplateProjects.GetTemplateProjectsTasksStats>(
      `/api/stats/template-tasks/for-projects`,
      { params }
    )
    .then(({ data }) => data);
}

export function getTemplateProjectsForFilter(
  params: Request.TemplateProjects.GetTemplateProjectsForFilter
) {
  return axios
    .get<Response.TemplateProjects.GetTemplateProjectsForFilter>(`/api/templates/projects/filter`, {
      params,
    })
    .then(({ data }) => data);
}

export function getTemplateProject(id: number) {
  return axios
    .get<Response.TemplateProjects.GetTemplateProject>(`/api/templates/projects/${id}`)
    .then(({ data }) => data);
}

export function getTemplateProjectKeys(id: number) {
  return axios
    .get<Response.TemplateProjects.GetTemplateProjectKeys>(`/api/templates/projects/${id}/keys`)
    .then(({ data }) => data);
}

export function createTemplateProject(params: Request.TemplateProjects.CreateTemplateProject) {
  return axios
    .post<Response.TemplateProjects.CreateTemplateProject>(`/api/templates/projects`, params)
    .then(({ data }) => data);
}

export function updateTemplateProject({
  id,
  ...params
}: Request.TemplateProjects.UpdateTemplateProject) {
  return axios
    .patch<Response.TemplateProjects.UpdateTemplateProject>(`/api/templates/projects/${id}`, params)
    .then(({ data }) => data);
}

export function deleteTemplateProject({
  template_project_id,
}: Request.TemplateProjects.DeleteTemplateProject) {
  return axios.delete<Response.TemplateProjects.DeleteTemplateProject>(
    `/api/templates/projects/${template_project_id}`
  );
}

export function getTemplateDepartmentsTasksStats(
  params: Request.TemplateDepartments.GetTemplateDepartmentsTasksStats
) {
  return axios
    .get<Response.TemplateDepartments.GetTemplateDepartmentsTasksStats>(
      `/api/stats/template-tasks/for-departments`,
      { params }
    )
    .then(({ data }) => data);
}

export function getTemplateDepartmentsForFilter(
  params: Request.TemplateDepartments.GetTemplateDepartmentsForFilter
) {
  return axios
    .get<Response.TemplateDepartments.GetTemplateDepartmentsForFilter>(
      `/api/templates/departments/filter`,
      { params }
    )
    .then(({ data }) => data);
}

export function getTemplateDepartment(id: number) {
  return axios
    .get<Response.TemplateDepartments.GetTemplateDepartment>(`/api/templates/departments/${id}`)
    .then(({ data }) => data);
}

export function createTemplateDepartment(
  params: Request.TemplateDepartments.CreateTemplateDepartment
) {
  return axios
    .post<Response.TemplateDepartments.CreateTemplateDepartment>(
      `/api/templates/departments`,
      params
    )
    .then(({ data }) => data);
}

export function updateTemplateDepartment({
  id,
  ...params
}: Request.TemplateDepartments.UpdateTemplateDepartment) {
  return axios
    .patch<Response.TemplateDepartments.UpdateTemplateDepartment>(
      `/api/templates/departments/${id}`,
      params
    )
    .then(({ data }) => data);
}

export function removeTemplateDepartment(id: number) {
  return axios.delete<void>(`/api/templates/departments/${id}`).then(({ data }) => data);
}

export function addMembersToTemplateDepartment({
  id,
  ...params
}: Request.TemplateDepartments.AddMembersToTemplateDepartment) {
  return axios
    .post<Response.TemplateDepartments.AddMembersToTemplateDepartment>(
      `/api/templates/departments/${id}/members`,
      params
    )
    .then(({ data }) => data);
}

export function removeMemberFromTemplateDepartment({
  id,
  member_id,
}: Request.TemplateDepartments.RemoveMemberFromTemplateDepartment) {
  return axios
    .delete<Response.TemplateDepartments.RemoveMemberFromTemplateDepartment>(
      `/api/templates/departments/${id}/members/${member_id}`
    )
    .then(({ data }) => data);
}

export function getTemplateDepartmentMembers(id: number) {
  return axios
    .get<Response.TemplateDepartments.GetTemplateDepartmentMembers>(
      `/api/templates/departments/${id}/members`
    )
    .then(({ data }) => data);
}

export function getTemplateTitle(id: number) {
  return axios
    .get<Response.TemplateTitles.GetTemplateTitle>(`/api/templates/titles/${id}`)
    .then(({ data }) => data);
}

export function getTemplateTitles(params: Request.TemplateTitles.GetTemplateTitles) {
  return axios
    .get<Response.TemplateTitles.GetTemplateTitles>(`/api/templates/titles`, { params })
    .then(({ data }) => data);
}

export function createTemplateTitle(params: Request.TemplateTitles.CreateTemplateTitle) {
  return axios
    .post<Response.TemplateTitles.CreateTemplateTitle>(`/api/templates/titles`, params)
    .then(({ data }) => data);
}

export function updateTemplateTitle({
  template_title_id: id,
  ...params
}: Request.TemplateTitles.UpdateTemplateTitle) {
  return axios
    .patch<Response.TemplateTitles.UpdateTemplateTitle>(`/api/templates/titles/${id}`, params)
    .then(({ data }) => data);
}

export function removeTemplateTitle(id: number) {
  return axios.delete<void>(`/api/templates/titles/${id}`).then(({ data }) => data);
}

export function removeMemberFromTemplateTitle({
  template_title_id: id,
  member_id,
}: Request.TemplateTitles.RemoveMemberFromTemplateTitle) {
  return axios
    .delete<Response.TemplateTitles.RemoveMemberFromTemplateTitle>(
      `/api/templates/titles/${id}/members/${member_id}`
    )
    .then(({ data }) => data);
}

export function getTemplateTask(id: number) {
  return axios
    .get<Response.TemplateTasks.GetTemplateTask>(`/api/templates/tasks/${id}`)
    .then(({ data }) => data);
}

export function getTemplateTasks(params: Request.TemplateTasks.GetTemplateTasks) {
  return axios
    .get<Response.TemplateTasks.GetTemplateTasks>("/api/templates/tasks", {
      params,
      paramsSerializer: (params) => stringify(params),
    })
    .then(({ data }) => data);
}

export function getTemplateTasksForFilter(params: Request.TemplateTasks.GetTemplateTasksForFilter) {
  return axios
    .get<Response.TemplateTasks.GetTemplateTasksForFilter>("/api/templates/tasks/filter", {
      params,
    })
    .then(({ data }) => data);
}

export function getTemplateTaskAssigneesForFilter(
  params: Request.TemplateTasks.GetTemplateTaskAssigneesForFilter
) {
  return axios
    .get<Response.TemplateTasks.GetTemplateTaskAssigneesForFilter>(
      "/api/templates/tasks/assignees/filter",
      {
        params,
      }
    )
    .then(({ data }) => data);
}

export function createTemplateTask(params: Request.TemplateTasks.CreateTemplateTask) {
  return axios
    .post<Response.TemplateTasks.CreateTemplateTask>(`/api/templates/tasks`, params)
    .then(({ data }) => data);
}

export function updateTemplateTask(params: Request.TemplateTasks.UpdateTemplateTask) {
  return axios
    .patch<Response.TemplateTasks.UpdateTemplateTask>(
      `/api/templates/tasks/${params.template_task_id}`,
      params
    )
    .then(({ data }) => data);
}

export function removeTemplateTask(id: number) {
  return axios.delete<void>(`/api/templates/tasks/${id}`);
}

export function restoreTemplateTask(id: number) {
  return axios.post<void>(`/api/templates/tasks/${id}/restore`);
}

export function compareTemplateTasks(params: Request.TemplateTasks.CompareTemplateTasks) {
  return axios
    .get<Response.TemplateTasks.CompareTemplateTasks>(`/api/templates/tasks/compare`, { params })
    .then(({ data }) => data);
}
