import { forwardRef } from "react";
import { Panel } from "../panel";
import { PanelProps } from "../panel/Panel";

type FormPanelProps = Omit<PanelProps, "overflow">;

export const FormPanel = forwardRef<HTMLDivElement, FormPanelProps>(function FormPanel(
  { children, ...props },
  ref
) {
  return (
    <Panel {...props} ref={ref} overflow="overflow-visible">
      <div className="grid grid-cols-6 gap-6">{children}</div>
    </Panel>
  );
});
