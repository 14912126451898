import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverBody,
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
} from "../../components/form/SlideOver";
import { useTemplateProjectCreateForm } from "../../hooks/useTemplateProject";
import { useNavigateToTemplateProject } from "../utils/navigation";
import {
  TemplateProjectForm,
  TemplateProjectSidebarForm,
} from "./components/forms/TemplateProjectForm";

function TemplateProjectCreateForm() {
  const navigate = useNavigateToTemplateProject();

  const { methods, handleSubmit } = useTemplateProjectCreateForm(navigate);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="templates.projects.create" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverBody>
            <TemplateProjectForm />
          </SlideOverBody>
          <SlideOverSidebar>
            <TemplateProjectSidebarForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TemplateProjectCreateSlideOver() {
  return <TemplateProjectCreateForm />;
}
