export function Loader() {
  const count = 8;
  const size = 0.3;
  const width = 2.5;
  const speed = 0.15;
  const angle = 360 / count;
  const elements = Array.from(Array(count).keys());

  return (
    <div
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"
      style={{
        width: `${width}rem`,
        height: `${width}rem`,
      }}
    >
      {elements.map((index) => (
        <div
          key={index}
          className="absolute left-1/2 top-1/2 h-px w-full"
          style={{
            transform: `translateX(-50%) translateY(-50%) rotate(${index * angle}deg`,
          }}
        >
          <div
            className="rounded-full bg-blue absolute bg-violet"
            style={{
              width: `${size}rem`,
              height: `${size}rem`,
              transform: `rotate(${index * angle * -1}deg`,
              animation: `preloader-animation ${count * speed}s linear infinite ${index * speed}s`,
            }}
          />
        </div>
      ))}
    </div>
  );
}
