import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { RestrictedRoute, ToastRoot } from "./components/common";
import { AuthProvider } from "./hooks/useAuth";
import { ToastProvider } from "./hooks/useToast";
import { useTrackLocation } from "./hooks/useTracking";
import I18nProvider from "./i18n";
import QueryProvider from "./queries";
import { LoginPage, LogoutPage } from "./routes/Auth";
import { DashboardPage } from "./routes/Dashboard/DashboardPage";
import { ProjectsRoutes } from "./routes/Projects/ProjectsRoutes";
import { SlideOvers } from "./routes/SlideOvers";
import { TasksPage } from "./routes/Tasks/TasksPage";
import { TemplatesRoutes } from "./routes/Templates/TemplatesRoutes";
import { TrackingRoutes } from "./routes/Tracking/TrackingRoutes";
import { UsersRoutes } from "./routes/Users/UsersRoutes";

function Routes() {
  useTrackLocation();

  return useRoutes([
    // auth
    { path: "/auth/login", element: <RestrictedRoute element={<LoginPage />} notAuthOnly /> },
    { path: "/auth/logout", element: <RestrictedRoute element={<LogoutPage />} authOnly /> },
    // app
    { path: "/", element: <RestrictedRoute element={<DashboardPage />} authOnly /> },
    {
      path: "/projects/*",
      element: <RestrictedRoute element={<ProjectsRoutes />} authOnly />,
    },
    { path: "/tasks", element: <RestrictedRoute element={<TasksPage />} authOnly /> },
    {
      path: "/templates/*",
      element: <RestrictedRoute element={<TemplatesRoutes />} adminOnly />,
    },
    {
      path: "/users/*",
      element: <RestrictedRoute element={<UsersRoutes />} adminOnly />,
    },
    {
      path: "/tracking/*",
      element: <RestrictedRoute element={<TrackingRoutes />} adminOnly />,
    },
    { path: "*", element: <div>404</div> },
    //
  ]);
}

function App() {
  return (
    <I18nProvider>
      <QueryProvider>
        <AuthProvider>
          <ToastProvider>
            <Router>
              <Routes />
              <SlideOvers />
            </Router>
            <ToastRoot />
          </ToastProvider>
        </AuthProvider>
      </QueryProvider>
    </I18nProvider>
  );
}

export default App;
