import { Project, Request, TemplateProjectKey } from "@dh-critical-path/api-types";
import { ProcessType } from "@dh-critical-path/shared";
import { useCallback } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createProject,
  deleteProject,
  getTemplateProject,
  getTemplateProjectKeys,
  updateProject,
} from "../api";
import { hasAnyOf } from "../components/form/TagsInput";
import { projectsKeys, tasksKeys } from "../queries";
import { interceptValidationErrorsAndThrow } from "../utils/form";
import { dateToSqlFormat } from "../utils/misc";
import { nextIdNumeric } from "../utils/nextId";

export type ProjectCreateFields = Request.Projects.CreateProject;

export type ProjectUpdateFields = Request.Projects.UpdateProject;

const keyValuesDefaultValue = [
  {
    list_id: 1,
    name: "",
    value: "",
    sort_order: 0,
  },
];

export function useProjectCreateForm() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(createProject);

  const methods = useForm<ProjectCreateFields>({
    defaultValues: {
      template_project_id: null,
      process_type: ProcessType.OPENING,
      approximate_completion_date: "",
      name: "",
      description: "",
      due_date: dateToSqlFormat(new Date()),
      jira_key: "",
      jira_project_create: false,
      tags: [hasAnyOf()],
      key_values: keyValuesDefaultValue,
    },
  });

  useTemplateKeysSync(methods);
  useProcessTypeSync(methods);

  const handleCreate = async ({ key_values, ...data }: ProjectCreateFields) => {
    try {
      const project = await mutateAsync({
        ...data,
        tags: data.template_project_id ? data.tags : [],
        key_values: key_values.map((key, index) => ({ ...key, sort_order: index })),
      });

      queryClient.invalidateQueries(projectsKeys.allProjectsTasksStats());

      return project;
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  };

  return { methods, handleCreate };
}

export function useProjectEditForm(project: Project) {
  const methods = useForm<ProjectUpdateFields>({
    defaultValues: {
      project_id: project.id,
      template_project_id: project.template_project_id,
      process_type: project.process_type,
      approximate_completion_date: project.approximate_completion_date,
      name: project.name,
      description: project.description,
      due_date: project.due_date,
      key_values: project.projectKeyValues.map((keyValue, index) => ({
        id: keyValue.id,
        template_project_key_id: keyValue.template_project_key_id,
        list_id: keyValue.id,
        name: keyValue.name,
        value: keyValue.value,
        sort_order: index,
      })),
    },
  });

  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateProject);

  const handleUpdate = useCallback(
    async ({ key_values, approximate_completion_date, ...data }: ProjectUpdateFields) => {
      try {
        await mutateAsync({
          ...data,
          approximate_completion_date: approximate_completion_date || null,
          key_values: key_values.map((key, index) => ({ ...key, sort_order: index })),
        });

        queryClient.invalidateQueries(tasksKeys.allTasks());
        queryClient.invalidateQueries(projectsKeys.project(project.id));
        queryClient.invalidateQueries(projectsKeys.keyFacts(project.id));
      } catch (e) {
        interceptValidationErrorsAndThrow(methods, e);
      }
    },
    [queryClient, mutateAsync, methods, project]
  );

  return { methods, handleUpdate };
}

function useTemplateKeysSync(methods: UseFormReturn<ProjectCreateFields>) {
  const templateProjectId = methods.watch("template_project_id");

  const keyValues = methods.watch("key_values");

  const updateKeyValues = useCallback(
    (templateKeys: TemplateProjectKey[]) => {
      const newKeyValues = keyValues.filter(
        (item) => !item.template_project_key_id && (item.value || item.name)
      );

      const templateKeyValues = templateKeys.map((key) => ({
        template_project_key_id: key.id,
        name: key.name,
        value: "",
      }));

      const value = [...newKeyValues, ...templateKeyValues].map((item, index) => ({
        ...item,
        list_id: nextIdNumeric(),
        sort_order: index,
      }));

      methods.setValue("key_values", value.length ? value : keyValuesDefaultValue);
    },
    [keyValues, methods]
  );

  useQuery(
    ["templates:projects:keys", templateProjectId],
    () => (templateProjectId ? getTemplateProjectKeys(templateProjectId) : Promise.resolve(null)),
    { onSuccess: (data) => updateKeyValues(data ?? []) }
  );
}

function useProcessTypeSync(methods: UseFormReturn<ProjectCreateFields>) {
  const templateProjectId = methods.watch("template_project_id");

  useQuery(
    ["templates:projects", templateProjectId],
    () => {
      return templateProjectId ? getTemplateProject(templateProjectId) : Promise.resolve(null);
    },
    {
      onSuccess: (templateProject) => {
        if (templateProject) {
          methods.setValue("process_type", templateProject.process_type);
        }
      },
    }
  );
}

export function useProjectDelete(project: Project) {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteProject);

  const handleRemove = useCallback(async () => {
    await mutateAsync({ project_id: project.id });
    queryClient.invalidateQueries(projectsKeys.allProjectsTasksStats());
  }, [queryClient, mutateAsync, project]);

  return { handleRemove, isLoading };
}
