import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: "tracked",
    },
  },
});

const QueryProvider: React.FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export default QueryProvider;

export * from "./auth";
export * from "./attachments";
export * from "./comments";
export * from "./projects";
export * from "./tasks";
export * from "./titles";
export * from "./departments";
export * from "./users";
export * from "./templates";
export * from "./tracking";
