import { TemplateProject } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useTasksComparisonFilter } from "../../hooks/useTasksComparisonFilter";
import { TemplateProjectComparisonActions } from "./components/TemplateProjectComparisonActions";
import { TemplateTasksComparisonTable } from "./components/TemplateTasksComparisonTable";
import { TemplateTasksComparisonFilter } from "./components/forms/TemplateTasksComparisonFilter";

type TemplateProjectComparisonPageProps = {
  project: TemplateProject;
};

export function TemplateProjectComparisonPage({ project }: TemplateProjectComparisonPageProps) {
  const { methods, query } = useTasksComparisonFilter({
    template_project_id: project.id,
  });

  useDocumentTitle(`${project.name} - Compare to project`);

  return (
    <PageContainer>
      <Layout.Column>
        <Layout.Spacer space={8}>
          <FormProvider {...methods}>
            <PageSectionHeader buttons={<TemplateProjectComparisonActions project={project} />}>
              <FormattedMessage id="headings.tasks" values={{ count: query.data?.length ?? 0 }} />
            </PageSectionHeader>
            <TemplateTasksComparisonFilter />
            <TemplateTasksComparisonTable templateProject={project} query={query} />
          </FormProvider>
        </Layout.Spacer>
      </Layout.Column>
    </PageContainer>
  );
}
