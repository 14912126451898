import { Department, Title, User } from "@dh-critical-path/api-types";
import { prefixTitleId } from "@dh-critical-path/shared";
import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { ActionButton, Button } from "../../../components/button";
import { useActionWithConfirmation } from "../../../components/modals";
import { PageSectionHeader } from "../../../components/page";
import { Panel, PanelFooter } from "../../../components/panel";
import { ProfileRow } from "../../../components/profile";
import { useTitleMemberRemove, useTitleRemove } from "../../../hooks/useTitle";
import { useTasksQuery, useTitlesQuery } from "../../../queries";
import { fullName } from "../../../utils/text";
import { useNavigateToTitleCreate, useNavigateToTitleEdit } from "../../utils/navigation";
import { AffectedTasksDetails } from "./AffectedTasksDetails";

type TitlesProps = {
  department: Department;
};

type TitleProps = {
  title: Title;
};

type TitleMemberProps = {
  title: Title;
  member: User;
};

export function useTitleRemovingConfirmationDialog(handleRemove: (title: Title) => Promise<any>) {
  const { modal, handleWithConfirmation } = useActionWithConfirmation(
    handleRemove,
    async (title) => ({
      description: <TitleRemovingConfirmationText title={title} />,
    })
  );

  return { modal, handleRemoveWithConfirmation: handleWithConfirmation };
}

function TitleRemovingConfirmationText({ title }: { title: Title }) {
  const params = useParams<"id">();

  const projectId = Number(params.id);

  const tasksQuery = useTasksQuery({
    department_ids: [title.department_id],
    assignee_ids: [prefixTitleId(title.id)],
    limit: 1,
  });

  return (
    <div>
      <p>Do you really want to remove this title?</p>
      <AffectedTasksDetails
        totalTasks={tasksQuery.data?.meta.total ?? 0}
        departmentId={title.department_id}
        projectId={projectId}
        assigneeId={prefixTitleId(title.id)}
      />
    </div>
  );
}

function TitleRemoveAction({ title }: TitleProps) {
  const { handleRemove, isLoading } = useTitleRemove();

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useTitleRemovingConfirmationDialog(handleRemove);

  return (
    <>
      <ActionButton
        variant="delete"
        onClick={() => handleRemoveWithConfirmation(title)}
        loading={isLoading}
      />
      {confirmModal}
    </>
  );
}

function TitleEditAction({ title }: TitleProps) {
  const navigateToTitleEdit = useNavigateToTitleEdit();

  return (
    <>
      <ActionButton variant="edit" onClick={() => navigateToTitleEdit(title)} />
    </>
  );
}

function TitleMemberRemoveAction({ title, member }: TitleMemberProps) {
  const { handleRemove, isLoading } = useTitleMemberRemove();

  const { modal: confirmModal, handleWithConfirmation: handleRemoveWithConfirmation } =
    useActionWithConfirmation(handleRemove, async (title, user) => ({
      description: `Do you really want to remove ${fullName(user)} from ${title.name}`,
    }));

  return (
    <>
      <ActionButton
        variant="delete"
        onClick={() => handleRemoveWithConfirmation(title, member)}
        loading={isLoading}
      />
      {confirmModal}
    </>
  );
}

function TitleActions({ title }: TitleProps) {
  return (
    <div className="flex md:invisible group-hover:visible items-center space-x-3 pl-3">
      <TitleRemoveAction title={title} />
      <TitleEditAction title={title} />
    </div>
  );
}

function TitleMemberActions({ title, member }: TitleMemberProps) {
  return (
    <div className="flex md:invisible group-hover:visible items-center space-x-3 pl-3">
      <TitleMemberRemoveAction title={title} member={member} />
    </div>
  );
}

function TitleMember({ title, member }: TitleMemberProps) {
  return (
    <div className="flex w-full justify-between items-start md:items-center relative group mb-4 last:mb-0">
      <ProfileRow user={member} />
      <TitleMemberActions title={title} member={member} />
    </div>
  );
}

function TitlesEmpty() {
  return (
    <div className="py-6 text-center">
      <FormattedMessage id="titles.empty" />
    </div>
  );
}

function TitleMembersEmpty() {
  return (
    <div className="py-6 text-center">
      <FormattedMessage id="title.members.empty" />
    </div>
  );
}

function TitleMembers({ title }: TitleProps) {
  if (!title.members.length) {
    return <TitleMembersEmpty />;
  }

  return (
    <div>
      {title.members.map((member) => (
        <TitleMember key={member.id} title={title} member={member} />
      ))}
    </div>
  );
}

function TitlesListItem({ title }: TitleProps) {
  return (
    <div className="py-6 border-t border-stone-100 first:pt-0 first:border-t-0">
      <div className="flex justify-between group">
        <h3 className="font-medium text-xl mb-3 leading-tight">
          {title.name} ({title.members.length})
        </h3>
        <TitleActions title={title} />
      </div>
      <TitleMembers title={title} />
    </div>
  );
}

function TitlesList({ department }: TitlesProps) {
  const { data } = useTitlesQuery({ department_id: department.id });

  if (!data || !data.length) {
    return <TitlesEmpty />;
  }

  return (
    <>
      {data.map((title) => (
        <TitlesListItem key={title.id} title={title} />
      ))}
    </>
  );
}

export function TitlesPanel({ department }: TitlesProps) {
  return (
    <Panel>
      <TitlesList department={department} />
      <TitlesPanelFooter department={department} />
    </Panel>
  );
}

function TitlesPanelFooter({ department }: TitlesProps) {
  const navigateToTitlesAdd = useNavigateToTitleCreate();

  return (
    <PanelFooter>
      <Button
        icon={() => <PlusIcon className="w-4" />}
        onClick={() => navigateToTitlesAdd(department)}
      >
        <FormattedMessage id="titles.add" />
      </Button>
    </PanelFooter>
  );
}

export function TitlesPanelHeader({ department }: TitlesProps) {
  const { data } = useTitlesQuery({ department_id: department.id });

  return (
    <PageSectionHeader>
      <FormattedMessage id="headings.departments.titles" values={{ count: data?.length ?? 0 }} />
    </PageSectionHeader>
  );
}
