import { TemplateProject } from "@dh-critical-path/api-types";
import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../components/button";
import { PageSectionHeaderButtonGroup } from "../../../components/page/PageSectionHeader";
import { useNavigateToTemplateTaskCreate } from "../../utils/navigation";

type TemplateProjectTasksActionsProps = {
  project: TemplateProject;
};

function TaskCreateAction({ project }: TemplateProjectTasksActionsProps) {
  const navigateToTemplateTaskCreate = useNavigateToTemplateTaskCreate();

  return (
    <Button icon={PlusIcon} onClick={() => navigateToTemplateTaskCreate(project)}>
      <FormattedMessage id="tasks.create" />
    </Button>
  );
}

export function TemplateProjectTasksActions({ project }: TemplateProjectTasksActionsProps) {
  return (
    <PageSectionHeaderButtonGroup>
      <TaskCreateAction project={project} />
    </PageSectionHeaderButtonGroup>
  );
}
