import { MenuAlt4Icon } from "@heroicons/react/solid";
import { HTMLAttributes } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableChildrenFn,
  DropResult,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";

type SortableListProps = {
  sortableId: string;
  onOrderChange: (source: number, destination: number) => void;
  children: (
    provided: DroppableProvided,
    snapshot: DroppableStateSnapshot
  ) => React.ReactElement<HTMLElement>;
};

type SortableListItemProps = {
  sortableListItemId: string;
  index: number;
  children: DraggableChildrenFn;
};

export function SortHandle(props: Omit<HTMLAttributes<HTMLDivElement>, "className">) {
  return (
    <div {...props} className="cursor-pointer">
      <MenuAlt4Icon className="w-4 h-4" />
    </div>
  );
}

export function SortableListItem({ sortableListItemId, index, children }: SortableListItemProps) {
  return (
    <Draggable draggableId={sortableListItemId} index={index}>
      {children}
    </Draggable>
  );
}

export function SortableList({ sortableId, onOrderChange, children }: SortableListProps) {
  function handleDragEnd(result: DropResult) {
    if (result.destination) {
      onOrderChange(result.source.index, result.destination.index);
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={sortableId}>{children}</Droppable>
    </DragDropContext>
  );
}
