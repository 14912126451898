import { RefreshIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";

type FilterResetButtonProps = {
  onClick: () => void;
};

export function FilterResetButton({ onClick }: FilterResetButtonProps) {
  return (
    <button
      type="button"
      data-cy="filter-reset-button"
      className="text-iron group hover:text-iron-600 text-base inline-flex items-center space-x-2"
      onClick={onClick}
    >
      <RefreshIcon className="fill-current w-4 text-iron-600 inline-block group-hover:text-iron-400" />
      <span>
        <FormattedMessage id="filter.reset" />
      </span>
    </button>
  );
}
