export function paginate(current: number, total: number) {
  if (total === 1) {
    return [1];
  }

  const center: number[] = [
    current - 2,
    current - 1,
    current,
    current + 1,
    current + 2,
    //
  ].filter((page) => page > 1 && page < total);

  if (current === 5) {
    center.unshift(2);
  }

  if (current === total - 4) {
    center.push(total - 1);
  }

  if (current > 5) {
    center.unshift(0);
  }

  if (current < total - 4) {
    center.push(0);
  }

  return [1, ...center, total];
}
