import { pluralize } from "../../../utils/text";

type AffectedTasksDetailsProps = {
  totalTasks: number;
  totalTemplateTasks: number;
};

export function AffectedTasksDetails({
  totalTasks,
  totalTemplateTasks,
}: AffectedTasksDetailsProps) {
  const total = totalTasks + totalTemplateTasks;

  return (
    <>
      {total > 0 && (
        <p>
          This will affect{" "}
          {totalTemplateTasks > 0 && (
            <>
              {totalTemplateTasks} {pluralize(totalTemplateTasks, "task", "tasks")} in templates
            </>
          )}
          {totalTasks > 0 && (
            <>
              {" "}
              {totalTemplateTasks > 0 && "and"} {totalTasks}{" "}
              {pluralize(totalTasks, "task", "tasks")} in projects
            </>
          )}
        </p>
      )}
    </>
  );
}
