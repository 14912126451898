import {
  ForwardRefExoticComponent,
  PropsWithChildren,
  PropsWithoutRef,
  ReactElement,
  RefAttributes,
  forwardRef,
} from "react";

// We don't use styled components here, but we want to extract "styled" components
// so here is the way to to that.
//
// Example:
//
// const BlueButton = styled<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
//   ({ children, className, ...props }) => (
//     <button {...props} className={classnames("bg-blue-500", className)}>
//       {children}
//     </button>
//   )
// );
//
// Usage:
//
// <BlueButton type="button" onClick={() => console.log("clicked")} className="text-red-500">
//   click me
// </BlueButton>;

type StyledComponentRendererFunction<T, P = {}> = (
  props: PropsWithChildren<P> & RefAttributes<T>
) => ReactElement | null;

type StyledComponent<T, P = {}> = ForwardRefExoticComponent<
  PropsWithoutRef<PropsWithChildren<P>> & RefAttributes<T>
>;

export function styled<T, P = {}>(
  render: StyledComponentRendererFunction<T, P>
): StyledComponent<T, P> {
  return forwardRef<T, P>((props, ref) => render({ ...props, ref }));
}
