import { User } from "@dh-critical-path/api-types";
import { Menu, Transition } from "@headlessui/react";
import classnames from "classnames";
import React, { Fragment, HTMLAttributes } from "react";
import { styled } from "../../utils/styled";
import { ProfileAvatar, ProfileRow } from "../profile";

interface HeaderProfileMenuProps {
  user: User;
  onAccountSettingsClick: () => void;
  onSignOutClick: () => void;
}

interface MenuItemProps {
  isActive?: boolean;
  onClick: () => void;
}

const MenuItems = styled<HTMLDivElement>(({ children, ...props }) => (
  <Menu.Items
    {...props}
    as="div"
    data-cy="profile-menu"
    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
  >
    {children}
  </Menu.Items>
));

const MenuItem = styled<HTMLButtonElement, MenuItemProps>(
  ({ children, isActive = false, ...props }) => (
    <button
      {...props}
      className={classnames("block w-full text-left px-4 py-2 text-base", {
        "text-violet": isActive,
        "text-black hover:bg-beige-100": !isActive,
      })}
    >
      {children}
    </button>
  )
);

const DesktopProfileMenu: React.FC = ({ children }) => (
  <Menu as="div" className="relative hidden lg:inline-block text-left">
    {children}
  </Menu>
);

const MobileProfileMenu: React.FC = ({ children, ...props }) => (
  <div
    {...props}
    className="flex lg:hidden flex-col border-t border-violet-400 border-opacity-20 px-3 pt-5 pb-3 space-y-1"
  >
    {children}
  </div>
);

const MobileProfileMenuButton: React.FC<HTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => (
  <button {...props} className="text-white hover:text-beige-400 text-left py-1.5">
    {children}
  </button>
);

const HeaderProfileMenu: React.FC<HeaderProfileMenuProps> = ({
  user,
  onAccountSettingsClick,
  onSignOutClick,
}) => (
  <>
    <DesktopProfileMenu>
      <div className="flex">
        <Menu.Button data-cy="profile-menu-button">
          <ProfileAvatar user={user} showTooltip={false} isRing />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems>
          <div className="px-4 py-3">
            <p className="text-sm text-iron">Signed in as</p>
            <p className="text-sm font-bold text-black truncate" data-cy="profile-menu-user-email">
              {user.email}
            </p>
          </div>
          {/* <div className="py-1">
            <MenuItem onClick={onAccountSettingsClick} data-cy="profile-menu-settings-link">
              Account settings
            </MenuItem>
          </div> */}
          <div className="py-1">
            <MenuItem onClick={onSignOutClick} data-cy="profile-menu-sign-out-link">
              Sign out
            </MenuItem>
          </div>
        </MenuItems>
      </Transition>
    </DesktopProfileMenu>
    <MobileProfileMenu data-cy="mobile-profile-menu">
      <ProfileRow user={user} variant="light" className="py-1.5" />
      {/* <MobileProfileMenuButton
        onClick={onAccountSettingsClick}
        data-cy="mobile-profile-menu-settings-link"
      >
        Account settings
      </MobileProfileMenuButton> */}
      <MobileProfileMenuButton onClick={onSignOutClick} data-cy="mobile-profile-menu-sign-out-link">
        Sign out
      </MobileProfileMenuButton>
    </MobileProfileMenu>
  </>
);

export default HeaderProfileMenu;
