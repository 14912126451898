import { Request, TemplateProject } from "@dh-critical-path/api-types";
import { useTemplateProjectsTasksStatsQuery } from "../../../queries";
import { TemplateTasksStatsCards, TemplateTasksStatsEntityLink } from "./TemplateTasksStatsCards";

type TemplateProjectsTasksStatsCardsProps = {
  header?: (count: number) => React.ReactElement;
  filter: Request.Projects.GetProjectsTasksStats;
  entityLink: TemplateTasksStatsEntityLink<TemplateProject>;
};

export function TemplateProjectsTasksStatsCards({
  header,
  filter,
  entityLink,
}: TemplateProjectsTasksStatsCardsProps) {
  const { data } = useTemplateProjectsTasksStatsQuery(filter);

  if (!data || !data.length) {
    return null;
  }

  return (
    <>
      {header && header(data.length)}
      <TemplateTasksStatsCards items={data} entityLink={entityLink} />
    </>
  );
}
