import { Department, User } from "@dh-critical-path/api-types";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  addMembers,
  createDepartment,
  removeDepartment,
  removeMember,
  updateDepartment,
} from "../api";
import { departmentsKeys, titlesKeys } from "../queries";
import { interceptValidationErrorsAndThrow } from "../utils/form";

type DepartmentFields = {
  name: string;
  project_id?: number;
};

type DepartmentMembersAddFields = {
  member_ids: number[];
};

type DepartmentCallback = (department: Department) => void;

export function useDepartmentCreateForm(
  projectId: number | undefined,
  onCreated: DepartmentCallback
) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(createDepartment);

  const methods = useForm<DepartmentFields>({
    defaultValues: {
      name: "",
      project_id: projectId,
    },
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onCreated(await mutateAsync(data));
      queryClient.invalidateQueries(departmentsKeys.allDepartments());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useDepartmentUpdateForm(department: Department, onUpdated: DepartmentCallback) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateDepartment);

  const methods = useForm<DepartmentFields>({
    defaultValues: {
      name: department.name,
      project_id: department.project_id,
    },
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onUpdated(await mutateAsync({ id: department.id, ...data }));
      queryClient.invalidateQueries(departmentsKeys.allDepartments());
      queryClient.invalidateQueries(departmentsKeys.department(department.id));
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useDepartmentMembersAddForm(department: Department, onUpdated: DepartmentCallback) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(addMembers);

  const methods = useForm<DepartmentMembersAddFields>({
    defaultValues: {
      member_ids: [],
    },
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      await mutateAsync({ id: department.id, ...data });
      onUpdated(department);
      queryClient.invalidateQueries(departmentsKeys.departmentMembers(department.id));
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useDepartmentMemberRemove() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(removeMember);

  const handleRemove = useCallback(
    async (department: Department, member: User) => {
      await mutateAsync({ id: department.id, member_id: member.id });

      queryClient.invalidateQueries(titlesKeys.all());
      queryClient.invalidateQueries(departmentsKeys.departmentMembers(department.id));

      return department;
    },
    [queryClient, mutateAsync]
  );

  return { handleRemove, isLoading };
}

export function useDepartmentRemove() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(removeDepartment);

  const handleRemove = useCallback(
    async (department: Department) => {
      await mutateAsync({ id: department.id });

      queryClient.invalidateQueries(departmentsKeys.allDepartments());

      return department;
    },
    [queryClient, mutateAsync]
  );

  return { handleRemove, isLoading };
}
