import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SlideOver } from "../components/form";
import { serializeQueryParams } from "../utils/misc";
import { DepartmentCreateSlideOver } from "./Departments/DepartmentCreateSlideOver";
import { DepartmentEditSlideOver } from "./Departments/DepartmentEditSlideOver";
import { DepartmentMembersAddSlideOver } from "./Departments/DepartmentMembersAddSlideOver";
import { TitleCreateSlideOver } from "./Departments/TitleCreateSlideOver";
import { TitleEditSlideOver } from "./Departments/TitleEditSlideOver";
import { ProjectCreateSlideOver } from "./Projects/ProjectCreateSlideOver";
import { ProjectEditSlideOver } from "./Projects/ProjectEditSlideOver";
import { TaskSlideOver } from "./Tasks/TaskSlideOver";
import { TaskCreateSlideOver, TaskEditSlideOver } from "./Tasks/components/TaskSlideOverLegacy";
import { TaskMassUpdateSlideOver } from "./Tasks/components/TasksMassUpdateSlideOver";
import { TemplateDepartmentCreateSlideOver } from "./Templates/TemplateDepartmentCreateSlideOver";
import { TemplateDepartmentEditSlideOver } from "./Templates/TemplateDepartmentEditSlideOver";
import { TemplateDepartmentMembersAddSlideOver } from "./Templates/TemplateDepartmentMembersAddSlideOver";
import { TemplateProjectCreateSlideOver } from "./Templates/TemplateProjectCreateSlideOver";
import { TemplateProjectEditSlideOver } from "./Templates/TemplateProjectEditSlideOver";
import { TemplateTaskComparisonSlideOver } from "./Templates/TemplateTaskComparisonSlideOver";
import { TemplateTaskCreateSlideOver } from "./Templates/TemplateTaskCreateSlideOver";
import { TemplateTaskEditSlideOver } from "./Templates/TemplateTaskEditSlideOver";
import { TemplateTaskSlideOver } from "./Templates/TemplateTaskSlideOver";
import { TemplateTitleCreateSlideOver } from "./Templates/TemplateTitleCreateSlideOver";
import { TemplateTitleEditSlideOver } from "./Templates/TemplateTitleEditSlideOver";
import { UserAddSlideOver } from "./Users/UserAddSlideOver";
import { UserEditSlideOver } from "./Users/UserEditSlideOver";
import { UserSlideOver } from "./Users/UserSlideOver";

export enum RouteKey {
  ProjectCreate = "ProjectCreate",
  ProjectEdit = "ProjectEdit",
  Task = "Task",
  TaskEdit = "TaskEdit",
  TaskCreate = "TaskCreate",
  TasksMassUpdate = "TasksMassUpdate",
  TemplateTask = "TemplateTask",
  TemplateTaskEdit = "TemplateTaskEdit",
  TemplateTaskCreate = "TemplateTaskCreate",
  DepartmentCreate = "DepartmentCreate",
  DepartmentEdit = "DepartmentEdit",
  DepartmentMembersAdd = "DepartmentMembersAdd",
  TitleCreate = "TitleCreate",
  TitleEdit = "TitleEdit",
  TemplateDepartmentCreate = "TemplateDepartmentCreate",
  TemplateDepartmentEdit = "TemplateDepartmentEdit",
  TemplateDepartmentMembersAdd = "TemplateDepartmentMembersAdd",
  TemplateTitleCreate = "TemplateTitleCreate",
  TemplateTitleEdit = "TemplateTitleEdit",
  TemplateProjectCreate = "TemplateProjectCreate",
  TemplateProjectEdit = "TemplateProjectEdit",
  TemplateTaskComparison = "TemplateTaskComparison",
  User = "User",
  UserEdit = "UserEdit",
  UserAdd = "UserAdd",
}

type ProjectCreateRouteParams = {
  //
};

type ProjectEditRouteParams = {
  projectId: number;
};

type TaskRouteParams = {
  taskId: number;
};

type TaskEditRouteParams = {
  taskId: number;
};

type TaskCreateRouteParams = {
  projectId: number;
  departmentId?: number;
};

type TasksMassUpdateParams = {
  filter: string;
};

type TemplateTaskRouteParams = {
  templateTaskId: number;
};

type TemplateTaskEditRouteParams = {
  templateTaskId: number;
};

type TemplateTaskCreateRouteParams = {
  templateProjectId: number;
  templateDepartmentId?: number;
};

type DepartmentCreateRouteParams = {
  projectId?: number;
};

type DepartmentEditRouteParams = {
  departmentId: number;
};

type DepartmentMembersAddRouteParams = {
  departmentId: number;
};

type TitleCreateRouteParams = {
  departmentId: number;
};

type TitleEditRouteParams = {
  titleId: number;
};

type TemplateDepartmentCreateRouteParams = {
  templateProjectId?: number;
};

type TemplateDepartmentEditRouteParams = {
  templateDepartmentId: number;
};

type TemplateDepartmentMembersAddRouteParams = {
  templateDepartmentId: number;
};

type TemplateTitleCreateRouteParams = {
  templateDepartmentId: number;
};

type TemplateTitleEditRouteParams = {
  templateTitleId: number;
};

type TemplateProjectCreateRouteParams = {
  //
};

type TemplateProjectEditRouteParams = {
  templateProjectId: number;
};

type TemplateTaskComparisonRouteParams = {
  templateProjectId: number;
  projectId: number;
  templateTaskId: number;
  taskId: number;
};

type UserRouteParams = {
  userId: number;
};

type UserAddRouteParams = {
  //
};

type UserEditRouteParams = {
  userId: number;
};

export type SlideOverRouteParams<Key extends RouteKey> = Key extends RouteKey.ProjectCreate
  ? ProjectCreateRouteParams
  : Key extends RouteKey.ProjectEdit
  ? ProjectEditRouteParams
  : Key extends RouteKey.Task
  ? TaskRouteParams
  : Key extends RouteKey.TaskEdit
  ? TaskEditRouteParams
  : Key extends RouteKey.TaskCreate
  ? TaskCreateRouteParams
  : Key extends RouteKey.TasksMassUpdate
  ? TasksMassUpdateParams
  : Key extends RouteKey.TemplateTask
  ? TemplateTaskRouteParams
  : Key extends RouteKey.TemplateTaskEdit
  ? TemplateTaskEditRouteParams
  : Key extends RouteKey.TemplateTaskCreate
  ? TemplateTaskCreateRouteParams
  : Key extends RouteKey.DepartmentCreate
  ? DepartmentCreateRouteParams
  : Key extends RouteKey.DepartmentEdit
  ? DepartmentEditRouteParams
  : Key extends RouteKey.DepartmentMembersAdd
  ? DepartmentMembersAddRouteParams
  : Key extends RouteKey.TitleCreate
  ? TitleCreateRouteParams
  : Key extends RouteKey.TitleEdit
  ? TitleEditRouteParams
  : Key extends RouteKey.TemplateDepartmentCreate
  ? TemplateDepartmentCreateRouteParams
  : Key extends RouteKey.TemplateDepartmentEdit
  ? TemplateDepartmentEditRouteParams
  : Key extends RouteKey.TemplateDepartmentMembersAdd
  ? TemplateDepartmentMembersAddRouteParams
  : Key extends RouteKey.TemplateTitleCreate
  ? TemplateTitleCreateRouteParams
  : Key extends RouteKey.TemplateTitleEdit
  ? TemplateTitleEditRouteParams
  : Key extends RouteKey.TemplateProjectCreate
  ? TemplateProjectCreateRouteParams
  : Key extends RouteKey.TemplateProjectEdit
  ? TemplateProjectEditRouteParams
  : Key extends RouteKey.TemplateTaskComparison
  ? TemplateTaskComparisonRouteParams
  : Key extends RouteKey.User
  ? UserRouteParams
  : Key extends RouteKey.UserAdd
  ? UserAddRouteParams
  : Key extends RouteKey.UserEdit
  ? UserEditRouteParams
  : never;

// TODO: better type checking

export function slideOverRoute<Key extends RouteKey>(
  key: Key,
  query: SlideOverRouteParams<Key>
): string {
  return Object.keys(query).length
    ? `?route=${key}&${serializeQueryParams(query)}`
    : `?route=${key}`;
}

export function useSlideOverNavigate<Key extends RouteKey>() {
  const navigate = useNavigate();

  return useCallback(
    function (key?: Key, query?: SlideOverRouteParams<Key>) {
      if (key && query) {
        navigate(slideOverRoute(key, query));
      } else {
        navigate("?");
      }
    },
    [navigate]
  );
}

function resolveId(searchParams: URLSearchParams, param: string): number | undefined {
  const value = Number(searchParams.get(param));

  return value > 0 ? value : undefined;
}

function resolveSlideOverRouteParams<Key extends RouteKey>(
  key: Key,
  searchParams: URLSearchParams
): SlideOverRouteParams<Key> {
  if (key === RouteKey.ProjectEdit) {
    return { projectId: resolveId(searchParams, "projectId") } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.Task) {
    return { taskId: resolveId(searchParams, "taskId") } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TaskEdit) {
    return { taskId: resolveId(searchParams, "taskId") } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TaskCreate) {
    return {
      projectId: resolveId(searchParams, "projectId"),
      departmentId: resolveId(searchParams, "departmentId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TasksMassUpdate) {
    return {
      filter: searchParams.get("filter"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateTask) {
    return {
      templateTaskId: resolveId(searchParams, "templateTaskId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateTaskEdit) {
    return {
      templateTaskId: resolveId(searchParams, "templateTaskId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateTaskCreate) {
    return {
      templateProjectId: resolveId(searchParams, "templateProjectId"),
      templateDepartmentId: resolveId(searchParams, "templateDepartmentId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.DepartmentCreate) {
    return { projectId: resolveId(searchParams, "projectId") } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.DepartmentEdit) {
    return { departmentId: resolveId(searchParams, "departmentId") } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.DepartmentMembersAdd) {
    return { departmentId: resolveId(searchParams, "departmentId") } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TitleCreate) {
    return {
      departmentId: resolveId(searchParams, "departmentId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TitleEdit) {
    return {
      titleId: resolveId(searchParams, "titleId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateDepartmentCreate) {
    return {
      templateProjectId: resolveId(searchParams, "templateProjectId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateDepartmentEdit) {
    return {
      templateDepartmentId: resolveId(searchParams, "templateDepartmentId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateDepartmentMembersAdd) {
    return {
      templateDepartmentId: resolveId(searchParams, "templateDepartmentId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateTitleCreate) {
    return {
      templateDepartmentId: resolveId(searchParams, "templateDepartmentId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateTitleEdit) {
    return {
      templateTitleId: resolveId(searchParams, "templateTitleId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateProjectEdit) {
    return {
      templateProjectId: resolveId(searchParams, "templateProjectId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.TemplateTaskComparison) {
    return {
      templateProjectId: resolveId(searchParams, "templateProjectId"),
      projectId: resolveId(searchParams, "projectId"),
      templateTaskId: resolveId(searchParams, "templateTaskId"),
      taskId: resolveId(searchParams, "taskId"),
    } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.User) {
    return { userId: resolveId(searchParams, "userId") } as SlideOverRouteParams<Key>;
  } else if (key === RouteKey.UserEdit) {
    return { userId: resolveId(searchParams, "userId") } as SlideOverRouteParams<Key>;
  } else {
    return {} as SlideOverRouteParams<Key>;
  }
}

function slideOverContent<Key extends RouteKey>(
  key: Key,
  params: SlideOverRouteParams<Key>
): React.ReactNode {
  if (key === RouteKey.ProjectCreate) {
    return <ProjectCreateSlideOver {...(params as ProjectCreateRouteParams)} />;
  } else if (key === RouteKey.ProjectEdit) {
    return <ProjectEditSlideOver {...(params as ProjectEditRouteParams)} />;
  } else if (key === RouteKey.Task) {
    return <TaskSlideOver {...(params as TaskRouteParams)} />;
  } else if (key === RouteKey.TaskEdit) {
    return <TaskEditSlideOver {...(params as TaskEditRouteParams)} />;
  } else if (key === RouteKey.TaskCreate) {
    return <TaskCreateSlideOver {...(params as TaskCreateRouteParams)} />;
  } else if (key === RouteKey.TasksMassUpdate) {
    return <TaskMassUpdateSlideOver {...(params as TasksMassUpdateParams)} />;
  } else if (key === RouteKey.TemplateTask) {
    return <TemplateTaskSlideOver {...(params as TemplateTaskRouteParams)} />;
  } else if (key === RouteKey.TemplateTaskEdit) {
    return <TemplateTaskEditSlideOver {...(params as TemplateTaskEditRouteParams)} />;
  } else if (key === RouteKey.TemplateTaskCreate) {
    return <TemplateTaskCreateSlideOver {...(params as TemplateTaskCreateRouteParams)} />;
  } else if (key === RouteKey.DepartmentCreate) {
    return <DepartmentCreateSlideOver {...(params as DepartmentCreateRouteParams)} />;
  } else if (key === RouteKey.DepartmentEdit) {
    return <DepartmentEditSlideOver {...(params as DepartmentEditRouteParams)} />;
  } else if (key === RouteKey.DepartmentMembersAdd) {
    return <DepartmentMembersAddSlideOver {...(params as DepartmentMembersAddRouteParams)} />;
  } else if (key === RouteKey.TitleCreate) {
    return <TitleCreateSlideOver {...(params as TitleCreateRouteParams)} />;
  } else if (key === RouteKey.TitleEdit) {
    return <TitleEditSlideOver {...(params as TitleEditRouteParams)} />;
  } else if (key === RouteKey.TemplateDepartmentCreate) {
    return (
      <TemplateDepartmentCreateSlideOver {...(params as TemplateDepartmentCreateRouteParams)} />
    );
  } else if (key === RouteKey.TemplateDepartmentEdit) {
    return <TemplateDepartmentEditSlideOver {...(params as TemplateDepartmentEditRouteParams)} />;
  } else if (key === RouteKey.TemplateDepartmentMembersAdd) {
    return (
      <TemplateDepartmentMembersAddSlideOver
        {...(params as TemplateDepartmentMembersAddRouteParams)}
      />
    );
  } else if (key === RouteKey.TemplateTitleCreate) {
    return <TemplateTitleCreateSlideOver {...(params as TemplateTitleCreateRouteParams)} />;
  } else if (key === RouteKey.TemplateTitleEdit) {
    return <TemplateTitleEditSlideOver {...(params as TemplateTitleEditRouteParams)} />;
  } else if (key === RouteKey.TemplateProjectCreate) {
    return <TemplateProjectCreateSlideOver {...(params as TemplateProjectCreateRouteParams)} />;
  } else if (key === RouteKey.TemplateProjectEdit) {
    return <TemplateProjectEditSlideOver {...(params as TemplateProjectEditRouteParams)} />;
  } else if (key === RouteKey.TemplateTaskComparison) {
    return <TemplateTaskComparisonSlideOver {...(params as TemplateTaskComparisonRouteParams)} />;
  } else if (key === RouteKey.User) {
    return <UserSlideOver {...(params as UserRouteParams)} />;
  } else if (key === RouteKey.UserEdit) {
    return <UserEditSlideOver {...(params as UserEditRouteParams)} />;
  } else if (key === RouteKey.UserAdd) {
    return <UserAddSlideOver {...(params as UserAddRouteParams)} />;
  }

  return null;
}

export function SlideOvers() {
  const [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get("route") as RouteKey;
  const params = resolveSlideOverRouteParams(key, searchParams);

  const handleClose = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  // TODO: add ability to disable onClose
  return useMemo(
    () => (
      <SlideOver
        open={Boolean(key)}
        onClose={handleClose}
        width={key === RouteKey.TemplateTaskComparison ? "wide" : "base"}
      >
        {slideOverContent(key, params)}
      </SlideOver>
    ),
    [key, params, handleClose]
  );
}
