import { PropsWithChildren } from "react";

function Center({ children }: PropsWithChildren<{}>) {
  return (
    <div className="w-full flex-1 flex flex-col items-center justify-center bg-white space-y-4 text-center px-4">
      {children}
    </div>
  );
}

function Title({ children }: PropsWithChildren<{}>) {
  return <h1 className="text-2xl md:text-4xl xl:text-6xl font-medium leading-none">{children}</h1>;
}

function Details({ children }: PropsWithChildren<{}>) {
  return <p className="text-stone text-lg md:text-xl font-medium">{children}</p>;
}

export function Loading() {
  return (
    <Center>
      <Title>HWI ProPlan</Title>
      <Details>Loading…</Details>
    </Center>
  );
}

export function LoginRedirect() {
  return (
    <Center>
      <Title>Redirecting to SSO…</Title>
      <Details>You should be able to login in a moment</Details>
    </Center>
  );
}

export function LoginProcessing() {
  return (
    <Center>
      <Title>Logging you in…</Title>
      <Details>You should be able to use the system in a moment</Details>
    </Center>
  );
}

export function UnknownError() {
  return (
    <Center>
      <Title>HWI ProPlan</Title>
      <Details>Something went wrong. Please reload page</Details>
    </Center>
  );
}

export function NotFound() {
  return (
    <Center>
      <Title>HWI ProPlan</Title>
      <Details>Not found</Details>
    </Center>
  );
}

export function AccessDenied() {
  return (
    <Center>
      <Title>Access Denied</Title>
      <Details>You don't have access to the system</Details>
    </Center>
  );
}
