import { useNavigate } from "react-router";
import { HeaderProfileMenu } from "../../components/header";
import { useAuth } from "../../hooks/useAuth";

const HeaderProfileMenuHoc = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  const handleAccountSettingsClick = () => console.log("handleAccountSettingsClick");

  const handleSignOutClick = () => navigate("/auth/logout");

  return (
    <HeaderProfileMenu
      user={user}
      onAccountSettingsClick={handleAccountSettingsClick}
      onSignOutClick={handleSignOutClick}
    />
  );
};

export default HeaderProfileMenuHoc;
