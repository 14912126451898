import { Project } from "@dh-critical-path/api-types";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useEffect, useRef } from "react";
import { ActivityFeedItem } from "../../../components/common";
import { VirtualizerContainer, virtualItemStyle } from "../../../components/common/Virtualizer";
import { Panel } from "../../../components/panel";
import { useProjectActivityQuery } from "../../../queries";

type ProjectActivityFeedProps = {
  project: Project;
};

export function ProjectActivityFeedPanel({ project }: ProjectActivityFeedProps) {
  const virtualizerContainerRef = useRef<HTMLDivElement>(null);

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = useProjectActivityQuery({
    project_id: project.id,
  });

  const items = data ? data.pages.flatMap((page) => page.data) : [];

  const virtualizer = useVirtualizer({
    count: hasNextPage ? items.length + 1 : items.length,
    getScrollElement: () => virtualizerContainerRef.current,
    estimateSize: () => 72,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useEffect(() => {
    const [lastItem] = [...virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= items.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, items.length, isFetchingNextPage, virtualItems]);

  return (
    <VirtualizerContainer ref={virtualizerContainerRef} offset={200}>
      <Panel
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {virtualItems.map((virtualItem) => {
          const isLoaderRow = virtualItem.index > items.length - 1;

          return (
            <div key={virtualItem.index} style={virtualItemStyle(virtualItem)} className="p-6">
              {isLoaderRow ? null : <ActivityFeedItem item={items[virtualItem.index]} />}
            </div>
          );
        })}
      </Panel>
    </VirtualizerContainer>
  );
}
