import { Route, Routes, useParams } from "react-router";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useTemplateProjectQuery } from "../../queries";
import { TemplateProjectActivityPage } from "./TemplateProjectActivityPage";
import { TemplateProjectAttachmentsPage } from "./TemplateProjectAttachmentsPage";
import { TemplateProjectComparisonPage } from "./TemplateProjectComparisonPage";
import { TemplateProjectDepartmentPage } from "./TemplateProjectDepartmentPage";
import { TemplateProjectDepartmentsPage } from "./TemplateProjectDepartmentsPage";
import { TemplateProjectOverviewPage } from "./TemplateProjectOverviewPage";
import { TemplateProjectTasksPage } from "./TemplateProjectTasksPage";
import { TemplateProjectPageLayout } from "./components/TemplateProjectPageLayout";

export function TemplateProjectRoutes() {
  const params = useParams<"id">();

  const { data } = useTemplateProjectQuery(Number(params.id));

  useDocumentTitle(data?.name);

  if (!data) {
    return null;
  }

  return (
    <TemplateProjectPageLayout project={data}>
      <Routes>
        <Route path="/" element={<TemplateProjectOverviewPage project={data} />} />
        <Route path="/tasks" element={<TemplateProjectTasksPage project={data} />} />
        <Route path="/departments" element={<TemplateProjectDepartmentsPage project={data} />} />
        <Route
          path="/departments/:departmentId"
          element={<TemplateProjectDepartmentPage project={data} />}
        />
        <Route path="/attachments" element={<TemplateProjectAttachmentsPage project={data} />} />
        <Route path="/comparison" element={<TemplateProjectComparisonPage project={data} />} />
        <Route path="/activity" element={<TemplateProjectActivityPage project={data} />} />
      </Routes>
    </TemplateProjectPageLayout>
  );
}
