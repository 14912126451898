import { PropsWithChildren } from "react";
import { PageHeader } from "../../../components/page";
import HeaderHoc from "../../../hoc/header/HeaderHoc";
import { TemplateProjectsTabs } from "./TemplateProjectsTabs";

type TemplateProjectsPageLayoutProps = {
  title: React.ReactNode;
};

export function TemplateProjectsPageLayout({
  title,
  children,
}: PropsWithChildren<TemplateProjectsPageLayoutProps>) {
  return (
    <>
      <HeaderHoc />
      <div>
        <PageHeader title={title}>
          <TemplateProjectsTabs />
        </PageHeader>
        {children}
      </div>
    </>
  );
}
