import { TemplateProject } from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { NavigationTab } from "../../../components/common";
import { PageHeaderTabs } from "../../../components/page";

type TemplateProjectTabsProps = {
  project: TemplateProject;
};

export function TemplateProjectTabs({ project }: TemplateProjectTabsProps) {
  return (
    <PageHeaderTabs>
      <NavigationTab to={`/templates/${project.id}`} end>
        <FormattedMessage id="navigation.overview" />
      </NavigationTab>
      <NavigationTab to={`/templates/${project.id}/tasks`}>
        <FormattedMessage id="navigation.tasks" />
      </NavigationTab>
      <NavigationTab to={`/templates/${project.id}/departments`}>
        <FormattedMessage id="navigation.departments" />
      </NavigationTab>
      <NavigationTab to={`/templates/${project.id}/attachments`}>
        <FormattedMessage id="navigation.files" />
      </NavigationTab>
      <NavigationTab to={`/templates/${project.id}/comparison`}>
        <FormattedMessage id="navigation.comparison" />
      </NavigationTab>
      <NavigationTab to={`/templates/${project.id}/activity`}>
        <FormattedMessage id="navigation.activity" />
      </NavigationTab>
    </PageHeaderTabs>
  );
}
