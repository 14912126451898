import { Request, Title, User } from "@dh-critical-path/api-types";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { createTitle, removeMemberFromTitle, removeTitle, updateTitle } from "../api";
import { titlesKeys } from "../queries";
import { interceptValidationErrorsAndThrow } from "../utils/form";
import { mapIds } from "../utils/misc";

export type TitleCreateFields = Request.Titles.CreateTitle;

export type TitleUpdateFields = Request.Titles.UpdateTitle;

type TitleCallback = (title: Omit<Title, "members">) => void;

export function useTitleCreateForm(departmentId: number, onCreated: TitleCallback) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(createTitle);

  const methods = useForm<TitleCreateFields>({
    defaultValues: {
      name: "",
      department_id: departmentId,
      member_ids: [],
    },
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onCreated(await mutateAsync(data));
      queryClient.invalidateQueries(titlesKeys.all());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useTitleUpdateForm(title: Title, onUpdated: TitleCallback) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateTitle);

  const methods = useForm<TitleUpdateFields>({
    defaultValues: {
      title_id: title.id,
      name: title.name,
      department_id: title.department_id,
      member_ids: mapIds(title.members),
    },
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onUpdated(await mutateAsync(data));
      queryClient.invalidateQueries(titlesKeys.all());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useTitleRemove() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(removeTitle);

  const handleRemove = useCallback(
    async (title: Title) => {
      await mutateAsync(title.id);

      queryClient.invalidateQueries(titlesKeys.all());

      return title;
    },
    [queryClient, mutateAsync]
  );

  return { handleRemove, isLoading };
}

export function useTitleMemberRemove() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(removeMemberFromTitle);

  const handleRemove = useCallback(
    async (title: Title, member: User) => {
      await mutateAsync({ title_id: title.id, member_id: member.id });

      queryClient.invalidateQueries(titlesKeys.all());

      return title;
    },
    [queryClient, mutateAsync]
  );

  return { handleRemove, isLoading };
}
