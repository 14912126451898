import { Response } from "@dh-critical-path/api-types";
import axios, { AxiosError, AxiosResponse } from "axios";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

function isAxiosError<T = any>(error: unknown): error is AxiosError<T> {
  return axios.isAxiosError(error);
}

function isValidationError<T = Response.ValidationErrors>(
  error: unknown
): error is AxiosError<T> & { response: AxiosResponse<T> } {
  return isAxiosError(error) && error.response?.status === 422;
}

export function firstErrorMessage(error: unknown, field?: string) {
  let message: string | undefined;

  if (isValidationError(error)) {
    message = error.response.data[field ?? Object.keys(error.response.data)[0]]?.[0];
  }

  return message ?? "Unknown error";
}

export function interceptValidationErrors<TFieldValues extends FieldValues = FieldValues>(
  methods: UseFormReturn<TFieldValues>,
  error: unknown
) {
  if (isValidationError(error)) {
    Object.entries(error.response.data).forEach(([field, [message]]) =>
      methods.setError(field as Path<TFieldValues>, { message })
    );
  }
}

export function interceptValidationErrorsAndThrow<TFieldValues extends FieldValues = FieldValues>(
  methods: UseFormReturn<TFieldValues>,
  error: unknown
) {
  interceptValidationErrors(methods, error);
  throw error; // this error should be handled somehow
}

export function validationErrorsInterceptor<TFieldValues extends FieldValues = FieldValues>(
  methods: UseFormReturn<TFieldValues>
) {
  return (error: unknown) => interceptValidationErrors(methods, error);
}
