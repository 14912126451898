import { Project, Request } from "@dh-critical-path/api-types";
import { StatusType } from "@dh-critical-path/shared";
import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Button } from "../../components/button";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useDepartmentQuery } from "../../queries";
import { DepartmentHeader } from "../Departments/components/DepartmentHeader";
import {
  DepartmentMembersPanel,
  DepartmentMembersPanelHeader,
} from "../Departments/components/DepartmentMembers";
import { TitlesPanel, TitlesPanelHeader } from "../Departments/components/Titles";
import { TasksGroup } from "../Tasks/components/TasksGroup";
import { useNavigateToTaskCreate } from "../utils/navigation";

type ProjectDepartmentPageProps = {
  project: Project;
};

function ProjectDepartmentPage({ project }: ProjectDepartmentPageProps) {
  const params = useParams<"departmentId">();

  const navigateToTaskCreate = useNavigateToTaskCreate();

  const { data } = useDepartmentQuery(Number(params.departmentId));

  useDocumentTitle(data ? `${project.name} - ${data.name}` : project.name);

  if (!data) {
    return null;
  }

  const filter: Request.Tasks.GetTasks = {
    project_ids: [project.id],
    department_ids: [data.id],
  };

  return (
    <PageContainer>
      <Layout.Column width={12}>
        <Layout.Container>
          {/* header */}
          <Layout.Column width={12}>
            <DepartmentHeader department={data} />
          </Layout.Column>
          {/* members column */}
          <Layout.Column lgWidth={4}>
            <Layout.Spacer space={8}>
              <Layout.Spacer space={4}>
                <DepartmentMembersPanelHeader department={data} />
                <DepartmentMembersPanel department={data} />
              </Layout.Spacer>
              <Layout.Spacer space={4}>
                <TitlesPanelHeader department={data} />
                <TitlesPanel department={data} />
              </Layout.Spacer>
            </Layout.Spacer>
          </Layout.Column>
          {/* tasks column */}
          <Layout.Column lgWidth={8}>
            <Layout.Spacer space={8}>
              {/* overdue tasks */}
              <TasksGroup
                filter={{ ...filter, is_overdue: true }}
                header={(count) => (
                  <PageSectionHeader isDanger>
                    <FormattedMessage id="headings.tasks.overdue" values={{ count }} />
                  </PageSectionHeader>
                )}
              />
              {/* active tasks */}
              <TasksGroup
                filter={{ ...filter, task_status_types: [StatusType.IN_PROGRESS] }}
                header={(count) => (
                  <PageSectionHeader>
                    <FormattedMessage id="headings.tasks.active" values={{ count }} />
                  </PageSectionHeader>
                )}
              />
              {/* upcoming tasks */}
              <TasksGroup
                filter={{ ...filter, task_status_types: [StatusType.TODO] }}
                header={(count) => (
                  <PageSectionHeader
                    buttons={
                      <Button icon={PlusIcon} onClick={() => navigateToTaskCreate(project, data)}>
                        <FormattedMessage id="tasks.create" />
                      </Button>
                    }
                  >
                    <FormattedMessage id="headings.tasks.upcoming" values={{ count }} />
                  </PageSectionHeader>
                )}
              />
            </Layout.Spacer>
          </Layout.Column>
        </Layout.Container>
      </Layout.Column>
    </PageContainer>
  );
}

export default ProjectDepartmentPage;
