import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Loader } from "../../components/common/Loader";
import { PageContainer, PageHeader } from "../../components/page";
import HeaderHoc from "../../hoc/header/HeaderHoc";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useMetabaseTokenQuery } from "../../queries";

export function TrackingPage() {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { data: token, isLoading } = useMetabaseTokenQuery();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useDocumentTitle("Tracking");

  useEffect(() => {
    try {
      const iframe = iframeRef.current;
      const iframeWindow = iframe?.contentWindow;
      if (iframeWindow) {
        const iframeDoc = iframeWindow?.document;
        const style = iframeDoc.createElement("style");
        style.innerHTML = `
        body {
          font-family: "Avenir", "Arial", sans-serif !important;
        }
        .table-row {
          font-size: 14px !important;
          line-height: 1 !important;
        }
      `;
        const head = iframeDoc.getElementsByTagName("head")[0];
        head.appendChild(style);
      }
    } catch (error) {
      console.log("Error occurred:", error);
    }
  }, [iframeRef, iframeLoaded]);

  const iframeUrl = `${process.env.REACT_APP_METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=false`;

  return (
    <>
      <HeaderHoc />
      <PageHeader title={<FormattedMessage id="navigation.tracking.title" />} />
      <PageContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <iframe
            onLoad={() => setIframeLoaded(true)}
            ref={iframeRef}
            src={iframeUrl}
            className="tracking-dashboard w-full px-4 bg-white rounded-lg"
            title="Tracking"
          />
        )}
      </PageContainer>
    </>
  );
}
