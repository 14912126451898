import { Request } from "@dh-critical-path/api-types";
import { keepTruthy } from "@dh-critical-path/shared";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { hasAnyOf } from "../components/form/TagsInput";
import { useTemplateTasksQuery } from "../queries";

export type TemplateTasksFilterFormFields = Omit<
  Request.TemplateTasks.GetTemplateTasks,
  "page" | "template_project_ids"
> & {
  template_project_ids: number[];
};

export function useTemplateTasksFilter(defaultValues: TemplateTasksFilterFormFields) {
  const [page, setPage] = useState(1);

  const [queryParams, setQueryParams] = useState<TemplateTasksFilterFormFields>(defaultValues);

  const methods = useForm<TemplateTasksFilterFormFields>({
    defaultValues: {
      tags: [hasAnyOf()],
      order_column: "due_date_offset",
      order_direction: "desc",
      ...defaultValues,
    },
  });

  const fields = methods.watch();

  useEffect(() => {
    setPage(1);
    setQueryParams({
      name: fields.name,
      is_milestone: fields.is_milestone,
      template_project_ids: fields.template_project_ids,
      template_department_ids: fields.template_department_ids,
      assignee_ids: fields.assignee_ids,
      supporter_ids: fields.supporter_ids,
      tags: fields.tags,
      depends_on_id: fields.depends_on_id,
      dependency_for_id: fields.dependency_for_id,
      is_removed: fields.is_removed,
      without_dependencies: fields.without_dependencies,
      without_due_date_offset: fields.without_due_date_offset,
      order_column: fields.order_column,
      order_direction: fields.order_direction,
    });
  }, [
    fields.name,
    fields.is_milestone,
    fields.template_project_ids,
    fields.template_department_ids,
    fields.assignee_ids,
    fields.supporter_ids,
    fields.tags,
    fields.depends_on_id,
    fields.dependency_for_id,
    fields.is_removed,
    fields.without_dependencies,
    fields.without_due_date_offset,
    fields.order_column,
    fields.order_direction,
  ]);

  const activeFiltersCount = keepTruthy([
    fields.tags?.find((tag) => {
      return ["is_empty", "is_not_empty"].includes(tag.condition) ? true : Boolean(tag.ids.length);
    }),
    fields.name?.trim(),
    fields.is_milestone,
    fields.assignee_ids?.length,
    fields.supporter_ids?.length,
    fields.depends_on_id,
    fields.dependency_for_id,
    fields.is_removed,
    fields.without_dependencies,
    fields.without_due_date_offset,
  ]).length;

  const query = useTemplateTasksQuery({ ...queryParams, page });

  return { methods, query, setPage, activeFiltersCount };
}
