import classnames from "classnames";
import { HTMLAttributes, forwardRef } from "react";

export type PanelProps = HTMLAttributes<HTMLDivElement> & {
  overflow?: "overflow-hidden" | "overflow-visible";
};

const Panel = forwardRef<HTMLDivElement, PanelProps>(function (
  { children, overflow = "overflow-hidden", className, ...props },
  ref
) {
  return (
    <div
      {...props}
      ref={ref}
      className={classnames(
        "p-6 bg-white shadow hover:shadow-sm rounded-lg transition",
        overflow,
        className
      )}
    >
      {children}
    </div>
  );
});

export default Panel;
