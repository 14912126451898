import { TaskStatus } from "@dh-critical-path/api-types";
import { ResourceIdPrefix, StatusType, prefixResourceId } from "@dh-critical-path/shared";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { PageContainer, PageHeader, PageSectionHeader } from "../../components/page";
import HeaderHoc from "../../hoc/header/HeaderHoc";
import { useAuth } from "../../hooks/useAuth";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useTasksFilter } from "../../hooks/useTasksFilter";
import { useTaskStatusesForFilterQuery } from "../../queries";
import { TasksPagination } from "../Projects/components/TasksPagination";
import { TasksTable, TasksTableHeadRowSortable } from "../Projects/components/TasksTable";
import { MyTasksFilterForm } from "../Projects/components/forms/MyTasksFilterForm";

export function TasksPage() {
  useDocumentTitle("My Tasks");

  const { user } = useAuth();

  const { methods, query, setPage } = useTasksFilter({
    project_ids: [],
    assignee_ids: [prefixResourceId(user!.id, ResourceIdPrefix.USER)],
  });

  const { data: taskStatuses } = useTaskStatusesForFilterQuery();

  useEffect(() => {
    if (taskStatuses) {
      methods.setValue(
        "task_status_ids",
        taskStatuses
          ?.filter((item: TaskStatus) =>
            [StatusType.TODO, StatusType.IN_PROGRESS, StatusType.ON_HOLD].includes(item.status_type)
          )
          .map((item: TaskStatus) => item.id)
      );
    }
  }, [methods, taskStatuses]);

  return (
    <>
      <HeaderHoc />
      <div>
        <PageHeader title={<FormattedMessage id="navigation.my-tasks" />} />
        <PageContainer>
          <PageSectionHeader>
            <FormattedMessage
              id="headings.my-tasks.ongoing"
              values={{ count: query.data?.meta.total ?? 0 }}
            />
          </PageSectionHeader>
          <FormProvider {...methods}>
            <MyTasksFilterForm />
            <TasksTable
              tasks={query.data?.data ?? []}
              headRenderer={<TasksTableHeadRowSortable />}
            />
            {query.data && <TasksPagination pagination={query.data.meta} onChange={setPage} />}
          </FormProvider>
        </PageContainer>
      </div>
    </>
  );
}
