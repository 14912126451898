import { Project, TasksComparisonResult, TemplateProject } from "@dh-critical-path/api-types";
import { PlusIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { HTMLAttributes, PropsWithChildren, RefObject, forwardRef, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { UseQueryResult } from "react-query";
import { useNavigate } from "react-router";
import { ActionButton, Button } from "../../../components/button";
import { Loader } from "../../../components/common/Loader";
import {
  Virtualizer,
  VirtualizerContainer,
  virtualItemStyle,
} from "../../../components/common/Virtualizer";
import {
  useTaskOrTemplateTaskRemovingConfirmationDialog,
  useTaskOrTemplateTaskRemovingErrorHandling,
} from "../../../components/modals";
import {
  TaskAvatar,
  TaskDepartment,
  TaskDependenciesPerDepartments,
  TaskLabels,
  TaskName,
} from "../../../components/table/tasks";
import { useConfirmModal } from "../../../hooks/useModal";
import { useTaskCreateFromTemplate, useTaskRemoveAction } from "../../../hooks/useTask";
import { TasksComparisonFields } from "../../../hooks/useTasksComparisonFilter";
import { useTemplateTaskRemoveAction } from "../../../hooks/useTemplateTask";
import { useProjectQuery } from "../../../queries";
import { RouteKey, slideOverRoute } from "../../SlideOvers";

type ComparisonTableProps = {
  templateProject: TemplateProject;
  project: Project;
  query: UseQueryResult<TasksComparisonResult[]>;
};

type ComparisonTableHeadingRowProps = {
  templateProject: Pick<TemplateProject, "name">;
  project: Pick<Project, "name">;
  isSticky?: boolean;
};

type ComparisonTableRowValueFieldProps = {
  label: React.ReactNode;
  value: React.ReactNode;
};

type ComparisonTableRowsProps = {
  virtualizerContainerRef: RefObject<HTMLDivElement>;
  query: UseQueryResult<TasksComparisonResult[]>;
  templateProject: TemplateProject;
  project: Project;
};

type ComparisonTableRowContainerProps = {
  variant: "base" | "equal" | "changed" | "removed";
};

type ComparisonTableRowProps = {
  result: TasksComparisonResult;
  templateProject: TemplateProject;
  project: Project;
};

type TemplateTasksComparisonTableProps = {
  templateProject: TemplateProject;
  query: UseQueryResult<TasksComparisonResult[]>;
};

function isNew(result: TasksComparisonResult) {
  return Boolean(result.is_new);
}

function isRemoved(result: TasksComparisonResult) {
  return Boolean(result.is_removed);
}

function isChanged(result: TasksComparisonResult) {
  return Boolean(
    result.is_name_changed ||
      result.is_description_changed ||
      result.is_milestone_changed ||
      result.is_duration_changed ||
      result.is_due_date_offset_changed ||
      result.is_start_date_changed ||
      result.is_due_date_changed ||
      result.is_milestone_changed ||
      result.is_template_department_id_changed ||
      result.is_tag_ids_changed ||
      result.is_attachment_ids_changed ||
      result.is_assignee_user_id_changed ||
      result.is_assignee_template_title_id_changed ||
      result.is_supporter_user_ids_changed ||
      result.is_supporter_template_title_ids_changed
  );
}

function ComparisonTableContainer({ children }: PropsWithChildren<{}>) {
  return (
    <div className="overflow-hidden rounded-lg shadow">
      <div className="flex flex-col flex-1 overflow-auto">{children}</div>
    </div>
  );
}

function ComparisonTableHeadingColumn({ children }: PropsWithChildren<{}>) {
  return (
    <div className="bg-beige-100 flex-none w-4/5-screen lg:w-1/2 lg:flex-1 px-3 py-4 text-sm text-iron uppercase">
      {children}
    </div>
  );
}

function ComparisonTableTaskActionsContainer({ children }: PropsWithChildren<{}>) {
  return <div className="flex-none flex">{children}</div>;
}

function ComparisonTableTaskContainer({
  result,
  children,
}: PropsWithChildren<ComparisonTableRowProps>) {
  const isMilestoneOrRemoved =
    result.task?.is_milestone || result.template_task?.is_milestone || isRemoved(result);

  return (
    <div
      className={classNames(
        "relative flex flex-col sm:items-start sm:flex-row flex-none w-4/5-screen lg:w-1/2 lg:flex-1 px-3 pb-4 space-y-4 sm:space-y-0 sm:space-x-4",
        {
          "pt-4": !isMilestoneOrRemoved,
          "pt-8": isMilestoneOrRemoved,
        }
      )}
    >
      <div className="flex flex-1">{children}</div>
    </div>
  );
}

export function ComparisonTableRowDivider() {
  return <div className="w-px flex-none bg-stone-100 last:hidden -mt-4"></div>;
}

function ComparisonTableTaskAddAction({ result, project }: ComparisonTableRowProps) {
  const isVisible = !isRemoved(result) && isNew(result) && !result.task;

  const { modal, openModal } = useConfirmModal();

  const { handleCreate, isLoading } = useTaskCreateFromTemplate(project, result.template_task);

  return isVisible ? (
    <>
      <Button
        icon={PlusIcon}
        disabled={isLoading}
        loading={isLoading}
        onClick={() => {
          openModal(
            () => {
              handleCreate();
            },
            { description: "Are you sure you want to add a task?" }
          );
        }}
      >
        Add Task
      </Button>
      {modal}
    </>
  ) : null;
}

function ComparisonTableTaskRemoveAction({ result }: ComparisonTableRowProps) {
  const isVisible =
    isRemoved(result) &&
    Boolean(
      result.task &&
        result.template_task &&
        !result.task.deleted_at &&
        result.template_task.deleted_at
    );
  const { handleRemove, isLoading } = useTaskRemoveAction();

  const { modal: errorModal, handleRemoveWithErrorHandling } =
    useTaskOrTemplateTaskRemovingErrorHandling(handleRemove);

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useTaskOrTemplateTaskRemovingConfirmationDialog(handleRemoveWithErrorHandling);

  return isVisible ? (
    <>
      <ActionButton
        variant="delete"
        className="my-auto"
        loading={isLoading}
        onClick={() => handleRemoveWithConfirmation(result.task!)}
      />
      {confirmModal}
      {errorModal}
    </>
  ) : null;
}

function ComparisonTableTemplateTaskRemoveAction({ result }: ComparisonTableRowProps) {
  const isVisible =
    isRemoved(result) &&
    Boolean(
      result.task &&
        result.template_task &&
        result.task.deleted_at &&
        !result.template_task.deleted_at
    );
  const { handleRemove, isLoading } = useTemplateTaskRemoveAction();

  const { modal: errorModal, handleRemoveWithErrorHandling } =
    useTaskOrTemplateTaskRemovingErrorHandling(handleRemove);

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useTaskOrTemplateTaskRemovingConfirmationDialog(handleRemoveWithErrorHandling);

  return isVisible ? (
    <>
      <ActionButton
        variant="delete"
        className="my-auto"
        loading={isLoading}
        onClick={() => handleRemoveWithConfirmation(result.template_task!)}
      />
      {confirmModal}
      {errorModal}
    </>
  ) : null;
}

function ComparisonTableTaskApplyAction({
  result,
  templateProject,
  project,
}: ComparisonTableRowProps) {
  const isVisible =
    !isNew(result) &&
    !isRemoved(result) &&
    isChanged(result) &&
    Boolean(result.task) &&
    Boolean(result.template_task);

  const navigate = useNavigate();

  return isVisible ? (
    <Button
      variant="secondary"
      onClick={() => {
        if (!result.task || !result.template_task) {
          return;
        }

        navigate(
          slideOverRoute(RouteKey.TemplateTaskComparison, {
            templateProjectId: templateProject.id,
            projectId: project.id,
            templateTaskId: result.template_task.id,
            taskId: result.task.id,
          })
        );
      }}
    >
      Apply to project
    </Button>
  ) : null;
}

function ComparisonTableTaskActions(props: ComparisonTableRowProps) {
  return (
    <ComparisonTableTaskActionsContainer>
      <ComparisonTableTaskRemoveAction {...props} />
      <ComparisonTableTaskAddAction {...props} />
      <ComparisonTableTaskApplyAction {...props} />
    </ComparisonTableTaskActionsContainer>
  );
}

function ComparisonTableTemplateTaskActions(props: ComparisonTableRowProps) {
  return (
    <ComparisonTableTaskActionsContainer>
      <ComparisonTableTemplateTaskRemoveAction {...props} />
    </ComparisonTableTaskActionsContainer>
  );
}

function ComparisonTableTask({ result, ...props }: ComparisonTableRowProps) {
  const task = result.task;

  return (
    <ComparisonTableTaskContainer result={result} {...props}>
      {task && (
        <div className="flex flex-1">
          <TaskLabels leftIndent="left-auto" task={task} />
          <TaskAvatar task={task} />
          <div>
            <TaskDepartment task={task} link="view" />
            <TaskName task={task} />
            <TaskDependenciesPerDepartments task={task} variant="comparison" />
          </div>
        </div>
      )}
      <ComparisonTableTaskActions result={result} {...props} />
    </ComparisonTableTaskContainer>
  );
}

function ComparisonTableTemplateTask({ result, ...props }: ComparisonTableRowProps) {
  const templateTask = result.template_task;

  return (
    <ComparisonTableTaskContainer result={result} {...props}>
      {templateTask && (
        <div className="flex flex-1">
          <TaskLabels leftIndent="left-auto" task={templateTask} />
          <TaskAvatar task={templateTask} />
          <div>
            <TaskDepartment task={templateTask} link="view" />
            <TaskName task={templateTask} />
            <TaskDependenciesPerDepartments task={templateTask} variant="comparison" />
          </div>
        </div>
      )}
      <ComparisonTableTemplateTaskActions result={result} {...props} />
    </ComparisonTableTaskContainer>
  );
}

export function ComparisonTableHeadingRow({
  templateProject,
  project,
  isSticky = true,
}: ComparisonTableHeadingRowProps) {
  return (
    <div className={classNames("flex min-w-min top-0 z-20", { sticky: isSticky })}>
      <ComparisonTableHeadingColumn>{templateProject.name}</ComparisonTableHeadingColumn>
      <ComparisonTableRowDivider />
      <ComparisonTableHeadingColumn>{project.name}</ComparisonTableHeadingColumn>
    </div>
  );
}

export function ComparisonTableRowValueField({ label, value }: ComparisonTableRowValueFieldProps) {
  return (
    <div className="relative flex flex-none w-4/5-screen lg:w-1/2 lg:flex-1 px-3 py-4 space-y-4">
      <div className="flex flex-col">
        <p className="text-sm text-iron-600">{label}</p>
        <p className="text-black text-base mt-1 leading-none break-words-hyphens">
          {value || <span>&mdash;</span>}
        </p>
      </div>
    </div>
  );
}

export const ComparisonTableRowContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ComparisonTableRowContainerProps> &
    Pick<HTMLAttributes<HTMLDivElement>, "style">
>(function ComparisonTableRowContainer({ variant, children, ...props }, ref) {
  return (
    <div
      {...props}
      ref={ref}
      className="relative flex flex-1 transition duration-300 min-w-min border-t bg-white border-stone-100 pr-0.75 hover:shadow-tableRow hover:z-10"
    >
      <div
        className={classNames("absolute left-0 top-0 h-full w-0.75", {
          "bg-green-500": variant === "equal",
          "bg-yellow-60": variant === "changed",
          "bg-red-500": variant === "removed",
        })}
      ></div>
      {children}
    </div>
  );
});

const ComparisonTableRow = forwardRef<
  HTMLDivElement,
  ComparisonTableRowProps & Pick<HTMLAttributes<HTMLDivElement>, "style">
>(function ComparisonTableRow({ result, templateProject, project, ...props }, ref) {
  return (
    <ComparisonTableRowContainer
      {...props}
      ref={ref}
      variant={
        isRemoved(result) ? "removed" : isChanged(result) || isNew(result) ? "changed" : "equal"
      }
    >
      <ComparisonTableTemplateTask
        result={result}
        templateProject={templateProject}
        project={project}
      />
      <ComparisonTableRowDivider />
      <ComparisonTableTask result={result} templateProject={templateProject} project={project} />
    </ComparisonTableRowContainer>
  );
});

function ComparisonTableRowsEmpty() {
  return <div className="px-3 py-4 text-center">No tasks found</div>;
}

function ComparisonTableRows({
  virtualizerContainerRef,
  templateProject,
  project,
  query,
}: ComparisonTableRowsProps) {
  return query.isFetching ? (
    <Loader />
  ) : query.data?.length ? (
    <Virtualizer
      containerRef={virtualizerContainerRef}
      itemHeight={90}
      items={query.data}
      itemsRenderer={(virtualItems) =>
        virtualItems.map((virtualItem) => (
          <ComparisonTableRow
            key={virtualItem.key}
            ref={virtualItem.measureElement}
            style={virtualItemStyle(virtualItem)}
            templateProject={templateProject}
            project={project}
            result={virtualItem.item}
          />
        ))
      }
    />
  ) : (
    <ComparisonTableRowsEmpty />
  );
}

function ComparisonTable({ query, templateProject, project }: ComparisonTableProps) {
  const virtualizerContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <ComparisonTableContainer>
      <VirtualizerContainer ref={virtualizerContainerRef} offset={64}>
        <ComparisonTableHeadingRow templateProject={templateProject} project={project} />
        <ComparisonTableRows
          virtualizerContainerRef={virtualizerContainerRef}
          project={project}
          templateProject={templateProject}
          query={query}
        />
      </VirtualizerContainer>
    </ComparisonTableContainer>
  );
}

export function TemplateTasksComparisonTable({
  templateProject,
  query,
}: TemplateTasksComparisonTableProps) {
  const { watch } = useFormContext<TasksComparisonFields>();

  const projectId = watch("project_id");

  const { data: project } = useProjectQuery(projectId);

  return project ? (
    <ComparisonTable query={query} templateProject={templateProject} project={project} />
  ) : null;
}
