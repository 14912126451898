import { Disclosure } from "@headlessui/react";
import { FilterIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { PropsWithChildren } from "react";

export type CollapsibleFilterProps = {
  activeFiltersCount: number;
  open?: boolean;
};

export function CollapsibleFilterFooter({ children }: PropsWithChildren<{}>) {
  return (
    <div className="col-span-6 md:col-span-6 flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0">
      {children}
    </div>
  );
}

export function CollapsibleFilterActions({
  open,
  activeFiltersCount,
  children,
}: PropsWithChildren<CollapsibleFilterProps>) {
  return (
    <div className="flex justify-end ml-auto">
      {children}
      <CollapsibleFilterToggleButton open={open} activeFiltersCount={activeFiltersCount} />
    </div>
  );
}

export function CollapsibleFilterToggleButton({
  open,
  activeFiltersCount,
}: CollapsibleFilterProps) {
  return (
    <Disclosure.Button
      as="button"
      className="group ml-4 flex items-center text-iron group hover:text-iron-600 text-base whitespace-nowrap"
    >
      <FilterIcon className="w-4 text-iron-600 inline-block group-hover:text-iron-400 mr-1" />
      <span>Filters ({activeFiltersCount})</span>
      <ChevronDownIcon
        className={classNames(
          "w-5 ml-0.5 text-iron-600 group-hover:text-iron-400 inline-block transform",
          { "rotate-180": open }
        )}
      />
    </Disclosure.Button>
  );
}

export function CollapsibleFilterSwitchGroup({ children }: PropsWithChildren<{}>) {
  return (
    <Disclosure.Panel unmount={false} className="flex flex-wrap -mx-3 -my-1">
      {children}
    </Disclosure.Panel>
  );
}

export const CollapsibleFilter = Disclosure;
