import { FormattedRelativeTime } from "react-intl";

type PropsOf<T> = T extends React.FC<infer P> ? P : never;

type FormattedRelativeTimeProps = PropsOf<typeof FormattedRelativeTime>;

interface FormattedRelativeDateTimeProps
  extends Omit<FormattedRelativeTimeProps, "value" | "unit"> {
  value: Date;
}

export const FormattedRelativeDateTime: React.VFC<FormattedRelativeDateTimeProps> = ({
  value,
  updateIntervalInSeconds,
  ...props
}) => {
  const secondsAgo = Math.round((value.getTime() - Date.now()) / 1000);
  const minutesAgo = Math.abs(secondsAgo) >= 60 ? Math.round(secondsAgo / 60) : 0;
  const hoursAgo = Math.abs(secondsAgo) >= 60 * 60 ? Math.round(secondsAgo / 60 / 60) : 0;
  const daysAgo = Math.abs(secondsAgo) >= 60 * 60 * 24 ? Math.round(secondsAgo / 60 / 60 / 24) : 0;
  const yearsAgo =
    Math.abs(secondsAgo) >= 60 * 60 * 24 * 365.25
      ? Math.round(secondsAgo / 60 / 60 / 24 / 365.25)
      : 0;

  return (
    <FormattedRelativeTime
      {...props}
      unit={
        yearsAgo ? "year" : daysAgo ? "day" : hoursAgo ? "hour" : minutesAgo ? "minute" : "second"
      }
      value={yearsAgo || daysAgo || hoursAgo || minutesAgo || secondsAgo}
      updateIntervalInSeconds={Math.abs(minutesAgo) > 60 ? undefined : updateIntervalInSeconds}
    />
  );
};
