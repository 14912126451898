import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
} from "../../components/form/SlideOver";
import { useTemplateDepartmentCreateForm } from "../../hooks/useTemplateDepartment";
import { useNavigateToTemplateDepartment } from "../utils/navigation";
import { TemplateDepartmentForm } from "./components/forms/TemplateDepartmentForm";

type TemplateDepartmentCreateSlideOverProps = {
  templateProjectId?: number;
};

type TemplateDepartmentCreateFormProps = {
  templateProjectId?: number;
};

export function TemplateDepartmentCreateForm({
  templateProjectId,
}: TemplateDepartmentCreateFormProps) {
  const navigate = useNavigateToTemplateDepartment();

  const { methods, handleSubmit } = useTemplateDepartmentCreateForm(templateProjectId, navigate);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="departments.modal.heading.create" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <TemplateDepartmentForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TemplateDepartmentCreateSlideOver({
  templateProjectId,
}: TemplateDepartmentCreateSlideOverProps) {
  return <TemplateDepartmentCreateForm templateProjectId={templateProjectId} />;
}
