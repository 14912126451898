import { Department, User } from "@dh-critical-path/api-types";
import { prefixUserId } from "@dh-critical-path/shared";
import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { ActionButton, Button } from "../../../components/button";
import { useActionWithConfirmation } from "../../../components/modals";
import { PageSectionHeader } from "../../../components/page";
import { Panel, PanelFooter } from "../../../components/panel";
import { ProfileRow } from "../../../components/profile";
import { AdminOnly } from "../../../hooks/useAuth";
import { useDepartmentMemberRemove } from "../../../hooks/useDepartment";
import { useDepartmentMembersQuery, useTasksQuery } from "../../../queries";
import { fullName } from "../../../utils/text";
import { useNavigateToDepartmentMembersAdd } from "../../utils/navigation";
import { AffectedTasksDetails } from "./AffectedTasksDetails";

type DepartmentMembersPanelProps = {
  department: Department;
};

type DepartmentMembersProps = {
  department: Department;
};

type DepartmentMemberProps = {
  department: Department;
  member: User;
};

export function useDepartmentMemberRemovingConfirmationDialog(
  handleRemove: (department: Department, member: User) => Promise<any>
) {
  const { modal, handleWithConfirmation } = useActionWithConfirmation(
    handleRemove,
    async (department, member) => ({
      description: <DepartmentMemberRemovingConfirmation department={department} member={member} />,
    })
  );

  return { modal, handleRemoveWithConfirmation: handleWithConfirmation };
}

function DepartmentMemberRemovingConfirmation({
  department,
  member,
}: {
  department: Department;
  member: User;
}) {
  const params = useParams<"id">();

  const projectId = Number(params.id);

  const query = useTasksQuery({
    department_ids: [department.id],
    project_ids: [projectId],
    assignee_ids: [prefixUserId(member.id)],
    limit: 1,
  });

  return (
    <div>
      <p>
        Do you really want to remove {fullName(member)} from {department.name}
      </p>
      <AffectedTasksDetails
        totalTasks={query.data?.meta.total ?? 0}
        departmentId={department.id}
        projectId={projectId}
        assigneeId={prefixUserId(member.id)}
      />
    </div>
  );
}

function DepartmentMemberRemoveAction({ department, member }: DepartmentMemberProps) {
  const { handleRemove, isLoading } = useDepartmentMemberRemove();

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useDepartmentMemberRemovingConfirmationDialog(handleRemove);

  return (
    <>
      <ActionButton
        variant="delete"
        onClick={() => handleRemoveWithConfirmation(department, member)}
        loading={isLoading}
      />
      {confirmModal}
    </>
  );
}

function DepartmentMemberActions({ department, member }: DepartmentMemberProps) {
  // Global departments are not editable here
  if (!department.project_id) {
    return null;
  }

  return (
    <AdminOnly
      render={() => (
        <div className="flex md:invisible group-hover:visible items-center space-x-3 pl-3">
          <DepartmentMemberRemoveAction department={department} member={member} />
        </div>
      )}
    />
  );
}

function DepartmentMember({ department, member }: DepartmentMemberProps) {
  return (
    <div className="flex w-full justify-between items-start md:items-center relative group mb-4 last:mb-0">
      <ProfileRow user={member} />
      <DepartmentMemberActions department={department} member={member} />
    </div>
  );
}

function DepartmentMembersEmpty() {
  return (
    <div className="py-6 text-center">
      <FormattedMessage id="department.members.empty" />
    </div>
  );
}

function DepartmentMembers({ department }: DepartmentMembersProps) {
  const { data } = useDepartmentMembersQuery(department.id);

  if (!data || !data.length) {
    return <DepartmentMembersEmpty />;
  }

  return (
    <>
      {data.map((member) => (
        <DepartmentMember key={member.id} department={department} member={member} />
      ))}
    </>
  );
}

export function DepartmentMembersPanel({ department }: DepartmentMembersPanelProps) {
  return (
    <Panel>
      <DepartmentMembers department={department} />
      {/* Global departments are not editable here */}
      {Boolean(department.project_id) && (
        <AdminOnly render={() => <DepartmentMembersPanelFooter department={department} />} />
      )}
    </Panel>
  );
}

function DepartmentMembersPanelFooter({ department }: DepartmentMembersPanelProps) {
  const navigateToMembersAdd = useNavigateToDepartmentMembersAdd();

  return (
    <PanelFooter>
      <Button
        icon={() => <PlusIcon className="w-4" />}
        onClick={() => navigateToMembersAdd(department)}
      >
        <FormattedMessage id="department.members.add" />
      </Button>
    </PanelFooter>
  );
}

export function DepartmentMembersPanelHeader({ department }: DepartmentMembersPanelProps) {
  const { data } = useDepartmentMembersQuery(department.id);

  return (
    <PageSectionHeader>
      <FormattedMessage id="headings.departments.members" values={{ count: data?.length ?? 0 }} />
    </PageSectionHeader>
  );
}
