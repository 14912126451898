import { Project, Response } from "@dh-critical-path/api-types";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import { UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { UseQueryResult } from "react-query";
import { Button } from "../../../components/button";
import JiraIcon from "../../../components/icons/JiraIcon";
import {
  useActionWithConfirmation,
  useActionWithSuccessHandling,
} from "../../../components/modals";
import { PageSectionHeaderButtonGroup } from "../../../components/page/PageSectionHeader";
import { AdminOnly } from "../../../hooks/useAuth";
import { usePushTasksUpdatesToJiraAction } from "../../../hooks/useTask";
import { TasksFilterFormFields } from "../../../hooks/useTasksFilter";
import { useNavigateToTaskCreate, useNavigateToTasksMassUpdate } from "../../utils/navigation";

type ProjectTasksActionsProps = {
  project: Project;
  query: UseQueryResult<Response.Tasks.GetTasks>;
  methods: UseFormReturn<TasksFilterFormFields>;
};

function TaskCreateAction({ project }: ProjectTasksActionsProps) {
  const navigateToTaskCreate = useNavigateToTaskCreate();

  return (
    <Button icon={PlusIcon} onClick={() => navigateToTaskCreate(project)}>
      <FormattedMessage id="tasks.create" />
    </Button>
  );
}

function TasksUpdateAction({ query, methods }: ProjectTasksActionsProps) {
  const navigateToTasksMassUpdate = useNavigateToTasksMassUpdate();

  if (!query.data?.meta.total) {
    return null;
  }

  return (
    <Button icon={PencilIcon} onClick={() => navigateToTasksMassUpdate(methods.getValues())}>
      <FormattedMessage id="tasks.mass-update" values={{ count: query.data.meta.total }} />
    </Button>
  );
}

function TasksPushToJiraAction({ query, methods }: ProjectTasksActionsProps) {
  const filter = methods.getValues();
  const count = query.data?.meta.total ?? 0;

  const { handlePushUpdatesToJira, isLoading } = usePushTasksUpdatesToJiraAction({ filter });

  const { modal: confirmationModal, handleWithConfirmation } = useActionWithConfirmation(
    handlePushUpdatesToJira,
    async () => ({
      description: `You are about to push updates to Jira. Please ensure that these changes have not been pushed to Jira already.`,
    })
  );

  const { modal: successModal, handleWithSuccessHandling } = useActionWithSuccessHandling(
    handleWithConfirmation,
    async () => ({ description: "The action may take a moment to complete." })
  );

  if (!count) {
    return null;
  }

  return (
    <>
      <Button icon={JiraIcon} loading={isLoading} onClick={handleWithSuccessHandling}>
        <FormattedMessage id="tasks.push-updates-to-jira" />
      </Button>
      {confirmationModal}
      {successModal}
    </>
  );
}

export function ProjectTasksActions(props: ProjectTasksActionsProps) {
  return (
    <PageSectionHeaderButtonGroup>
      <AdminOnly render={() => <TasksPushToJiraAction {...props} />} />
      <AdminOnly render={() => <TasksUpdateAction {...props} />} />
      <TaskCreateAction {...props} />
    </PageSectionHeaderButtonGroup>
  );
}
