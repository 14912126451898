import classnames from "classnames";
import { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";

interface PageSectionSubHeaderProps extends HTMLAttributes<HTMLHeadingElement> {}

const PageSectionSubHeader = styled<HTMLHeadingElement, PageSectionSubHeaderProps>(
  ({ children, className, ...props }) => (
    <h4 {...props} className={classnames("font-normal leading-tight md:leading-normal", className)}>
      {children}
    </h4>
  )
);

export default PageSectionSubHeader;
