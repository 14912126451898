import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Layout, PageContainer, PageHeader, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useUsersFilter } from "../../hooks/useUsersFilter";
import { TasksPagination } from "../Projects/components/TasksPagination";
import { UsersActions } from "./components/UsersActions";
import { UsersPageLayout } from "./components/UsersPageLayout";
import { UsersTable } from "./components/UsersTable";
import { UsersFilterForm } from "./components/forms/UsersFilterForm";

export function UsersPage() {
  useDocumentTitle("Users");

  const { methods, query, setPage } = useUsersFilter();

  return (
    <UsersPageLayout>
      <PageHeader title={<FormattedMessage id="navigation.users" />} />
      <PageContainer>
        <Layout.Column>
          <Layout.Spacer space={8}>
            <PageSectionHeader buttons={<UsersActions />}>
              <FormattedMessage
                id="headings.users"
                values={{ count: query.data?.meta.total ?? 0 }}
              />
            </PageSectionHeader>
            <FormProvider {...methods}>
              <UsersFilterForm />
              <UsersTable users={query.data?.data ?? []} />
              {query.data && <TasksPagination pagination={query.data.meta} onChange={setPage} />}
            </FormProvider>
          </Layout.Spacer>
        </Layout.Column>
      </PageContainer>
    </UsersPageLayout>
  );
}
