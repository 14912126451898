import { ProcessType } from "@dh-critical-path/shared";
import { PropsWithChildren } from "react";
import { FieldArrayWithId, useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { ActionButton, Button } from "../../../../components/button";
import { SortHandle, SortableList, SortableListItem } from "../../../../components/common";
import {
  ControlledDatePicker,
  ControlledInput,
  ControlledMarkdown,
  ControlledSelect,
  ControlledSwitch,
  ControlledTagsInput,
} from "../../../../components/form/controlled";
import { ProjectCreateFields, ProjectUpdateFields } from "../../../../hooks/useProject";
import { useTemplateProjectsForFilterQuery } from "../../../../queries";
import { useTagsForFilterQuery } from "../../../../queries/tags";
import { nextIdNumeric } from "../../../../utils/nextId";
import { processTypeText } from "../../../../utils/text";

type KeyValueProps = {
  index: number;
  field: FieldArrayWithId<ProjectUpdateFields, "key_values">;
  onRemove: (index: number) => void;
};

function KeyValueListColumns({ children }: PropsWithChildren<{}>) {
  return <div className="flex gap-4">{children}</div>;
}

function DragHandleColumn({ children }: PropsWithChildren<{}>) {
  return <div className="flex-none w-4">{children}</div>;
}

function NameColumn({ children }: PropsWithChildren<{}>) {
  return <div className="flex-1">{children}</div>;
}

function ActionsColumn({ children }: PropsWithChildren<{}>) {
  return <div className="flex-none w-10">{children}</div>;
}

function KeyValue({ index, field, onRemove }: KeyValueProps) {
  const { formatMessage } = useIntl();

  return (
    <SortableListItem sortableListItemId={String(field.list_id)} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} className="py-4">
          <KeyValueListColumns>
            <DragHandleColumn>
              <div className="mt-4">
                <SortHandle {...provided.dragHandleProps} />
              </div>
            </DragHandleColumn>
            <NameColumn>
              <div className="space-y-2">
                <ControlledInput
                  name={`key_values.${index}.name`}
                  placeholder={formatMessage({
                    id: "projects.form.key-values.name.placeholder",
                  })}
                  disabled={Boolean(field.template_project_key_id)}
                />
                <ControlledInput
                  name={`key_values.${index}.value`}
                  placeholder={formatMessage({
                    id: "projects.form.key-values.value.placeholder",
                  })}
                />
              </div>
            </NameColumn>
            <ActionsColumn>
              <div className="mt-3">
                <ActionButton variant="delete" rounding="base" onClick={() => onRemove(index)} />
              </div>
            </ActionsColumn>
          </KeyValueListColumns>
        </div>
      )}
    </SortableListItem>
  );
}

function KeyValueList() {
  const { control } = useFormContext<ProjectUpdateFields>();
  const { fields, remove, append, move } = useFieldArray({ control, name: "key_values" });

  function handleAppend() {
    append({
      list_id: nextIdNumeric(),
      name: "",
      value: "",
      sort_order: fields.length,
    });
  }

  return (
    <div>
      <div>
        <FormattedMessage id="projects.form.key-values" />
      </div>
      <SortableList sortableId="KeyValueList" onOrderChange={move}>
        {(provided) => (
          <div ref={provided.innerRef}>
            {fields.map((field, index) => (
              <KeyValue key={field.list_id} index={index} field={field} onRemove={remove} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </SortableList>
      <div className="flex justify-end">
        <Button onClick={handleAppend}>
          <FormattedMessage id="projects.form.key-values.append" />
        </Button>
      </div>
    </div>
  );
}

function TemplateProjectField() {
  const { data } = useTemplateProjectsForFilterQuery();

  return (
    <ControlledSelect
      name="template_project_id"
      label="Template"
      placeholder="Select template"
      options={data}
      showHash
      isClearable
    />
  );
}

function JiraKeyField() {
  const { formatMessage } = useIntl();

  const { watch } = useFormContext<ProjectCreateFields>();

  const shouldCreateJiraProject = watch("jira_project_create");

  if (shouldCreateJiraProject) {
    return null;
  }

  return (
    <ControlledInput
      name="jira_key"
      label={formatMessage({ id: "projects.form.jira_key" })}
      placeholder={formatMessage({ id: "projects.form.jira_key.placeholder" })}
    />
  );
}

function ProcessTypeField() {
  const { formatMessage } = useIntl();

  const { watch } = useFormContext<ProjectUpdateFields>();

  const templateProjectId = watch("template_project_id");
  const id = watch("project_id");

  return (
    <ControlledSelect
      name="process_type"
      label={formatMessage({ id: "projects.form.process-type" })}
      placeholder={formatMessage({ id: "projects.form.process-type.placeholder" })}
      options={[
        { id: ProcessType.OPENING, name: "Opening" },
        { id: ProcessType.CLOSURE, name: "Closure" },
        { id: ProcessType.RE_OPENING, name: "Re-Opening" },
        { id: ProcessType.TRANSITION, name: "Transition" },
      ]}
      isDisabled={Boolean(id || templateProjectId)}
    />
  );
}

function DueDateField() {
  const { watch } = useFormContext<ProjectCreateFields>();

  const processType = watch("process_type");

  return (
    <ControlledDatePicker
      name="due_date"
      label={processTypeText(
        { process_type: processType! },
        "Opening Date",
        "Closure Date",
        "Re-Opening Date",
        "Transition Date"
      )}
    />
  );
}

function ApproximateCompletionDateField() {
  const { watch } = useFormContext<ProjectCreateFields>();

  const processType = watch("process_type");

  return (
    <ControlledInput
      name="approximate_completion_date"
      label={processTypeText(
        { process_type: processType! },
        "Public Opening Date",
        "Public Closure Date",
        "Public Re-Opening Date",
        "Public Transition Date"
      )}
      placeholder={processTypeText(
        { process_type: processType! },
        "Enter Public Opening Date",
        "Enter Public Closure Date",
        "Enter Public Re-Opening Date",
        "Enter Public Transition Date"
      )}
    />
  );
}

function CreateJiraProjectField() {
  return <ControlledSwitch name="jira_project_create" label="Create Project in Jira" />;
}

function TagsField() {
  const { watch } = useFormContext<ProjectCreateFields>();

  const templateProjectId = watch("template_project_id");

  const { data } = useTagsForFilterQuery({
    with_counts_for_template_project: templateProjectId,
  });

  if (!templateProjectId) {
    return null;
  }

  return <ControlledTagsInput name="tags" tags={data ?? []} variant="block" />;
}

export function ProjectForm() {
  const { formatMessage } = useIntl();

  return (
    <>
      <ControlledInput
        name="name"
        label={formatMessage({ id: "projects.form.name" })}
        placeholder={formatMessage({ id: "projects.form.name.placeholder" })}
        required
      />
      <ControlledMarkdown
        name="description"
        label={formatMessage({ id: "projects.form.description" })}
        placeholder={formatMessage({ id: "projects.form.description.placeholder" })}
        required
      />
      <ProcessTypeField />
    </>
  );
}

export function ProjectSidebarCreateForm() {
  return (
    <>
      <TemplateProjectField />
      <DueDateField />
      <ApproximateCompletionDateField />
      <CreateJiraProjectField />
      <JiraKeyField />
      <TagsField />
      <KeyValueList />
    </>
  );
}

export function ProjectSidebarEditForm() {
  return (
    <>
      <DueDateField />
      <ApproximateCompletionDateField />
      <KeyValueList />
    </>
  );
}
