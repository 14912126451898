import { XIcon } from "@heroicons/react/outline";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import ReactSelect, {
  components as Components,
  ControlProps,
  IndicatorProps,
  InputProps,
  OptionProps,
  PlaceholderProps,
  Props,
} from "react-select";
import { GroupHeadingProps } from "react-select/src/components/Group";
import {
  MultiValueGenericProps,
  MultiValueRemoveProps,
} from "react-select/src/components/MultiValue";
import { FormElementProps, Label, ValidationText } from ".";
import { Hash } from "../common";
import { ProfileAvatar } from "../profile";

type OptionType = any;
type IsMulti = boolean;

export interface SelectProps extends Props<OptionType, IsMulti>, FormElementProps {
  size?: "base" | "small";
  className?: string;
}

// TODO: refactor to get rid of these functions
function wrapInArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

export function mapIdNameToValueLabel<T extends { id: number | string; name: string }>(
  data?: T[],
  hash = false
): { value: T["id"]; label: T["name"] }[] {
  return (data || []).map((entry) => ({
    ...entry,
    value: entry.id,
    label: entry.name,
    hash: hash ? entry.id : null,
  }));
}

export function selectedOptionsFromIds<T extends { value: number | string }>(
  options: T[],
  value: null | number | number[] | string | string[]
): T[] {
  const arrayValue = wrapInArray(value);

  return options.filter((option) => arrayValue.indexOf(option.value) >= 0);
}

export function handleChangeUsingIds<T extends { value: number }>(
  onChange: (value: T["value"][]) => void
) {
  return function (values: T[]) {
    onChange(values.map((value) => value.value));
  };
}

export function handleChangeUsingId<T extends { value: number }>(
  onChange: (value: T["value"]) => void
) {
  return function (values: T[]) {
    onChange(
      wrapInArray(values)
        .filter(Boolean)
        .map((value) => value.value)[0]
    );
  };
}

const Option = (props: OptionProps<OptionType, IsMulti>) => {
  const { children, className, isDisabled, isFocused, isSelected, innerRef, innerProps, data } =
    props;
  return (
    <div
      ref={innerRef}
      className={classnames(
        "flex items-center px-3 py-1.5 text-black cursor-pointer",
        {
          "opacity-60 bg-gray-100": isDisabled,
          "bg-beige-100": isFocused,
          "selected-option": isSelected,
        },
        className
      )}
      {...innerProps}
    >
      {isSelected && !data.user && <SelectedOptionIndicator />}
      {isSelected && data.user && <SelectedOptionIndicator variant="round" />}
      {children}
    </div>
  );
};

const FormatOptionLabel = ({ name, label, user, title, color, hash }: OptionType) => (
  <div className="flex items-center text-black cursor-pointer leading-tight break-words">
    {color && <span className={classnames("w-2 h-2 mr-2 rounded-full", color)}></span>}
    {user && (
      <ProfileAvatar showTooltip={false} user={user} size="small" className="mr-3 font-normal" />
    )}
    {title && (
      <ProfileAvatar
        showTooltip={false}
        user={title}
        size="small"
        className="mr-3 font-normal"
        isOutline
      />
    )}
    <span>
      {label || name}
      {hash && (
        <span>
          &nbsp;
          <Hash>{hash}</Hash>
        </span>
      )}
    </span>
  </div>
);

const IndicatorsContainer: React.FC = ({ children }) => (
  <span className="absolute max-h-10 h-full top-0 right-0 flex items-center pr-2">{children}</span>
);

const IndicatorSeparator = () => null;

const DropdownIndicator = () => {
  return <SelectorIcon className="h-5 w-5 text-stone" aria-hidden="true" />;
};

const ClearIndicator = (props: IndicatorProps<OptionType, IsMulti>) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <button {...restInnerProps} ref={ref} className="hover:opacity-50">
      <XIcon className="w-5 h-5 text-stone" />
    </button>
  );
};

const MultiValueContainer = (props: MultiValueGenericProps<OptionType>) => {
  const { children, innerProps } = props;
  return (
    <div
      {...innerProps}
      className="flex items-center bg-beige-100 rounded text-base leading-none text-black py-1 px-2 m-1"
    >
      {children}
    </div>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<OptionType>) => {
  const {
    innerProps: { onClick, onMouseDown, onTouchEnd },
  } = props;
  return (
    <button
      onClick={onClick}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
      className="hover:opacity-50 pl-1"
    >
      <XIcon className="w-4 h-4 text-stone" />
    </button>
  );
};

const SelectedOptionIndicator: React.VFC<{ variant?: "base" | "round" }> = ({ variant }) => (
  <span
    className={classnames("flex items-center justify-center mr-2", {
      "w-6 h-6 border border-beige rounded-full": variant === "round",
    })}
  >
    <CheckIcon className="w-4" />
  </span>
);

const Control = (props: ControlProps<OptionType, IsMulti>) => {
  const { children, isDisabled, isFocused, innerRef, innerProps } = props;
  return (
    <span
      ref={innerRef}
      {...innerProps}
      className={classnames(
        "select-control shadow-sm flex flex-1 overflow-hidden flex-wrap items-center w-full text-base text-black border rounded-md placeholder-stone bg-white",
        {
          "opacity-60 bg-gray-100": isDisabled,
          "isFocused ring-1 ring-beige border-beige": isFocused,
          "border-stone-400 hover:border-beige": !isFocused,
        }
      )}
    >
      {children}
    </span>
  );
};

const Placeholder = (props: PlaceholderProps<OptionType, IsMulti>) => {
  const { children, innerProps, isFocused } = props;
  return (
    <span {...innerProps} className="select-placeholder text-stone flex items-center">
      {!isFocused && children}
    </span>
  );
};

const GroupHeading = (props: GroupHeadingProps<OptionType, IsMulti>) => {
  const { children } = props;
  return <div className="p-3 text-sm text-black uppercase font-bold bg-stone-100">{children}</div>;
};

const Input = ({ ...props }: InputProps) => {
  return (
    <Components.Input {...props} aria-autocomplete="none" autoComplete={new Date().toISOString()} />
  );
};

const Select: React.FC<SelectProps> = ({
  id,
  invalid,
  invalidText,
  isClearable = false,
  label,
  required,
  size = "base",
  className,
  ...props
}) => (
  <div className={classnames("min-w-0 max-w-full", className)}>
    {label && (
      <Label required={required} className="mb-1">
        {label}
      </Label>
    )}
    <ReactSelect
      {...props}
      classNamePrefix="react-select"
      className={classnames("react-select-container", size, {
        "react-select_is-clearable": isClearable,
        "isInvalid": invalid,
      })}
      hideSelectedOptions={false}
      formatOptionLabel={FormatOptionLabel}
      invalid={invalid}
      isClearable={isClearable}
      components={{
        Input,
        Option,
        ClearIndicator,
        DropdownIndicator,
        IndicatorSeparator,
        IndicatorsContainer,
        Control,
        MultiValueRemove,
        MultiValueContainer,
        Placeholder,
        GroupHeading,
      }}
    />
    {invalid && invalidText && <ValidationText className="mt-1">{invalidText}</ValidationText>}
  </div>
);

export default Select;
