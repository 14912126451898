import { useCallback } from "react";
import {
  ConfirmModalProps,
  DangerModalProps,
  SuccessModalProps,
  useConfirmModalPromise,
  useDangerModalPromise,
  useSuccessModalPromise,
} from "../../hooks/useModal";
import { firstErrorMessage } from "../../utils/form";

type AsyncFunction = (...args: any) => Promise<any>;

type AsyncFunctionWithResult = (...args: any) => Promise<boolean>;

export function useActionWithConfirmation<T extends AsyncFunction>(
  handleAction: T,
  propsResolver?: (...args: Parameters<T>) => Promise<Partial<ConfirmModalProps>>
) {
  const { modal, confirmAction } = useConfirmModalPromise();

  const handleWithConfirmation = useCallback(
    async (...args: Parameters<T>) => {
      const confirmed = await confirmAction(await propsResolver?.(...args));

      if (confirmed) {
        await handleAction(...args);
      }

      return confirmed;
    },
    [handleAction, confirmAction, propsResolver]
  );

  return { modal, handleWithConfirmation };
}

export function useActionWithErrorHandling<T extends AsyncFunction>(
  handleAction: T,
  propsResolver?: (error: unknown, ...args: Parameters<T>) => Promise<Partial<DangerModalProps>>
) {
  const { modal, openModal } = useDangerModalPromise();

  const handleWithErrorHandling = useCallback(
    async (...args: Parameters<T>) => {
      try {
        await handleAction(...args);
      } catch (error) {
        await openModal(await propsResolver?.(error, ...args));
      }
    },
    [handleAction, openModal, propsResolver]
  );

  return { modal, handleWithErrorHandling };
}

export function useActionWithSuccessHandling<T extends AsyncFunctionWithResult>(
  handleAction: T,
  propsResolver?: (...args: Parameters<T>) => Promise<Partial<SuccessModalProps>>
) {
  const { modal, openModal } = useSuccessModalPromise();

  const handleWithSuccessHandling = useCallback(
    async (...args: Parameters<T>) => {
      const result = await handleAction(...args);

      if (result) {
        await openModal(await propsResolver?.(...args));
      }

      return result;
    },
    [handleAction, openModal, propsResolver]
  );

  return { modal, handleWithSuccessHandling };
}

export function useTaskOrTemplateTaskRemovingConfirmationDialog<T extends AsyncFunction>(
  handleRemove: T
) {
  const { modal, handleWithConfirmation } = useActionWithConfirmation(handleRemove, async () => ({
    description: "Do you really want to remove this task?",
  }));

  return { modal, handleRemoveWithConfirmation: handleWithConfirmation };
}

export function useTaskOrTemplateTaskRestoringConfirmationDialog<T extends AsyncFunction>(
  handleRestore: T
) {
  const { modal, handleWithConfirmation } = useActionWithConfirmation(handleRestore, async () => ({
    description: "Do you really want to restore this task?",
  }));

  return { modal, handleRestoreWithConfirmation: handleWithConfirmation };
}

export function useTaskOrTemplateTaskRemovingErrorHandling<T extends AsyncFunction>(
  handleRemove: T
) {
  const { modal, handleWithErrorHandling } = useActionWithErrorHandling(
    handleRemove,
    async (error, ..._args) => ({
      title: "Task deleting failed",
      description:
        firstErrorMessage(error) === "task has dependencies"
          ? "Please, detach all dependencies before deleting the task"
          : undefined,
    })
  );

  return { modal, handleRemoveWithErrorHandling: handleWithErrorHandling };
}
