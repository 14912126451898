import { TemplateProject } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverBody,
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useTemplateProjectUpdateForm } from "../../hooks/useTemplateProject";
import { useTemplateProjectQuery } from "../../queries";
import { useSlideOverNavigate } from "../SlideOvers";
import {
  TemplateProjectForm,
  TemplateProjectSidebarForm,
} from "./components/forms/TemplateProjectForm";

type TemplateProjectEditSlideOverProps = {
  templateProjectId: number;
};

type TemplateProjectEditFormProps = {
  project: TemplateProject;
};

function TemplateProjectEditForm({ project }: TemplateProjectEditFormProps) {
  const slideOverNavigate = useSlideOverNavigate();

  const { methods, handleSubmit } = useTemplateProjectUpdateForm(project, slideOverNavigate);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="templates.projects.edit" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverBody>
            <TemplateProjectForm />
          </SlideOverBody>
          <SlideOverSidebar>
            <TemplateProjectSidebarForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TemplateProjectEditSlideOver({
  templateProjectId,
}: TemplateProjectEditSlideOverProps) {
  const result = useTemplateProjectQuery(templateProjectId);

  return (
    <SlideOverWithLoading
      result={result}
      render={(project) => <TemplateProjectEditForm project={project} />}
    />
  );
}
