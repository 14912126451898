import { TemplateProject } from "@dh-critical-path/api-types";
import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { Button } from "../../components/button";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import {
  templateProjectDepartmentLinkFactory,
  useNavigateToTemplateDepartmentCreate,
} from "../utils/navigation";
import { TemplateDepartmentsTasksStatsCards } from "./components/TemplateDepartmentsTasksStatsCards";

type TemplateProjectDepartmentsPageProps = {
  project: TemplateProject;
};

export function TemplateProjectDepartmentsPage({ project }: TemplateProjectDepartmentsPageProps) {
  useDocumentTitle(`${project.name} - Departments`);

  const navigateToCreationView = useNavigateToTemplateDepartmentCreate();

  return (
    <PageContainer>
      <Layout.Column width={12}>
        <Layout.Spacer space={8}>
          <TemplateDepartmentsTasksStatsCards
            filter={{ template_project_ids: [project.id], is_local: true }}
            entityLink={templateProjectDepartmentLinkFactory(project)}
            header={(count) => (
              <PageSectionHeader
                buttons={
                  <Button icon={PlusIcon} onClick={() => navigateToCreationView(project)}>
                    <FormattedMessage id="departments.create-local" />
                  </Button>
                }
              >
                <FormattedMessage id="headings.templates.departments.local" values={{ count }} />
              </PageSectionHeader>
            )}
            showHeaderAsFallback={true}
          />
        </Layout.Spacer>
      </Layout.Column>
    </PageContainer>
  );
}
