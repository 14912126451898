import { Project } from "@dh-critical-path/api-types";
import { Layout, PageContainer } from "../../components/page";
import { AdminOnly } from "../../hooks/useAuth";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { ProjectActivityFeedPanel } from "./components/ProjectActivityFeed";

type ProjectActivityPageProps = {
  project: Project;
};

function ProjectActivityPage({ project }: ProjectActivityPageProps) {
  useDocumentTitle(`${project.name} - Activity`);

  return (
    <PageContainer>
      <Layout.Column>
        <Layout.Spacer space={8}>
          <AdminOnly render={() => <ProjectActivityFeedPanel project={project} />} />
        </Layout.Spacer>
      </Layout.Column>
    </PageContainer>
  );
}

export default ProjectActivityPage;
