import { Request } from "@dh-critical-path/api-types";
import { keepTruthy } from "@dh-critical-path/shared";
import { useCallback } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getTemplateTasks } from "../../api";
import {
  SlideOverBody,
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
} from "../../components/form/SlideOver";
import { useConfirmModalPromise } from "../../hooks/useModal";
import { ProjectCreateFields, useProjectCreateForm } from "../../hooks/useProject";
import { templateKeys } from "../../queries";
import { useActionCompleted } from "../../utils/hooks";
import { pluralize } from "../../utils/text";
import { useNavigateToProject } from "../utils/navigation";
import { ProjectForm, ProjectSidebarCreateForm } from "./components/forms/ProjectForm";

function useCreateWithTemplateConfirmationDialog(
  methods: UseFormReturn<Request.Projects.CreateProject>,
  handleCreate: (data: ProjectCreateFields) => Promise<any>
) {
  const { modal, confirmAction } = useConfirmModalPromise();

  const templateProjectId = methods.watch("template_project_id");

  const tags = methods.watch("tags");

  const filter = {
    template_project_ids: keepTruthy([templateProjectId]),
    tags,
  };

  const query = useQuery(templateKeys.templateTasks(filter), () => getTemplateTasks(filter), {
    enabled: Boolean(templateProjectId),
  });

  const count = query.data?.meta.total ?? 0;

  const handleCreateWithTemplateConfirmation = useCallback(
    async (data: ProjectCreateFields) => {
      const confirmed = data.template_project_id
        ? await confirmAction({
            description: `Do you really want to create a project with ${pluralize(
              count,
              "1 task",
              `${count} tasks`
            )}?`,
          })
        : true;

      if (confirmed) {
        await handleCreate(data);
      }
    },
    [handleCreate, confirmAction, count]
  );

  return { modal, handleCreateWithTemplateConfirmation };
}

function ProjectCreateForm() {
  const navigate = useNavigateToProject();

  const { methods, handleCreate } = useProjectCreateForm();

  const handleCreateAndRedirect = useActionCompleted(handleCreate, navigate);

  const { modal: confirmModal, handleCreateWithTemplateConfirmation } =
    useCreateWithTemplateConfirmationDialog(methods, handleCreateAndRedirect);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={methods.handleSubmit(handleCreateWithTemplateConfirmation)}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="projects.create" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverBody>
            <ProjectForm />
          </SlideOverBody>
          <SlideOverSidebar>
            <ProjectSidebarCreateForm />
          </SlideOverSidebar>
          {confirmModal}
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function ProjectCreateSlideOver() {
  return <ProjectCreateForm />;
}
