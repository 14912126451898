import { CollapsibleFilterFooter } from "../../../../components/common/CollapsibleFilter";
import { FormPanel } from "../../../../components/form/FormPanel";
import {
  ControlledDebouncedInput,
  ControlledSelect,
  ControlledSwitch,
} from "../../../../components/form/controlled";
import { Status, backgroundClassName } from "../../../../enums";
import { useAuth } from "../../../../hooks/useAuth";
import { useProjectsForFilterQuery, useTaskStatusesForFilterQuery } from "../../../../queries";
import { FormResetButton } from "./TasksFilterForm";

function StatusField() {
  const { data } = useTaskStatusesForFilterQuery();

  const options = data?.map((status) => ({
    ...status,
    color: backgroundClassName(status.status_type as Status),
  }));

  return <ControlledSelect name="task_status_ids" label="Statuses" options={options} isMulti />;
}

function ProjectsField() {
  const { user } = useAuth();

  const { data } = useProjectsForFilterQuery({
    assignee_user_id: user!.id,
  });

  const options = data?.map((project) => ({ id: project.id, name: project.name }));

  return (
    <ControlledSelect
      name="project_ids"
      placeholder="Select..."
      label="Projects"
      options={options}
      isMulti
    />
  );
}

function NameField() {
  return <ControlledDebouncedInput name="name" label="Name" debounce={500} />;
}

function MyTasksFilterFormFields() {
  return (
    <>
      <div className="col-span-6 md:col-span-2">
        <NameField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <StatusField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <ProjectsField />
      </div>
    </>
  );
}

function MyTasksFilterFormFooter() {
  return (
    <CollapsibleFilterFooter>
      <div className="flex flex-wrap -mx-3 -my-1">
        <ControlledSwitch name="is_milestone" label="Milestone" className="my-1 mx-3" />
        <ControlledSwitch name="is_overdue" label="Overdue" className="my-1 mx-3" />
      </div>
      <FormResetButton />
    </CollapsibleFilterFooter>
  );
}

export function MyTasksFilterForm() {
  return (
    <FormPanel>
      <MyTasksFilterFormFields />
      <MyTasksFilterFormFooter />
    </FormPanel>
  );
}
