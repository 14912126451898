import { Title } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useTitleUpdateForm } from "../../hooks/useTitle";
import { useTitleQuery } from "../../queries";
import { useSlideOverNavigate } from "../SlideOvers";
import { TitleForm } from "./components/forms/TitleForm";

type TitleEditSlideOverProps = {
  titleId: number;
};

type TitleEditFormProps = {
  title: Title;
};

function TitleEditForm({ title }: TitleEditFormProps) {
  const slideOverNavigate = useSlideOverNavigate();

  const { methods, handleSubmit } = useTitleUpdateForm(title, () => {
    slideOverNavigate();
  });

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="titles.modal.heading.edit" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <TitleForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TitleEditSlideOver({ titleId }: TitleEditSlideOverProps) {
  const result = useTitleQuery(titleId);

  return (
    <SlideOverWithLoading result={result} render={(title) => <TitleEditForm title={title} />} />
  );
}
