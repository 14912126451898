import classNames from "classnames";
import { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";

const PanelFooter = styled<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }) => (
    <div
      {...props}
      className={classNames(
        "flex items-center justify-end pt-6 border-t border-stone-100",
        className
      )}
    >
      {children}
    </div>
  )
);

export default PanelFooter;
