import classnames from "classnames";
import React, { LabelHTMLAttributes } from "react";
import { styled } from "../../utils/styled";

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  disabled?: boolean;
  required?: boolean;
}

const RequiredIcon = () => <span className="text-red-500 pl-1">*</span>;

const LabelStyled = styled<HTMLLabelElement, LabelProps>(
  ({ children, disabled, required, className, ...props }) => (
    <label
      {...props}
      className={classnames(
        "block text-base font-base leading-tight text-iron",
        {
          "pointer-events-none opacity-60": disabled,
        },
        className
      )}
    >
      {children}
    </label>
  )
);

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(function Label(
  { children, disabled, required, className, ...props },
  ref
) {
  return (
    <LabelStyled {...props} disabled={disabled} required={required} className={className} ref={ref}>
      {children}
      {required && <RequiredIcon />}
    </LabelStyled>
  );
});

export default Label;
