import { User } from "@dh-critical-path/api-types";
import { SsoUserRole } from "@dh-critical-path/shared";
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { logout as apiLogout } from "../api";
import { setBearer } from "../http";
import { useMe } from "../queries";
import { useUpdateEffect } from "./useUpdateEffect";

type AuthContextType = {
  isLoading: boolean;
  user?: User;
  login: (token: string | null) => void;
  logout: () => void;
};

type AdminOnlyProps = {
  render: () => React.ReactElement;
  fallback?: () => React.ReactElement | null;
};

const AuthContext = createContext<AuthContextType | null>(null);

function getTokenFromStorage(): string | null {
  return localStorage.getItem("token");
}

function setTokenInStorage(token: string | null): void {
  if (token) {
    localStorage.setItem("token", token);
  } else {
    localStorage.removeItem("token");
  }
}

function redirectToLogin() {
  window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/auth/sso/login?redirect_to=${window.location.origin}/auth/login`;
}

function logout() {
  apiLogout(`${window.location.origin}/auth/login`).then((response) => {
    setTokenInStorage(null);
    window.location.href = response.data;
  });
}

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [token, setToken] = useState<string | null>(() => getTokenFromStorage());
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [isTokenLoading, setIsTokenLoading] = useState(true);
  const me = useMe(isQueryEnabled);

  // token is updated (login/logout)
  useUpdateEffect(() => {
    setTokenInStorage(token);
  }, [token]);

  // token is updated or initial render
  useEffect(() => {
    if (token) {
      setBearer(token);
      setIsQueryEnabled(true);
    }
    setIsTokenLoading(false);
  }, [token]);

  function login(token?: string | null) {
    if (token) {
      setToken(token);
    } else {
      redirectToLogin();
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading: isTokenLoading || me.isLoading,
        user: me.data,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

export function AdminOnly({ render, fallback = () => null }: AdminOnlyProps) {
  const { user } = useAuth();

  return user?.sso_role === SsoUserRole.ADMINISTRATOR ? render() : fallback();
}
