import { User } from "@dh-critical-path/api-types";
import classnames from "classnames";
import React, { HTMLAttributes, PropsWithChildren } from "react";
import ReactDOM from "react-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import { fullName, toInitials } from "../../utils/text";

type ProfileAvatarSize = "lg" | "base" | "small";

type ProfileAvatarUser = Pick<User, "first_name" | "last_name" | "avatar">;

type ProfileAvatarUserOrTitle = ProfileAvatarUser & { name?: string };

type ProfileAvatarContainerProps = {
  size?: ProfileAvatarSize;
  isOutline?: boolean;
  isRing?: boolean;
  className?: string;
};

type ProfileAvatarProps = Pick<HTMLAttributes<HTMLDivElement>, "style"> & {
  user: ProfileAvatarUserOrTitle;
  showTooltip?: boolean;
} & ProfileAvatarContainerProps;

type ProfileAvatarsStackedProps = HTMLAttributes<HTMLDivElement> & {
  users: User[];
  total?: number;
  limit?: number;
  size?: ProfileAvatarSize;
};

type ProfileAvatarRemainingProps = {
  remains: number;
  size?: ProfileAvatarSize;
};
type ProfileAvatarPlaceholderProps = ProfileAvatarTooltipProps & {
  className?: string;
  size?: ProfileAvatarSize;
};

type ProfileAvatarPreviewProps = {
  user: ProfileAvatarUserOrTitle;
};

type ProfileAvatarTooltipProps = {
  tooltipText?: string;
};

function ProfileAvatarContainer({
  size = "base",
  isOutline,
  isRing,
  className,
  children,
  ...props
}: PropsWithChildren<ProfileAvatarContainerProps>) {
  return (
    <div
      {...props}
      className={classnames(
        "profile-avatar flex items-center justify-center rounded-full relative flex-none text-iron",
        {
          "h-10 w-10 text-base": size === "lg",
          "h-8 w-8 text-xs": size === "base",
          "h-7 w-7 text-xs": size === "small",
          "bg-beige-400": !isOutline,
          "border-2 border-violet": isOutline,
        },
        className
      )}
    >
      {isRing && <ProfileAvatarRing />}
      {children}
    </div>
  );
}

function ProfileAvatarRing() {
  return (
    <div className="absolute inset-0 border-2 border-white z-10 rounded-full hover:border-violet-600" />
  );
}

function ProfileAvatarPreview({ user }: ProfileAvatarPreviewProps) {
  if (user?.avatar) {
    return <img src={user.avatar} alt={fullName(user)} className="w-full h-full rounded-full" />;
  } else {
    return <span className="uppercase">{toInitials(user.name ?? fullName(user))}</span>;
  }
}

function ProfileAvatarTooltip({ tooltipText }: ProfileAvatarTooltipProps) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  if (!tooltipText) {
    return null;
  }

  return (
    <>
      <span ref={setTriggerRef} className="absolute inset-0"></span>
      {visible &&
        ReactDOM.createPortal(
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className:
                "px-2 py-1.5 bg-white shadow-tooltip rounded-md text-center xl:whitespace-nowrap text-xs z-50",
            })}
          >
            {tooltipText}
          </div>,
          document.body
        )}
    </>
  );
}

export function ProfileAvatar({ user, showTooltip = true, ...props }: ProfileAvatarProps) {
  return (
    <ProfileAvatarContainer {...props}>
      <ProfileAvatarPreview user={user} />
      {showTooltip && <ProfileAvatarTooltip tooltipText={user.name ?? fullName(user)} />}
    </ProfileAvatarContainer>
  );
}

export function ProfileAvatarPlaceholder({
  className,
  tooltipText,
  ...props
}: ProfileAvatarPlaceholderProps) {
  return (
    <ProfileAvatarContainer {...props} className={classnames("bg-opacity-0", className)}>
      <svg
        className="w-full h-full flex-none"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)">
          <path
            d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Z"
            fill="#F3F4F6"
          />
          <path
            d="M24 20.994v3.007H0v-2.996a14.976 14.976 0 0 1 12.004-6.004c4.904 0 9.26 2.354 11.996 5.993ZM16.002 9a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
            fill="#D1D5DB"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path
              d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
      <ProfileAvatarTooltip tooltipText={tooltipText} />
    </ProfileAvatarContainer>
  );
}

function ProfileAvatarsRemaining({ remains, ...props }: ProfileAvatarRemainingProps) {
  return (
    <ProfileAvatarContainer {...props} className={"border-2 border-white z-10"}>
      +{remains}
    </ProfileAvatarContainer>
  );
}

export function ProfileAvatarsStacked({
  users,
  size = "base",
  limit = 5,
  total = users.length,
  className,
  ...props
}: ProfileAvatarsStackedProps) {
  return (
    <div
      {...props}
      className={classnames(
        "avatars-stacked flex items-center",
        {
          "-space-x-2": size === "base",
          "-space-x-1.5": size === "small",
        },
        className
      )}
    >
      {users.map((user, index) =>
        index < limit ? (
          <ProfileAvatar
            key={user.id}
            user={user}
            size={size}
            style={{ zIndex: index }}
            className="border-2 border-white"
          />
        ) : null
      )}
      {total > limit && <ProfileAvatarsRemaining size={size} remains={total - limit} />}
    </div>
  );
}
