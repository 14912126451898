import classnames from "classnames";
import { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";

const ValidationText = styled<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
  ({ children, className, ...props }) => (
    <p {...props} className={classnames("text-sm text-red-500", className)}>
      {children}
    </p>
  )
);

export default ValidationText;
