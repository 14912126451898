import { VirtualItem, useVirtualizer } from "@tanstack/react-virtual";
import { CSSProperties, PropsWithChildren, RefObject, forwardRef } from "react";

type ExtendedVirtualItem<T> = VirtualItem<unknown> & { item: T };

type VirtualizerProps<T = unknown> = {
  items: T[];
  itemHeight: number;
  containerRef: RefObject<HTMLDivElement>;
  itemsRenderer: (virtualItems: ExtendedVirtualItem<T>[]) => React.ReactNode;
};

type VirtualizerContainerProps = {
  offset: number;
};

export function Virtualizer<T = unknown>({
  items,
  containerRef,
  itemsRenderer,
  itemHeight,
}: VirtualizerProps<T>) {
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => itemHeight,
  });

  return (
    <div
      style={{
        height: `${virtualizer.getTotalSize()}px`,
        width: "100%",
        position: "relative",
      }}
    >
      {itemsRenderer(
        virtualizer.getVirtualItems().map((virtualItem) => ({
          ...virtualItem,
          item: items[virtualItem.index],
        }))
      )}
    </div>
  );
}

export const VirtualizerContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<VirtualizerContainerProps>
>(function VirtualizerContainer({ offset, children }, ref) {
  return (
    <div
      ref={ref}
      className="relative"
      style={{
        maxHeight: `calc(100vh - ${offset}px)`,
        minHeight: "400px",
        overflow: "auto",
      }}
    >
      {children}
    </div>
  );
});

export function virtualItemStyle<T = unknown>(virtualItem: VirtualItem<T>): CSSProperties {
  return {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    minHeight: `${virtualItem.size}px`,
    transform: `translateY(${virtualItem.start}px)`,
  };
}
