import { Project } from "@dh-critical-path/api-types";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Panel } from "../../../components/panel";
import { Status, statusName, textClassName } from "../../../enums";
import { useProjectsTasksStatsQuery } from "../../../queries";
import { tasksByStatusCount } from "../../../utils/misc";

type ProjectTasksStatsProps = {
  project: Project;
};

type TasksStatsPanelProps = {
  status: Status;
  to: string;
};

function TasksStatsPanel({ to, status, children }: PropsWithChildren<TasksStatsPanelProps>) {
  return (
    <Panel className="relative">
      <Link to={to} data-cy="status-panel-link" className="absolute inset-0" />
      <div className="flex w-full justify-between items-center space-x-2 text-base text-iron-800 truncate">
        <span>{statusName(status)}</span>
      </div>
      <p className={classNames("mt-1 text-4xl font-bold", textClassName(status))}>{children}</p>
    </Panel>
  );
}

function TasksStatsPanelContainer({ children }: PropsWithChildren<{}>) {
  return <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5">{children}</div>;
}

export function ProjectTasksStats({ project }: ProjectTasksStatsProps) {
  const { data } = useProjectsTasksStatsQuery({ project_ids: [project.id] });

  if (!data || !data[0]) {
    return null;
  }

  const stats = data[0];

  return (
    <TasksStatsPanelContainer>
      <TasksStatsPanel
        to={`/projects/${stats.entity.id}/tasks?status=${Status.completed}`}
        status={Status.completed}
      >
        {tasksByStatusCount(stats, Status.completed)}
      </TasksStatsPanel>
      <TasksStatsPanel
        to={`/projects/${stats.entity.id}/tasks?status=${Status.todo}`}
        status={Status.todo}
      >
        {tasksByStatusCount(stats, Status.todo)}
      </TasksStatsPanel>
      <TasksStatsPanel
        to={`/projects/${stats.entity.id}/tasks?status=${Status.in_progress}`}
        status={Status.in_progress}
      >
        {tasksByStatusCount(stats, Status.in_progress)}
      </TasksStatsPanel>
      <TasksStatsPanel
        to={`/projects/${stats.entity.id}/tasks?is_overdue=1`}
        status={Status.overdue}
      >
        {tasksByStatusCount(stats, Status.overdue)}
      </TasksStatsPanel>
      <TasksStatsPanel
        to={`/projects/${stats.entity.id}/tasks?status=${Status.backlog}`}
        status={Status.backlog}
      >
        {tasksByStatusCount(stats, Status.backlog)}
      </TasksStatsPanel>
    </TasksStatsPanelContainer>
  );
}
