import { Request } from "@dh-critical-path/api-types";
import { Layout } from "../../../components/page";
import { useTasksQuery } from "../../../queries";
import { TasksTable } from "../../Projects/components/TasksTable";

type TasksGroupProps = {
  filter: Request.Tasks.GetTasks;
  header: (count: number) => React.ReactElement;
};

export function TasksGroup({ filter, header }: TasksGroupProps) {
  const { data } = useTasksQuery({ ...filter, limit: 10 });

  if (!data || !data.data.length) {
    return null;
  }

  return (
    <Layout.Spacer space={4}>
      {header(data.meta.total)}
      <TasksTable tasks={data.data} />
    </Layout.Spacer>
  );
}
