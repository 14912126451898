import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { ControlledInput } from "../../../../components/form/controlled";

export function DepartmentForm() {
  const { formatMessage } = useIntl();

  const { setFocus } = useFormContext();

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  return (
    <>
      <ControlledInput
        name="name"
        label={formatMessage({ id: "department.form.name" })}
        placeholder={formatMessage({ id: "department.form.name.placeholder" })}
        required
      />
    </>
  );
}
