import { useInfiniteQuery, useQuery } from "react-query";
import {
  getProject,
  getProjectActivity,
  getProjectKeyValues,
  getProjectsForFilter,
  getProjectsTasksStats,
} from "../api";
import { getNextPageParam, getPreviousPageParam } from "./utils";

export const projectsKeys = {
  projectsForFilter: (...args: Parameters<typeof getProjectsForFilter>) => [
    "projects:filter",
    ...args,
  ],
  project: (id: number) => ["project", id],
  keyFacts: (id: number) => ["project", id, "key-facts"],
  allProjectsTasksStats: () => ["projects:stats"],
  projectsTasksStats: (...args: Parameters<typeof getProjectsTasksStats>) => [
    "projects:stats",
    ...args,
  ],
  activity: (...args: Parameters<typeof getProjectActivity>) => ["project:activity", ...args],
};

export function useProjectsForFilterQuery(...args: Parameters<typeof getProjectsForFilter>) {
  return useQuery(projectsKeys.projectsForFilter(...args), () => getProjectsForFilter(...args));
}

export function useProjectQuery(id: number) {
  return useQuery(projectsKeys.project(id), () => getProject(id), { enabled: Boolean(id) });
}

export function useProjectKeyFactsQuery(id: number) {
  return useQuery(projectsKeys.keyFacts(id), () => getProjectKeyValues(id));
}

export function useProjectsTasksStatsQuery(...args: Parameters<typeof getProjectsTasksStats>) {
  return useQuery(projectsKeys.projectsTasksStats(...args), () => {
    return getProjectsTasksStats(...args);
  });
}

export function useProjectActivityQuery(...[params]: Parameters<typeof getProjectActivity>) {
  return useInfiniteQuery(
    projectsKeys.activity(params),
    ({ pageParam = 1 }) => getProjectActivity({ ...params, page: pageParam }),
    { getNextPageParam, getPreviousPageParam }
  );
}
