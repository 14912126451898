import { TemplateDepartment } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useTemplateDepartmentMembersAddForm } from "../../hooks/useTemplateDepartment";
import { useTemplateDepartmentQuery } from "../../queries";
import { useNavigateToTemplateDepartment } from "../utils/navigation";
import { TemplateDepartmentMembersForm } from "./components/forms/TemplateDepartmentMembersForm";

type TemplateDepartmentMembersAddSlideOverProps = {
  templateDepartmentId: number;
};

type TemplateDepartmentMembersAddFormProps = {
  department: TemplateDepartment;
};

function TemplateDepartmentMembersAddForm({ department }: TemplateDepartmentMembersAddFormProps) {
  const navigate = useNavigateToTemplateDepartment();

  const { methods, handleSubmit } = useTemplateDepartmentMembersAddForm(department, navigate);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="departments.modal.heading.add-member" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <TemplateDepartmentMembersForm department={department} />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TemplateDepartmentMembersAddSlideOver({
  templateDepartmentId,
}: TemplateDepartmentMembersAddSlideOverProps) {
  const result = useTemplateDepartmentQuery(templateDepartmentId);

  return (
    <SlideOverWithLoading
      result={result}
      render={(department) => <TemplateDepartmentMembersAddForm department={department} />}
    />
  );
}
