import { Project } from "@dh-critical-path/api-types";
import { differenceInDays } from "date-fns";
import { useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverBody,
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useConfirmModalPromise } from "../../hooks/useModal";
import { ProjectUpdateFields, useProjectEditForm } from "../../hooks/useProject";
import { useProjectQuery } from "../../queries";
import { useActionCompleted } from "../../utils/hooks";
import { pluralize, processTypeText } from "../../utils/text";
import { useSlideOverNavigate } from "../SlideOvers";
import { ProjectForm, ProjectSidebarEditForm } from "./components/forms/ProjectForm";

type ProjectEditSlideOverProps = {
  projectId: number;
};

type ProjectEditFormProps = {
  project: Project;
};

export function useDatesShiftConfirmationDialog(
  project: Project,
  handleUpdate: (data: ProjectUpdateFields) => Promise<any>
) {
  const { modal, confirmAction } = useConfirmModalPromise();

  const handleUpdateWithDatesShiftConfirmation = useCallback(
    async (data: ProjectUpdateFields) => {
      const shiftInDays = Math.abs(
        differenceInDays(new Date(data.due_date), new Date(project.due_date))
      );

      const confirmed = shiftInDays
        ? await confirmAction({
            description: <DatesShiftConfirmationText project={project} shiftInDays={shiftInDays} />,
          })
        : true;

      if (confirmed) {
        await handleUpdate(data);
      }
    },
    [handleUpdate, confirmAction, project]
  );

  return { modal, handleUpdateWithDatesShiftConfirmation };
}

function DatesShiftConfirmationText({
  project,
  shiftInDays,
}: {
  project: Project;
  shiftInDays: number;
}) {
  return (
    <div className="space-y-2">
      <p>
        You chose to change the Project{" "}
        {processTypeText(project, "Opening", "Closure", "Re-Opening", "Transition")} Date for{" "}
        {shiftInDays} {pluralize(shiftInDays, "day", "days")}. When you click "Confirm" all open
        project tasks will be shifted accordingly, for {shiftInDays}{" "}
        {pluralize(shiftInDays, "day", "days")}.
      </p>
      <p>The tasks with the following statuses won't be affected:</p>
      <ul className="list-disc pl-5">
        <li>Completed</li>
        <li>Canceled</li>
        <li>With fixed dates</li>
      </ul>
      <p>Are you sure you want to perform this action?</p>
    </div>
  );
}

function ProjectEditForm({ project }: ProjectEditFormProps) {
  const navigate = useSlideOverNavigate();

  const { methods, handleUpdate } = useProjectEditForm(project);

  const handleUpdateAndRedirect = useActionCompleted(handleUpdate, () => navigate());

  const { modal: confirmModal, handleUpdateWithDatesShiftConfirmation } =
    useDatesShiftConfirmationDialog(project, handleUpdateAndRedirect);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={methods.handleSubmit(handleUpdateWithDatesShiftConfirmation)}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="projects.edit" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverBody>
            <ProjectForm />
            {confirmModal}
          </SlideOverBody>
          <SlideOverSidebar>
            <ProjectSidebarEditForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function ProjectEditSlideOver({ projectId }: ProjectEditSlideOverProps) {
  const result = useProjectQuery(projectId);

  return (
    <SlideOverWithLoading
      result={result}
      render={(project) => <ProjectEditForm project={project} />}
    />
  );
}
