import { useQuery } from "react-query";
import {
  compareTemplateTasks,
  getTemplateDepartment,
  getTemplateDepartmentMembers,
  getTemplateDepartmentsForFilter,
  getTemplateDepartmentsTasksStats,
  getTemplateProject,
  getTemplateProjectKeys,
  getTemplateProjectsForFilter,
  getTemplateProjectsTasksStats,
  getTemplateTask,
  getTemplateTaskAssigneesForFilter,
  getTemplateTasks,
  getTemplateTasksForFilter,
  getTemplateTitle,
  getTemplateTitles,
} from "../api";

export const templateKeys = {
  // projects
  templateProject: (id: number) => ["templates:project", id],
  templateProjectKeys: (id: number) => ["templates:project:keys", id],
  allTemplateProjectsTasksStats: () => ["templates:projects:stats"],
  templateProjectsTasksStats: (...args: Parameters<typeof getTemplateProjectsTasksStats>) => [
    "templates:projects:stats",
    ...args,
  ],
  templateProjectsForFilter: () => ["templates:projects:filter"],
  // departments
  allTemplateDepartments: () => ["templates:departments"],
  templateDepartment: (id: number) => ["templates:department", id],
  templateDepartmentsForFilter: (...args: Parameters<typeof getTemplateDepartmentsForFilter>) => [
    "templates:departments:filter",
    ...args,
  ],
  templateDepartmentMembers: (id: number) => ["templates:department:members", id],
  templateDepartmentsTasksStats: (...args: Parameters<typeof getTemplateDepartmentsTasksStats>) => [
    "templates:departments:stats",
    ...args,
  ],
  // titles
  allTemplateTitles: () => ["templates:titles"],
  templateTitle: (id: number) => ["templates:title", id],
  templateTitles: (...args: Parameters<typeof getTemplateTitles>) => ["templates:titles", ...args],
  // tasks
  allTemplateTasks: () => ["templates:tasks"],
  templateTask: (id: number) => ["templates:tasks", id],
  templateTasks: (...args: Parameters<typeof getTemplateTasks>) => ["templates:tasks", ...args],
  templateTasksForFilter: (...args: Parameters<typeof getTemplateTasksForFilter>) => [
    "templates:tasks:filter",
    ...args,
  ],
  templateTasksAssigneesForFilter: (
    ...args: Parameters<typeof getTemplateTaskAssigneesForFilter>
  ) => ["templates:tasks:assignees", ...args],
  compareTemplateTasks: (...args: Parameters<typeof compareTemplateTasks>) => [
    "templates:tasks",
    ...args,
  ],
};

export function useTemplateProjectQuery(id: number) {
  return useQuery(templateKeys.templateProject(id), () => {
    return getTemplateProject(id);
  });
}

export function useTemplateProjectsForFilterQuery() {
  return useQuery(templateKeys.templateProjectsForFilter(), getTemplateProjectsForFilter);
}

export function useTemplateProjectKeysQuery(id: number) {
  return useQuery(templateKeys.templateProjectKeys(id), () => {
    return getTemplateProjectKeys(id);
  });
}

export function useTemplateProjectsTasksStatsQuery(
  ...args: Parameters<typeof getTemplateProjectsTasksStats>
) {
  return useQuery(templateKeys.templateProjectsTasksStats(...args), () => {
    return getTemplateProjectsTasksStats(...args);
  });
}

export function useTemplateDepartmentsTasksStatsQuery(
  ...args: Parameters<typeof getTemplateDepartmentsTasksStats>
) {
  return useQuery(templateKeys.templateDepartmentsTasksStats(...args), () => {
    return getTemplateDepartmentsTasksStats(...args);
  });
}

export function useTemplateDepartmentsForFilterQuery(
  ...args: Parameters<typeof getTemplateDepartmentsForFilter>
) {
  return useQuery(templateKeys.templateDepartmentsForFilter(...args), () => {
    return getTemplateDepartmentsForFilter(...args);
  });
}

export function useTemplateDepartmentQuery(id: number) {
  return useQuery(templateKeys.templateDepartment(id), () => {
    return getTemplateDepartment(id);
  });
}

export function useTemplateDepartmentMembersQuery(id: number) {
  return useQuery(templateKeys.templateDepartmentMembers(id), () => {
    return getTemplateDepartmentMembers(id);
  });
}

export function useTemplateTitleQuery(id: number) {
  return useQuery(templateKeys.templateTitle(id), () => {
    return getTemplateTitle(id);
  });
}

export function useTemplateTitlesQuery(...args: Parameters<typeof getTemplateTitles>) {
  return useQuery(templateKeys.templateTitles(...args), () => {
    return getTemplateTitles(...args);
  });
}

export function useTemplateTaskQuery(id: number) {
  return useQuery(templateKeys.templateTask(id), () => {
    return getTemplateTask(id);
  });
}

export function useTemplateTasksQuery(...args: Parameters<typeof getTemplateTasks>) {
  return useQuery(templateKeys.templateTasks(...args), () => {
    return getTemplateTasks(...args);
  });
}

export function useTemplateTasksForFilterQuery(
  ...args: Parameters<typeof getTemplateTasksForFilter>
) {
  return useQuery(templateKeys.templateTasksForFilter(...args), () => {
    return getTemplateTasksForFilter(...args);
  });
}

export function useTemplateTaskAssigneesForFilterQuery(
  ...args: Parameters<typeof getTemplateTaskAssigneesForFilter>
) {
  return useQuery(templateKeys.templateTasksAssigneesForFilter(...args), () => {
    return getTemplateTaskAssigneesForFilter(...args);
  });
}

export function useCompareTemplateTasks(...args: Parameters<typeof compareTemplateTasks>) {
  return useQuery(templateKeys.compareTemplateTasks(...args), () => compareTemplateTasks(...args), {
    enabled: Boolean(args[0].project_id),
  });
}
