import { TemplateProject } from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { ActionButton, Button } from "../../../components/button";
import { PageSectionHeader } from "../../../components/page";
import { useConfirmModal } from "../../../hooks/useModal";
import { useTemplateProjectDelete } from "../../../hooks/useTemplateProject";
import {
  useNavigateToTemplateProjectEdit,
  useNavigateToTemplateProjects,
} from "../../utils/navigation";

type TemplateProjectHeaderProps = {
  project: TemplateProject;
};

function TemplateProjectEditButton({ project }: TemplateProjectHeaderProps) {
  const navigateToEditView = useNavigateToTemplateProjectEdit();

  return (
    <Button variant="secondary" onClick={() => navigateToEditView(project)}>
      <FormattedMessage id="templates.projects.edit" />
    </Button>
  );
}

function TemplateProjectExportAsCsvButton({ project }: TemplateProjectHeaderProps) {
  const [path, params] = project.export_url.split("?");
  const exportUrlParams = new URLSearchParams(params);
  return (
    <form action={`${process.env.REACT_APP_BACKEND_URL}${path}`}>
      <input hidden name="signature" value={exportUrlParams.get("signature") as string} />
      <Button type="submit" variant="secondary">
        <FormattedMessage id="templates.projects.export-as-csv" />
      </Button>
    </form>
  );
}

function TemplateProjectHeaderActions({ project }: TemplateProjectHeaderProps) {
  return (
    <>
      <TemplateProjectExportAsCsvButton project={project} />
      <TemplateProjectEditButton project={project} />
      <TemplateProjectDeleteButton project={project} />
    </>
  );
}

function TemplateProjectDeleteButton({ project }: TemplateProjectHeaderProps) {
  const { modal, openModal } = useConfirmModal();
  const { handleRemove, isLoading } = useTemplateProjectDelete(project);
  const navigateToProjects = useNavigateToTemplateProjects();

  function handleClick() {
    openModal(() => handleRemove().then(() => navigateToProjects()));
  }

  return (
    <>
      <ActionButton
        variant="delete"
        size="lg"
        rounding="base"
        onClick={handleClick}
        loading={isLoading}
      />
      {modal}
    </>
  );
}

export function TemplateProjectHeader({ project }: TemplateProjectHeaderProps) {
  return <PageSectionHeader buttons={<TemplateProjectHeaderActions project={project} />} />;
}
