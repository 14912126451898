import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
} from "../../components/form/SlideOver";
import { useUserAddForm } from "../../hooks/useUser";
import { useNavigateToUser } from "../utils/navigation";
import { EmailField, SsoRoleField } from "./components/forms/UserForm";

function UserAddForm() {
  return (
    <>
      <EmailField />
      <SsoRoleField />
    </>
  );
}

function UserAddView() {
  const navigateToView = useNavigateToUser();

  const { methods, handleSubmit } = useUserAddForm(navigateToView);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="users.modal.heading.add" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <UserAddForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function UserAddSlideOver() {
  return <UserAddView />;
}
