import { Route, Routes } from "react-router";
import { TemplateDepartmentPage } from "./TemplateDepartmentPage";
import { TemplateDepartmentsPage } from "./TemplateDepartmentsPage";
import { TemplateProjectRoutes } from "./TemplateProjectRoutes";
import { TemplateProjectsPage } from "./TemplateProjectsPage";

export function TemplatesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<TemplateProjectsPage />} />
      <Route path="/departments" element={<TemplateDepartmentsPage />} />
      <Route path="/departments/:id" element={<TemplateDepartmentPage />} />
      <Route path="/:id/*" element={<TemplateProjectRoutes />} />
    </Routes>
  );
}
