import { Project, Request, Response, TasksStats } from "@dh-critical-path/api-types";
import { Layout, PageSectionSubHeader } from "../../../components/page";
import { useProjectsTasksStatsQuery, useTasksQueries } from "../../../queries";
import { TasksTable } from "./TasksTable";

type ProjectsTasksProps = {
  header: React.ReactElement;
  filter: Request.Projects.GetProjectsTasksStats;
  tasksFilter: Request.Tasks.GetTasks;
};

type ProjectTasksGroupProps = {
  stats: TasksStats<Project>;
  tasks: Response.Tasks.GetTasks;
};

function ProjectTasksGroup({ stats, tasks }: ProjectTasksGroupProps) {
  return (
    <>
      <PageSectionSubHeader>
        {stats.entity.name} ({tasks.meta.total})
      </PageSectionSubHeader>
      <TasksTable tasks={tasks.data} />
    </>
  );
}

export function ProjectsTasksGroup({ header, filter, tasksFilter }: ProjectsTasksProps) {
  const { data } = useProjectsTasksStatsQuery(filter);

  const results = useTasksQueries(
    (data ?? []).map((item) => ({
      ...tasksFilter,
      project_ids: [item.entity.id],
    }))
  );

  const total = results.reduce(
    (total, result) => total + (result.data ? result.data.data.length : 0),
    0
  );

  if (!data || !total) {
    return null;
  }

  return (
    <Layout.Spacer space={4}>
      {header}
      {results.map((result, index) =>
        result.data?.meta.total ? (
          <ProjectTasksGroup key={index} stats={data[index]} tasks={result.data} />
        ) : null
      )}
    </Layout.Spacer>
  );
}
