import { TemplateProject } from "@dh-critical-path/api-types";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { hasAnyOf } from "../../components/form/TagsInput";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useSearchParameter } from "../../hooks/useSearchParameter";
import { useTemplateTasksFilter } from "../../hooks/useTemplateTasksFilter";
import { TasksPagination } from "../Projects/components/TasksPagination";
import { TemplateProjectTasksActions } from "./components/TemplateProjectTasksActions";
import {
  TemplateTasksTable,
  TemplateTasksTableHeadRowSortable,
} from "./components/TemplateTasksTable";
import { TemplateTasksFilterForm } from "./components/forms/TemplateTasksFilterForm";

type TemplateProjectTasksPageProps = {
  project: TemplateProject;
};

export function TemplateProjectTasksPage({ project }: TemplateProjectTasksPageProps) {
  useDocumentTitle(`${project.name} - Tasks`);

  const { methods, query, setPage, activeFiltersCount } = useTemplateTasksFilter({
    template_project_ids: [project.id],
  });

  // TODO: wrap in hook
  const tagId = useSearchParameter("tag");
  const assigneeId = useSearchParameter("assignee");
  const templateDepartmentId = useSearchParameter("department");

  useEffect(() => {
    if (tagId) {
      methods.setValue("tags", [hasAnyOf([Number(tagId)])]);
    }
  }, [methods, tagId]);

  useEffect(() => {
    if (assigneeId) {
      methods.setValue("assignee_ids", [Number(assigneeId)]);
    }
  }, [methods, assigneeId]);

  useEffect(() => {
    if (templateDepartmentId) {
      methods.setValue("template_department_ids", [Number(templateDepartmentId)]);
    }
  }, [methods, templateDepartmentId]);

  return (
    <PageContainer>
      <Layout.Column>
        <Layout.Spacer space={8}>
          <PageSectionHeader buttons={<TemplateProjectTasksActions project={project} />}>
            <FormattedMessage
              id="headings.templates.tasks"
              values={{ count: query.data?.meta.total ?? 0 }}
            />
          </PageSectionHeader>
          <FormProvider {...methods}>
            <TemplateTasksFilterForm activeFiltersCount={activeFiltersCount} />
            <TemplateTasksTable
              headRenderer={<TemplateTasksTableHeadRowSortable templateProject={project} />}
              tasks={query.data?.data ?? []}
            />
            {query.data && <TasksPagination pagination={query.data.meta} onChange={setPage} />}
          </FormProvider>
        </Layout.Spacer>
      </Layout.Column>
    </PageContainer>
  );
}
