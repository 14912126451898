import { PropsWithChildren } from "react";
import { PageHeader } from "../../../components/page";
import HeaderHoc from "../../../hoc/header/HeaderHoc";

type ProjectsPageLayoutProps = {
  title: React.ReactNode;
};

export function ProjectsPageLayout({
  title,
  children,
}: PropsWithChildren<ProjectsPageLayoutProps>) {
  return (
    <>
      <HeaderHoc />
      <div>
        <PageHeader title={title} />
        {children}
      </div>
    </>
  );
}
