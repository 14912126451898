import { Project } from "@dh-critical-path/api-types";
import { useQuery } from "react-query";
import { getTasksForGantt } from "../../api";
import { GanttChartFixed, tasksToGanttTasks } from "../../components/gantt/GanttChart";
import { PageContainer } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { tasksKeys } from "../../queries";
import { useNavigateToTask } from "../utils/navigation";

type ProjectTimelinePageProps = {
  project: Project;
};

function useTasksForGantt(project: Project) {
  // TODO: query tasks without description and other useless fields for Gantt

  const params = { project_id: project.id };

  // TODO: is this really needed?
  const { data: tasks } = useQuery(
    tasksKeys.tasksForGantt(params),
    () => getTasksForGantt(params),
    { notifyOnChangeProps: ["data"] }
  );

  return tasks;
}

function ProjectTimelinePage({ project }: ProjectTimelinePageProps) {
  useDocumentTitle(`${project.name} - Timeline`);

  const navigateToTask = useNavigateToTask();

  const tasks = useTasksForGantt(project);

  if (!tasks) {
    return null;
  }

  const data = tasksToGanttTasks(tasks);

  return (
    <PageContainer type="full-width">
      <GanttChartFixed data={data} onTaskClick={(id) => navigateToTask({ id })} />
    </PageContainer>
  );
}

export default ProjectTimelinePage;
