/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const mounting = useRef(true);

  return useEffect(() => {
    if (mounting.current) {
      mounting.current = false;
    } else {
      return effect();
    }
  }, deps);
}
