import { FormattedMessage } from "react-intl";
import { Header, HeaderLink } from "../../components/header";
import { AdminOnly } from "../../hooks/useAuth";
import HeaderProfileMenuHoc from "./HeaderProfileMenuHoc";

function HeaderHoc() {
  return (
    <Header
      profileMenu={<HeaderProfileMenuHoc />}
      links={
        <>
          <HeaderLink to="/" end data-cy="header-dashboard-link">
            <FormattedMessage id="navigation.dashboard" />
          </HeaderLink>
          <HeaderLink to="/projects" data-cy="header-projects-link">
            <FormattedMessage id="navigation.my-projects" />
          </HeaderLink>
          <HeaderLink to="/tasks" data-cy="header-tasks-link">
            <FormattedMessage id="navigation.my-tasks" />
          </HeaderLink>
          <AdminOnly
            render={() => (
              <HeaderLink to="/templates" data-cy="header-template-link">
                <FormattedMessage id="navigation.templates" />
              </HeaderLink>
            )}
          />
          <AdminOnly
            render={() => (
              <HeaderLink to="/users" data-cy="header-template-link">
                <FormattedMessage id="navigation.users" />
              </HeaderLink>
            )}
          />
          <AdminOnly
            render={() => (
              <HeaderLink to="/tracking" data-cy="header-tracking-link">
                <FormattedMessage id="navigation.tracking" />
              </HeaderLink>
            )}
          />
        </>
      }
    />
  );
}

export default HeaderHoc;
