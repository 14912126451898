import { Project, Request, Task, TemplateTask } from "@dh-critical-path/api-types";
import { ResourceIdPrefix, TaskFields, prefixResourceId } from "@dh-critical-path/shared";
import { useCallback, useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  createTask,
  createTaskFromTemplate,
  deleteTask,
  pushTasksUpdatesToJiraByFilter,
  restoreTask,
  updateTask,
  updateTaskFromTemplate,
  updateTasksByFilter,
} from "../api";
import { attachmentsKeys, tasksKeys, templateKeys } from "../queries";
import { interceptValidationErrorsAndThrow, validationErrorsInterceptor } from "../utils/form";
import { dateToSqlFormat, mapIds } from "../utils/misc";

export type TaskCreateFields = Request.Tasks.CreateTask;

export type TaskUpdateFields = Request.Tasks.UpdateTask;

export type UpdateTasksByFilterFields = Request.Tasks.UpdateTasksByFilter;

type TaskCallback = (task: Pick<Task, "id" | "name">) => void;

type TaskCreateFormParams = {
  projectId: number;
  departmentId?: number;
};

type TasksMassUpdateFormParams = {
  filter: Request.Tasks.GetTasks;
};

export function useTaskCreateForm(params: TaskCreateFormParams, onCreated: TaskCallback) {
  const queryClient = useQueryClient();

  const { mutate, isLoading: isCreating } = useMutation(createTask);

  const form = useForm<TaskCreateFields>({
    defaultValues: {
      project_id: params.projectId,
      department_id: params.departmentId,
      start_date: dateToSqlFormat(new Date()),
      due_date: dateToSqlFormat(new Date()),
    },
  });

  useDepartmentAssigneeSync(form as any);

  function handleCreate(params: TaskUpdateFields) {
    mutate(params, {
      onSuccess: (response) => {
        // reset attachments for another task
        queryClient.setQueryData(attachmentsKeys.taskAttachments(undefined), []);
        queryClient.invalidateQueries(tasksKeys.allTasks());
        onCreated(response);
      },
      onError: validationErrorsInterceptor(form),
    });
  }

  return { form, isCreating, handleCreate };
}

export function useTaskUpdateForm(task: Task, onUpdated: TaskCallback) {
  const queryClient = useQueryClient();

  const { mutate, isLoading: isUpdating } = useMutation(updateTask);

  const form = useForm<TaskUpdateFields>({
    defaultValues: {
      task_id: task.id,
      project_id: task.project_id,
      department_id: task.department_id ?? undefined,
      task_status_id: task.task_status_id,
      name: task.name,
      description: task.description,
      start_date: task.start_date,
      due_date: task.due_date,
      is_milestone: Boolean(task.is_milestone),
      is_dates_frozen: Boolean(task.is_dates_frozen),
      assignee_id: task.assignee_user_id
        ? prefixResourceId(task.assignee_user_id, ResourceIdPrefix.USER)
        : task.assignee_title_id
        ? prefixResourceId(task.assignee_title_id, ResourceIdPrefix.TITLE)
        : null,
      supporter_ids: [
        ...mapIds(task.supportedByUsers).map((id) => prefixResourceId(id, ResourceIdPrefix.USER)),
        ...mapIds(task.supportedByTitles).map((id) => prefixResourceId(id, ResourceIdPrefix.TITLE)),
      ],
      depends_on_ids: mapIds(task.dependsOn),
      dependency_for_ids: mapIds(task.dependencyFor),
      tag_ids: mapIds(task.tags),
    },
  });

  useDepartmentAssigneeSync(form);

  function handleUpdate(params: TaskUpdateFields) {
    mutate(params, {
      onSuccess: (response) => {
        queryClient.invalidateQueries(tasksKeys.task(task.id));
        queryClient.invalidateQueries(tasksKeys.allTasks());
        queryClient.invalidateQueries(templateKeys.allTemplateTasks());
        onUpdated(response.data);
      },
      onError: validationErrorsInterceptor(form),
    });
  }

  return { form, isUpdating, handleUpdate };
}

export function useTaskRemoveAction() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteTask);

  const handleRemove = useCallback(
    async (task: Task) => {
      await mutateAsync(task.id);
      queryClient.invalidateQueries(tasksKeys.allTasks());
      queryClient.invalidateQueries(templateKeys.allTemplateTasks());
    },
    [queryClient, mutateAsync]
  );

  return { handleRemove, isLoading };
}

export function useTaskRestoreAction() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(restoreTask);

  const handleRestore = useCallback(
    async (task: Task) => {
      await mutateAsync(task.id);
      queryClient.invalidateQueries(tasksKeys.task(task.id));
      queryClient.invalidateQueries(tasksKeys.allTasks());
      queryClient.invalidateQueries(templateKeys.allTemplateTasks());
    },
    [queryClient, mutateAsync]
  );

  return { handleRestore, isLoading };
}

export function useTaskCreateFromTemplate(project: Project, templateTask: TemplateTask | null) {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(createTaskFromTemplate);

  const handleCreate = useCallback(async () => {
    if (templateTask) {
      const task = await mutateAsync({
        project_id: project.id,
        template_task_id: templateTask.id,
      });

      queryClient.invalidateQueries(tasksKeys.allTasks());
      queryClient.invalidateQueries(templateKeys.allTemplateTasks());

      return task;
    }
  }, [queryClient, mutateAsync, project, templateTask]);

  return { handleCreate, isLoading };
}

export function useTaskUpdateFromTemplate(templateTask: TemplateTask | null, task: Task | null) {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess } = useMutation(updateTaskFromTemplate);

  const handleUpdate = useCallback(async () => {
    if (task && templateTask) {
      await mutateAsync({
        template_task_id: templateTask.id,
        task_id: task.id,
        fields: Object.values(TaskFields),
      });
      queryClient.invalidateQueries(tasksKeys.allTasks());
      queryClient.invalidateQueries(templateKeys.allTemplateTasks());
    }
  }, [queryClient, mutateAsync, task, templateTask]);

  return { handleUpdate, isLoading, isSuccess };
}

export function useTasksMassUpdateForm(params: TasksMassUpdateFormParams) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateTasksByFilter);

  const methods = useForm<UpdateTasksByFilterFields>({
    defaultValues: {
      filter: params.filter,
      data: {},
    },
  });

  const data = methods.watch("data");

  // sync assignee and department
  useEffect(() => {
    methods.setValue("data.assignee_id", undefined);
  }, [methods, data.department_id]);

  const handleMassUpdate = useCallback(
    async ({ filter, data }) => {
      try {
        await mutateAsync({ filter, data });

        queryClient.invalidateQueries(tasksKeys.allTasks());
      } catch (e) {
        interceptValidationErrorsAndThrow(methods, e);
      }
    },
    [queryClient, methods, mutateAsync]
  );

  return { methods, handleMassUpdate };
}

export function usePushTasksUpdatesToJiraAction(params: TasksMassUpdateFormParams) {
  const { mutateAsync, isLoading } = useMutation(pushTasksUpdatesToJiraByFilter);

  const handlePushUpdatesToJira = useCallback(() => mutateAsync(params), [mutateAsync, params]);

  return { handlePushUpdatesToJira, isLoading };
}

function useDepartmentAssigneeSync({
  watch,
  setValue,
  formState: { dirtyFields },
}: UseFormReturn<TaskUpdateFields>) {
  const departmentId = watch("department_id");

  useEffect(() => {
    if (dirtyFields.department_id) {
      setValue("assignee_id", null);
    }
  }, [departmentId, dirtyFields.department_id, setValue]);
}
