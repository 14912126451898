import { Project } from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { NavigationTab } from "../../../components/common";
import { PageHeaderTabs } from "../../../components/page";
import { AdminOnly } from "../../../hooks/useAuth";

type ProjectTabsProps = {
  project: Project;
};

export function ProjectTabs({ project }: ProjectTabsProps) {
  return (
    <PageHeaderTabs>
      <NavigationTab to={`/projects/${project.id}`} end data-cy="project-navigation-overview-link">
        <FormattedMessage id="navigation.overview" />
      </NavigationTab>
      <NavigationTab to={`/projects/${project.id}/tasks`} data-cy="project-navigation-tasks-link">
        <FormattedMessage id="navigation.tasks" />
      </NavigationTab>
      <NavigationTab
        to={`/projects/${project.id}/departments`}
        data-cy="project-navigation-departments-link"
      >
        <FormattedMessage id="navigation.departments" />
      </NavigationTab>
      <NavigationTab
        to={`/projects/${project.id}/attachments`}
        data-cy="project-navigation-attachments-link"
      >
        <FormattedMessage id="navigation.files" />
      </NavigationTab>
      <AdminOnly
        render={() => (
          <NavigationTab
            to={`/projects/${project.id}/activity`}
            data-cy="project-navigation-activity-link"
          >
            <FormattedMessage id="navigation.activity" />
          </NavigationTab>
        )}
      />
      <NavigationTab
        to={`/projects/${project.id}/timeline`}
        data-cy="project-navigation-timeline-link"
      >
        <FormattedMessage id="navigation.timeline" />
      </NavigationTab>
    </PageHeaderTabs>
  );
}
