import { Request, Response, TemplateProject } from "@dh-critical-path/api-types";
import { ProcessType } from "@dh-critical-path/shared";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { createTemplateProject, deleteTemplateProject, updateTemplateProject } from "../api";
import { templateKeys } from "../queries";
import { interceptValidationErrorsAndThrow } from "../utils/form";

export type TemplateProjectCreateFields = Request.TemplateProjects.CreateTemplateProject;

export type TemplateProjectUpdateFields = Request.TemplateProjects.UpdateTemplateProject;

type TemplateProjectCallback = (project: Response.TemplateProjects.CreateTemplateProject) => void;

export function useTemplateProjectCreateForm(onCreated: TemplateProjectCallback) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(createTemplateProject);

  const methods = useForm<TemplateProjectCreateFields>({
    defaultValues: {
      process_type: ProcessType.OPENING,
      name: "",
      description: "",
      keys: [
        {
          list_id: 1,
          name: "",
          sort_order: 0,
          is_required: false,
        },
      ],
    },
  });

  const handleSubmit = methods.handleSubmit(async ({ keys, ...data }) => {
    try {
      onCreated(
        await mutateAsync({
          ...data,
          keys: keys.map((key, index) => ({ ...key, sort_order: index })),
        })
      );

      queryClient.invalidateQueries(templateKeys.allTemplateProjectsTasksStats());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useTemplateProjectUpdateForm(project: TemplateProject, onUpdated: () => void) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateTemplateProject);

  const methods = useForm<TemplateProjectUpdateFields>({
    defaultValues: {
      id: project.id,
      process_type: project.process_type,
      name: project.name,
      description: project.description,
      keys: project.keys.map((key, index) => ({
        id: key.id,
        list_id: key.id,
        name: key.name,
        is_required: key.is_required ?? false,
        sort_order: index,
      })),
    },
  });

  const handleSubmit = methods.handleSubmit(async ({ keys, ...data }) => {
    try {
      await mutateAsync({
        ...data,
        keys: keys.map((key, index) => ({ ...key, sort_order: index })),
      });

      onUpdated();

      queryClient.invalidateQueries(templateKeys.templateProject(project.id));
      queryClient.invalidateQueries(templateKeys.templateProjectKeys(project.id));
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useTemplateProjectDelete(project: TemplateProject) {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteTemplateProject);

  const handleRemove = useCallback(async () => {
    await mutateAsync({ template_project_id: project.id });
    queryClient.invalidateQueries(templateKeys.allTemplateProjectsTasksStats());
  }, [queryClient, mutateAsync, project]);

  return { handleRemove, isLoading };
}
