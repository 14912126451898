import classnames from "classnames";
import React, { TextareaHTMLAttributes } from "react";
import { FormElementProps, Label, ValidationText } from ".";
import { styled } from "../../utils/styled";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    FormElementProps {
  //
}

interface TextareaStyledProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  invalid?: boolean;
}

const TextareaStyled = styled<HTMLTextAreaElement, TextareaStyledProps>(
  ({ disabled, invalid, className, ...props }) => (
    <textarea
      {...props}
      disabled={disabled}
      className={classnames(
        "shadow-sm block w-full text-base text-black rounded-md placeholder-stone",
        {
          "opacity-60 bg-gray-100": disabled,
          "border-stone-400 focus:ring-beige focus:border-beige hover:border-beige": !invalid,
          "border-red-300 text-red-600 focus:ring-0 focus:border-red-300": invalid,
        },
        className
      )}
    />
  )
);

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ id, label, invalid, invalidText, disabled, required, rows = 5, ...props }, ref) => (
    <div>
      <Label htmlFor={id} disabled={disabled} required={required} className="mb-1">
        {label}
      </Label>
      <TextareaStyled
        {...props}
        id={id}
        rows={rows}
        invalid={invalid}
        disabled={disabled}
        ref={ref}
      />
      {invalid && invalidText && <ValidationText className="mt-1">{invalidText}</ValidationText>}
    </div>
  )
);

export default Textarea;
