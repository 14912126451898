import { User } from "@dh-critical-path/api-types";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { TableCell } from "..";
import { RouteKey, slideOverRoute } from "../../../routes/SlideOvers";
import { Hash } from "../../common";
import { ProfileAvatar, ProfileAvatarPlaceholder } from "../../profile";

type TaskAvatarProps = {
  task: {
    assignedToUser: User | null;
  };
};

type TaskDependenciesProps = {
  task: {
    dependsOn?: {
      department?: { name: string };
      templateDepartment?: { name: string };
    }[];
  };
  variant?: "base" | "comparison";
};

type TaskDepartmentProps = {
  task: {
    id: number;
    // project
    department?: { id: number; name: string };
    project?: { id: number; name: string };
    // template
    templateDepartment?: { id: number; name: string };
    templateProject?: { id: number; name: string };
  };
  link: "view" | "filter";
};

type TaskNameProps = {
  task: {
    id: number;
    name: string;
    project?: { id: number };
    templateProject?: { id: number };
  };
};

type TaskLabelProps = {
  task: {
    is_milestone: number;
    deleted_at: string | null;
  };
  leftIndent?: "left-0" | "left-auto";
};

type TaskCellProps = {
  task: {
    is_milestone: number;
    deleted_at: string | null;
  };
  className?: string;
};

export function TaskAvatar({ task }: TaskAvatarProps) {
  return task.assignedToUser ? (
    <ProfileAvatar user={task.assignedToUser} className="mr-3" />
  ) : (
    <ProfileAvatarPlaceholder className="mr-3" tooltipText="No assignee" />
  );
}

export function TaskDependenciesPerDepartments({ task, variant = "base" }: TaskDependenciesProps) {
  if (!task.dependsOn?.length) {
    return null;
  }

  const departments = task.dependsOn.reduce<Record<string, number>>((carry, task) => {
    const department = task.department || task.templateDepartment;

    return department
      ? {
          ...carry,
          [department.name]: (carry[department.name] ?? 0) + 1,
        }
      : carry;
  }, {});

  return (
    <div
      className={classNames("flex flex-wrap  text-xs mt-1", {
        "text-pink-700": variant === "base",
        "text-black": variant === "comparison",
      })}
    >
      <span className="pr-0.5">
        <FormattedMessage id="table.tasks.dependent-on" />
      </span>
      {Object.entries(departments).map(([name, count], index) => (
        <strong key={index} className="px-0.5">
          {name} ({count})
        </strong>
      ))}
    </div>
  );
}

export function TaskDepartment({ task, link }: TaskDepartmentProps) {
  const project = task.project ?? task.templateProject;
  const projectName = project?.name ?? "";
  const department = task.department ?? task.templateDepartment;

  const filterLink =
    task.project && task.department
      ? `/projects/${task.project.id}/tasks?department=${task.department.id}`
      : task.project
      ? `/projects/${task.project.id}`
      : task.templateProject && task.templateDepartment
      ? `/templates/${task.templateProject.id}/tasks?department=${task.templateDepartment.id}`
      : task.templateProject
      ? `/projects/${task.templateProject.id}`
      : "/";

  const viewLink = task.project
    ? slideOverRoute(RouteKey.Task, { taskId: task.id })
    : slideOverRoute(RouteKey.TemplateTask, { templateTaskId: task.id });

  return (
    <Link
      to={link === "filter" ? filterLink : viewLink}
      className="block font-bold leading-none text-iron-400 text-sm mb-1"
    >
      <span className="pr-1 align-middle">
        {project && department ? (
          <>
            <span>{projectName}</span>
            <Hash>{project.id}</Hash>, {department.name} <Hash>{department.id}</Hash>
          </>
        ) : project ? (
          <>
            <span className="uppercase">{projectName}</span>
            <Hash>{project.id}</Hash>
          </>
        ) : null}
      </span>
    </Link>
  );
}

export function TaskName({ task }: TaskNameProps) {
  const to = task.project
    ? slideOverRoute(RouteKey.Task, { taskId: task.id })
    : slideOverRoute(RouteKey.TemplateTask, { templateTaskId: task.id });

  return (
    <Link to={to} className="block font-bold leading-none flex-1 hover:text-violet transition">
      <span className="pr-1 align-middle">{task.name}</span>
      <Hash>{task.id}</Hash>
    </Link>
  );
}

export function TaskLabels({ task, leftIndent = "left-0" }: TaskLabelProps) {
  return (
    <div className={classNames("absolute top-0", leftIndent)}>
      <div className="flex">
        {Boolean(task.deleted_at) && (
          <span className="bg-red-500 text-xs text-white py-0.5 px-1.5 rounded-br-lg">
            <FormattedMessage id="table.tasks.removed" />
          </span>
        )}
        {Boolean(task.is_milestone) && (
          <span className="bg-yellow-300 text-xs text-black py-0.5 px-1.5 rounded-b-lg first:rounded-bl-none">
            <FormattedMessage id="table.tasks.milestone" />
          </span>
        )}
      </div>
    </div>
  );
}

export function TaskCell({ task, className, children }: PropsWithChildren<TaskCellProps>) {
  return (
    <TableCell
      className={classNames(className, {
        // padding for labels
        "first:pt-8 pt-6 sm:pt-8": task.is_milestone || task.deleted_at,
      })}
    >
      {children}
    </TableCell>
  );
}
