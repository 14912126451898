import { Route, Routes } from "react-router";
import { TrackingPage } from "./TrackingPage";

export function TrackingRoutes() {
  return (
    <Routes>
      <Route path="/" element={<TrackingPage />} />
    </Routes>
  );
}
