import { Project, ProjectKeyValue as ProjectKeyValueType } from "@dh-critical-path/api-types";
import { Panel } from "../../../components/panel";
import { useProjectKeyFactsQuery } from "../../../queries";
import { isUrl } from "../../../utils/misc";

type ProjectKeyValueProps = {
  keyValue: ProjectKeyValueType;
};

type ProjectKeyValuesProps = {
  project: Project;
};

type ProjectKeyValuesPanelProps = {
  project: Project;
};

function ProjectKeyValueContentRenderer({ keyValue }: ProjectKeyValueProps) {
  return (
    <>
      {keyValue.value && isUrl(keyValue.value) ? (
        <a
          href={keyValue.value}
          target="_blank"
          rel="noreferrer"
          className="hover:text-violet transition"
        >
          {keyValue.value}
        </a>
      ) : (
        keyValue.value
      )}
    </>
  );
}

function ProjectKeyValue({ keyValue }: ProjectKeyValueProps) {
  return (
    <div className="text-base space-y-1 leading-tight whitespace-pre-line break-words">
      <p className="text-iron-800">{keyValue.name}</p>
      <p className="text-black">
        <ProjectKeyValueContentRenderer keyValue={keyValue} />
      </p>
    </div>
  );
}

function ProjectKeyValues({ project }: ProjectKeyValuesProps) {
  const { data } = useProjectKeyFactsQuery(project.id);

  if (!data) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-1.5">
      {data
        .filter((keyValue) => keyValue.value)
        .map((keyValue) => (
          <ProjectKeyValue key={keyValue.id} keyValue={keyValue} />
        ))}
    </div>
  );
}

export function ProjectKeyValuesPanel({ project }: ProjectKeyValuesPanelProps) {
  return (
    <Panel className="col-span-1 sm:col-span-2">
      <ProjectKeyValues project={project} />
    </Panel>
  );
}
