import { Department } from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { ActionButton, Button } from "../../../components/button";
import { Hash } from "../../../components/common";
import { useActionWithConfirmation } from "../../../components/modals";
import { PageSectionHeader } from "../../../components/page";
import { AdminOnly } from "../../../hooks/useAuth";
import { useDepartmentRemove } from "../../../hooks/useDepartment";
import { useTasksQuery } from "../../../queries";
import { useActionCompleted } from "../../../utils/hooks";
import {
  useNavigateToDepartmentEdit,
  useNavigateToProjectDepartments,
} from "../../utils/navigation";
import { AffectedTasksDetails } from "./AffectedTasksDetails";

type DepartmentPageSectionHeaderProps = {
  department: Department;
};

export function useDepartmentRemovingConfirmationDialog(
  handleRemove: (department: Department) => Promise<any>
) {
  const { modal, handleWithConfirmation } = useActionWithConfirmation(
    handleRemove,
    async (department) => ({
      description: <DepartmentRemovingConfirmationText department={department} />,
    })
  );

  return { modal, handleRemoveWithConfirmation: handleWithConfirmation };
}

function DepartmentRemovingConfirmationText({ department }: { department: Department }) {
  const params = useParams<"id">();

  const projectId = Number(params.id);

  const query = useTasksQuery({
    department_ids: [department.id],
    project_ids: [projectId],
    limit: 1,
  });

  return (
    <div>
      <p>Do you really want to remove this department?</p>
      <AffectedTasksDetails
        totalTasks={query.data?.meta.total ?? 0}
        departmentId={department.id}
        projectId={projectId}
      />
    </div>
  );
}

function DepartmentEditButton({ department }: DepartmentPageSectionHeaderProps) {
  const navigateToEditView = useNavigateToDepartmentEdit();

  return (
    <Button variant="secondary" onClick={() => navigateToEditView(department)}>
      <FormattedMessage id="headings.departments.edit" />
    </Button>
  );
}

function DepartmentDeleteButton({ department }: DepartmentPageSectionHeaderProps) {
  const { handleRemove, isLoading } = useDepartmentRemove();

  const navigateToDepartments = useNavigateToProjectDepartments();

  const handleRemoveAndRedirect = useActionCompleted(handleRemove, navigateToDepartments);

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useDepartmentRemovingConfirmationDialog(handleRemoveAndRedirect);

  return (
    <>
      <ActionButton
        variant="delete"
        size="lg"
        rounding="base"
        onClick={() => handleRemoveWithConfirmation(department)}
        loading={isLoading}
      >
        <FormattedMessage id="headings.departments.edit" />
      </ActionButton>
      {confirmModal}
    </>
  );
}

function DepartmentHeaderActions({ department }: DepartmentPageSectionHeaderProps) {
  // Global departments are not editable here
  if (!department.project_id) {
    return null;
  }

  return (
    <>
      <DepartmentEditButton department={department} />
      <DepartmentDeleteButton department={department} />
    </>
  );
}

export function DepartmentHeader({ department }: DepartmentPageSectionHeaderProps) {
  return (
    <PageSectionHeader
      className="mb-2"
      buttons={<AdminOnly render={() => <DepartmentHeaderActions department={department} />} />}
    >
      <span className="pr-1 uppercase">{department.name}</span>
      <Hash>{department.id}</Hash>
    </PageSectionHeader>
  );
}
