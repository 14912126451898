import {
  ArrowCircleLeftIcon,
  PencilIcon,
  SwitchHorizontalIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import classnames from "classnames";
import React, { ButtonHTMLAttributes } from "react";
import { styled } from "../../utils/styled";

interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "edit" | "delete" | "move" | "restore";
  rounding?: "base" | "full";
  size?: "base" | "lg";
  loading?: boolean;
}

interface ActionButtonIconProps extends Pick<ActionButtonProps, "size"> {
  icon: React.ElementType;
}

const ActionButtonStyled = styled<HTMLButtonElement, ActionButtonProps>(
  ({
    variant,
    children,
    rounding = "full",
    size = "base",
    type = "button",
    loading,
    className,
    ...props
  }) => (
    <button
      {...props}
      type={type}
      className={classnames(
        "flex items-center justify-center bg-white hover:bg-beige-100 hover:border-stone-400 text-stone focus:outline-none ",
        {
          "hover:text-black": variant === "edit" || variant === "move",
          "hover:text-red-500": variant === "delete",
          "h-5 w-5 border border-stone-100 hover:border-stone-400 focus:border-beige":
            size === "base",
          "p-1.5 shadow-sm border border-iron-100  focus:ring-beige focus:ring": size === "lg",
          "rounded-full": rounding === "full",
          "rounded-lg": rounding === "base",
          "opacity-50": loading,
        },
        className
      )}
    >
      <span className="relative">
        <span className="inline-block absolute inset-0 -m-2"></span>
        {children}
      </span>
    </button>
  )
);

const ActionButtonIcon = styled<HTMLElement, ActionButtonIconProps>(
  ({ icon: Component, size = "base", ...props }) => (
    <Component
      {...props}
      className={classnames({
        "w-3": size === "base",
        "w-4": size === "lg",
      })}
    />
  )
);

const ActionButton = React.forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ variant, size, ...props }, ref) => (
    <ActionButtonStyled {...props} ref={ref} variant={variant} size={size}>
      {variant === "edit" && <ActionButtonIcon icon={PencilIcon} size={size} />}
      {variant === "move" && <ActionButtonIcon icon={SwitchHorizontalIcon} size={size} />}
      {variant === "delete" && <ActionButtonIcon icon={TrashIcon} size={size} />}
      {variant === "restore" && <ActionButtonIcon icon={ArrowCircleLeftIcon} size={size} />}
    </ActionButtonStyled>
  )
);

export default ActionButton;
