import { Attachment, Task } from "@dh-critical-path/api-types";
import { keepTruthy } from "@dh-critical-path/shared";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Attachments } from "../../../components/form/Attachments";
import { SlideOverBody, SlideOverSidebar } from "../../../components/form/SlideOver";
import {
  ControlledDatePicker,
  ControlledInput,
  ControlledMarkdown,
  ControlledSelect,
  ControlledSwitch,
} from "../../../components/form/controlled";
import { Status, backgroundClassName } from "../../../enums";
import { useTaskAttachments } from "../../../hooks/useAttachments";
import { TaskUpdateFields } from "../../../hooks/useTask";
import {
  useDepartmentsForFilterQuery,
  useTaskAssigneesForFilterQuery,
  useTaskStatusesForFilterQuery,
  useTasksForFilterQuery,
} from "../../../queries";
import { useTagsForFilterQuery } from "../../../queries/tags";
import { assigneesToOptions } from "../../../utils/misc";

interface SidebarFormFieldsProps {
  task?: Task;
}

interface TaskAttachmentsProps {
  task?: Task;
  onChange: (attachments: Attachment[]) => void;
}

type BodyFormFields = Pick<TaskUpdateFields, "name" | "description" | "attachment_ids">;

function TaskAttachments({ task, onChange }: TaskAttachmentsProps) {
  const { attachments, handleUpload, handleDelete, isUploading } = useTaskAttachments(task?.id);

  useEffect(() => {
    onChange(attachments || []);
  }, [attachments, onChange]);

  return (
    <Attachments
      onUpload={handleUpload}
      onDelete={handleDelete}
      attachments={attachments}
      isUploading={isUploading}
      isRemovable
    />
  );
}

function useTaskAssigneeOptions() {
  const { watch } = useFormContext<TaskUpdateFields>();
  const projectId = watch("project_id");
  const departmentId = watch("department_id");
  const supporterIds = watch("supporter_ids");
  const { data } = useTaskAssigneesForFilterQuery({
    project_id: projectId,
    department_ids: keepTruthy([departmentId]),
  });

  return assigneesToOptions(
    data?.filter((assignee) => !(supporterIds || []).includes(assignee.id)) ?? []
  );
}

function useTaskSupportersOptions() {
  const { watch } = useFormContext<TaskUpdateFields>();
  const projectId = watch("project_id");
  const assigneeId = watch("assignee_id");
  const { data } = useTaskAssigneesForFilterQuery({ project_id: projectId });

  return assigneesToOptions(data?.filter((assignee) => ![assigneeId].includes(assignee.id)) ?? []);
}

export function TaskSlideOverSidebarForm() {
  const { watch } = useFormContext();
  const projectId = watch("project_id");
  const isMilestone = watch("is_milestone");
  const isDatesFrozen = watch("is_dates_frozen");

  // TODO: refactor this shit
  // see useTemplateTasksGroupedByDepartment
  const [groupedTasks, setGroupedTasks] = useState<
    {
      label: string;
      options: {
        value: number;
        label: string;
      }[];
    }[]
  >([]);

  const { data: statuses } = useTaskStatusesForFilterQuery();
  const { data: tags } = useTagsForFilterQuery({});
  const { data: departments } = useDepartmentsForFilterQuery({ project_id: projectId });
  const { data: tasks } = useTasksForFilterQuery({ project_ids: [projectId] });
  const assigneeOptions = useTaskAssigneeOptions();
  const supportersOptions = useTaskSupportersOptions();

  useEffect(() => {
    if (!departments || !tasks) {
      return;
    }

    setGroupedTasks(
      departments
        .map((department) => ({
          label: department.name,
          options: tasks
            .filter((task) => task.department_id === department.id)
            .map((task) => ({
              value: task.id,
              label: task.name,
              departmentLabel: department.name,
            })),
        }))
        .filter((group) => group.options.length > 0)
    );
  }, [departments, tasks]);

  const statusOptions = (statuses || []).map((status) => ({
    ...status,
    color: backgroundClassName(status.status_type as Status),
  }));

  const filterTasksByGroup = useCallback((option: any, input: string) => {
    return input
      ? String(option?.data?.departmentLabel)
          .toLocaleLowerCase()
          .includes(input.toLocaleLowerCase()) ||
          String(option?.data?.label).toLocaleLowerCase().includes(input.toLocaleLowerCase())
      : true;
  }, []);

  // TODO: too many re-renders
  return (
    <SlideOverSidebar>
      <ControlledSelect
        name="task_status_id"
        label="Status"
        placeholder="Select status"
        options={statusOptions}
        showHash
        required
      />
      <ControlledSelect
        name="department_id"
        label="Responsible Department/Team"
        placeholder="Select department"
        options={departments}
        showHash
        required
      />
      <ControlledSelect
        name="assignee_id"
        label="Assigned To"
        placeholder="Select assignee"
        options={assigneeOptions}
        isClearable
      />
      <ControlledSelect
        name="supporter_ids"
        label="Supporter"
        placeholder="Select supporter"
        options={supportersOptions}
        isMulti
        isClearable
      />
      <ControlledSelect
        name="tag_ids"
        label="Tags"
        placeholder="Select Tags"
        options={tags}
        isMulti
      />
      <ControlledSelect
        name="depends_on_ids"
        label="Dependent on"
        placeholder="Select Task"
        options={tasks}
        groupedOptions={groupedTasks}
        filterOption={filterTasksByGroup}
        showHash
        isMulti
      />
      <ControlledSelect
        name="dependency_for_ids"
        label="Dependency for"
        placeholder="Select Task"
        options={tasks}
        groupedOptions={groupedTasks}
        filterOption={filterTasksByGroup}
        showHash
        isMulti
      />
      {!isMilestone && (
        <ControlledDatePicker
          name="start_date"
          label="Start Date"
          required={!isDatesFrozen}
          disabled={isDatesFrozen}
        />
      )}
      <ControlledDatePicker
        name="due_date"
        label="Due Date"
        required={!isDatesFrozen}
        disabled={isDatesFrozen}
      />
      <ControlledSwitch name="is_dates_frozen" label="Fixed dates" />
      <ControlledSwitch
        name="is_milestone"
        label="Milestone"
        data-cy="slide-over-task-milestone-switch"
      />
    </SlideOverSidebar>
  );
}

export function TaskSlideOverBodyForm({ task }: SidebarFormFieldsProps) {
  const { setValue, setFocus } = useFormContext<BodyFormFields>();

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  const { formatMessage } = useIntl();

  const handleAttachmentsChange = useCallback(
    (attachments: Attachment[]) => {
      setValue(
        "attachment_ids",
        attachments.map((attachment) => attachment.id)
      );
    },
    [setValue]
  );

  return (
    <SlideOverBody>
      <ControlledInput
        name="name"
        label={formatMessage({ id: "tasks.form.name" })}
        placeholder={formatMessage({ id: "tasks.form.name.placeholder" })}
        required
        data-cy="task-form-name-input"
      />
      <ControlledMarkdown
        name="description"
        label={formatMessage({ id: "tasks.form.description" })}
        placeholder={formatMessage({ id: "tasks.form.description.placeholder" })}
        required
        data-cy="task-form-description-textarea"
      />
      <TaskAttachments task={task} onChange={handleAttachmentsChange} />
    </SlideOverBody>
  );
}
