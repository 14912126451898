import { Request, Response } from "@dh-critical-path/api-types";
import axios from "axios";

export function getTitle(id: number) {
  return axios.get<Response.Titles.GetTitle>(`/api/titles/${id}`).then(({ data }) => data);
}

export function getTitles(params: Request.Titles.GetTitles) {
  return axios.get<Response.Titles.GetTitles>(`/api/titles`, { params }).then(({ data }) => data);
}

export function createTitle(params: Request.Titles.CreateTitle) {
  return axios.post<Response.Titles.CreateTitle>(`/api/titles`, params).then(({ data }) => data);
}

export function updateTitle({ title_id, ...params }: Request.Titles.UpdateTitle) {
  return axios
    .patch<Response.Titles.UpdateTitle>(`/api/titles/${title_id}`, params)
    .then(({ data }) => data);
}

export function removeTitle(id: number) {
  return axios.delete<void>(`/api/titles/${id}`).then(({ data }) => data);
}

export function removeMemberFromTitle({
  title_id,
  member_id,
}: Request.Titles.RemoveMemberFromTitle) {
  return axios
    .delete<Response.Titles.RemoveMemberFromTitle>(`/api/titles/${title_id}/members/${member_id}`)
    .then(({ data }) => data);
}
