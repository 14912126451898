import { Attachment as AttachmentType } from "@dh-critical-path/api-types";
import { SearchIcon } from "@heroicons/react/outline";
import { DocumentTextIcon, DownloadIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import prettyBytes from "pretty-bytes";
import React, { HTMLAttributes, useState } from "react";
import Dropzone, { DropzoneState } from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import { Input } from "../../components/form";
import { useConfirmModal } from "../../hooks/useModal";
import { nextId } from "../../utils/nextId";
import { styled } from "../../utils/styled";
import { ActionButton } from "../button";
import { ConfirmModal, Spinner } from "../common";
import { FormattedDateTime } from "../formatting";

type UploadHandler = (files: File[], silent?: boolean) => void;

type DeleteHandler = (attachment: AttachmentType) => void;

type AttachmentsDropAreaSize = "base" | "small";

interface AttachmentsProps {
  attachments?: AttachmentType[];
  onUpload: UploadHandler;
  onDelete: DeleteHandler;
  size?: AttachmentsDropAreaSize;
  isSearchable?: boolean;
  isRemovable?: boolean;
  isUploading?: boolean;
  confirmNotifications?: boolean;
}

interface AttachmentsDropAreaProps
  extends Pick<DropzoneState, "getRootProps" | "getInputProps" | "isDragActive"> {
  id: string;
  isUploading?: boolean;
  size?: AttachmentsDropAreaSize;
}

interface AttachmentListProps {
  attachments: AttachmentType[];
  onDelete?: DeleteHandler;
  isSearchable?: boolean;
  isRemovable?: boolean;
}

interface AttachmentProps {
  attachment: AttachmentType;
  isRemovable?: boolean;
  onDelete?: DeleteHandler;
}

interface AttachmentDeleteButtonProps {
  attachment: AttachmentType;
  onDelete: DeleteHandler;
}

export const AttachmentList: React.VFC<AttachmentListProps> = ({
  attachments,
  onDelete,
  isSearchable,
  isRemovable,
}) => {
  const { formatMessage } = useIntl();

  const [search, setSearch] = useState("");

  const matchedAttachments = attachments.filter((attachment) =>
    attachment.original_filename.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      {isSearchable && (
        <Input
          placeholder={formatMessage({ id: "attachments.list.search" })}
          iconLeft={() => <SearchIcon className="w-5" />}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          data-cy="attachments-serach-input"
          isClearable
        />
      )}
      {matchedAttachments.length > 0 && (
        <div className="py-6 mb-6 border-b border-stone-100">
          <div className="grid gap-y-3 gap-x-6 lg:gap-x-9">
            {matchedAttachments.map((attachment) => (
              <Attachment
                key={attachment.id}
                attachment={attachment}
                onDelete={onDelete}
                isRemovable={isRemovable}
              />
            ))}
          </div>
        </div>
      )}
      {isSearchable && matchedAttachments.length === 0 && (
        <div className="py-9 mb-6 border-b border-stone-100 text-center text-iron">
          <FormattedMessage id="attachments.list.empty" />
        </div>
      )}
    </>
  );
};

const AttachmentIcon = () => (
  <div className="flex items-center justify-center w-10 h-10 bg-stone-100 group-hover:bg-beige-100 rounded-lg mr-2 flex-none">
    <DocumentTextIcon className="w-6 fill-current text-beige group-hover:hidden" />
    <DownloadIcon className="w-6 fill-current text-beige hidden group-hover:block" />
  </div>
);

const AttachmentsDropAreaIcon = () => (
  <svg
    className="mx-auto h-9 w-9 text-stone-400"
    stroke="currentColor"
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <path
      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AttachmentsDropArea: React.VFC<AttachmentsDropAreaProps> = ({
  id,
  size,
  isUploading,
  getInputProps,
  getRootProps,
  isDragActive,
  ...props
}) => {
  return (
    <div
      {...props}
      {...getRootProps()}
      className={classnames(
        "relative cursor-pointer flex justify-center border-2 hover:border-beige border-dashed rounded-md px-4",
        {
          "py-14": size === "base",
          "py-7": size === "small",
          "pointer-events-none": isUploading,
        },
        isDragActive ? "border-beige" : "border-stone-400"
      )}
    >
      {isUploading && <Spinner size="lg" />}
      <div className="space-y-1 text-center">
        <AttachmentsDropAreaIcon />
        <div className="text-base leading-tight">
          <span className="relative">
            <span className="pr-1 block sm:inline-block">
              <FormattedMessage id="attachments.drop-zone.upload-file" />
            </span>
          </span>
          <span className="text-stone">
            <FormattedMessage id="attachments.drop-zone.drop-file" />
          </span>
          <input
            {...getInputProps()}
            id={id}
            type="file"
            multiple
            className="sr-only"
            data-cy="attachments-input"
          />
        </div>
        <p className="text-xs text-iron">
          <FormattedMessage id="attachments.drop-zone.allowed-types-and-size" />
        </p>
      </div>
    </div>
  );
};

const AttachmentDeleteButton: React.VFC<AttachmentDeleteButtonProps> = ({
  attachment,
  onDelete,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleConfirm = () => {
    closeConfirmModal();
    onDelete(attachment);
  };

  return (
    <>
      <ActionButton
        variant="delete"
        className="group-hover:flex hidden absolute top-0 right-0"
        onClick={openConfirmModal}
        data-cy="attachment-delete-button"
      />
      <ConfirmModal
        open={confirmModalOpen}
        title={<FormattedMessage id="attachments.modal.delete.title" />}
        description={<FormattedMessage id="attachments.modal.delete.description" />}
        cancelButtonText={<FormattedMessage id="attachments.modal.cancel" />}
        confirmButtonText={<FormattedMessage id="attachments.modal.confirm" />}
        onClose={closeConfirmModal}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export const Attachment = React.forwardRef<HTMLDivElement, AttachmentProps>(
  ({ attachment, isRemovable, onDelete, ...props }, ref) => {
    return (
      <div {...props} ref={ref} className="relative flex pr-8 group">
        <a href={attachment.download_url} className="flex" data-cy="attachment-download-link">
          <AttachmentIcon />
          <div>
            <p className="text-base text-black leading-tight">{attachment.original_filename}</p>
            <p className="text-sm text-iron">
              {prettyBytes(attachment.size)}
              <span>,&nbsp;</span>
              <FormattedDateTime date={new Date(attachment.created_at)} />
            </p>
          </div>
        </a>
        {isRemovable && typeof onDelete === "function" && (
          <AttachmentDeleteButton attachment={attachment} onDelete={onDelete} />
        )}
      </div>
    );
  }
);

export const Attachments = React.forwardRef<HTMLDivElement, AttachmentsProps>(
  (
    {
      attachments = [],
      onUpload,
      onDelete,
      isSearchable,
      isRemovable,
      isUploading,
      confirmNotifications,
      size = "base",
      ...props
    },
    ref
  ) => {
    const id = nextId("input");
    const { modal: confirmModal, openModal: openConfirmModal } = useConfirmModal();

    function handleDrop(files: File[]) {
      if (confirmNotifications) {
        openConfirmModal(
          () => onUpload(files),
          () => onUpload(files, true),
          {
            title: "Notifications",
            description: "Do you want to notify project users?",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }
        );
      } else {
        onUpload(files);
      }
    }

    return (
      <div {...props} ref={ref}>
        {attachments.length > 0 && (
          <AttachmentList
            attachments={attachments}
            onDelete={onDelete}
            isSearchable={isSearchable}
            isRemovable={isRemovable}
          />
        )}
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <AttachmentsDropArea
              isDragActive={isDragActive}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              id={id}
              size={size}
              isUploading={isUploading}
            />
          )}
        </Dropzone>
        {confirmModal}
      </div>
    );
  }
);

export const AttachmentsPanel = styled<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }) => (
    <div {...props} className={classnames("bg-white p-8 w-full", className)}>
      {children}
    </div>
  )
);
