import classnames from "classnames";
import React, { HTMLAttributes } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { styled } from "../../utils/styled";

interface NavigationTabProps extends NavLinkProps {
  icon?: React.ReactNode;
  isActive?: boolean;
}

const EnhancedNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ to, className, style, children, ...props }, ref) => {
    if (typeof to === "string" && to.startsWith("http")) {
      const styleProps = { isActive: false };
      return (
        <a
          {...props}
          href={to}
          ref={ref}
          className={typeof className === "function" ? className(styleProps) : className}
          style={typeof style === "function" ? style(styleProps) : style}
        >
          {children}
        </a>
      );
    }

    return (
      <NavLink {...props} to={to} ref={ref} className={className} style={style}>
        {children}
      </NavLink>
    );
  }
);

const NavigationTabs = styled<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }) => (
    <div {...props} className={classnames("flex overflow-auto items-center space-x-4", className)}>
      {children}
    </div>
  )
);

const NavigationTabStyled = styled<HTMLAnchorElement, NavigationTabProps>(
  ({ children, className, isActive: isForceActive, ...props }) => (
    <EnhancedNavLink
      {...props}
      className={({ isActive }) =>
        classnames(
          "inline-flex items-center text-iron py-1.5 px-3 m-1 rounded-md leading-tight whitespace-nowrap focus:ring focus:ring-beige-600 hover:bg-beige-100",
          {
            "bg-beige-100 hover:bg-beige-100":
              isForceActive === undefined ? isActive : isForceActive,
          },
          className
        )
      }
    >
      {children}
    </EnhancedNavLink>
  )
);

const NavigationTab = React.forwardRef<HTMLAnchorElement, NavigationTabProps>(
  ({ children, icon, ...props }, ref) => (
    <NavigationTabStyled {...props} ref={ref}>
      {icon && <span className="mr-2">{icon}</span>}
      <span>{children}</span>
    </NavigationTabStyled>
  )
);

export { NavigationTab, NavigationTabs };
