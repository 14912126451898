import { TemplateTasksStats, TemplateTasksStatsEntity } from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { Hash } from "../../../components/common";
import { PageContainer } from "../../../components/page";
import { Panel } from "../../../components/panel";
import { ProfileAvatarsStacked } from "../../../components/profile";

export type TemplateTasksStatsEntityLink<T extends TemplateTasksStatsEntity> = (
  entity: T
) => string;

type TemplateTasksStatsCardsProps<T extends TemplateTasksStatsEntity> = {
  items: TemplateTasksStats<T>[];
  entityLink: TemplateTasksStatsEntityLink<T>;
};

type TasksStatsCardProps<T extends TemplateTasksStatsEntity> = {
  item: TemplateTasksStats<T>;
  entityLink: TemplateTasksStatsEntityLink<T>;
};

type TasksStatsComponentProps<T extends TemplateTasksStatsEntity> = {
  item: TemplateTasksStats<T>;
};

function TemplateTasksStatsCardHeader<T extends TemplateTasksStatsEntity>({
  item,
  entityLink,
}: TasksStatsCardProps<T>) {
  return (
    <div className="flex flex-col-reverse sm:flex-row items-start justify-between w-full mb-1">
      <NavLink to={entityLink(item.entity)} className="absolute inset-0"></NavLink>
      <div className="flex-1 mt-2 sm:mt-0 sm:pr-4 md:pr-9">
        <div className="font-bold leading-none flex flex-wrap align-baseline whitespace-pre-wrap">
          {item.entity.name} <Hash className="py-px">{item.entity.id}</Hash>
        </div>
      </div>
    </div>
  );
}

function TemplateTasksStatsTopAssignees<T extends TemplateTasksStatsEntity>({
  item,
}: TasksStatsComponentProps<T>) {
  if (!item.assignees_top.length) {
    return null;
  }

  return (
    <ProfileAvatarsStacked
      size="small"
      users={item.assignees_top}
      total={item.assignees_total}
      className="mb-2 empty-hidden"
    />
  );
}

function TemplateTasksStatsCount<T extends TemplateTasksStatsEntity>({
  item,
}: TasksStatsComponentProps<T>) {
  return (
    <div className="relative pb-5 mt-2">
      <div className="absolute -bottom-1 inset-x-0">
        <p className="text-iron sm:text-right leading-none">
          <span className="text-sm">
            {item.tasks_total > 0 ? (
              <FormattedMessage id="tasks-stats.total-tasks" values={{ count: item.tasks_total }} />
            ) : (
              <FormattedMessage id="tasks-stats.no-tasks-available" />
            )}
          </span>
        </p>
      </div>
    </div>
  );
}

function TemplateTasksStatsCard<T extends TemplateTasksStatsEntity>({
  item,
  entityLink,
}: TasksStatsCardProps<T>) {
  return (
    <Panel className="relative flex flex-col">
      <TemplateTasksStatsCardHeader item={item} entityLink={entityLink} />
      <TemplateTasksStatsTopAssignees item={item} />
      <div className="mt-auto">
        <TemplateTasksStatsCount item={item} />
      </div>
    </Panel>
  );
}

export function TemplateTasksStatsCards<T extends TemplateTasksStatsEntity>({
  items,
  entityLink,
}: TemplateTasksStatsCardsProps<T>) {
  return (
    <PageContainer type="grid">
      {items.map((item) => (
        <TemplateTasksStatsCard key={item.entity.id} item={item} entityLink={entityLink} />
      ))}
    </PageContainer>
  );
}
