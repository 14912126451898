import { Route, Routes } from "react-router";
import { DepartmentPage } from "../Departments/DepartmentPage";
import { ProjectRoutes } from "./ProjectRoutes";
import { ProjectsPage } from "./ProjectsPage";

export function ProjectsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProjectsPage />} />
      <Route path="/departments/:id" element={<DepartmentPage />} />
      <Route path="/:id/*" element={<ProjectRoutes />} />
    </Routes>
  );
}
