import { Request, User } from "@dh-critical-path/api-types";
import { SsoUserRole } from "@dh-critical-path/shared";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { addUser, deleteUser, updateUser } from "../api";
import { usersKeys } from "../queries";
import { interceptValidationErrorsAndThrow } from "../utils/form";

type UserCallback = (department: User) => void;

export type UserCreateFields = Request.Users.AddUser;

export type UserUpdateFields = Request.Users.UpdateUser;

export function useUserAddForm(onCreated: UserCallback) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(addUser);

  const methods = useForm<UserCreateFields>({
    defaultValues: {
      email: "",
      sso_role: SsoUserRole.GLOBAL_DEPARTMENT,
    },
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onCreated(await mutateAsync(data));
      queryClient.invalidateQueries(usersKeys.allUsers());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useUserUpdateForm(user: User, onUpdated: UserCallback) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateUser);

  const methods = useForm<UserUpdateFields>({
    defaultValues: {
      sso_role: user.sso_role,
    },
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onUpdated(await mutateAsync({ ...data, id: user.id }));
      queryClient.invalidateQueries(usersKeys.allUsers());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useUserRemove() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteUser);

  const handleRemove = useCallback(
    async (user: User) => {
      await mutateAsync({ id: user.id });

      queryClient.invalidateQueries(usersKeys.allUsers());

      return user;
    },
    [queryClient, mutateAsync]
  );

  return { handleRemove, isLoading };
}
