import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
} from "../../components/form/SlideOver";
import { useTemplateTitleCreateForm } from "../../hooks/useTemplateTitle";
import { useSlideOverNavigate } from "../SlideOvers";
import { TemplateTitleForm } from "./components/forms/TemplateTitleForm";

type TemplateTitleCreateSlideOverProps = {
  templateDepartmentId: number;
};

type TemplateTitleCreateFormProps = {
  templateDepartmentId: number;
};

export function TemplateTitleCreateForm({ templateDepartmentId }: TemplateTitleCreateFormProps) {
  const slideOverNavigate = useSlideOverNavigate();

  const { methods, handleSubmit } = useTemplateTitleCreateForm(templateDepartmentId, () => {
    slideOverNavigate();
  });

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="titles.modal.heading.create" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <TemplateTitleForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TemplateTitleCreateSlideOver({
  templateDepartmentId,
}: TemplateTitleCreateSlideOverProps) {
  return <TemplateTitleCreateForm templateDepartmentId={templateDepartmentId} />;
}
