import { XIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";

const CloseButton = styled<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
  ({ className, ...props }) => (
    <button
      {...props}
      type="button"
      className={classnames("text-black hover:text-iron-600 p-0.5", className)}
    >
      <XIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  )
);

export default CloseButton;
