import { createContext, useContext, useState } from "react";
import { nextId } from "../utils/nextId";

type ToastType = "danger" | "success";

export type Toast = {
  id: string;
  text: string;
  type?: ToastType;
  dismissed?: boolean;
};

interface ToastContextType {
  toasts: Toast[];
  showToast: (toast: Omit<Toast, "id">) => void;
  dismissToast: (toast: Toast) => void;
  dismissAllToasts: () => void;
  removeToast: (toast: Toast) => void;
}

const ToastContext = createContext<ToastContextType | null>(null);

export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  function showToast({ text, type }: Omit<Toast, "id">) {
    setToasts((toasts) => [...toasts, { id: nextId("toast"), text, type }]);
  }

  function dismissToast({ id }: Toast) {
    setToasts((toasts) =>
      toasts.map((toast) => (toast.id === id ? { ...toast, dismissed: true } : toast))
    );
  }

  function dismissAllToasts() {
    setToasts((toasts) => toasts.map((toast) => ({ ...toast, dismissed: true })));
  }

  function removeToast({ id }: Toast) {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  }

  return (
    <ToastContext.Provider
      value={{ toasts, showToast, dismissToast, dismissAllToasts, removeToast }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export function useToast() {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return context;
}
