import { TemplateProject, TemplateTask } from "@dh-critical-path/api-types";
import { Request } from "@dh-critical-path/api-types";
import { OrderDirection, TemplateTasksOrderColumn } from "@dh-critical-path/shared";
import { ChatAlt2Icon, DocumentTextIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  Table,
  TableDivider,
  TableHeadCell,
  TableHeadCellSortable,
  TableHeadRow,
  TableRow,
} from "../../../components/table";
import {
  TaskAvatar,
  TaskCell,
  TaskDepartment,
  TaskDependenciesPerDepartments,
  TaskLabels,
  TaskName,
} from "../../../components/table/tasks";
import { processTypeText } from "../../../utils/text";

type TemplateTasksTableOrderType = Pick<
  Request.TemplateTasks.GetTemplateTasks,
  "order_column" | "order_direction"
>;

type TasksTableProps = {
  templateProject?: TemplateProject;
  tasks: TemplateTask[];
  headRenderer?: React.ReactNode;
};

type TemplateTasksTableHeadRowProps = {
  templateProject?: TemplateProject;
};

type TemplateTaskRowProps = {
  task: TemplateTask;
};

type TaskColumnStatusCounterProps = {
  icon: React.ElementType;
  count: number;
};

function TemplateTasksTableHeadRow({ templateProject }: TemplateTasksTableHeadRowProps) {
  return (
    <TableHeadRow>
      <TableHeadCell className="text-left uppercase">
        <FormattedMessage id="table.tasks.name" />
      </TableHeadCell>
      <TableHeadCell className="hidden sm:table-cell text-right uppercase">
        <FormattedMessage
          id={
            templateProject
              ? processTypeText(
                  templateProject,
                  "table.tasks.days-prior-opening",
                  "table.tasks.days-prior-closure",
                  "table.tasks.days-prior-re-opening",
                  "table.tasks.days-prior-transition"
                )
              : "table.tasks.days-prior-opening-or-closure"
          }
        />
      </TableHeadCell>
      <TableHeadCell className="hidden sm:table-cell text-right uppercase">
        <FormattedMessage id="table.tasks.duration" />
      </TableHeadCell>
    </TableHeadRow>
  );
}

export function TemplateTasksTableHeadRowSortable({
  templateProject,
}: TemplateTasksTableHeadRowProps) {
  const { watch, setValue } = useFormContext<TemplateTasksTableOrderType>();
  const orderColumn = watch("order_column");
  const orderDirection = watch("order_direction");

  function handleSort(orderColumn: TemplateTasksOrderColumn, orderDirection: OrderDirection) {
    setValue("order_column", orderColumn);
    setValue("order_direction", orderDirection);
  }

  return (
    <TableHeadRow>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="name"
        className="text-left uppercase"
      >
        <FormattedMessage id="table.tasks.name" />
      </TableHeadCellSortable>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="due_date_offset"
        className="hidden sm:table-cell text-right uppercase"
      >
        <FormattedMessage
          id={
            templateProject
              ? processTypeText(
                  templateProject,
                  "table.tasks.days-prior-opening",
                  "table.tasks.days-prior-closure",
                  "table.tasks.days-prior-re-opening",
                  "table.tasks.days-prior-transition"
                )
              : "table.tasks.days-prior-opening-or-closure"
          }
        />
      </TableHeadCellSortable>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="duration"
        className="hidden sm:table-cell text-right uppercase"
      >
        <FormattedMessage id="table.tasks.duration" />
      </TableHeadCellSortable>
    </TableHeadRow>
  );
}

function TemplateTaskColumnName({ task }: TemplateTaskRowProps) {
  return (
    <TaskCell task={task} className="relative p-3 pb-1 sm:pb-3 w-full">
      <TaskLabels task={task} />
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0">
        <TaskAvatar task={task} />
        <div>
          <TaskDepartment task={task} link="filter" />
          <TaskName task={task} />
          <TaskDependenciesPerDepartments task={task} />
        </div>
      </div>
    </TaskCell>
  );
}

function TemplateTaskColumnDueDateOffset({ task }: TemplateTaskRowProps) {
  return (
    <TaskCell task={task} className="p-1 px-3 sm:p-3 sm:text-right">
      {task.due_date_offset}
    </TaskCell>
  );
}

function TemplateTaskColumnStatusCounter({ icon: Component, count }: TaskColumnStatusCounterProps) {
  return (
    <div className="inline-flex items-center flex-none text-stone-400">
      <Component className="w-4 fill-current" />
      <span className="pl-1 text-iron text-xs">{count}</span>
    </div>
  );
}

function TemplateTaskColumnDuration({ task }: TemplateTaskRowProps) {
  return (
    <TaskCell task={task} className="p-3 sm:text-right pt-1 sm:pt-3">
      <div className="inline-flex sm:flex-col items-end sm:space-x-4 sm:space-y-3">
        <span>{task.duration}</span>
        <div className="flex items-center flex-none space-x-3">
          <TemplateTaskColumnStatusCounter icon={ChatAlt2Icon} count={task.meta.comments_count} />
          <TemplateTaskColumnStatusCounter
            icon={DocumentTextIcon}
            count={task.meta.attachments_count}
          />
        </div>
      </div>
    </TaskCell>
  );
}

function TemplateTaskRow({ task }: TemplateTaskRowProps) {
  return (
    <TableRow>
      <TemplateTaskColumnName task={task} />
      <TemplateTaskColumnDueDateOffset task={task} />
      <TemplateTaskColumnDuration task={task} />
    </TableRow>
  );
}

function TasksEmptyRow() {
  return (
    <tr>
      <td colSpan={3} className="text-base font-bold text-center py-6 bg-white">
        <FormattedMessage id="table.tasks.empty" />
      </td>
    </tr>
  );
}

export function TemplateTasksTable({
  templateProject,
  tasks,
  headRenderer = <TemplateTasksTableHeadRow templateProject={templateProject} />,
}: TasksTableProps) {
  return (
    <Table>
      {headRenderer}
      {tasks.map((task, index) => (
        <Fragment key={task.id}>
          <TemplateTaskRow task={task} />
          {index + 1 !== tasks.length && <TableDivider />}
        </Fragment>
      ))}
      {tasks.length === 0 && <TasksEmptyRow />}
    </Table>
  );
}
