import { AccessDenied, NotFound, UnknownError } from "../../components/page/PageFallback";
import HeaderHoc from "../../hoc/header/HeaderHoc";
import { is403, is404 } from "../../utils/query";

type ErrorFallbackProps = {
  error: unknown;
};

export function ErrorFallback({ error }: ErrorFallbackProps) {
  let component = null;
  if (is403(error)) {
    component = <AccessDenied />;
  } else if (is404(error)) {
    component = <NotFound />;
  } else {
    component = <UnknownError />;
  }

  return (
    <>
      <HeaderHoc />
      {component}
    </>
  );
}
