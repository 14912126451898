import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { pluralize } from "../../../utils/text";

type TasksLinkProps = {
  departmentId: number;
  projectId: number;
  assigneeId?: number | null;
};

type AffectedTasksDetailsProps = {
  departmentId: number;
  projectId: number;
  assigneeId?: number | null;
  totalTasks: number;
};

function TasksLink({
  departmentId,
  projectId,
  assigneeId,
  children,
}: PropsWithChildren<TasksLinkProps>) {
  return (
    <Link
      to={`/projects/${projectId}/tasks?department=${departmentId}&assignee=${assigneeId ?? ""}`}
      className="font-bold leading-none hover:text-violet transition"
      target="_blank"
    >
      {children}
    </Link>
  );
}

export function AffectedTasksDetails({ totalTasks, ...props }: AffectedTasksDetailsProps) {
  return (
    <>
      {totalTasks > 0 && (
        <p>
          This will affect{" "}
          <TasksLink {...props}>
            {totalTasks} {pluralize(totalTasks, "task", "tasks")}
          </TasksLink>{" "}
          in current project
        </p>
      )}
    </>
  );
}
