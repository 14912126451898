import { TemplateDepartment } from "@dh-critical-path/api-types";
import { keepTruthy } from "@dh-critical-path/shared";
import React, { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Attachments } from "../../../../components/form/Attachments";
import {
  ControlledInput,
  ControlledMarkdown,
  ControlledSelect,
  ControlledSwitch,
} from "../../../../components/form/controlled";
import { useTemplateTaskAttachments } from "../../../../hooks/useAttachments";
import {
  TemplateTaskCreateFields,
  TemplateTaskUpdateFields,
} from "../../../../hooks/useTemplateTask";
import {
  useTemplateDepartmentsForFilterQuery,
  useTemplateProjectQuery,
  useTemplateTaskAssigneesForFilterQuery,
  useTemplateTasksForFilterQuery,
} from "../../../../queries";
import { useTagsForFilterQuery } from "../../../../queries/tags";
import { assigneesToOptions, mapIds } from "../../../../utils/misc";
import { processTypeText } from "../../../../utils/text";

type OptionsGroupItem = {
  groupLabel: string;
  value: number;
  label: string;
};

type OptionsGroup = {
  label: string;
  options: OptionsGroupItem[];
};

function useTemplateTasksGroupedByDepartment() {
  const { watch } = useFormContext<TemplateTaskUpdateFields>();

  const id = watch("template_task_id");

  const projectId = watch("template_project_id");

  const { data: tasks } = useTemplateTasksForFilterQuery({
    template_project_ids: [projectId!],
  });

  const { data: departments } = useTemplateDepartmentsForFilterQuery({
    template_project_id: projectId!,
  });

  const groupedTasks = useMemo(
    () =>
      (departments ?? [])
        .map((department: TemplateDepartment) => {
          return {
            label: department.name,
            options: (tasks ?? [])
              .filter((task) => task.id !== id && task.template_department_id === department.id)
              .map((task): OptionsGroupItem => {
                return {
                  value: task.id,
                  label: task.name,
                  groupLabel: department.name,
                };
              }),
          };
        })
        .filter((group: OptionsGroup) => group.options.length > 0),
    [id, departments, tasks]
  );

  const filter = useCallback((option: { data: OptionsGroupItem }, input: string) => {
    return (
      option.data.groupLabel.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
      option.data.label.toLocaleLowerCase().includes(input.toLocaleLowerCase())
    );
  }, []);

  return { tasks, groupedTasks, filter };
}

function AttachmentsField() {
  const { setValue, watch } = useFormContext<TemplateTaskCreateFields & TemplateTaskUpdateFields>();

  const id = watch("template_task_id");

  const { attachments, handleUpload, handleDelete, isUploading } = useTemplateTaskAttachments(id);

  // pass ids of uploaded attachments to a task
  // when a task is created template_task_id is filled and files are directly attached to a task
  useEffect(() => {
    if (!id) {
      setValue("attachment_ids", mapIds(attachments));
    }
  }, [id, attachments, setValue]);

  return (
    <Attachments
      onUpload={handleUpload}
      onDelete={handleDelete}
      attachments={attachments}
      isUploading={isUploading}
      isRemovable
    />
  );
}

function DepartmentField() {
  const { watch } = useFormContext<TemplateTaskUpdateFields>();
  const projectId = watch("template_project_id");

  const { data } = useTemplateDepartmentsForFilterQuery({
    template_project_id: projectId!,
  });

  return (
    <ControlledSelect
      name="template_department_id"
      label="Responsible Department/Team"
      placeholder="Select department"
      options={data}
      showHash
      required
    />
  );
}

function AssigneeField() {
  const { watch } = useFormContext<TemplateTaskUpdateFields>();
  const projectId = watch("template_project_id");
  const departmentId = watch("template_department_id");
  const supporterIds = watch("supporter_ids");

  const { data } = useTemplateTaskAssigneesForFilterQuery({
    template_project_id: projectId,
    template_department_ids: keepTruthy([departmentId]),
  });

  const assigneesWithoutSupporters = data?.filter(
    (assignee) => !supporterIds?.includes(assignee.id)
  );

  return (
    <ControlledSelect
      name="assignee_id"
      label="Assigned To"
      placeholder="Select assignee"
      options={assigneesToOptions(assigneesWithoutSupporters ?? [])}
      isClearable
    />
  );
}

function SupportersField() {
  const { watch } = useFormContext<TemplateTaskUpdateFields>();
  const projectId = watch("template_project_id");
  const assigneeId = watch("assignee_id");

  const { data } = useTemplateTaskAssigneesForFilterQuery({
    template_project_id: projectId,
  });

  const supportersWithoutAssignee = data?.filter((assignee) => assignee.id !== assigneeId);

  return (
    <ControlledSelect
      name="supporter_ids"
      label="Supporter"
      placeholder="Select supporter"
      options={assigneesToOptions(supportersWithoutAssignee ?? [])}
      isMulti
      isClearable
    />
  );
}

function TagsField() {
  const { data } = useTagsForFilterQuery({});

  return (
    <ControlledSelect
      name="tag_ids"
      label="Tags"
      placeholder="Select Tags"
      options={data}
      isMulti
    />
  );
}

function DependsOnField() {
  const { tasks, groupedTasks, filter } = useTemplateTasksGroupedByDepartment();

  return (
    <ControlledSelect
      name="depends_on_ids"
      label="Dependent on"
      placeholder="Select Task"
      options={tasks}
      groupedOptions={groupedTasks}
      filterOption={filter}
      showHash
      isMulti
    />
  );
}

function DependencyForField() {
  const { tasks, groupedTasks, filter } = useTemplateTasksGroupedByDepartment();

  return (
    <ControlledSelect
      name="dependency_for_ids"
      label="Dependency for"
      placeholder="Select Task"
      options={tasks}
      groupedOptions={groupedTasks}
      filterOption={filter}
      showHash
      isMulti
    />
  );
}

function DurationField() {
  const { watch } = useFormContext<TemplateTaskUpdateFields>();

  const isMilestone = watch("is_milestone");

  if (isMilestone) {
    return null;
  }

  return <ControlledInput name="duration" label="Duration (days)" />;
}

function DueDateOffsetField() {
  const { watch } = useFormContext<TemplateTaskUpdateFields>();

  const templateProjectId = watch("template_project_id");

  const { data: templateProject } = useTemplateProjectQuery(templateProjectId!);

  return (
    <ControlledInput
      name="due_date_offset"
      label={processTypeText(
        templateProject,
        "Days Prior Opening",
        "Days Prior Closure",
        "Days Prior Re-Opening",
        "Days Prior Transition"
      )}
    />
  );
}

function IsMilestoneField() {
  return <ControlledSwitch name="is_milestone" label="Milestone" />;
}

export function TemplateTaskMainForm() {
  const { formatMessage } = useIntl();

  return (
    <>
      <ControlledInput
        name="name"
        label={formatMessage({ id: "tasks.form.name" })}
        placeholder={formatMessage({ id: "tasks.form.name.placeholder" })}
        required
      />
      <ControlledMarkdown
        name="description"
        label={formatMessage({ id: "tasks.form.description" })}
        placeholder={formatMessage({ id: "tasks.form.description.placeholder" })}
        required
      />
      <AttachmentsField />
    </>
  );
}

export function TemplateTaskSidebarForm() {
  return (
    <>
      <DepartmentField />
      <AssigneeField />
      <SupportersField />
      <TagsField />
      <DependsOnField />
      <DependencyForField />
      <DurationField />
      <DueDateOffsetField />
      <IsMilestoneField />
    </>
  );
}
