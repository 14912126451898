import { Request } from "@dh-critical-path/api-types";
import { StatusType } from "@dh-critical-path/shared";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useDepartmentQuery } from "../../queries";
import { ProjectsPageLayout } from "../Projects/components/ProjectsPageLayout";
import { TasksGroup } from "../Tasks/components/TasksGroup";
import { DepartmentHeader } from "./components/DepartmentHeader";
import {
  DepartmentMembersPanel,
  DepartmentMembersPanelHeader,
} from "./components/DepartmentMembers";

export function DepartmentPage() {
  const params = useParams<"id">();

  const { data } = useDepartmentQuery(Number(params.id));

  useDocumentTitle(data?.name);

  if (!data) {
    return null;
  }

  const filter: Request.Tasks.GetTasks = {
    department_ids: [data.id],
  };

  return (
    <ProjectsPageLayout title={<FormattedMessage id="navigation.departments" />}>
      <PageContainer>
        <Layout.Container>
          {/* header */}
          <Layout.Column width={12}>
            <DepartmentHeader department={data} />
          </Layout.Column>
          {/* members column */}
          <Layout.Column lgWidth={4}>
            <Layout.Spacer space={4}>
              <DepartmentMembersPanelHeader department={data} />
              <DepartmentMembersPanel department={data} />
            </Layout.Spacer>
          </Layout.Column>
          {/* tasks column */}
          <Layout.Column lgWidth={8}>
            <Layout.Spacer space={8}>
              {/* overdue tasks */}
              <TasksGroup
                filter={{ ...filter, is_overdue: true }}
                header={(count) => (
                  <PageSectionHeader isDanger>
                    <FormattedMessage id="headings.tasks.overdue" values={{ count }} />
                  </PageSectionHeader>
                )}
              />
              {/* active tasks */}
              <TasksGroup
                filter={{ ...filter, task_status_types: [StatusType.IN_PROGRESS] }}
                header={(count) => (
                  <PageSectionHeader>
                    <FormattedMessage id="headings.tasks.active" values={{ count }} />
                  </PageSectionHeader>
                )}
              />
              {/* upcoming tasks */}
              <TasksGroup
                filter={{ ...filter, task_status_types: [StatusType.TODO] }}
                header={(count) => (
                  <PageSectionHeader>
                    <FormattedMessage id="headings.tasks.upcoming" values={{ count }} />
                  </PageSectionHeader>
                )}
              />
            </Layout.Spacer>
          </Layout.Column>
        </Layout.Container>
      </PageContainer>
    </ProjectsPageLayout>
  );
}
