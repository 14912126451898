import { Project } from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { ActionButton, Button } from "../../../components/button";
import { PageSectionHeader } from "../../../components/page";
import { AdminOnly } from "../../../hooks/useAuth";
import { useConfirmModal } from "../../../hooks/useModal";
import { useProjectDelete } from "../../../hooks/useProject";
import { useNavigateToProjectEdit, useNavigateToProjects } from "../../utils/navigation";

type ProjectHeaderProps = {
  project: Project;
};

function ProjectEditButton({ project }: ProjectHeaderProps) {
  const navigateToEditView = useNavigateToProjectEdit();

  return (
    <Button variant="secondary" onClick={() => navigateToEditView(project)}>
      <FormattedMessage id="projects.edit" />
    </Button>
  );
}

function ProjectDeleteButton({ project }: ProjectHeaderProps) {
  const { modal, openModal } = useConfirmModal();
  const { handleRemove, isLoading } = useProjectDelete(project);
  const navigateToProjects = useNavigateToProjects();

  function handleClick() {
    openModal(() => handleRemove().then(() => navigateToProjects()));
  }

  return (
    <>
      <ActionButton
        variant="delete"
        size="lg"
        rounding="base"
        onClick={handleClick}
        loading={isLoading}
      />
      {modal}
    </>
  );
}

function ProjectHeaderActions({ project }: ProjectHeaderProps) {
  return (
    <>
      <ProjectEditButton project={project} />
      <ProjectDeleteButton project={project} />
    </>
  );
}

export function ProjectHeader({ project }: ProjectHeaderProps) {
  return (
    <PageSectionHeader
      buttons={<AdminOnly render={() => <ProjectHeaderActions project={project} />} />}
    />
  );
}
