import { keepTruthy } from "@dh-critical-path/shared";
import { useFormContext } from "react-hook-form";
import { ControlledSelect } from "../../../../components/form/controlled";
import { Status, backgroundClassName } from "../../../../enums";
import { UpdateTasksByFilterFields } from "../../../../hooks/useTask";
import {
  useDepartmentsForFilterQuery,
  useTaskAssigneesForFilterQuery,
  useTaskStatusesForFilterQuery,
} from "../../../../queries";
import { useTagsForFilterQuery } from "../../../../queries/tags";
import { assigneesToOptions } from "../../../../utils/misc";

function useProjectId() {
  const { watch } = useFormContext<UpdateTasksByFilterFields>();

  const projectIds = watch("filter.project_ids");

  if (!projectIds?.[0]) {
    throw new Error("filter.project_ids must be filled");
  }

  return projectIds?.[0];
}

function StatusField() {
  const { data } = useTaskStatusesForFilterQuery();

  return (
    <ControlledSelect
      name="data.task_status_id"
      label="Status"
      placeholder="Select status"
      options={data?.map((status) => ({
        ...status,
        color: backgroundClassName(status.status_type as Status),
      }))}
      isClearable
    />
  );
}

function DepartmentField() {
  const projectId = useProjectId();

  const { data } = useDepartmentsForFilterQuery({
    project_id: projectId,
  });

  return (
    <ControlledSelect
      name="data.department_id"
      label="Responsible Department/Team"
      placeholder="Select department"
      options={data}
      showHash
      isClearable
    />
  );
}

function AssigneeField() {
  const { watch } = useFormContext<UpdateTasksByFilterFields>();

  const projectId = useProjectId();
  const departmentId = watch("data.department_id");
  const supporterIds = watch("data.supporter_ids");

  const { data } = useTaskAssigneesForFilterQuery({
    project_id: projectId,
    department_ids: keepTruthy([departmentId]),
  });

  const assigneesWithoutSupporters = data?.filter(
    (assignee) => !supporterIds?.includes(assignee.id)
  );

  return (
    <ControlledSelect
      name="data.assignee_id"
      label="Assigned To"
      placeholder="Select assignee"
      options={assigneesToOptions(assigneesWithoutSupporters ?? [])}
      isDisabled={!departmentId}
      isClearable
    />
  );
}
function SupportersField() {
  const { watch } = useFormContext<UpdateTasksByFilterFields>();

  const projectId = useProjectId();

  const assigneeId = watch("data.assignee_id");

  const { data } = useTaskAssigneesForFilterQuery({
    project_id: projectId,
  });

  const supportersWithoutAssignees = data?.filter((assignee) => assignee.id !== assigneeId);

  return (
    <ControlledSelect
      name="data.supporter_ids"
      label="Supporter"
      placeholder="Select supporter"
      options={assigneesToOptions(supportersWithoutAssignees ?? [])}
      isMulti
      isClearable
    />
  );
}

function TagsField() {
  const { data } = useTagsForFilterQuery({});

  return (
    <ControlledSelect
      name="data.tag_ids"
      label="Tags"
      placeholder="Select Tags"
      options={data}
      isMulti
      isClearable
    />
  );
}

export function TasksMassUpdateSidebarForm() {
  return (
    <>
      <StatusField />
      <DepartmentField />
      <AssigneeField />
      <SupportersField />
      <TagsField />
      {/* is_milestone cannot be updated this way since we don't know was it changed or not */}
    </>
  );
}
