import { TemplateProject } from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { templateProjectDepartmentLinkFactory } from "../utils/navigation";
import { TemplateDepartmentsTasksStatsCards } from "./components/TemplateDepartmentsTasksStatsCards";
import { TemplateProjectHeader } from "./components/TemplateProjectHeader";
import {
  TemplateProjectKeysPanel,
  TemplateProjectKeysPanelHeader,
} from "./components/TemplateProjectKeys";

type TemplateProjectOverviewPageProps = {
  project: TemplateProject;
};

export function TemplateProjectOverviewPage({ project }: TemplateProjectOverviewPageProps) {
  useDocumentTitle(`${project.name} - Overview`);

  return (
    <PageContainer>
      <Layout.Column width={12}>
        {/* header */}
        <TemplateProjectHeader project={project} />
        {/* keys & completion date */}
        <Layout.Spacer space={4}>
          <TemplateProjectKeysPanelHeader project={project} />
          <TemplateProjectKeysPanel project={project} />
        </Layout.Spacer>
      </Layout.Column>
      {/* departments */}
      <Layout.Column width={12}>
        <Layout.Spacer space={4}>
          <TemplateDepartmentsTasksStatsCards
            filter={{ template_project_ids: [project.id], is_global: true }}
            entityLink={templateProjectDepartmentLinkFactory(project)}
            header={(count) => (
              <PageSectionHeader>
                <FormattedMessage id="headings.templates.departments.global" values={{ count }} />
              </PageSectionHeader>
            )}
          />
          <TemplateDepartmentsTasksStatsCards
            filter={{ template_project_ids: [project.id], is_local: true }}
            entityLink={templateProjectDepartmentLinkFactory(project)}
            header={(count) => (
              <PageSectionHeader>
                <FormattedMessage id="headings.templates.departments.local" values={{ count }} />
              </PageSectionHeader>
            )}
          />
        </Layout.Spacer>
      </Layout.Column>
    </PageContainer>
  );
}
