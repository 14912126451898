import { Request } from "@dh-critical-path/api-types";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useTemplateDepartmentQuery } from "../../queries";
import { TemplateTasksGroup } from "./components/TempalteTasksGroup";
import { TemplateDepartmentHeader } from "./components/TemplateDepartmentHeader";
import {
  TemplateDepartmentMembersPanel,
  TemplateDepartmentMembersPanelHeader,
} from "./components/TemplateDepartmentMembers";
import { TemplateProjectsPageLayout } from "./components/TemplateProjectsPageLayout";
import { TemplateTitlesPanel, TemplateTitlesPanelHeader } from "./components/TemplateTitles";

export function TemplateDepartmentPage() {
  const params = useParams<"id">();

  const { data } = useTemplateDepartmentQuery(Number(params.id));

  useDocumentTitle(data?.name);

  if (!data) {
    return null;
  }

  const filter: Request.TemplateTasks.GetTemplateTasks = {
    template_department_ids: [data.id],
  };

  return (
    <TemplateProjectsPageLayout title={<FormattedMessage id="navigation.departments" />}>
      <PageContainer>
        <Layout.Container>
          {/* header */}
          <Layout.Column width={12}>
            <TemplateDepartmentHeader department={data} />
          </Layout.Column>
          {/* members column */}
          <Layout.Column lgWidth={4}>
            <Layout.Spacer space={8}>
              <Layout.Spacer space={4}>
                <TemplateDepartmentMembersPanelHeader department={data} />
                <TemplateDepartmentMembersPanel department={data} />
              </Layout.Spacer>
              <Layout.Spacer space={4}>
                <TemplateTitlesPanelHeader department={data} />
                <TemplateTitlesPanel department={data} />
              </Layout.Spacer>
            </Layout.Spacer>
          </Layout.Column>
          {/* tasks column */}
          <Layout.Column lgWidth={8}>
            <Layout.Spacer space={8}>
              {/* tasks */}
              <TemplateTasksGroup
                filter={{ ...filter }}
                header={(count) => (
                  <PageSectionHeader>
                    <FormattedMessage id="headings.tasks" values={{ count }} />
                  </PageSectionHeader>
                )}
              />
            </Layout.Spacer>
          </Layout.Column>
        </Layout.Container>
      </PageContainer>
    </TemplateProjectsPageLayout>
  );
}
