import { Request } from "@dh-critical-path/api-types";
import { keepTruthy } from "@dh-critical-path/shared";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { hasAnyOf } from "../components/form/TagsInput";
import { useTasksQuery } from "../queries";

export type TasksFilterFormFields = Omit<
  Request.Tasks.GetTasks,
  "page" | "project_ids" | "task_status_types"
> & {
  project_ids: number[];
};

export function useTasksFilter(defaultValues: TasksFilterFormFields) {
  const [page, setPage] = useState(1);

  const [queryParams, setQueryParams] = useState<TasksFilterFormFields>(defaultValues);

  const methods = useForm<TasksFilterFormFields>({
    defaultValues: {
      tags: [hasAnyOf()],
      order_column: "start_date",
      order_direction: "asc",
      ...defaultValues,
    },
  });

  const fields = methods.watch();

  useEffect(() => {
    setPage(1);
    setQueryParams({
      project_ids: fields.project_ids,
      assignee_ids: fields.assignee_ids,
      supporter_ids: fields.supporter_ids,
      tags: fields.tags,
      department_ids: fields.department_ids,
      task_status_ids: fields.task_status_ids,
      name: fields.name,
      start_date_from: fields.start_date_from,
      start_date_to: fields.start_date_to,
      due_date_from: fields.due_date_from,
      due_date_to: fields.due_date_to,
      depends_on_id: fields.depends_on_id,
      dependency_for_id: fields.dependency_for_id,
      is_milestone: fields.is_milestone,
      is_dates_frozen: fields.is_dates_frozen,
      is_overdue: fields.is_overdue,
      is_removed: fields.is_removed,
      without_dependencies: fields.without_dependencies,
      without_due_date_offset: fields.without_due_date_offset,
      without_duration: fields.without_duration,
      order_column: fields.order_column,
      order_direction: fields.order_direction,
    });
  }, [
    fields.project_ids,
    fields.assignee_ids,
    fields.supporter_ids,
    fields.tags,
    fields.department_ids,
    fields.task_status_ids,
    fields.name,
    fields.start_date_from,
    fields.start_date_to,
    fields.due_date_from,
    fields.due_date_to,
    fields.depends_on_id,
    fields.dependency_for_id,
    fields.is_milestone,
    fields.is_dates_frozen,
    fields.is_overdue,
    fields.is_removed,
    fields.without_dependencies,
    fields.without_due_date_offset,
    fields.without_duration,
    fields.order_column,
    fields.order_direction,
  ]);

  const activeFiltersCount = keepTruthy([
    fields.tags?.find((tag) => {
      return ["is_empty", "is_not_empty"].includes(tag.condition) ? true : Boolean(tag.ids.length);
    }),
    fields.assignee_ids?.length,
    fields.supporter_ids?.length,
    fields.department_ids?.length,
    fields.task_status_ids?.length,
    fields.name?.trim(),
    fields.start_date_from,
    fields.start_date_to,
    fields.due_date_from,
    fields.due_date_to,
    fields.depends_on_id,
    fields.dependency_for_id,
    fields.is_milestone,
    fields.is_dates_frozen,
    fields.is_overdue,
    fields.is_removed,
    fields.without_dependencies,
    fields.without_due_date_offset,
    fields.without_duration,
  ]).length;

  const query = useTasksQuery({ ...queryParams, page });

  return { methods, query, setPage, queryParams, activeFiltersCount };
}
