import { ArrowsExpandIcon, MenuAlt1Icon, ZoomInIcon, ZoomOutIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import React, { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";
import Button from "../button/Button";
import { Switch } from "../form";

interface ToolbarProps extends HTMLAttributes<HTMLDivElement> {
  zoomIn: () => void;
  zoomOut: () => void;
  zoomToFit: () => void;
  toggleGrid: () => void;
  gridShown: boolean;
  showLinks: boolean;
  onShowLinksChange: (showLinks: boolean) => void;
  dueDate?: Date;
  onDueDateChange?: (startDate: Date) => void;
  isMilestonesOnly: boolean;
  onIsMilestonesOnlyChange: (value: boolean) => void;
}

interface ToggleGridButtonProps extends HTMLAttributes<HTMLButtonElement> {
  gridShown: boolean;
}

const ToolbarWrapper = styled<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }) => (
    <div
      {...props}
      className={classnames(
        "relative flex flex-col md:flex-row md:items-center md:justify-between py-2 px-6  space-y-4 md:space-y-0",
        className
      )}
    >
      {children}
    </div>
  )
);

const ToolbarActions: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  return <div className="flex flex-col sm:flex-row md:ml-auto">{children}</div>;
};

const ToggleGridButton: React.VFC<ToggleGridButtonProps> = ({ gridShown, ...props }) => {
  return (
    <Button {...props} variant="secondary" icon={MenuAlt1Icon}>
      <DesktopText className="text-left">
        <span className="hidden xl:block xl:w-24"></span>
        {gridShown ? "Hide" : "Show"} Sidebar
      </DesktopText>
    </Button>
  );
};

const ZoomButtons: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  return <div className="flex items-center space-x-2">{children}</div>;
};

const DesktopText: React.FC<HTMLAttributes<HTMLSpanElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <span
      {...props}
      className={classnames("invisible w-0 xl:w-auto xl:visible -mr-2 xl:mr-0", className)}
    >
      {children}
    </span>
  );
};

const Toolbar = React.forwardRef<HTMLDivElement, ToolbarProps>(function GanttToolbar(
  {
    zoomIn,
    zoomOut,
    zoomToFit,
    toggleGrid,
    gridShown,
    showLinks,
    onShowLinksChange,
    dueDate,
    onDueDateChange,
    isMilestonesOnly,
    onIsMilestonesOnlyChange,
    ...props
  },
  ref
) {
  return (
    <ToolbarWrapper {...props} ref={ref}>
      {/* <DatePicker
        className="lg:flex items-center lg:space-x-2 lg:space-y-0 mr-4"
        label="Playground Due Date"
        selected={dueDate}
        onChange={onDueDateChange}
        showYearDropdown
      /> */}

      <ToolbarActions>
        <Switch
          className="mr-6 mb-4 sm:mb-0"
          label="Milestone"
          checked={isMilestonesOnly}
          onChange={onIsMilestonesOnlyChange}
        />
        <Switch
          className="mr-6 mb-4 sm:mb-0"
          label="Show Dependencies"
          checked={showLinks}
          onChange={onShowLinksChange}
        />
        <ZoomButtons>
          <ToggleGridButton onClick={toggleGrid} gridShown={gridShown} />
          <Button variant="secondary" icon={ArrowsExpandIcon} onClick={zoomToFit}>
            <DesktopText>Zoom To Fit</DesktopText>
          </Button>
          <Button variant="secondary" icon={ZoomOutIcon} onClick={zoomIn}>
            <DesktopText>Zoom Out</DesktopText>
          </Button>
          <Button variant="secondary" icon={ZoomInIcon} onClick={zoomOut}>
            <DesktopText>Zoom In</DesktopText>
          </Button>
        </ZoomButtons>
      </ToolbarActions>
    </ToolbarWrapper>
  );
});

export default Toolbar;
