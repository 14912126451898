import { FormattedMessage } from "react-intl";
import { PageContainer, PageHeader, PageSectionHeader } from "../../components/page";
import HeaderHoc from "../../hoc/header/HeaderHoc";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useProjectsTasksStatsQuery } from "../../queries";
import { ProjectsTasksGroup } from "../Projects/components/ProjectsTasksGroup";
import { ProjectsTasksStatsCards } from "../Projects/components/ProjectsTasksStatsCards";
import { ErrorFallback } from "../components/ErrorFallback";
import { projectLinkFactory } from "../utils/navigation";

export function DashboardPage() {
  useDocumentTitle("Dashboard");

  const projectsFilter = {};
  const { error } = useProjectsTasksStatsQuery(projectsFilter);

  if (error) {
    return <ErrorFallback error={error} />;
  }

  return (
    <>
      <HeaderHoc />
      <div>
        <PageHeader title={<FormattedMessage id="navigation.dashboard" />} />
        <PageContainer>
          <ProjectsTasksStatsCards
            filter={projectsFilter}
            entityLink={projectLinkFactory()}
            header={(count) => (
              <PageSectionHeader>
                <FormattedMessage id="headings.projects.ongoing" values={{ count }} />
              </PageSectionHeader>
            )}
          />
          <ProjectsTasksGroup
            filter={projectsFilter}
            tasksFilter={{ is_overdue: true }}
            header={
              <PageSectionHeader isDanger>
                <FormattedMessage id="headings.dashboard.overdue-tasks" />
              </PageSectionHeader>
            }
          />
        </PageContainer>
      </div>
    </>
  );
}
