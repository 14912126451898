import isHotkey from "is-hotkey";
import { KeyboardEvent } from "react";
import {
  BlockFormat,
  BlockType,
  CustomEditor,
  CustomElementType,
  Editor,
  LinkElement,
  MarkFormat,
  Point,
  Range,
  Element as SlateElement,
  Transforms,
} from "slate";
import { ReactEditor } from "slate-react";

function isAlignFormat(blockType: any, format: any): format is BlockFormat<"align"> {
  return blockType === "align";
}

function isBlockTypeFormat(blockType: any, format: any): format is BlockFormat<"type"> {
  return blockType === "type";
}

function isListTypeFormat(blockType: any, format: any): format is "bulleted-list" {
  return blockType === "type" && format === "bulleted-list";
}

function isCheckListTypeFormat(blockType: any, format: any): format is BlockFormat<"checked"> {
  return blockType === "checked";
}

function isElement(node: unknown): node is SlateElement {
  return !Editor.isEditor(node) && SlateElement.isElement(node);
}

export function isLinkActive(editor: CustomEditor) {
  const [link] = Editor.nodes(editor, {
    match: (node) => isElement(node) && node.type === "link",
  });

  return Boolean(link);
}

export function unwrapLink(editor: CustomEditor) {
  if (!isLinkActive(editor)) {
    return;
  }

  Transforms.unwrapNodes(editor, {
    match: (node) => {
      return isElement(node) && node.type === "link";
    },
  });
}

export function wrapLink(editor: CustomEditor, url: string) {
  const { selection } = editor;

  if (!selection || !url) {
    return;
  }

  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const isCollapsed = Range.isCollapsed(selection);

  const link: LinkElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
}

export function toggleBlock<T extends BlockType>(
  editor: CustomEditor,
  blockType: T,
  format: BlockFormat<T>,
  element?: SlateElement
) {
  const isActive = isBlockActive(editor, blockType, format);

  if (blockType === "type" && format === "link") {
    throw new Error("use wrapLink/unwrapLink");
  }

  const unwrappableNodes: BlockFormat<"type">[] = [
    "bulleted-list",
    //
  ];

  Transforms.unwrapNodes(editor, {
    match: (node) => {
      return (
        isElement(node) && !isAlignFormat(blockType, format) && unwrappableNodes.includes(node.type)
      );
    },
    split: true,
  });

  let newProperties: Partial<SlateElement>;

  if (isAlignFormat(blockType, format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else if (isBlockTypeFormat(blockType, format)) {
    newProperties = {
      type: isActive ? "paragraph" : isListTypeFormat(blockType, format) ? "list-item" : format,
    };
  } else if (isCheckListTypeFormat(blockType, format)) {
    newProperties = {
      checked: format,
    };
  } else {
    throw new Error(`Not implemented ${blockType}:${format}`);
  }

  Transforms.setNodes(editor, newProperties, {
    at: element ? ReactEditor.findPath(editor, element) : undefined,
  });

  if (!isActive && isListTypeFormat(blockType, format)) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
  }
}

export function toggleMark(editor: CustomEditor, format: MarkFormat) {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

export function isBlockActive<T extends BlockType>(
  editor: CustomEditor,
  blockType: T,
  format: BlockFormat<T>
) {
  const { selection } = editor;

  if (!selection) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) => {
        return isElement(node) && node[blockType as keyof typeof node] === format;
      },
    })
  );

  return Boolean(match);
}

export function isMarkActive(editor: CustomEditor, format: MarkFormat) {
  return Boolean(Editor.marks(editor)?.[format]);
}

export function isAstChanged(editor: CustomEditor) {
  return editor.operations.some((operation) => operation.type !== "set_selection");
}

export function withChecklists(editor: CustomEditor) {
  const { deleteBackward } = editor;

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: (node) => isElement(node) && node.type === "check-list-item",
      });

      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        if (Point.equals(selection.anchor, start)) {
          Transforms.setNodes(
            editor,
            { type: "paragraph" },
            { match: (node) => isElement(node) && node.type === "check-list-item" }
          );
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
}

export function withEnhancedLists(editor: CustomEditor) {
  // double line break should terminate the list
  const { insertBreak } = editor;

  editor.insertBreak = (...args) => {
    const { selection } = editor;
    const listItemTypes: CustomElementType[] = ["list-item", "check-list-item"];

    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: (node) => isElement(node) && listItemTypes.includes(node.type),
      });

      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        if (Point.equals(selection.anchor, start)) {
          // unwrap from <ul></ul>
          Transforms.unwrapNodes(editor, {
            match: (node) => isElement(node) && node.type === "bulleted-list",
            split: true,
          });

          Transforms.setNodes(
            editor,
            { type: "paragraph" },
            { match: (node) => isElement(node) && listItemTypes.includes(node.type) }
          );
          return;
        }
      }
    }

    insertBreak(...args);
  };

  return editor;
}

export function withInlines(editor: CustomEditor) {
  const { isInline } = editor;

  editor.isInline = (element) => ["link"].includes(element.type) || isInline(element);

  return editor;
}

export function handleHotKey(editor: CustomEditor, event: KeyboardEvent) {
  const marks: Record<string, MarkFormat> = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+shift+s": "strikethrough",
  };

  for (let hotkey in marks) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      toggleMark(editor, marks[hotkey]);
      return;
    }
  }
}
