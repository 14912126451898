import { Request } from "@dh-critical-path/api-types";
import { Layout } from "../../../components/page";
import { useTemplateTasksQuery } from "../../../queries";
import { TemplateTasksTable } from "./TemplateTasksTable";

type TemplateTasksGroupProps = {
  filter: Request.TemplateTasks.GetTemplateTasks;
  header: (count: number) => React.ReactElement;
};

export function TemplateTasksGroup({ filter, header }: TemplateTasksGroupProps) {
  const { data } = useTemplateTasksQuery({ ...filter, limit: 10 });

  if (!data || !data.data.length) {
    return null;
  }

  return (
    <Layout.Spacer space={4}>
      {header(data.meta.total)}
      <TemplateTasksTable tasks={data.data} />
    </Layout.Spacer>
  );
}
