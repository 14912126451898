import { SearchIcon } from "@heroicons/react/solid";
import { useFormContext } from "react-hook-form";
import { FilterResetButton } from "../../../../components/button/FilterResetButton";
import { FormPanel } from "../../../../components/form/FormPanel";
import { ControlledDebouncedInput, ControlledSwitch } from "../../../../components/form/controlled";
import {
  TasksComparisonFields,
  tasksComparisonFilterDefaultValues,
} from "../../../../hooks/useTasksComparisonFilter";

function TemplateTasksComparisonFilterResetButton() {
  const { watch, reset } = useFormContext<TasksComparisonFields>();

  const templateProjectId = watch("template_project_id");
  const projectId = watch("project_id");

  return (
    <FilterResetButton
      onClick={() =>
        reset({
          template_project_id: templateProjectId,
          project_id: projectId,
          ...tasksComparisonFilterDefaultValues(),
        })
      }
    />
  );
}

export function TemplateTasksComparisonFilter() {
  return (
    <FormPanel>
      <div className="col-span-6 md:col-span-2">
        <ControlledDebouncedInput
          name="search"
          iconLeft={() => <SearchIcon className="w-5" />}
          placeholder="Search"
          debounce={500}
        />
      </div>
      <div className="col-span-6 md:col-span-6 flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0">
        <div className="flex flex-wrap -mx-2 -my-1">
          <ControlledSwitch
            inverse
            name="only_changed"
            label="Inc. identical"
            className="my-1 mx-2"
          />
          <ControlledSwitch
            name="only_template_tasks"
            label="Existing in template"
            className="my-1 mx-2"
          />
          <ControlledSwitch
            name="only_project_tasks"
            label="Existing in project"
            className="my-1 mx-2"
          />
          <ControlledSwitch
            name="only_removed_template_tasks"
            label="Removed from template"
            className="my-1 mx-2"
          />
          <ControlledSwitch
            name="only_removed_project_tasks"
            label="Removed from project"
            className="my-1 mx-2"
          />
        </div>
        <div className="flex justify-end">
          <TemplateTasksComparisonFilterResetButton />
        </div>
      </div>
    </FormPanel>
  );
}
