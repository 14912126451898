import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { ControlledInput, ControlledSelect } from "../../../../components/form/controlled";
import { TitleCreateFields } from "../../../../hooks/useTitle";
import { useUsersForFilterQuery } from "../../../../queries";

function NameField() {
  const { formatMessage } = useIntl();

  const { setFocus } = useFormContext();

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  return (
    <ControlledInput
      name="name"
      label={formatMessage({ id: "title.form.name" })}
      placeholder={formatMessage({ id: "title.form.name.placeholder" })}
      required
    />
  );
}

function MembersField() {
  const { formatMessage } = useIntl();

  const { watch } = useFormContext<TitleCreateFields>();

  const departmentId = watch("department_id");

  const { data } = useUsersForFilterQuery({ department_ids: [departmentId] });

  return (
    <>
      <ControlledSelect
        name="member_ids"
        label={formatMessage({ id: "title.form.members" })}
        placeholder={formatMessage({ id: "title.form.members.placeholder" })}
        options={data?.map((user) => ({ ...user, user }))}
        showHash
        isMulti
      />
    </>
  );
}

export function TitleForm() {
  return (
    <>
      <NameField />
      <MembersField />
    </>
  );
}
