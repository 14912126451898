import { Request, Response } from "@dh-critical-path/api-types";
import axios from "axios";

export function getProjectsForFilter(params: Request.Projects.GetProjectsForFilter) {
  return axios
    .get<Response.Projects.GetProjectsForFilter>("/api/projects/filter", { params })
    .then(({ data }) => data);
}

export function getProject(id: number) {
  return axios.get<Response.Projects.GetProject>(`/api/projects/${id}`).then(({ data }) => data);
}

export function getProjectActivity({ project_id, ...params }: Request.Projects.GetProjectActivity) {
  return axios
    .get<Response.Projects.GetProjectActivity>(`/api/projects/${project_id}/activity`, { params })
    .then(({ data }) => data);
}

export function getProjectKeyValues(id: number) {
  return axios
    .get<Response.Projects.GetKeyValues>(`/api/projects/${id}/key-values`)
    .then(({ data }) => data);
}

export function createProject(params: Request.Projects.CreateProject) {
  return axios
    .post<Response.Projects.CreateProject>(`/api/projects`, params)
    .then(({ data }) => data);
}

export function updateProject(params: Request.Projects.UpdateProject) {
  return axios
    .patch<Response.Projects.UpdateProject>(`/api/projects/${params.project_id}`, params)
    .then(({ data }) => data);
}

export function deleteProject({ project_id }: Request.Projects.DeleteProject) {
  return axios.delete<Response.Projects.DeleteProject>(`/api/projects/${project_id}`);
}

export function getProjectsTasksStats(params: Request.Projects.GetProjectsTasksStats) {
  return axios
    .get<Response.Projects.GetProjectsTasksStats>(`/api/stats/tasks/for-projects`, { params })
    .then(({ data }) => data);
}
