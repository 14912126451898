import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverBody,
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
} from "../../components/form/SlideOver";
import { useTemplateTaskCreateForm } from "../../hooks/useTemplateTask";
import { useNavigateToTemplateTask } from "../utils/navigation";
import { TemplateTaskMainForm, TemplateTaskSidebarForm } from "./components/forms/TemplateTaskForm";

type TemplateTaskCreateSlideOverProps = {
  templateProjectId: number;
  templateDepartmentId?: number;
};

type TemplateTaskCreateFormProps = {
  templateProjectId: number;
  templateDepartmentId?: number;
};

export function TemplateTaskCreateForm(props: TemplateTaskCreateFormProps) {
  const navigateToTemplateTask = useNavigateToTemplateTask();

  const { methods, handleSubmit } = useTemplateTaskCreateForm(props, (task) => {
    navigateToTemplateTask(task);
  });

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="tasks.modal.heading.create" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverBody>
            <TemplateTaskMainForm />
          </SlideOverBody>
          <SlideOverSidebar>
            <TemplateTaskSidebarForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TemplateTaskCreateSlideOver(props: TemplateTaskCreateSlideOverProps) {
  return <TemplateTaskCreateForm {...props} />;
}
