import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { PropsWithChildren } from "react";
import { paginate } from "../../utils/pagination";
import { Button } from "../button";

type PaginationProps = {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
};

type PaginationButtonProps = {
  current: boolean;
  onClick: () => void;
};

type PaginationArrowButtonProps = {
  direction: "prev" | "next";
  disabled: boolean;
  onClick: () => void;
};

function PaginationArrowButton({ direction, disabled, onClick }: PaginationArrowButtonProps) {
  return (
    <button
      className={classnames(
        direction === "prev" ? "rounded-l-md" : "rounded-r-md",
        disabled ? "opacity-60 pointer-events-none" : "",
        "relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-stone-100 focus:ring focus:ring-beige cursor-pointer focus:z-10"
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {direction === "prev" ? (
        <ChevronLeftIcon className="h-5 w-5 fill-current text-iron-600" aria-hidden="true" />
      ) : (
        <ChevronRightIcon className="h-5 w-5 fill-current text-iron-600" aria-hidden="true" />
      )}
    </button>
  );
}

function PaginationButton({
  children,
  current,
  onClick,
}: PropsWithChildren<PaginationButtonProps>) {
  return (
    <button
      className={classnames(
        current ? "bg-beige-400 text-black" : "bg-white hover:bg-stone-100 text-iron",
        "relative inline-flex items-center px-4 py-2 text-base focus:ring focus:ring-beige cursor-pointer leading-none focus:z-10"
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function PaginationDivider() {
  return (
    <span className="relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium text-gray-500">
      &#8230;
    </span>
  );
}

function Pagination({ totalPages, currentPage, onPageChange }: PaginationProps) {
  const pages = paginate(currentPage, totalPages);
  const isNextDisabled = currentPage >= totalPages;
  const isPrevDisabled = currentPage <= 1;

  const onNextClick = () => onPageChange(currentPage + 1);
  const onPrevClick = () => onPageChange(currentPage - 1);

  return (
    <div className="flex items-center justify-between">
      <div className="flex-1 flex justify-between sm:hidden space-x-4">
        <Button
          disabled={isPrevDisabled}
          onClick={onPrevClick}
          data-cy="pagination-mobile-prev-button"
        >
          Previous page
        </Button>
        <Button
          disabled={isNextDisabled}
          onClick={onNextClick}
          data-cy="pagination-mobile-next-button"
        >
          Next page
        </Button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center">
        <nav className="relative z-0 inline-flex rounded-md shadow-sm" aria-label="Pagination">
          <PaginationArrowButton
            onClick={onPrevClick}
            direction="prev"
            disabled={isPrevDisabled}
            data-cy="pagination-prev-button"
          />
          {pages.map((page, index) =>
            page ? (
              <PaginationButton
                key={index}
                onClick={() => onPageChange(page)}
                current={page === currentPage}
                data-cy="pagination-button"
              >
                {page}
              </PaginationButton>
            ) : (
              <PaginationDivider key={index} />
            )
          )}
          <PaginationArrowButton
            onClick={onNextClick}
            direction="next"
            disabled={isNextDisabled}
            data-cy="pagination-next-button"
          />
        </nav>
      </div>
    </div>
  );
}

export default Pagination;
