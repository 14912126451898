import { SsoUserRole } from "@dh-critical-path/shared";
import { SearchIcon } from "@heroicons/react/solid";
import { useFormContext } from "react-hook-form";
import { FilterResetButton } from "../../../../components/button/FilterResetButton";
import { FormPanel } from "../../../../components/form/FormPanel";
import { ControlledDebouncedInput, ControlledSelect } from "../../../../components/form/controlled";
import { userRoleBackgroundColor } from "../../../../enums";
import { useDepartmentsForFilterQuery } from "../../../../queries";

function UsersFilterFormResetButton() {
  const form = useFormContext();

  return <FilterResetButton onClick={() => form.reset()} />;
}

function SearchField() {
  return (
    <ControlledDebouncedInput
      iconLeft={() => <SearchIcon className="w-5" />}
      name="search"
      label="Search"
      placeholder="Search"
      debounce={500}
    />
  );
}

function DepartmentsField() {
  const { data } = useDepartmentsForFilterQuery({});

  return (
    <ControlledSelect
      name="department_ids"
      label="Department(s)"
      options={[{ id: -1, name: "None" }, ...(data ?? [])]}
      isMulti
      isClearable
    />
  );
}

function RoleField() {
  return (
    <ControlledSelect
      name="sso_role"
      label="Role"
      options={[
        { id: "none", name: "None" },
        {
          id: SsoUserRole.ADMINISTRATOR,
          name: "Administrator",
          color: userRoleBackgroundColor(SsoUserRole.ADMINISTRATOR),
        },
        {
          id: SsoUserRole.LOCAL_DEPARTMENT,
          name: "Local Department",
          color: userRoleBackgroundColor(SsoUserRole.LOCAL_DEPARTMENT),
        },
        {
          id: SsoUserRole.GLOBAL_DEPARTMENT,
          name: "Global Department",
          color: userRoleBackgroundColor(SsoUserRole.GLOBAL_DEPARTMENT),
        },
      ]}
      isClearable
    />
  );
}

export function UsersFilterForm() {
  return (
    <FormPanel>
      <div className="col-span-6 md:col-span-2">
        <SearchField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <DepartmentsField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <RoleField />
      </div>
      <div className="col-span-6">
        <div className="flex justify-end">
          <UsersFilterFormResetButton />
        </div>
      </div>
    </FormPanel>
  );
}
