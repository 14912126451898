import { Tag, TagGroup } from "@dh-critical-path/api-types";
import { GroupInputBool, GroupInputCondition } from "@dh-critical-path/shared";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useCallback } from "react";
import { Button } from "../button";
import Label from "./Label";
import Select, { mapIdNameToValueLabel, selectedOptionsFromIds } from "./Select";

type TagsInputVariant = "inline" | "block";

export type TagsInputProps = {
  variant: TagsInputVariant;
  tags: Tag[];
  value: TagGroup[];
  onChange: (value: TagGroup[]) => void;
};

type TagInputGroupProps = {
  tags: Tag[];
  group: TagGroup;
  onChange: (group: TagGroup) => void;
  onRemove: () => void;
  showBool: boolean;
  showRemoveButton: boolean;
  variant?: TagsInputVariant;
};

type BoolOption = {
  label: string;
  value: GroupInputBool;
};

type ConditionOption = {
  label: string;
  value: GroupInputCondition;
};

const boolOptions: BoolOption[] = [
  { label: "And", value: GroupInputBool.AND },
  { label: "Or", value: GroupInputBool.OR },
];

const conditionOptions: ConditionOption[] = [
  { label: "Is exactly", value: GroupInputCondition.IS_EXACTLY },
  { label: "Is empty", value: GroupInputCondition.IS_EMPTY },
  { label: "Is not empty", value: GroupInputCondition.IS_NOT_EMPTY },
  { label: "Has any of", value: GroupInputCondition.HAS_ANY_OF },
  { label: "Has all of", value: GroupInputCondition.HAS_ALL_OF },
  { label: "Has none of", value: GroupInputCondition.HAS_NONE_OF },
];

export function hasAnyOf(ids: number[] = []): TagGroup {
  return {
    bool: GroupInputBool.AND,
    condition: GroupInputCondition.HAS_ANY_OF,
    ids,
  };
}

export function TagsInput({ tags, value, onChange, variant }: TagsInputProps) {
  const handleGroupChange = useCallback(
    (group: TagGroup, index: number) => {
      onChange(
        value.map((currentGroup, currentIndex) => (currentIndex !== index ? currentGroup : group))
      );
    },
    [value, onChange]
  );

  const handleGroupRemove = useCallback(
    (index: number) => {
      onChange(value.filter((_, currentIndex) => currentIndex !== index));
    },
    [value, onChange]
  );

  const handleGroupAdd = useCallback(() => {
    onChange([...value, hasAnyOf()]);
  }, [value, onChange]);

  return (
    <div className="px-6 -mx-6 border-t border-b border-beige-400 leading-none">
      {value.map((group, index) => (
        <TagInputGroup
          key={index}
          tags={tags}
          group={group}
          onChange={(group) => handleGroupChange(group, index)}
          onRemove={() => handleGroupRemove(index)}
          showBool={index > 0}
          showRemoveButton={index > 0}
          variant={variant}
        />
      ))}
      <Button
        type="button"
        variant="text"
        className="my-6"
        onClick={handleGroupAdd}
        icon={PlusIcon}
      >
        Add condition
      </Button>
    </div>
  );
}

function TagInputGroup({
  tags,
  group,
  onChange,
  onRemove,
  showBool,
  showRemoveButton,
  variant = "block",
}: TagInputGroupProps) {
  return (
    <div
      className={classNames("py-6 px-6 -mx-6 border-b border-beige-400", {
        "grid grid-cols-6 gap-6": variant === "inline",
        "space-y-6": variant === "block",
      })}
    >
      {showBool && (
        <div
          className={classNames("col-span-6 md:col-span-2", {
            "md:order-3": variant === "inline",
          })}
        >
          {variant === "inline" && <Label className="mb-1 hidden md:block">&nbsp;</Label>}
          <Select
            options={boolOptions}
            value={selectedOptionsFromIds(boolOptions, group.bool)}
            onChange={(option: BoolOption) => {
              onChange({ ...group, bool: option.value });
            }}
            isSearchable={false}
          />
        </div>
      )}
      <div className="col-span-6 md:col-span-2">
        <Select
          label="Condition"
          options={conditionOptions}
          value={selectedOptionsFromIds(conditionOptions, group.condition)}
          onChange={(option: ConditionOption) => {
            onChange({ ...group, condition: option.value, ids: [] });
          }}
          isSearchable={false}
        />
      </div>
      <div className="col-span-6 md:col-span-2">
        <Select
          label="Tags"
          options={mapIdNameToValueLabel(tags)}
          value={selectedOptionsFromIds(mapIdNameToValueLabel(tags), group.ids)}
          isDisabled={[GroupInputCondition.IS_EMPTY, GroupInputCondition.IS_NOT_EMPTY].includes(
            group.condition
          )}
          onChange={(options: Tag[]) => {
            onChange({ ...group, ids: options.map((option) => option.id) });
          }}
          isMulti
        />
      </div>
      {showRemoveButton && (
        <div className="col-span-6 leading-none -mt-2 order-last">
          <Button type="button" variant="text" onClick={onRemove} icon={MinusIcon}>
            Remove condition
          </Button>
        </div>
      )}
    </div>
  );
}
