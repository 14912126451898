import { TaskStatus } from "@dh-critical-path/api-types";
import { useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { hasAnyOf } from "../components/form/TagsInput";
import { useTaskStatusesForFilterQuery } from "../queries";
import { useSearchParameter } from "./useSearchParameter";
import { TasksFilterFormFields } from "./useTasksFilter";

export function useTasksFilterSearchParameters(methods: UseFormReturn<TasksFilterFormFields>) {
  const { data: taskStatuses } = useTaskStatusesForFilterQuery();
  const isOverdue = useSearchParameter("is_overdue");
  const departmentId = useSearchParameter("department");
  const assigneeId = useSearchParameter("assignee");
  const tagId = useSearchParameter("tag");
  const status = useSearchParameter("status");

  const hasDefaultSearchParameters = useMemo(
    () => Boolean(isOverdue || departmentId || assigneeId || tagId || status),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (status && taskStatuses) {
      methods.setValue(
        "task_status_ids",
        taskStatuses
          ?.filter((item: TaskStatus) => item.status_type === status)
          .map((item) => item.id)
      );
    }
  }, [methods, status, taskStatuses]);

  useEffect(() => {
    if (isOverdue) {
      methods.setValue("is_overdue", Number(isOverdue) === 1);
    }
  }, [methods, isOverdue]);

  useEffect(() => {
    if (departmentId) {
      methods.setValue("department_ids", [Number(departmentId)]);
    }
  }, [methods, departmentId]);

  useEffect(() => {
    if (assigneeId) {
      methods.setValue("assignee_ids", [Number(assigneeId)]);
    }
  }, [methods, assigneeId]);

  useEffect(() => {
    if (tagId) {
      methods.setValue("tags", [hasAnyOf([Number(tagId)])]);
    }
  }, [methods, tagId]);

  return { hasDefaultSearchParameters };
}
