import { Tag, Task, TemplateTask, TemplateTitle, Title, User } from "@dh-critical-path/api-types";
import { Dialog, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { FormEventHandler, HTMLAttributes, PropsWithChildren, useState } from "react";
import { Fragment } from "react";
import { useFormState } from "react-hook-form";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { UseQueryResult } from "react-query";
import { Link } from "react-router-dom";
import { useSlideOverNavigate } from "../../routes/SlideOvers";
import { isTask } from "../../utils/misc";
import { is403, is404 } from "../../utils/query";
import { fullName } from "../../utils/text";
import { Button, CloseButton } from "../button";
import { DangerModal } from "../common";
import { ProfileAvatar } from "../profile";
import Label from "./Label";

interface SlideOverProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  onClose: () => void;
  header?: React.ReactNode;
  sidebar?: React.ReactNode;
  body?: React.ReactNode;
  title?: string;
  width?: "base" | "wide";
}

type SlideOverBodyProps = {
  width?: "base" | "wide";
  spacing?: "base" | "none";
};

type SlideOverWrapperProps = {
  width: "base" | "wide";
};

type SlideOverFormProps = {
  onSubmit: FormEventHandler;
};

type SlideOverFormHeaderProps = {
  title?: React.ReactNode;
  submitText: React.ReactNode;
  submitDisabled?: boolean;
  onDiscard?: () => void;
};

type SlideOverLoadingFailedModalProps = {
  error?: unknown;
  text403?: React.ReactNode;
  text404?: React.ReactNode;
  textDefault?: React.ReactNode;
};

type SlideOverWithLoadingProps<T, E> = {
  result: UseQueryResult<T, E>;
  render: (data: T) => React.ReactElement;
};

type SlideOverSidebarFieldProps = {
  label: React.ReactNode;
  value: React.ReactNode;
  valueFallback?: React.ReactNode;
};

type SlideOverSidebarProfileValueProps = {
  user: User | null;
  title: Title | TemplateTitle | null;
};

type SlideOverSidebarProfileListValueProps = {
  users: User[];
  titles: (Title | TemplateTitle)[];
};

type SlideOverSidebarTagsValueProps = {
  tags: Tag[];
  to: (tag: Tag) => string;
};

type SlideOverSidebarDateValueProps = {
  date: string;
};

type SlideOverSidebarTaskListValueProps<
  T =
    | Pick<Task, "id" | "name" | "project_id" | "start_date" | "due_date">
    | Pick<TemplateTask, "id" | "name">
> = {
  tasks: T[];
  to: (task: T) => string;
};

export function SlideOverContent({ children }: PropsWithChildren<{}>) {
  return (
    <div className="flex-1 flex flex-col md:flex-row bg-white shadow-xl lg:rounded-bl-lg">
      {children}
    </div>
  );
}

function SlideOverWrapper({ width, children }: PropsWithChildren<SlideOverWrapperProps>) {
  return (
    <div className="absolute inset-0 overflow-hidden">
      <SlideOverOverlay />
      <div className="fixed inset-y-0 right-0 max-w-screen flex">
        <SlideOverTransformTransition>
          <div
            className={classNames("flex flex-1 flex-col relative w-screen xl:w-auto", {
              "xl:max-w-5xl": width === "base",
              "xl:max-w-7xl": width === "wide",
            })}
          >
            <div className="flex flex-1 flex-col sm:pl-12 overflow-auto">{children}</div>
          </div>
        </SlideOverTransformTransition>
      </div>
    </div>
  );
}

export function SlideOverHeader({ children }: PropsWithChildren<{}>) {
  return (
    <div className="sticky top-0 w-full bg-white flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-6 sm:items-center justify-between py-3 pl-3 pr-8 sm:p-6 border-b border-stone-100 lg:rounded-tl-lg z-10">
      {children}
    </div>
  );
}

export function SlideOverWithLoading<T, E>({ result, render }: SlideOverWithLoadingProps<T, E>) {
  const { data, error } = result;

  if (data) {
    return render(data);
  }

  return (
    <SlideOverLoadingPlaceholder>
      <SlideOverLoadingFailedModal error={error} />
    </SlideOverLoadingPlaceholder>
  );
}

export function SlideOverLoadingPlaceholder({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <SlideOverHeader>Loading...</SlideOverHeader>
      <SlideOverContent>
        <SlideOverSidebar>Loading...</SlideOverSidebar>
        {children}
      </SlideOverContent>
    </>
  );
}

export function SlideOverTitle({ children }: PropsWithChildren<{}>) {
  return <h3 className="text-black text-xl font-medium">{children}</h3>;
}

function SlideOverOverlay() {
  return (
    <SlideOverFadeTransition>
      <Dialog.Overlay className="absolute inset-0 bg-stone-100 bg-opacity-75 transition-opacity" />
    </SlideOverFadeTransition>
  );
}

function SlideOverFadeTransition({ children }: PropsWithChildren<{}>) {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-in-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition.Child>
  );
}

const SlideOverTransformTransition: React.FC = ({ children }: PropsWithChildren<{}>) => (
  <Transition.Child
    as={Fragment}
    enter="transform transition ease-in-out duration-300"
    enterFrom="translate-x-full"
    enterTo="translate-x-0"
    leave="transform transition ease-in-out duration-300"
    leaveFrom="translate-x-0"
    leaveTo="translate-x-full"
  >
    {children}
  </Transition.Child>
);

export function SlideOverSidebar({ children }: PropsWithChildren<{}>) {
  return (
    <div className="slide-over-sidebar w-full md:w-screen md:max-w-xl flex-1 bg-beige-100 flex flex-col space-y-6 p-6">
      {children}
    </div>
  );
}

export function SlideOverSidebarField({
  label,
  value,
  valueFallback = <>&mdash;</>,
}: SlideOverSidebarFieldProps) {
  return (
    <div>
      <Label className="mb-2">{label}</Label>
      <div>{value === null || value === undefined ? valueFallback : value}</div>
    </div>
  );
}

export function SlideOverSidebarProfileValue({ user, title }: SlideOverSidebarProfileValueProps) {
  if (!user && !title) {
    return null;
  }

  return (
    <div className="flex items-center">
      <ProfileAvatar showTooltip={false} user={user ?? title!} isOutline={Boolean(title)} />
      <div className="ml-3">{user ? fullName(user) : title!.name}</div>
    </div>
  );
}

export function SlideOverSidebarProfileListValue({
  users,
  titles,
}: SlideOverSidebarProfileListValueProps) {
  return (
    <div className="space-y-1">
      {users.map((user) => (
        <SlideOverSidebarProfileValue key={`user${user.id}`} user={user} title={null} />
      ))}
      {titles.map((title) => (
        <SlideOverSidebarProfileValue key={`title${title.id}`} title={title} user={null} />
      ))}
    </div>
  );
}

export function SlideOverSidebarTagsValue({ tags, to }: SlideOverSidebarTagsValueProps) {
  return (
    <div className="flex flex-wrap -mt-2">
      {tags.map((tag) => (
        <Link
          key={tag.id}
          to={to(tag)}
          className="flex items-center justify-center rounded leading-none bg-beige-400 text-black h-6 px-2.5 text-sm mr-2 mt-2"
        >
          {tag.name}
        </Link>
      ))}
    </div>
  );
}

export function SlideOverSidebarDateValue({ date }: SlideOverSidebarDateValueProps) {
  return (
    <div className="flex items-center">
      <CalendarIcon className="w-5 stroke-current text-stone-800 mr-2" />
      <FormattedDate value={date} year="numeric" month="short" day="2-digit" />
    </div>
  );
}

export function SlideOverSidebarTaskListValue({ tasks, to }: SlideOverSidebarTaskListValueProps) {
  const [limit, setLimit] = useState(2);

  function FormattedDateWithFallback({ value }: { value: string | undefined }) {
    return value ? (
      <FormattedDate value={value} year="numeric" month="short" day="2-digit" />
    ) : (
      <>&mdash;</>
    );
  }

  return (
    <div className="space-y-3">
      {tasks.slice(0, limit).map((task) => {
        return (
          <div key={task.id}>
            <p className="text-base text-black font-bold leading-tight">
              <Link to={to(task)} className="pr-2 hover:text-violet transition">
                {task.name}
              </Link>
            </p>
            {isTask(task) && (
              <p className="text-sm leading-tight text-iron">
                <FormattedDateWithFallback value={task.start_date} /> &mdash;{" "}
                <FormattedDateWithFallback value={task.due_date} />
              </p>
            )}
          </div>
        );
      })}
      {tasks.length > limit && (
        <button
          onClick={() => setLimit(tasks.length)}
          className="text-base text-black hover:text-violet font-bold"
        >
          +{tasks.length - limit} more...
        </button>
      )}
    </div>
  );
}

export function SlideOverBody({
  width = "base",
  spacing = "base",
  children,
}: PropsWithChildren<SlideOverBodyProps>) {
  return (
    <div
      className={classNames("w-full md:w-screen md:flex-1 xl:flex-auto flex flex-col", {
        "md:max-w-xl": width === "base",
        "space-y-6 p-6": spacing === "base",
      })}
    >
      {children}
    </div>
  );
}

export function SlideOverForm({ onSubmit, children }: PropsWithChildren<SlideOverFormProps>) {
  return (
    <form onSubmit={onSubmit} className="flex flex-col flex-1">
      {children}
    </form>
  );
}

export function SlideOverFormHeader({
  title,
  submitText,
  submitDisabled = false,
  onDiscard,
  children,
}: PropsWithChildren<SlideOverFormHeaderProps>) {
  const { isSubmitting } = useFormState();

  const slideOverNavigate = useSlideOverNavigate();

  function handleDiscard() {
    onDiscard ? onDiscard() : slideOverNavigate();
  }

  return (
    <SlideOverHeader>
      {title ?? <SlideOverTitle>{children}</SlideOverTitle>}
      <SlideOverButtons>
        <Button variant="secondary" disabled={isSubmitting} onClick={handleDiscard}>
          <FormattedMessage id="modal.discard" />
        </Button>
        <Button
          type={isSubmitting ? "button" : "submit"}
          disabled={isSubmitting || submitDisabled}
          loading={isSubmitting}
        >
          {submitText}
        </Button>
      </SlideOverButtons>
    </SlideOverHeader>
  );
}

export function SlideOverLoadingFailedModal({
  error,
  text404,
  text403,
  textDefault,
}: SlideOverLoadingFailedModalProps) {
  const { formatMessage } = useIntl();

  const navigate = useSlideOverNavigate();

  const text403Translated = text403 ?? formatMessage({ id: "modal.error.403" });
  const text404Translated = text404 ?? formatMessage({ id: "modal.error.404" });
  const textDefaultTranslated = textDefault ?? formatMessage({ id: "modal.error.default" });

  return (
    <DangerModal
      open={Boolean(error)}
      onClose={() => navigate()}
      title={
        is403(error) ? text403Translated : is404(error) ? text404Translated : textDefaultTranslated
      }
      description={formatMessage({ id: "modal.error.loading-failed" })}
    />
  );
}

export function SlideOverButtons({ children }: PropsWithChildren<{}>) {
  return <div className="space-x-3 flex">{children}</div>;
}

function SlideOverCloseButton(props: Pick<Parameters<typeof CloseButton>[0], "onClick">) {
  return (
    <div className="absolute top-0 right-0 sm:right-auto sm:left-1 xl:left-0 z-20">
      <CloseButton
        {...props}
        className="bg-white w-9 h-9 flex items-center justify-center sm:mt-3 rounded-md focus:ring focus:ring-beige"
        data-cy="slide-over-close-button"
      />
    </div>
  );
}

const SlideOver = React.forwardRef<HTMLDivElement, SlideOverProps>(function SlideOver(
  { header, sidebar, body, title, open = false, width = "base", onClose, children, ...props },
  ref
) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        {...props}
        ref={ref}
        as="div"
        className="fixed inset-0 overflow-hidden z-30"
        onClose={() => false}
      >
        <SlideOverWrapper width={width}>
          <SlideOverCloseButton onClick={onClose} />
          {children || (
            <>
              <SlideOverHeader>
                {title && <SlideOverTitle>{title}</SlideOverTitle>}
                {header}
              </SlideOverHeader>
              <SlideOverContent>
                {body && <SlideOverBody>{body}</SlideOverBody>}
                {sidebar && <SlideOverSidebar>{sidebar}</SlideOverSidebar>}
              </SlideOverContent>
            </>
          )}
        </SlideOverWrapper>
      </Dialog>
    </Transition.Root>
  );
});

export default SlideOver;
