import React from "react";
import { IntlProvider } from "react-intl";
import en from "./en.json";

const locale = "en";
const defaultLocale = "en";
const messages = { en };

const I18nProvider: React.FC = ({ children }) => {
  // TODO: use locale from settings?
  return (
    <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={defaultLocale}>
      {children}
    </IntlProvider>
  );
};

export default I18nProvider;
