import { Project } from "@dh-critical-path/api-types";
import { PropsWithChildren } from "react";
import { PageHeader } from "../../../components/page";
import HeaderHoc from "../../../hoc/header/HeaderHoc";
import { ProjectTabs } from "./ProjectTabs";

type ProjectPageLayoutProps = {
  project: Project;
};

export function ProjectPageLayout({
  project,
  children,
}: PropsWithChildren<ProjectPageLayoutProps>) {
  return (
    <>
      <HeaderHoc />
      <div>
        <PageHeader title={project.name} hash={project.id}>
          <ProjectTabs project={project} />
        </PageHeader>
        {children}
      </div>
    </>
  );
}
