import { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { UseControllerProps, useController } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { Select } from "..";
import { dateToSqlFormat } from "../../../utils/misc";
import Checkbox, { CheckboxProps } from "../Checkbox";
import DatePicker, { DatePickerProps } from "../DatePicker";
import Input, { InputProps } from "../Input";
import Markdown from "../Markdown";
import { SelectProps, mapIdNameToValueLabel, selectedOptionsFromIds } from "../Select";
import Switch, { SwitchProps } from "../Switch";
import { TagsInput, TagsInputProps } from "../TagsInput";
import Textarea, { TextareaProps } from "../Textarea";

interface ControlledInputProps extends Omit<InputProps, "onChange" | "value" | "name"> {
  name: string;
}

interface ControlledDebouncedInputProps extends ControlledInputProps {
  debounce: number;
}

interface ControlledTextAreaProps extends Omit<TextareaProps, "onChange" | "value" | "name"> {
  name: string;
}

interface ControlledMarkdownProps extends Omit<TextareaProps, "onChange" | "value"> {
  name: string;
}

type SelectOption = { id: number | string; name: string; [key: string]: any };

interface ControlledSelectProps extends Omit<SelectProps, "onChange" | "value" | "options"> {
  name: string;
  showHash?: boolean;
  options?: SelectOption[];
  groupedOptions?: any[];
  required?: boolean;
}

interface ControlledDatePickerProps extends Omit<DatePickerProps, "selected" | "onChange"> {
  name: string;
}

interface ControlledSwitchProps extends Omit<SwitchProps, "checked" | "onChange"> {
  name: string;
  inverse?: boolean;
}

interface ControlledCheckboxProps extends Omit<CheckboxProps, "checked" | "onChange"> {
  name: string;
}

interface ControlledTagsInputProps extends Omit<TagsInputProps, "value" | "onChange"> {
  name: string;
}

function useControlledInput(props: UseControllerProps) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return {
    ...field,
    value: field.value ?? "",
    invalid: Boolean(error),
    invalidText: error?.message,
  };
}

export const ControlledInput: React.VFC<ControlledInputProps> = ({ name, required, ...props }) => {
  return (
    <Input
      {...props}
      {...useControlledInput({ name, rules: { required }, defaultValue: "" })}
      required={required}
    />
  );
};

export const ControlledDebouncedInput: React.VFC<ControlledDebouncedInputProps> = ({
  name,
  required,
  debounce,
  ...props
}) => {
  const { value, onChange, ...inputProps } = useControlledInput({
    name,
    rules: { required },
  });

  const [internalValue, setInternalValue] = useState("");

  const [debouncedValue, debouncedFunctions] = useDebounce(internalValue, debounce);

  useEffect(() => {
    if (value !== debouncedValue) {
      setInternalValue(value ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    onChange(debouncedValue ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  // clear up
  useEffect(() => debouncedFunctions.cancel, [debouncedFunctions]);

  return (
    <Input
      {...props}
      {...inputProps}
      value={internalValue}
      onChange={(event) => setInternalValue(event.target.value)}
      required={required}
    />
  );
};

export const ControlledTextArea: React.VFC<ControlledTextAreaProps> = ({
  name,
  required,
  ...props
}) => {
  return (
    <Textarea
      {...props}
      {...useControlledInput({ name, rules: { required }, defaultValue: "" })}
      required={required}
    />
  );
};

export const ControlledMarkdown: React.VFC<ControlledMarkdownProps> = ({
  name,
  required,
  ...props
}) => {
  const { invalid, invalidText, value, onChange } = useControlledInput({
    name,
    rules: { required },
  });

  return (
    <Markdown
      {...props}
      required={required}
      invalid={invalid}
      invalidText={invalidText}
      value={value}
      onChange={onChange}
    />
  );
};

export const ControlledSelect: React.VFC<ControlledSelectProps> = ({
  name,
  required,
  isMulti = false,
  options,
  groupedOptions,
  showHash,
  ...props
}) => {
  const mappedOptions = mapIdNameToValueLabel(options, showHash);

  const { invalid, invalidText, ...field } = useControlledInput({ name, rules: { required } });

  const selectedOptions = selectedOptionsFromIds(mappedOptions, field.value);

  const handleChange = useCallback(
    (value: any) => {
      if (!value) {
        field.onChange({ target: { value: null } });
      } else if (isMulti) {
        field.onChange({ target: { value: value.map((value: any) => value.value) } });
      } else {
        field.onChange({ target: { value: value.value } });
      }
    },
    [field, isMulti]
  );

  return (
    <Select
      {...props}
      options={groupedOptions ?? mappedOptions}
      value={selectedOptions}
      onChange={handleChange}
      isMulti={isMulti}
      invalid={invalid}
      invalidText={invalidText || "This field is required"}
      required={required}
    />
  );
};

export const ControlledDatePicker: React.VFC<ControlledDatePickerProps> = ({
  name,
  required,
  ...props
}) => {
  const { invalid, invalidText, value, onChange, ref } = useControlledInput({
    name,
    rules: { required },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.scrollIntoView();
        inputRef.current.focus();
      }
    },
  }));

  function handleChange(date: Date) {
    onChange(dateToSqlFormat(date));
  }

  return (
    <DatePicker
      {...props}
      ref={inputRef}
      selected={value ? new Date(value) : undefined}
      onChange={handleChange}
      invalid={invalid}
      invalidText={invalidText}
      showYearDropdown
    />
  );
};

export const ControlledSwitch: React.VFC<ControlledSwitchProps> = ({ name, inverse, ...props }) => {
  const { value: baseValue, onChange: baseOnChange } = useControlledInput({ name });

  const value = inverse ? !baseValue : baseValue;

  const onChange = useCallback(
    (checked: boolean) => baseOnChange(inverse ? !checked : checked),
    [inverse, baseOnChange]
  );

  return <Switch {...props} checked={value} onChange={onChange} />;
};

export const ControlledCheckbox: React.VFC<ControlledCheckboxProps> = ({ name, ...props }) => {
  const { value, onChange } = useControlledInput({ name });

  return <Checkbox {...props} checked={value} onChange={onChange} />;
};

export const ControlledTagsInput: React.VFC<ControlledTagsInputProps> = ({ name, ...props }) => {
  const { value, onChange } = useControlledInput({ name });

  return <TagsInput {...props} value={value} onChange={onChange} />;
};
