import { Request, Response } from "@dh-critical-path/api-types";
import axios from "axios";

export function getTaskComments(id: number) {
  return axios
    .get<Response.Comments.GetComments>(`/api/comments/list/by-task/${id}`)
    .then(({ data }) => data);
}

export function getTemplateTaskComments(id: number) {
  return axios
    .get<Response.Comments.GetComments>(`/api/comments/list/by-template-task/${id}`)
    .then(({ data }) => data);
}

export function createComment(params: Request.Comments.CreateComment) {
  return axios
    .post<Response.Comments.PostComment>(`/api/comments`, params)
    .then(({ data }) => data);
}

export function updateComment({ comment_id, ...params }: Request.Comments.UpdateComment) {
  return axios
    .patch<Response.Comments.UpdateComment>(`/api/comments/${comment_id}`, params)
    .then(({ data }) => data);
}

export function deleteComment({ comment_id }: Request.Comments.DeleteComment) {
  return axios.delete<void>(`/api/comments/${comment_id}`);
}
