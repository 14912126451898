import { useEffect } from "react";

const defaultTitle = document.title;

export function useDocumentTitle(title?: string | null) {
  useEffect(() => {
    document.title = title || defaultTitle;
  }, [title]);

  useEffect(
    () => () => {
      document.title = defaultTitle;
    },
    []
  );
}
