import { useCallback } from "react";

export function useActionCompleted<R, A extends (...args: any[]) => Promise<R | undefined>>(
  action: A,
  onCompleted: (response: R) => void
) {
  return useCallback(
    async (...args: Parameters<A>) =>
      action(...args).then((response) => {
        onCompleted(response!);
        return response;
      }),
    [action, onCompleted]
  );
}
