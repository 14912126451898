import { Request } from "@dh-critical-path/api-types";
import { useQueries, useQuery } from "react-query";
import {
  getTask,
  getTaskAssigneesForFilter,
  getTaskStatusesForFilter,
  getTasks,
  getTasksForFilter,
  getTasksForGantt,
} from "../api";

export const tasksKeys = {
  tasksForFilter: (params: Request.Tasks.GetTasksForFilter) => ["tasks:filter", params],
  taskStatusesForFilter: () => ["task-statuses:filter"],
  taskAssigneesForFilter: (params: Request.Tasks.GetTaskAssigneesForFilter) => [
    "task-assignees:filter",
    params,
  ],
  task: (id: number) => ["tasks", id],
  tasks: (params: Request.Tasks.GetTasks) => ["tasks", params],
  tasksForGantt: (params: Request.Tasks.GetTasksForGantt) => ["tasks", params],
  allTasks: () => ["tasks"],
};

export function useTasksForFilterQuery(params: Request.Tasks.GetTasksForFilter) {
  return useQuery(tasksKeys.tasksForFilter(params), () => getTasksForFilter(params));
}

export function useTaskAssigneesForFilterQuery(params: Request.Tasks.GetTaskAssigneesForFilter) {
  return useQuery(tasksKeys.taskAssigneesForFilter(params), () =>
    getTaskAssigneesForFilter(params)
  );
}

export function useTaskStatusesForFilterQuery() {
  return useQuery(tasksKeys.taskStatusesForFilter(), getTaskStatusesForFilter);
}

export function useTaskQuery(id: number) {
  return useQuery(tasksKeys.task(id), () => getTask(id));
}

export function useTasksQuery(params: Request.Tasks.GetTasks) {
  return useQuery(tasksKeys.tasks(params), () => getTasks(params));
}

export function useTasksForGanttQuery(params: Request.Tasks.GetTasksForGantt) {
  return useQuery(tasksKeys.tasksForGantt(params), () => getTasksForGantt(params));
}

export function useTasksQueries(paramsOfQueries: Request.Tasks.GetTasks[]) {
  return useQueries(
    paramsOfQueries.map((params) => ({
      queryKey: tasksKeys.tasks(params),
      queryFn: () => getTasks(params),
    }))
  );
}
