import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { Button } from "../../components/button";
import { PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import {
  templateProjectLinkFactory,
  useNavigateToTemplateProjectCreate,
} from "../utils/navigation";
import { TemplateProjectsPageLayout } from "./components/TemplateProjectsPageLayout";
import { TemplateProjectsTasksStatsCards } from "./components/TemplateProjectsTasksStatsCards";

export function TemplateProjectsPage() {
  useDocumentTitle("Templates");

  const navigateToCreationView = useNavigateToTemplateProjectCreate();

  return (
    <TemplateProjectsPageLayout title={<FormattedMessage id="navigation.templates" />}>
      <PageContainer>
        <TemplateProjectsTasksStatsCards
          header={(count) => (
            <PageSectionHeader
              buttons={
                <Button icon={PlusIcon} onClick={() => navigateToCreationView()}>
                  <FormattedMessage id="templates.projects.create" />
                </Button>
              }
            >
              <FormattedMessage id="headings.templates" values={{ count }} />
            </PageSectionHeader>
          )}
          filter={{}}
          entityLink={templateProjectLinkFactory()}
        />
      </PageContainer>
    </TemplateProjectsPageLayout>
  );
}
