import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { pluralize } from "../../../utils/text";

type TemplateTasksLinkProps = {
  templateDepartmentId: number;
  templateProjectId: number;
  assigneeId?: number | null;
};

type AffectedTasksDetailsProps = {
  templateDepartmentId: number;
  templateProjectId?: number | null;
  assigneeId?: number | null;
  totalTasks: number;
  totalTemplateTasks: number;
};

function TemplateTasksLink({
  templateDepartmentId,
  templateProjectId,
  assigneeId,
  children,
}: PropsWithChildren<TemplateTasksLinkProps>) {
  return (
    <Link
      to={`/templates/${templateProjectId}/tasks?department=${templateDepartmentId}&assignee=${
        assigneeId ?? ""
      }`}
      className="font-bold leading-none hover:text-violet transition"
      target="_blank"
    >
      {children}
    </Link>
  );
}

export function AffectedTasksDetails({
  templateProjectId,
  totalTasks,
  totalTemplateTasks,
  ...props
}: AffectedTasksDetailsProps) {
  const total = totalTasks + totalTemplateTasks;

  return (
    <>
      {total > 0 && (
        <p>
          This will affect{" "}
          {totalTemplateTasks > 0 && (
            <>
              {templateProjectId ? (
                <>
                  <TemplateTasksLink templateProjectId={templateProjectId} {...props}>
                    {totalTemplateTasks} {pluralize(totalTemplateTasks, "task", "tasks")}
                  </TemplateTasksLink>{" "}
                  in current project
                </>
              ) : (
                <>
                  {totalTemplateTasks} {pluralize(totalTemplateTasks, "task", "tasks")} in templates
                </>
              )}
            </>
          )}
          {totalTasks > 0 && (
            <>
              {" "}
              {totalTemplateTasks > 0 && "and"} {totalTasks}{" "}
              {pluralize(totalTasks, "task", "tasks")} in projects
            </>
          )}
        </p>
      )}
    </>
  );
}
