import {
  AssigneeForFilter,
  Department,
  Project,
  Task,
  TasksStats,
  TasksStatsEntity,
  TemplateTask,
} from "@dh-critical-path/api-types";
import { ResourceIdPrefix, isResourceIdPrefixedWith } from "@dh-critical-path/shared";
import { differenceInDays, format } from "date-fns";
import { Status } from "../enums";

export function dateToSqlFormat(date: Date) {
  return format(date, "yyyy-MM-dd");
}

export function durationInDays(startDate: string, endDate: string) {
  return differenceInDays(new Date(endDate), new Date(startDate)) + 1;
}

export function serializeQueryParams(query: Record<string, string | number>): string {
  return Object.entries(query)
    .filter(([key, value]) => value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&");
}

export function isProject(entity: TasksStatsEntity): entity is Project {
  return (entity as Project).description !== undefined;
}

export function isDepartment(entity: TasksStatsEntity): entity is Department {
  return (entity as Project).description === undefined;
}

export function isTask(entity: Partial<Task> | Partial<TemplateTask>): entity is Task {
  return (entity as Task).project_id !== undefined;
}

export function isTemplateTask(
  entity: Partial<Task> | Partial<TemplateTask>
): entity is TemplateTask {
  return (entity as TemplateTask).template_project_id !== undefined;
}

export function tasksByStatusCount<T extends TasksStatsEntity>(
  stats: TasksStats<T>,
  status: Status
): number {
  return status === Status.overdue ? stats.tasks_overdue : stats[`tasks_status_${status}`];
}

export function assigneesToOptions(assignees: AssigneeForFilter[]) {
  return (assignees || []).map((assignee) => {
    if (isResourceIdPrefixedWith(assignee.id, ResourceIdPrefix.USER)) {
      return { ...assignee, user: assignee };
    } else {
      return { ...assignee, title: assignee };
    }
  });
}

export function mapIds<T extends { id: number }>(entries: T[]) {
  return entries.map((entry) => entry.id);
}

export function isUrl(text: string) {
  return /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(
    text
  );
}
