import { ProjectActivityItem } from "@dh-critical-path/api-types";
import { ChangeType } from "@dh-critical-path/shared";
import { ArrowCircleLeftIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { RouteKey, slideOverRoute } from "../../routes/SlideOvers";
import { fullName } from "../../utils/text";
import { FormattedRelativeDateTime } from "../formatting";
import { ProfileAvatar } from "../profile";

// TODO: refactor to support templates, etc

type ActivityFeedItemProps = {
  item: ProjectActivityItem;
};

function FeedItemContainer({ children }: PropsWithChildren<{}>) {
  return <div className="relative flex items-start overflow-hidden">{children}</div>;
}

function FeedItemIconComponent({ icon: Component }: { icon: React.ElementType }) {
  return <Component className="w-5 flex-none" />;
}

function FeedItemChangeIcon({ item }: ActivityFeedItemProps) {
  return (
    <div className="flex justify-center w-4 text-beige">
      {item.change_type === ChangeType.CREATE && <FeedItemIconComponent icon={PlusIcon} />}
      {item.change_type === ChangeType.DELETE && <FeedItemIconComponent icon={TrashIcon} />}
      {item.change_type === ChangeType.RESTORE && (
        <FeedItemIconComponent icon={ArrowCircleLeftIcon} />
      )}
      {item.change_type === ChangeType.UPDATE && <FeedItemIconComponent icon={PencilIcon} />}
    </div>
  );
}

function FeedItemSideContainer({ children }: PropsWithChildren<{}>) {
  return <div className="relative flex items-center">{children}</div>;
}

function FeedItemDivider() {
  return (
    <div className="block absolute top-full left-1/2 transform -translate-x-1/2 h-screen w-0.5 bg-stone-100" />
  );
}

function FeedItemMainContainer({ children }: PropsWithChildren<{}>) {
  return <div className="my-auto">{children}</div>;
}

function FeedItemAvatar({ item }: ActivityFeedItemProps) {
  if (!item.createdByUser) {
    return null;
  }

  return (
    <div className="relative h-full mx-4">
      <ProfileAvatar user={item.createdByUser} />
      <FeedItemDivider />
    </div>
  );
}

function ActivityFeedItemLink({ item, children }: PropsWithChildren<ActivityFeedItemProps>) {
  return (
    <Link
      to={slideOverRoute(RouteKey.Task, { taskId: item.task_id })}
      className="hover:underline text-black"
    >
      {children}
    </Link>
  );
}

export function ActivityFeedItem({ item }: ActivityFeedItemProps) {
  return (
    <FeedItemContainer>
      <FeedItemSideContainer>
        <FeedItemChangeIcon item={item} />
        <FeedItemAvatar item={item} />
      </FeedItemSideContainer>
      <FeedItemMainContainer>
        <p>
          <span className="text-black">
            {item.createdByUser ? fullName(item.createdByUser) : "Unknown"}
          </span>
          &nbsp;
          <span className="text-iron">
            {item.change_type === ChangeType.CREATE && <>created</>}
            {item.change_type === ChangeType.DELETE && <>deleted</>}
            {item.change_type === ChangeType.RESTORE && <>restored</>}
            {item.change_type === ChangeType.UPDATE && <>updated</>}
            &nbsp;
            <ActivityFeedItemLink item={item}>{item.changedTask.name}</ActivityFeedItemLink>
          </span>
          &nbsp;
          <span className="text-stone">
            <FormattedRelativeDateTime value={new Date(item.created_at)} />
          </span>
        </p>
      </FeedItemMainContainer>
    </FeedItemContainer>
  );
}
