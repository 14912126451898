import { Request } from "@dh-critical-path/api-types";
import { keepTruthy } from "@dh-critical-path/shared";
import { useCallback } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
} from "../../../components/form/SlideOver";
import { useConfirmModalPromise } from "../../../hooks/useModal";
import { UpdateTasksByFilterFields, useTasksMassUpdateForm } from "../../../hooks/useTask";
import { useTasksQuery } from "../../../queries";
import { useActionCompleted } from "../../../utils/hooks";
import { pluralize } from "../../../utils/text";
import { TasksMassUpdateSidebarForm } from "../../Projects/components/forms/TasksMassUpdateForm";
import { useSlideOverNavigate } from "../../SlideOvers";

export type TasksMassUpdateSlideOverProps = {
  filter: string;
};

type TasksMassUpdateSlideOverFormProps = {
  filter: Request.Tasks.GetTasks;
};

export function useTasksMassUpdateConfirmationDialog(
  filter: Request.Tasks.GetTasks,
  methods: UseFormReturn<UpdateTasksByFilterFields>,
  handleMassUpdate: (fields: UpdateTasksByFilterFields) => Promise<any>
) {
  const { modal, confirmAction } = useConfirmModalPromise();

  const data = methods.watch("data");

  const query = useTasksQuery({ ...filter, limit: 1 });

  const dirtyFieldNames = keepTruthy([
    data.task_status_id ? "Status" : null,
    data.department_id ? "Department" : null,
    data.assignee_id ? "Assigned To" : null,
    data.supporter_ids?.length ? "Supporter" : null,
    data.tag_ids?.length ? "Tags" : null,
    //
  ]);

  const count = query.data?.meta.total ?? 0;

  const countOfDirtyFields = dirtyFieldNames.length;

  const handleSubmitWithConfirmation = useCallback(
    async (...args: Parameters<typeof handleMassUpdate>) => {
      const confirmed = await confirmAction({
        description: `Do you really want to overwrite ${pluralize(
          dirtyFieldNames.length,
          "field",
          "fields"
        )} for ${pluralize(count, `1 task`, `${count} tasks`)}?`,
      });

      if (confirmed) {
        await handleMassUpdate(...args);
      }
    },
    [handleMassUpdate, confirmAction, dirtyFieldNames, count]
  );

  return { modal, handleSubmitWithConfirmation, count, countOfDirtyFields };
}

function TasksMassUpdateSlideOverForm({ filter }: TasksMassUpdateSlideOverFormProps) {
  const navigate = useSlideOverNavigate();

  const { methods, handleMassUpdate } = useTasksMassUpdateForm({ filter });

  const handleMassUpdateAndRedirect = useActionCompleted(handleMassUpdate, () => navigate());

  const {
    modal: confirmModal,
    handleSubmitWithConfirmation,
    count,
    countOfDirtyFields,
  } = useTasksMassUpdateConfirmationDialog(filter, methods, handleMassUpdateAndRedirect);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={methods.handleSubmit(handleSubmitWithConfirmation)}>
        <SlideOverFormHeader
          submitText={<FormattedMessage id="modal.update" />}
          submitDisabled={countOfDirtyFields === 0}
        >
          <FormattedMessage id="tasks.modal.mass-update" values={{ count: count }} />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <TasksMassUpdateSidebarForm />
          </SlideOverSidebar>
          {confirmModal}
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TaskMassUpdateSlideOver({ filter }: TasksMassUpdateSlideOverProps) {
  return (
    <TasksMassUpdateSlideOverForm filter={JSON.parse(Buffer.from(filter, "base64").toString())} />
  );
}
