import classnames from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";
import { styled } from "../../utils/styled";

interface PageSectionHeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  buttons?: React.ReactNode;
  isDanger?: boolean;
}

export function PageSectionHeaderButtonGroup({ children }: PropsWithChildren<{}>) {
  return (
    <div className="flex w-full sm:w-auto sm:items-end flex-col sm:flex-row flex-wrap max-w-full space-y-4 sm:space-y-0 sm:space-x-4">
      {children}
    </div>
  );
}

const PageSectionHeader = styled<HTMLHeadingElement, PageSectionHeaderProps>(
  ({ children, className, buttons, isDanger, ...props }) => (
    <div
      className={classnames("flex flex-col xs:flex-row flex-wrap justify-between -m-2", className)}
    >
      <h3
        {...props}
        className={classnames("font-medium text-xl leading-tight md:leading-normal p-2", {
          "text-red-500": isDanger,
        })}
      >
        {children}
      </h3>
      {buttons && <div className="sm:ml-auto flex p-2 max-w-full space-x-4">{buttons}</div>}
    </div>
  )
);

export default PageSectionHeader;
