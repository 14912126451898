import { TasksStats, TasksStatsEntity } from "@dh-critical-path/api-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { Hash } from "../../../components/common";
import { PageContainer } from "../../../components/page";
import { Panel } from "../../../components/panel";
import { ProfileAvatarsStacked } from "../../../components/profile";
import { Status, backgroundClassName, textClassName } from "../../../enums";
import { isDepartment, isProject, tasksByStatusCount } from "../../../utils/misc";
import { ProjectDates } from "../../Projects/components/ProjectDates";

export type TasksStatsEntityLink<T extends TasksStatsEntity> = (entity: T) => string;

type TasksStatsCardsProps<T extends TasksStatsEntity> = {
  items: TasksStats<T>[];
  entityLink: TasksStatsEntityLink<T>;
};

type TasksStatsCardProps<T extends TasksStatsEntity> = {
  item: TasksStats<T>;
  entityLink: TasksStatsEntityLink<T>;
};

type TasksStatsComponentProps<T extends TasksStatsEntity> = {
  item: TasksStats<T>;
};

function tasksCountByStatus<T extends TasksStatsEntity>(
  stats: TasksStats<T>,
  status: Status
): number {
  return status === Status.completed
    ? stats.tasks_status_todo + stats.tasks_status_in_progress + stats.tasks_status_completed
    : stats.tasks_total;
}

function incompleteTasksCount<T extends TasksStatsEntity>(stats: TasksStats<T>): number {
  return stats.tasks_status_todo + stats.tasks_status_in_progress;
}

function tasksPercentageByStatus<T extends TasksStatsEntity>(
  stats: TasksStats<T>,
  status: Status
): number {
  const total = tasksCountByStatus(stats, status);

  return total ? Math.round((100 * tasksByStatusCount(stats, status)) / total) : 0;
}

function TasksStatsCardHeader<T extends TasksStatsEntity>({
  item,
  entityLink,
}: TasksStatsCardProps<T>) {
  return (
    <div className="flex flex-col-reverse sm:flex-row items-start justify-between w-full mb-1">
      <NavLink to={entityLink(item.entity)} className="absolute inset-0"></NavLink>
      <div className="flex-1 mt-2 sm:mt-0 sm:pr-4 md:pr-9">
        <div className="font-bold leading-none flex flex-wrap align-baseline whitespace-pre-wrap">
          {item.entity.name} <Hash className="py-px">{item.entity.id}</Hash>
        </div>
      </div>
    </div>
  );
}

function TasksStatsTopAssignees<T extends TasksStatsEntity>({ item }: TasksStatsComponentProps<T>) {
  if (!item.assignees_top.length) {
    return null;
  }

  return (
    <ProfileAvatarsStacked
      size="small"
      users={item.assignees_top}
      total={item.assignees_total}
      className="mb-2 empty-hidden"
    />
  );
}

function TasksStatsDates<T extends TasksStatsEntity>({ item }: TasksStatsComponentProps<T>) {
  if (isProject(item.entity)) {
    return <ProjectDates project={item.entity} />;
  }

  return null;
}

function TasksStatsProgressBarRemainingTodoTasks<T extends TasksStatsEntity>({
  item,
}: TasksStatsComponentProps<T>) {
  const tasksToCompleteCount = incompleteTasksCount(item);

  if (!tasksToCompleteCount) {
    return null;
  }

  return (
    <p className="text-iron sm:text-right leading-none mb-2">
      <span className="font-bold text-black">{tasksToCompleteCount}</span>
      &nbsp;
      <span className="text-sm">
        <FormattedMessage id="tasks-stats.tasks-to-complete" />
      </span>
    </p>
  );
}

function TasksStatsProgressBarNoTasksAvailable() {
  return (
    <div className="absolute -bottom-1 inset-x-0">
      <p className="text-iron sm:text-right leading-none">
        <span className="text-sm">
          <FormattedMessage id="tasks-stats.no-tasks-available" />
        </span>
      </p>
    </div>
  );
}

function TasksStatsProgressBarOverallStatus<T extends TasksStatsEntity>({
  item,
}: TasksStatsComponentProps<T>) {
  const isInProgress = incompleteTasksCount(item) > 0;
  const isOverdue = item.tasks_overdue > 0;
  const completedPercentage = tasksPercentageByStatus(item, Status.completed);
  const overduePercentage = tasksPercentageByStatus(item, Status.overdue);

  const completedText = (
    <span className={textClassName(Status.completed)}>
      <FormattedMessage
        id="tasks-stats.progress.completed"
        values={{ percentage: completedPercentage }}
      />
    </span>
  );

  const overdueText = (
    <span className={classNames("ml-auto", textClassName(Status.overdue))}>
      <FormattedMessage
        id="tasks-stats.status.overdue"
        values={{ percentage: overduePercentage }}
      />
    </span>
  );

  const onTrackText = (
    <span className={classNames("ml-auto", textClassName(Status.completed))}>
      <FormattedMessage id="tasks-stats.status.on-track" />
    </span>
  );

  return (
    <div className="absolute -bottom-1 inset-x-0 group-hover:opacity-0 transition duration-300">
      <div className="flex text-sm">
        {completedText}
        {isInProgress && (isOverdue ? overdueText : onTrackText)}
      </div>
    </div>
  );
}

function TasksStatsProgressBarStatusIndicator<T extends TasksStatsEntity>({
  item,
  status,
}: TasksStatsComponentProps<T> & { status: Status }) {
  const count = tasksByStatusCount(item, status);
  const percentage = tasksPercentageByStatus(item, status);

  if (!count) {
    return null;
  }

  return (
    <div
      className={classNames(
        "relative last:flex-1 first:rounded-tl first:rounded-bl last:rounded-br last:rounded-tr",
        backgroundClassName(status)
      )}
      style={{ width: `${percentage}%`, minWidth: "25px" }}
    >
      <span
        className={classNames(
          "absolute top-full left-0 pt-1 text-sm opacity-0-fixed group-hover:opacity-100 transition duration-300",
          textClassName(status)
        )}
      >
        {count}
      </span>
    </div>
  );
}

function TasksStatsProgressBarIndicator<T extends TasksStatsEntity>({
  item,
}: TasksStatsComponentProps<T>) {
  return (
    <div className="w-full flex h-2">
      <TasksStatsProgressBarStatusIndicator item={item} status={Status.completed} />
      <TasksStatsProgressBarStatusIndicator item={item} status={Status.in_progress} />
      <TasksStatsProgressBarStatusIndicator item={item} status={Status.todo} />
      {/* <TasksStatsProgressBarStatusIndicator item={item} status={Status.backlog} /> */}
      {/* <TasksStatsProgressBarStatusIndicator item={item} status={Status.on_hold} /> */}
      {/* <TasksStatsProgressBarStatusIndicator item={item} status={Status.canceled} /> */}
    </div>
  );
}

function TasksStatsProgressBar<T extends TasksStatsEntity>({ item }: TasksStatsComponentProps<T>) {
  const showRemainingTasks = isDepartment(item.entity);

  return (
    <div
      className={classNames("relative pb-5 group", {
        "mt-2": showRemainingTasks,
        "mt-4": !showRemainingTasks,
      })}
    >
      {item.tasks_total ? (
        <>
          {showRemainingTasks && <TasksStatsProgressBarRemainingTodoTasks item={item} />}
          <TasksStatsProgressBarOverallStatus item={item} />
          <TasksStatsProgressBarIndicator item={item} />
        </>
      ) : (
        <TasksStatsProgressBarNoTasksAvailable />
      )}
    </div>
  );
}

function TasksStatsCard<T extends TasksStatsEntity>({ item, entityLink }: TasksStatsCardProps<T>) {
  return (
    <Panel className="relative flex flex-col">
      <TasksStatsCardHeader item={item} entityLink={entityLink} />
      <TasksStatsTopAssignees item={item} />
      <div className="mt-auto">
        <TasksStatsDates item={item} />
        <TasksStatsProgressBar item={item} />
      </div>
    </Panel>
  );
}

export function TasksStatsCards<T extends TasksStatsEntity>({
  items,
  entityLink,
}: TasksStatsCardsProps<T>) {
  return (
    <PageContainer type="grid">
      {items.map((item) => (
        <TasksStatsCard key={item.entity.id} item={item} entityLink={entityLink} />
      ))}
    </PageContainer>
  );
}
