import { useFormContext } from "react-hook-form";
import { FilterResetButton } from "../../../../components/button/FilterResetButton";
import {
  CollapsibleFilter,
  CollapsibleFilterActions,
  CollapsibleFilterFooter,
  CollapsibleFilterProps,
  CollapsibleFilterSwitchGroup,
} from "../../../../components/common/CollapsibleFilter";
import { FormPanel } from "../../../../components/form/FormPanel";
import {
  ControlledDebouncedInput,
  ControlledSelect,
  ControlledSwitch,
  ControlledTagsInput,
} from "../../../../components/form/controlled";
import { Status, backgroundClassName } from "../../../../enums";
import { AdminOnly } from "../../../../hooks/useAuth";
import { TasksFilterFormFields } from "../../../../hooks/useTasksFilter";
import {
  useDepartmentsForFilterQuery,
  useTaskAssigneesForFilterQuery,
  useTaskStatusesForFilterQuery,
  useTasksForFilterQuery,
} from "../../../../queries";
import { useTagsForFilterQuery } from "../../../../queries/tags";
import { assigneesToOptions } from "../../../../utils/misc";

export function FormResetButton() {
  const form = useFormContext();

  return <FilterResetButton onClick={() => form.reset()} />;
}

function DepartmentsField() {
  const { watch } = useFormContext<TasksFilterFormFields>();

  const projectIds = watch("project_ids");

  const { data } = useDepartmentsForFilterQuery({
    project_id: projectIds[0],
  });

  return (
    <ControlledSelect
      name="department_ids"
      label="Responsible Department(s)"
      options={[{ id: -1, name: "None" }, ...(data ?? [])]}
      isMulti
    />
  );
}

function AssignedToField() {
  const { watch } = useFormContext<TasksFilterFormFields>();

  const projectIds = watch("project_ids");
  const departmentIds = watch("department_ids");

  const { data } = useTaskAssigneesForFilterQuery({
    project_id: projectIds[0],
    department_ids: departmentIds,
  });

  return (
    <ControlledSelect
      name="assignee_ids"
      label="Assigned To"
      options={[
        {
          id: -1,
          name: "Nobody",
        },
        ...assigneesToOptions(data ?? []),
      ]}
      isMulti
    />
  );
}

function SupporterField() {
  const { watch } = useFormContext<TasksFilterFormFields>();

  const projectIds = watch("project_ids");

  const { data } = useTaskAssigneesForFilterQuery({
    project_id: projectIds[0],
  });

  return (
    <ControlledSelect
      name="supporter_ids"
      label="Supporter"
      options={[
        {
          id: -1,
          name: "Nobody",
        },
        ...assigneesToOptions(data ?? []),
      ]}
      isMulti
    />
  );
}

function DependencyForField() {
  const { watch } = useFormContext<TasksFilterFormFields>();

  const projectIds = watch("project_ids");

  const { data } = useTasksForFilterQuery({
    project_ids: projectIds,
  });

  return (
    <ControlledSelect name="dependency_for_id" label="Dependency for" options={data} isClearable />
  );
}

function StatusField() {
  const { data } = useTaskStatusesForFilterQuery();

  return (
    <ControlledSelect
      name="task_status_ids"
      label="Statuses"
      options={data?.map((status) => ({
        ...status,
        color: backgroundClassName(status.status_type as Status),
      }))}
      isMulti
    />
  );
}

function NameField() {
  return <ControlledDebouncedInput name="name" label="Name" debounce={500} />;
}

function TagsField() {
  const { data } = useTagsForFilterQuery({});

  return <ControlledTagsInput name="tags" tags={data ?? []} variant="inline" />;
}

function TasksFilterFormExpandedFields() {
  return (
    <>
      <div className="col-span-6 md:col-span-2">
        <SupporterField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <DependencyForField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <StatusField />
      </div>
      <AdminOnly
        render={() => (
          <div className="col-span-6">
            <TagsField />
          </div>
        )}
      />
    </>
  );
}

function TasksFilterFormCollapsedFields() {
  return (
    <>
      <div className="col-span-6 md:col-span-2">
        <NameField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <DepartmentsField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <AssignedToField />
      </div>
    </>
  );
}

function TasksFilterFormFooter({ open, activeFiltersCount }: CollapsibleFilterProps) {
  return (
    <CollapsibleFilterFooter>
      <CollapsibleFilterSwitchGroup>
        <ControlledSwitch name="is_milestone" label="Milestone" className="my-1 mx-3" />
        <ControlledSwitch name="is_dates_frozen" label="Fixed dates" className="my-1 mx-3" />
        <ControlledSwitch name="is_overdue" label="Overdue" className="my-1 mx-3" />
        <ControlledSwitch
          name="without_dependencies"
          label="Without Dependencies"
          className="my-1 mx-3"
        />
        <ControlledSwitch name="is_removed" label="Removed" className="my-1 mx-3" />
      </CollapsibleFilterSwitchGroup>
      <CollapsibleFilterActions open={open} activeFiltersCount={activeFiltersCount}>
        <FormResetButton />
      </CollapsibleFilterActions>
    </CollapsibleFilterFooter>
  );
}

export function TasksFilterForm({ open, activeFiltersCount }: CollapsibleFilterProps) {
  return (
    <CollapsibleFilter defaultOpen={open}>
      {({ open }) => (
        <FormPanel>
          <TasksFilterFormCollapsedFields />
          {open && <TasksFilterFormExpandedFields />}
          <TasksFilterFormFooter open={open} activeFiltersCount={activeFiltersCount} />
        </FormPanel>
      )}
    </CollapsibleFilter>
  );
}
