import { TemplateTitle } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useTemplateTitleUpdateForm } from "../../hooks/useTemplateTitle";
import { useTemplateTitleQuery } from "../../queries";
import { useSlideOverNavigate } from "../SlideOvers";
import { TemplateTitleForm } from "./components/forms/TemplateTitleForm";

type TemplateTitleEditSlideOverProps = {
  templateTitleId: number;
};

type TemplateTitleEditFormProps = {
  title: TemplateTitle;
};

function TemplateTitleEditForm({ title }: TemplateTitleEditFormProps) {
  const slideOverNavigate = useSlideOverNavigate();

  const { methods, handleSubmit } = useTemplateTitleUpdateForm(title, () => {
    slideOverNavigate();
  });

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="titles.modal.heading.edit" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <TemplateTitleForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TemplateTitleEditSlideOver({ templateTitleId }: TemplateTitleEditSlideOverProps) {
  const result = useTemplateTitleQuery(templateTitleId);

  return (
    <SlideOverWithLoading
      result={result}
      render={(title) => <TemplateTitleEditForm title={title} />}
    />
  );
}
