import { TemplateProject } from "@dh-critical-path/api-types";
import { ControlledSelect } from "../../../components/form/controlled";
import { PageSectionHeaderButtonGroup } from "../../../components/page/PageSectionHeader";
import { useProjectsForFilterQuery } from "../../../queries";

type TemplateProjectComparisonActionsProps = {
  project: TemplateProject;
};

function CompareToProjectAction({ project }: TemplateProjectComparisonActionsProps) {
  const { data } = useProjectsForFilterQuery({
    template_project_id: project.id,
  });

  return (
    <ControlledSelect
      name="project_id"
      size="small"
      placeholder="Compare to Project"
      options={data}
      className="w-screen sm:w-96"
    />
  );
}

export function TemplateProjectComparisonActions({
  project,
}: TemplateProjectComparisonActionsProps) {
  return (
    <PageSectionHeaderButtonGroup>
      <CompareToProjectAction project={project} />
    </PageSectionHeaderButtonGroup>
  );
}
