import { Request } from "@dh-critical-path/api-types";
import { useQuery } from "react-query";
import {
  getDepartment,
  getDepartmentMembers,
  getDepartmentsForFilter,
  getDepartmentsTasksStats,
} from "../api";

export const departmentsKeys = {
  department: (id: number) => ["department", id],
  departmentMembers: (id: number) => ["department:members", id],
  departmentsForFilter: (params: Request.Departments.GetDepartmentsForFilter) => [
    "departments:filter",
    params,
  ],
  allDepartments: () => ["departments"],
  departmentsTasksStats: (...args: Parameters<typeof getDepartmentsTasksStats>) => [
    "departments:stats",
    ...args,
  ],
};

export function useDepartmentQuery(id: number) {
  return useQuery(departmentsKeys.department(id), () => {
    return getDepartment(id);
  });
}

export function useDepartmentMembersQuery(id: number) {
  return useQuery(departmentsKeys.departmentMembers(id), () => {
    return getDepartmentMembers(id);
  });
}

export function useDepartmentsForFilterQuery(params: Request.Departments.GetDepartmentsForFilter) {
  return useQuery(departmentsKeys.departmentsForFilter(params), () => {
    return getDepartmentsForFilter(params);
  });
}

export function useDepartmentsTasksStatsQuery(
  ...args: Parameters<typeof getDepartmentsTasksStats>
) {
  return useQuery(departmentsKeys.departmentsTasksStats(...args), () => {
    return getDepartmentsTasksStats(...args);
  });
}
