import { Project } from "@dh-critical-path/api-types";
import classNames from "classnames";
import React from "react";
import { FormattedDate } from "react-intl";
import { Panel } from "../../../components/panel";
import { processTypeText } from "../../../utils/text";

type ProjectDatesPanelProps = {
  project: Project;
  showProjectedDate?: boolean;
};

type ProjectDatesProps = {
  project: Project;
  showProjectedDate?: boolean;
};

type ProjectDatesRowProps = {
  date?: Date;
  dateFormatted?: string;
  title: React.ReactNode;
  isOverdue?: boolean;
  isOnTrack?: boolean;
  showDelay?: boolean;
};

export function ProjectDatesRow({
  date,
  dateFormatted,
  title,
  isOverdue,
  isOnTrack,
  showDelay,
}: ProjectDatesRowProps) {
  return (
    <div>
      <p className="text-iron-800 leading-tight mb-1">{title}</p>
      <div>
        <span
          className={classNames("leading-tight font-medium text-2xl pr-2", {
            "text-red-500": isOverdue,
            "text-green-500": isOnTrack,
          })}
        >
          {dateFormatted ? (
            dateFormatted
          ) : date ? (
            <FormattedDate value={date} year="numeric" month="short" day="2-digit" />
          ) : (
            "-"
          )}
        </span>
      </div>
    </div>
  );
}

export function ProjectDates({ project, showProjectedDate = false }: ProjectDatesProps) {
  return (
    <div className="space-y-3 mt-4 last:mt-0">
      <ProjectDatesRow
        date={project.due_date ? new Date(project.due_date) : undefined}
        dateFormatted={project.approximate_completion_date}
        title={processTypeText(
          project,
          "Opening Date",
          "Closure Date",
          "Re-Opening Date",
          "Transition Date"
        )}
      />
    </div>
  );
}

export function ProjectDatesPanel({ project, showProjectedDate = false }: ProjectDatesPanelProps) {
  return (
    <Panel className="self-start">
      {/* <div className="flex justify-end w-full -mb-4">
        {isProjectOverdue(project) ? (
          <Badge size="small" color="red" rounded>
            <FormattedMessage id="project-dates.overdue" />
          </Badge>
        ) : (
          <Badge size="small" color="green" rounded>
            <FormattedMessage id="project-dates.on-track" />
          </Badge>
        )}
      </div> */}
      <div>
        <ProjectDates project={project} showProjectedDate={showProjectedDate} />
      </div>
    </Panel>
  );
}
