import { SsoUserRole } from "@dh-critical-path/shared";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Loading } from "../page/PageFallback";

const RestrictedRoute: React.VFC<{
  element: React.ReactElement;
  adminOnly?: boolean;
  authOnly?: boolean;
  notAuthOnly?: boolean;
}> = ({ element, notAuthOnly, authOnly, adminOnly }) => {
  const { isLoading, user } = useAuth();

  const isLoggedInAsAdmin = user?.sso_role === SsoUserRole.ADMINISTRATOR;

  if (isLoading) {
    return <Loading />;
  } else if (adminOnly) {
    return isLoggedInAsAdmin ? element : <Navigate to="/projects" replace />;
  } else if (authOnly) {
    return user ? element : <Navigate to="/auth/login" replace />;
  } else if (notAuthOnly) {
    return user ? <Navigate to="/projects" replace /> : element;
  } else {
    return element;
  }
};

export default RestrictedRoute;
