import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
} from "../../components/form/SlideOver";
import { useDepartmentCreateForm } from "../../hooks/useDepartment";
import { useNavigateToDepartment } from "../utils/navigation";
import { DepartmentForm } from "./components/forms/DepartmentForm";

type CreateDepartmentSlideOverProps = {
  projectId?: number;
};

type DepartmentCreateFormProps = {
  projectId?: number;
};

export function DepartmentCreateForm({ projectId }: DepartmentCreateFormProps) {
  const navigate = useNavigateToDepartment();

  const { methods, handleSubmit } = useDepartmentCreateForm(projectId, navigate);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="departments.modal.heading.create" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <DepartmentForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function DepartmentCreateSlideOver({ projectId }: CreateDepartmentSlideOverProps) {
  return <DepartmentCreateForm projectId={projectId} />;
}
