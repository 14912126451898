import { User } from "@dh-critical-path/api-types";
import { OrderDirection, UsersOrderColumn } from "@dh-critical-path/shared";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Badge } from "../../../components/common";
import { ProfileAvatar } from "../../../components/profile";
import {
  Table,
  TableCell,
  TableDivider,
  TableHeadCell,
  TableHeadCellSortable,
  TableHeadRow,
  TableRow,
} from "../../../components/table";
import { userRoleBadgeColor } from "../../../enums";
import { UsersFilterFormFields } from "../../../hooks/useUsersFilter";
import { fullName } from "../../../utils/text";
import { RouteKey, slideOverRoute } from "../../SlideOvers";

type UsersTableProps = {
  users: User[];
};

type UsersTableRowProps = {
  user: User;
};

function UsersTableHeadRow() {
  const { watch, setValue } = useFormContext<UsersFilterFormFields>();
  const orderColumn = watch("order_column");
  const orderDirection = watch("order_direction");

  function handleSort(orderColumn: UsersOrderColumn, orderDirection: OrderDirection) {
    setValue("order_column", orderColumn);
    setValue("order_direction", orderDirection);
  }

  return (
    <TableHeadRow>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="first_name"
        className="uppercase text-left w-4/12"
      >
        <FormattedMessage id="table.users.name" />
      </TableHeadCellSortable>
      <TableHeadCell className="uppercase text-left w-3/12">
        <FormattedMessage id="table.users.role" />
      </TableHeadCell>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="assigned_to_tasks_count"
        className="uppercase text-left w-1/12"
      >
        <FormattedMessage id="table.users.assigned-to-tasks-count" />
      </TableHeadCellSortable>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="supporter_for_tasks_count"
        className="uppercase text-left w-1/12"
      >
        <FormattedMessage id="table.users.supporter-for-tasks-count" />
      </TableHeadCellSortable>
      <TableHeadCellSortable
        onSort={handleSort}
        orderColumn={orderColumn}
        orderDirection={orderDirection}
        columnName="last_logged_in_at"
        className="uppercase text-left w-3/12"
      >
        <FormattedMessage id="table.users.last-login-date" />
      </TableHeadCellSortable>
    </TableHeadRow>
  );
}

function UsersTableEmpty() {
  return (
    <tr>
      <td colSpan={3} className="text-base font-bold text-center py-6 bg-white">
        <FormattedMessage id="table.users.empty" />
      </td>
    </tr>
  );
}

function UsersTableRow({ user }: UsersTableRowProps) {
  return (
    <TableRow>
      <TableCell className="p-3">
        <div className="flex flex-col items-center sm:flex-row space-y-2 sm:space-y-0">
          <ProfileAvatar user={user} className="mr-3" />
          <div>
            <Link
              to={slideOverRoute(RouteKey.User, { userId: user.id })}
              className="block font-bold leading-none flex-1 hover:text-violet transition"
            >
              {fullName(user)}
            </Link>
            <div className="text-sm text-iron">{user.email}</div>
          </div>
        </div>
      </TableCell>
      <TableCell className="p-3 align-middle">
        {user.sso_role ? (
          <Badge size="small" color={userRoleBadgeColor(user.sso_role)} rounded>
            {user.sso_role}
          </Badge>
        ) : (
          " - "
        )}
      </TableCell>
      <TableCell className="p-3">{user?.meta?.assigned_to_tasks_count ?? " - "}</TableCell>
      <TableCell className="p-3">{user?.meta?.supporter_for_tasks_count ?? " - "}</TableCell>
      <TableCell className="p-3">
        {user.last_logged_in_at ? (
          <FormattedDate
            value={user.last_logged_in_at}
            year="numeric"
            month="short"
            day="2-digit"
          />
        ) : (
          " - "
        )}
      </TableCell>
    </TableRow>
  );
}

export function UsersTable({ users }: UsersTableProps) {
  return (
    <Table>
      <UsersTableHeadRow />
      {users.map((user, index) => (
        <Fragment key={user.id}>
          <UsersTableRow user={user} />
          {index + 1 !== users.length && <TableDivider />}
        </Fragment>
      ))}
      {users.length === 0 && <UsersTableEmpty />}
    </Table>
  );
}
