import { Request, Response } from "@dh-critical-path/api-types";
import { AttachmentOwner } from "@dh-critical-path/shared";
import axios from "axios";

function getAttachments(endpoint: string) {
  return axios.get<Response.Attachments.GetAttachments>(endpoint).then(({ data }) =>
    data.map((attachment) => ({
      ...attachment,
      download_url: `${process.env.REACT_APP_BACKEND_URL}${attachment.download_url}`,
    }))
  );
}

export function taskAttachments(taskId: number) {
  return getAttachments(`/api/attachments/list/by-task/${taskId}`);
}

export function projectAttachments(projectId: number) {
  return getAttachments(`/api/attachments/list/by-project/${projectId}`);
}

export function templateTaskAttachments(taskId: number) {
  return getAttachments(`/api/attachments/list/by-template-task/${taskId}`);
}

export function templateProjectAttachments(projectId: number) {
  return getAttachments(`/api/attachments/list/by-template-project/${projectId}`);
}

export function deleteAttachment(attachmentId: number, owner: AttachmentOwner) {
  return axios.delete<void>(`/api/attachments/${owner}/${attachmentId}`);
}

export function uploadAttachments(data: Request.Attachments.UploadAttachments) {
  const formData = new FormData();

  if (data.silent) {
    formData.append("silent", "1");
  }

  if (data.task_id) {
    formData.append("task_id", String(data.task_id));
  }

  if (data.project_id) {
    formData.append("project_id", String(data.project_id));
  }

  if (data.template_task_id) {
    formData.append("template_task_id", String(data.template_task_id));
  }

  if (data.template_project_id) {
    formData.append("template_project_id", String(data.template_project_id));
  }

  data.attachments.forEach((file) => {
    formData.append("attachments[]", file);
  });

  return axios.post<Response.Attachments.GetAttachments>("/api/attachments", formData);
}
