import { HTMLAttributes } from "react";

const JiraIcon: React.VFC<HTMLAttributes<SVGElement>> = ({ className }) => (
  <svg width="16" height="18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M15.808 8.537 8.69 1.419 8 .729 2.642 6.087l-2.45 2.45a.655.655 0 0 0 0 .926l4.895 4.895L8 17.271l5.358-5.358.083-.083 2.367-2.367a.655.655 0 0 0 0-.926ZM8 11.445 5.554 9 8 6.554 10.446 9 8 11.445Z"
      fill="#2684FF"
    />
  </svg>
);

export default JiraIcon;
