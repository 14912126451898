import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { FormElementProps, Label, ValidationText } from ".";
import { Editor, EditorToolbarBase } from "./markdown-editor";

export interface MarkdownProps extends FormElementProps {
  value: string;
  onChange: (value: string) => void;
  id?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  [key: string]: any;
}

function EditorWrapper({
  invalid,
  children,
}: PropsWithChildren<Pick<FormElementProps, "invalid">>) {
  return (
    <div
      className={classNames("shadow-sm rounded-md border", {
        "border-red-300": invalid,
        "border-stone-400": !invalid,
      })}
    >
      {children}
    </div>
  );
}

function Markdown({
  id,
  disabled,
  required,
  label,
  invalid,
  invalidText,
  placeholder,
  value,
  onChange,
}: MarkdownProps) {
  return (
    <div>
      <Label htmlFor={id} disabled={disabled} required={required} className="mb-1">
        {label}
      </Label>
      <EditorWrapper invalid={invalid}>
        <Editor
          toolbar={<EditorToolbarBase />}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={disabled}
        />
      </EditorWrapper>
      {invalid && invalidText && <ValidationText className="mt-1">{invalidText}</ValidationText>}
    </div>
  );
}

export default Markdown;
