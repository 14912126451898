import { Response } from "@dh-critical-path/api-types";

export function getNextPageParam<T extends { meta: Response.Pagination }>(
  lastPage: T,
  allPages: T[]
) {
  return lastPage.meta.next_page_url ? lastPage.meta.current_page + 1 : null;
}

export function getPreviousPageParam<T extends { meta: Response.Pagination }>(
  firstPage: T,
  allPages: T[]
) {
  return firstPage.meta.previous_page_url ? firstPage.meta.current_page - 1 : null;
}
