import { Attachment } from "@dh-critical-path/api-types";
import { AttachmentOwner } from "@dh-critical-path/shared";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteAttachment,
  projectAttachments,
  taskAttachments,
  templateProjectAttachments,
  templateTaskAttachments,
  uploadAttachments,
} from "../api";
import { attachmentsKeys } from "../queries";

export function useTaskAttachments(id?: number) {
  return useAttachments(AttachmentOwner.TASK, id);
}

export function useProjectAttachments(id?: number) {
  return useAttachments(AttachmentOwner.PROJECT, id);
}

export function useTemplateProjectAttachments(id?: number) {
  return useAttachments(AttachmentOwner.TEMPLATE_PROJECT, id);
}

export function useTemplateTaskAttachments(id?: number) {
  return useAttachments(AttachmentOwner.TEMPLATE_TASK, id);
}

function attachmentsQueryKey(type: AttachmentOwner, id?: number) {
  if (type === AttachmentOwner.PROJECT) {
    return attachmentsKeys.projectAttachments(id);
  } else if (type === AttachmentOwner.TASK) {
    return attachmentsKeys.taskAttachments(id);
  } else if (type === AttachmentOwner.TEMPLATE_PROJECT) {
    return attachmentsKeys.templateProjectAttachments(id);
  } else {
    return attachmentsKeys.templateTaskAttachments(id);
  }
}

function attachmentsApi(type: AttachmentOwner) {
  if (type === AttachmentOwner.TASK) {
    return taskAttachments;
  } else if (type === AttachmentOwner.PROJECT) {
    return projectAttachments;
  } else if (type === AttachmentOwner.TEMPLATE_TASK) {
    return templateTaskAttachments;
  } else if (type === AttachmentOwner.TEMPLATE_PROJECT) {
    return templateProjectAttachments;
  } else {
    throw new Error(`Attachments not supported for ${type}`);
  }
}

function attachmentsUploadPayload(type: AttachmentOwner, id: number | undefined, files: File[]) {
  if (type === AttachmentOwner.TASK) {
    return { task_id: id, attachments: files };
  } else if (type === AttachmentOwner.PROJECT) {
    return { project_id: id, attachments: files };
  } else if (type === AttachmentOwner.TEMPLATE_TASK) {
    return { template_task_id: id, attachments: files };
  } else if (type === AttachmentOwner.TEMPLATE_PROJECT) {
    return { template_project_id: id, attachments: files };
  } else {
    throw new Error(`Attachments not supported for ${type}`);
  }
}

export function useAttachments(type: AttachmentOwner, id?: number) {
  const queryClient = useQueryClient();
  const queryKey = attachmentsQueryKey(type, id);
  const query = useQuery(queryKey, () => attachmentsApi(type)(id!), { enabled: Boolean(id) });
  const uploadMutation = useMutation(uploadAttachments);
  const deleteMutation = useMutation((id: number) => deleteAttachment(id, type));

  const attachments = query.data || [];
  const isLoading = query.isLoading;
  const isUploading = uploadMutation.isLoading;

  function appendAttachmentsToQueryData(uploadedAttachments: Attachment[]) {
    queryClient.setQueryData(queryKey, [
      ...attachments,
      ...uploadedAttachments.map((attachment) => ({
        ...attachment,
        download_url: `${process.env.REACT_APP_BACKEND_URL}${attachment.download_url}`,
      })),
    ]);
  }

  function deleteAttachmentFromQueryData({ id }: Attachment) {
    queryClient.setQueryData(
      queryKey,
      attachments.filter((attachment) => attachment.id !== id)
    );
  }

  function handleUpload(files: File[], silent?: boolean) {
    const payload = attachmentsUploadPayload(type, id, files);

    uploadMutation.mutate(
      { ...payload, silent },
      {
        onSuccess: (response) => appendAttachmentsToQueryData(response.data),
      }
    );
  }

  function handleDelete(attachment: Attachment) {
    deleteMutation.mutate(attachment.id, {
      onSuccess: () => deleteAttachmentFromQueryData(attachment),
    });
  }

  return { attachments, isLoading, isUploading, handleUpload, handleDelete };
}
