import { TaskForGantt, TaskStatus } from "@dh-critical-path/api-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Status } from "../../enums";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import { durationInDays } from "../../utils/misc";
import { Toolbar } from "./";
import { config, nowMarker, plugins, templates, zoomLevels } from "./Config";
import { GStatic } from "./GanttSetup";

// TODO: refactor

type ScaleType = {
  unit: string;
  step: number;
  format: ((date: Date) => string) | string;
  css?: (date: Date) => string;
};

export type ZoomLevel = {
  name: string;
  scale_height: number;
  min_column_width: number;
  scales: ScaleType[];
};

export type GanttTask = {
  id: number | string;
  project_id?: number;
  name: string;
  start_date: string;
  due_date: string;
  type: "task" | "milestone" | "project"; // project means department here
  duration?: number;
  color?: string;
  open?: boolean;
  taskStatus: TaskStatus;
};

export type MarkerType = {
  id: string;
  start_date: Date;
  end_date?: Date;
  css?: string;
  text?: string;
  title?: string;
};

interface GanttData {
  data: GanttTask[];
  links?: any;
}

interface GanttProps {
  onDataUpdated?: (data: GanttData) => void;
  data: GanttData;
  onTaskClick: (id: number) => void;
}

export function statusColor(status: Status) {
  const map = {
    completed: "#10B981",
    in_progress: "#F59E0B",
    overdue: "#EF4444",
    todo: "#3B82F6",
    backlog: "#C9C4BF",
    canceled: "#C9C4BF",
    on_hold: "#C9C4BF",
  };

  return map[status];
}

type LinkType = { id: string | number; source: number; target: number; type: string };

export function tasksToGanttTasks(tasks: TaskForGantt[]): { data: GanttTask[]; links: LinkType[] } {
  const links: Array<LinkType> = [];

  const tasksForGantt = tasks.filter((task) => {
    return task.start_date && task.due_date;
  }) as (TaskForGantt & Required<Pick<TaskForGantt, "start_date" | "due_date">>)[];

  const data = tasksForGantt.map((task, index): GanttTask => {
    task.dependsOn.forEach((dependency): void => {
      links.push({ id: index, source: dependency.id, target: task.id, type: "0" });
    });

    return {
      id: task.id,
      project_id: task.project_id,
      name: task.name,
      start_date: task.start_date,
      due_date: task.due_date,
      type: task.is_milestone ? "milestone" : "task",
      duration: durationInDays(task.start_date, task.due_date),
      color: statusColor(task.taskStatus.status_type as unknown as Status),
      taskStatus: task.taskStatus,
    };
  });

  // TODO: do we really need this?
  data.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());

  return {
    data,
    links,
  };
}

const GanttChart = React.forwardRef<HTMLDivElement, Omit<GanttProps, "data">>(
  ({ onTaskClick }, ref) => {
    const [gridShown, setGridShown] = useState(GStatic.getInstance().config.show_grid); //default state equal to GStatic.getInstance().config.show_grid
    const [showLinks, setShowLinks] = useState(GStatic.getInstance().config.show_links);
    const [isMilestonesOnly, setIsMilestonesOnly] = useState(false);

    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35572#issuecomment-493942129
    const ganttRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    useEffect(() => {
      if (!ganttRef.current) {
        return;
      }

      GStatic.getInstance().plugins(plugins);
      GStatic.getInstance().config = { ...GStatic.getInstance().config, ...config };
      GStatic.getInstance().templates = { ...GStatic.getInstance().templates, ...templates };
      GStatic.getInstance().init(ganttRef.current);
      GStatic.getInstance().ext.zoom.init({ levels: zoomLevels });
      GStatic.getInstance().addMarker(nowMarker);
      GStatic.getInstance().ext.tooltips.tooltipFor({
        selector: ".gantt_grid",
        html: () => null,
      });

      GStatic.getInstance().ext.zoom.attachEvent("onAfterZoom", function (level: any, config: any) {
        if (GStatic.getInstance().getSelectedId()) {
          // without timeout it doesn't work sometimes
          setTimeout(() => {
            if (GStatic.getInstance().getSelectedId()) {
              GStatic.getInstance().showTask(GStatic.getInstance().getSelectedId());
            }
          }, 50);
        }
      });

      return () => {
        GStatic.destroy();
      };
    }, [ganttRef]); //to make sure this useEffect runs only when any of these variables change. You don't want to do all initialization every time you change state.

    useEffect(() => {
      if (!ganttRef.current) {
        return;
      }

      const element = ganttRef.current;

      const clickHandler = (event: MouseEvent) => {
        const target = event.target as HTMLElement | undefined;
        if (target?.classList.contains("js-task-show") && target.dataset.taskId) {
          onTaskClick(Number(target.dataset.taskId));
        }
      };

      element.addEventListener("click", clickHandler);

      return () => element.removeEventListener("click", clickHandler);
    }, [ganttRef, onTaskClick]);

    const toggleGrid = useCallback(
      () => setGridShown((previousValue) => !previousValue),
      [setGridShown]
    ); //toggle gridShown in react component

    useEffect(() => {
      GStatic.getInstance().config.grid_width = config.grid_width;
      GStatic.getInstance().config.show_grid = gridShown; //set Gantt parameter to match React state
      GStatic.getInstance().render(); //force gantt to re-render
    }, [gridShown]); //run this useEffect whenever gridShown changes

    useUpdateEffect(() => {
      GStatic.getInstance().config.show_links = showLinks;
      GStatic.getInstance().render();
    }, [showLinks]);

    const zoomIn = useCallback(() => {
      GStatic.getInstance().ext.zoom.zoomIn();
    }, []);

    const zoomOut = useCallback(() => {
      GStatic.getInstance().ext.zoom.zoomOut();
    }, []);

    const zoomToFit = useCallback(() => {
      GStatic.getInstance().ext.zoom.setLevel(zoomLevels[0].name);
    }, []);

    // TODO: refactor?
    const onIsMilestonesOnlyChangeCallback = useCallback((value) => {
      setIsMilestonesOnly(value);
      GStatic.filters.isMilestonesOnly = value;
      GStatic.getInstance().render();
    }, []);

    // we need to properly destroy gantt somewhere
    useEffect(() => {
      GStatic.filters.isMilestonesOnly = isMilestonesOnly;
    }, [isMilestonesOnly]);

    return (
      <div ref={ref}>
        <Toolbar
          isMilestonesOnly={isMilestonesOnly}
          onIsMilestonesOnlyChange={onIsMilestonesOnlyChangeCallback}
          gridShown={gridShown}
          showLinks={showLinks}
          onShowLinksChange={setShowLinks}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          zoomToFit={zoomToFit}
          toggleGrid={toggleGrid}
        />
        <div className="gantt" ref={ganttRef}></div>
      </div>
    );
  }
);

export const GanttChartFixed: React.VFC<GanttProps> = ({ data, onTaskClick }) => {
  useEffect(() => {
    GStatic.getInstance().parse(data);

    if (GStatic.getInstance().getSelectedId()) {
      if (
        !data.data.filter((task) => task.id === Number(GStatic.getInstance().getSelectedId()))
          .length
      ) {
        GStatic.getInstance().deleteTask(GStatic.getInstance().getSelectedId());
      }
    }
  }, [data]);

  return useMemo(() => {
    return <GanttChart onTaskClick={onTaskClick} />;
  }, [onTaskClick]);
};

export default GanttChart;
