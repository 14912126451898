import { BellIcon } from "@heroicons/react/outline";
import React, { ButtonHTMLAttributes } from "react";
import { styled } from "../../utils/styled";

interface NotificationsProps {
  unread?: boolean;
  onClick: () => void;
}

const Button = styled<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
  ({ children, ...props }) => (
    <button
      {...props}
      data-cy="header-notifications-button"
      type="button"
      className="relative flex items-center justify-center w-8 h-8 bg-violet text-white hover:text-beige rounded-full cursor-pointer"
    >
      {children}
    </button>
  )
);

const UnreadIcon = () => (
  <div className="absolute left-6 -top-px w-2.5 h-2.5 ring-2 ring-white bg-red-500 rounded-full" />
);

const HeaderNotification: React.FC<NotificationsProps> = ({ unread, onClick }) => (
  <Button onClick={onClick}>
    <BellIcon className="w-6 stroke-current" />
    {/* TODO: it'd be nice to have transition here */}
    {unread && <UnreadIcon />}
  </Button>
);

export default HeaderNotification;
