import { useFormContext } from "react-hook-form";
import { FilterResetButton } from "../../../../components/button/FilterResetButton";
import {
  CollapsibleFilter,
  CollapsibleFilterActions,
  CollapsibleFilterFooter,
  CollapsibleFilterProps,
  CollapsibleFilterSwitchGroup,
} from "../../../../components/common/CollapsibleFilter";
import { FormPanel } from "../../../../components/form/FormPanel";
import {
  ControlledDebouncedInput,
  ControlledSelect,
  ControlledSwitch,
  ControlledTagsInput,
} from "../../../../components/form/controlled";
import { TemplateTasksFilterFormFields } from "../../../../hooks/useTemplateTasksFilter";
import {
  useTemplateDepartmentsForFilterQuery,
  useTemplateProjectQuery,
  useTemplateTaskAssigneesForFilterQuery,
  useTemplateTasksForFilterQuery,
} from "../../../../queries";
import { useTagsForFilterQuery } from "../../../../queries/tags";
import { assigneesToOptions } from "../../../../utils/misc";
import { processTypeText } from "../../../../utils/text";

export function FormResetButton() {
  const form = useFormContext();

  return <FilterResetButton onClick={() => form.reset()} />;
}

function DepartmentsField() {
  const { watch } = useFormContext<TemplateTasksFilterFormFields>();

  const projectIds = watch("template_project_ids");

  const { data } = useTemplateDepartmentsForFilterQuery({
    template_project_id: projectIds[0],
  });

  return (
    <ControlledSelect
      name="template_department_ids"
      label="Responsible Department(s)"
      options={[{ id: -1, name: "None" }, ...(data ?? [])]}
      isMulti
    />
  );
}

function AssignedToField() {
  const { watch } = useFormContext<TemplateTasksFilterFormFields>();

  const projectIds = watch("template_project_ids");
  const departmentIds = watch("template_department_ids");

  const { data } = useTemplateTaskAssigneesForFilterQuery({
    template_project_id: projectIds[0],
    template_department_ids: departmentIds,
  });

  return (
    <ControlledSelect
      name="assignee_ids"
      label="Assigned To"
      options={[
        {
          id: -1,
          name: "Nobody",
        },
        ...assigneesToOptions(data ?? []),
      ]}
      isMulti
    />
  );
}

function SupporterField() {
  const { watch } = useFormContext<TemplateTasksFilterFormFields>();

  const projectIds = watch("template_project_ids");

  const { data } = useTemplateTaskAssigneesForFilterQuery({
    template_project_id: projectIds[0],
  });

  return (
    <ControlledSelect
      name="supporter_ids"
      label="Supporter"
      options={[
        {
          id: -1,
          name: "Nobody",
        },
        ...assigneesToOptions(data ?? []),
      ]}
      isMulti
    />
  );
}

function DependencyForField() {
  const { watch } = useFormContext<TemplateTasksFilterFormFields>();

  const projectIds = watch("template_project_ids");

  const { data } = useTemplateTasksForFilterQuery({
    template_project_ids: projectIds,
  });

  return (
    <ControlledSelect name="dependency_for_id" label="Dependency for" options={data} isClearable />
  );
}

function NameField() {
  return <ControlledDebouncedInput name="name" label="Name" debounce={500} />;
}

function TagsField() {
  const { data } = useTagsForFilterQuery({});

  return <ControlledTagsInput name="tags" tags={data ?? []} variant="inline" />;
}

function WithoutDueDateOffset() {
  const { watch } = useFormContext<TemplateTasksFilterFormFields>();

  const projectIds = watch("template_project_ids");

  const { data: templateProject } = useTemplateProjectQuery(projectIds[0]!);

  return (
    <ControlledSwitch
      name="without_due_date_offset"
      label={processTypeText(
        templateProject,
        "Without Days Prior Opening",
        "Without Days Prior Closure",
        "Without Days Prior Re-Opening",
        "Without Days Prior Transition"
      )}
      className="my-1 mx-3"
    />
  );
}

function TemplateTasksFilterFormExpandedFields() {
  return (
    <>
      <div className="col-span-6 md:col-span-3">
        <SupporterField />
      </div>
      <div className="col-span-6 md:col-span-3">
        <DependencyForField />
      </div>
      <div className="col-span-6">
        <TagsField />
      </div>
    </>
  );
}

function TemplateTasksFilterFormCollapsedFields() {
  return (
    <>
      <div className="col-span-6 md:col-span-2">
        <NameField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <DepartmentsField />
      </div>
      <div className="col-span-6 md:col-span-2">
        <AssignedToField />
      </div>
    </>
  );
}

function TemplateTasksFilterFormFooter({ open, activeFiltersCount }: CollapsibleFilterProps) {
  return (
    <CollapsibleFilterFooter>
      <CollapsibleFilterSwitchGroup>
        <ControlledSwitch name="is_milestone" label="Milestone" className="my-1 mx-3" />
        <ControlledSwitch
          name="without_dependencies"
          label="Without Dependencies"
          className="my-1 mx-3"
        />
        <WithoutDueDateOffset />
        <ControlledSwitch name="is_removed" label="Removed" className="my-1 mx-3" />
      </CollapsibleFilterSwitchGroup>
      <CollapsibleFilterActions open={open} activeFiltersCount={activeFiltersCount}>
        <FormResetButton />
      </CollapsibleFilterActions>
    </CollapsibleFilterFooter>
  );
}

export function TemplateTasksFilterForm({ activeFiltersCount }: CollapsibleFilterProps) {
  return (
    <CollapsibleFilter>
      {({ open }) => (
        <FormPanel>
          <TemplateTasksFilterFormCollapsedFields />
          {open && <TemplateTasksFilterFormExpandedFields />}
          <TemplateTasksFilterFormFooter open={open} activeFiltersCount={activeFiltersCount} />
        </FormPanel>
      )}
    </CollapsibleFilter>
  );
}
