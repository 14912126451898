import { OrderDirection } from "@dh-critical-path/shared";
import classNames from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";
import { styled } from "../../utils/styled";
import ArrowIcon from "../icons/ArrowIcon";

type TableProps = HTMLAttributes<HTMLDivElement> & {
  variant?: "base" | "responsive";
};

type TasksTableSortIndicatorProps = {
  active?: boolean;
};

type TableHeadCellSortableProps<OrderColumn extends string> = {
  orderColumn: OrderColumn | undefined;
  orderDirection: OrderDirection | undefined;
  onSort: (orderColumn: OrderColumn, orderDirection: OrderDirection) => void;
  columnName: OrderColumn;
  className?: string;
};

export const Table = styled<HTMLDivElement, TableProps>(
  ({ children, className, variant = "base", ...props }) => (
    <div
      {...props}
      className={classNames("rounded-lg shadow bg-white", className, {
        "overflow-hidden": variant === "base",
        "overflow-x-auto": variant === "responsive",
      })}
    >
      <table className="w-full">
        <tbody>{children}</tbody>
      </table>
    </div>
  )
);

export const TableHeadRow = styled<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ children, className, ...props }) => (
    <tr
      {...props}
      className={classNames(
        "hidden sm:table-row text-iron text-xs sm:text-sm uppercase",
        className
      )}
    >
      {children}
    </tr>
  )
);

export const TableRow = styled<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ className, children, ...props }) => (
    <tr {...props} className={classNames("relative", className)}>
      {children}
    </tr>
  )
);

export const TableHeadCell = styled<HTMLTableCellElement, HTMLAttributes<HTMLTableCellElement>>(
  ({ children, className, ...props }) => (
    <th
      {...props}
      className={classNames("py-4 px-3 bg-beige-100 font-normal whitespace-nowrap", className)}
    >
      {children}
    </th>
  )
);

export function TableHeadCellSortable<OrderColumn extends string>({
  columnName,
  orderColumn,
  orderDirection,
  children,
  onSort,
  className,
}: PropsWithChildren<TableHeadCellSortableProps<OrderColumn>>) {
  function handleClick() {
    if (columnName === orderColumn) {
      onSort(columnName, orderDirection === "asc" ? "desc" : "asc");
    } else {
      onSort(columnName, "asc");
    }
  }

  return (
    <TableHeadCell className={className}>
      {columnName ? (
        <button
          type="button"
          onClick={handleClick}
          className="inline-flex items-baseline text-left space-x-2 whitespace-nowrap uppercase"
        >
          <span>{children}</span>
          {orderColumn === columnName && (
            <TasksTableSortIndicator active={orderDirection === "asc"} />
          )}
        </button>
      ) : (
        <>{children}</>
      )}
    </TableHeadCell>
  );
}

export const TableDivider = styled<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ ...props }) => (
    <tr {...props} className="w-full border-b border-stone-100">
      <td className="h-0 p-0" colSpan={10}></td>
    </tr>
  )
);

export const TableCell = styled<HTMLTableCellElement, HTMLAttributes<HTMLTableCellElement>>(
  ({ children, className, ...props }) => (
    <td {...props} className={classNames("block sm:table-cell align-top", className)}>
      {children}
    </td>
  )
);

export function TasksTableSortIndicator({ active }: TasksTableSortIndicatorProps) {
  return (
    <ArrowIcon
      className={classNames("text-iron-600 hover:text-iron-800", {
        "transform rotate-180": active,
      })}
    />
  );
}
