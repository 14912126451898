import { Request, TemplateTask } from "@dh-critical-path/api-types";
import { ResourceIdPrefix, prefixResourceId } from "@dh-critical-path/shared";
import { useCallback, useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  createTemplateTask,
  removeTemplateTask,
  restoreTemplateTask,
  updateTemplateTask,
} from "../api";
import { attachmentsKeys, templateKeys } from "../queries";
import { interceptValidationErrorsAndThrow } from "../utils/form";
import { mapIds } from "../utils/misc";

export type TemplateTaskCreateFields = Request.TemplateTasks.CreateTemplateTask;

export type TemplateTaskUpdateFields = Request.TemplateTasks.UpdateTemplateTask;

type TemplateTaskCallback = (task: Pick<TemplateTask, "id" | "name">) => void;

type TemplateTaskCreateFormParams = {
  templateProjectId: number;
  templateDepartmentId?: number;
};

export function useTemplateTaskCreateForm(
  params: TemplateTaskCreateFormParams,
  onCreated: TemplateTaskCallback
) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(createTemplateTask);

  const methods = useForm<TemplateTaskCreateFields>({
    defaultValues: {
      template_project_id: params.templateProjectId,
      template_department_id: params.templateDepartmentId,
      duration: 1,
    },
  });

  useDepartmentAssigneeSync(methods as any);

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onCreated(await mutateAsync(data));
      queryClient.setQueryData(attachmentsKeys.templateTaskAttachments(undefined), []);
      queryClient.invalidateQueries(templateKeys.allTemplateTasks());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useTemplateTaskUpdateForm(task: TemplateTask, onUpdated: TemplateTaskCallback) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateTemplateTask);

  const methods = useForm<TemplateTaskUpdateFields>({
    defaultValues: {
      template_task_id: task.id,
      template_project_id: task.template_project_id,
      template_department_id: task.template_department_id,
      name: task.name,
      description: task.description,
      is_milestone: Boolean(task.is_milestone),
      duration: task.duration,
      due_date_offset: task.due_date_offset,
      assignee_id: task.assignee_user_id
        ? prefixResourceId(task.assignee_user_id, ResourceIdPrefix.USER)
        : task.assignee_template_title_id
        ? prefixResourceId(task.assignee_template_title_id, ResourceIdPrefix.TEMPLATE_TITLE)
        : null,
      supporter_ids: [
        ...mapIds(task.supportedByUsers).map((id) => prefixResourceId(id, ResourceIdPrefix.USER)),
        ...mapIds(task.supportedByTemplateTitles).map((id) =>
          prefixResourceId(id, ResourceIdPrefix.TEMPLATE_TITLE)
        ),
      ],
      depends_on_ids: mapIds(task.dependsOn),
      dependency_for_ids: mapIds(task.dependencyFor),
      tag_ids: mapIds(task.tags),
    },
  });

  useDepartmentAssigneeSync(methods);

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onUpdated(await mutateAsync(data));
      queryClient.invalidateQueries(templateKeys.templateTask(task.id));
      queryClient.invalidateQueries(templateKeys.allTemplateTasks());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useTemplateTaskRemoveAction() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(removeTemplateTask);

  const handleRemove = useCallback(
    async (task: TemplateTask) => {
      await mutateAsync(task.id);
      queryClient.invalidateQueries(templateKeys.allTemplateTasks());
    },
    [queryClient, mutateAsync]
  );

  return { handleRemove, isLoading };
}

export function useTemplateTaskRestoreAction() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(restoreTemplateTask);

  const handleRestore = useCallback(
    async (task: TemplateTask) => {
      await mutateAsync(task.id);
      queryClient.invalidateQueries(templateKeys.templateTask(task.id));
      queryClient.invalidateQueries(templateKeys.allTemplateTasks());
    },
    [queryClient, mutateAsync]
  );

  return { handleRestore, isLoading };
}

function useDepartmentAssigneeSync({
  watch,
  setValue,
  formState: { dirtyFields },
}: UseFormReturn<TemplateTaskUpdateFields>) {
  const departmentId = watch("template_department_id");

  useEffect(() => {
    if (dirtyFields.template_department_id) {
      setValue("assignee_id", null);
    }
  }, [departmentId, dirtyFields.template_department_id, setValue]);
}
