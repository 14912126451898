import classnames from "classnames";
import { PropsWithChildren } from "react";

type SpaceType = 2 | 4 | 6 | 8;

type ColumnsWidthType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type GapProps = {
  gap?: SpaceType;
  smGap?: SpaceType;
  mdGap?: SpaceType;
  lgGap?: SpaceType;
  xlGap?: SpaceType;
};

function Container({ children, gap = 6, smGap, mdGap, lgGap, xlGap }: PropsWithChildren<GapProps>) {
  return (
    <div
      className={classnames(
        "grid grid-cols-12",
        `gap-${gap}`,
        `sm:gap-${smGap}`,
        `md:gap-${mdGap}`,
        `lg:gap-${lgGap}`,
        `xl:gap-${xlGap}`
      )}
    >
      {children}
    </div>
  );
}

function Column({
  children,
  width = 12,
  smWidth,
  mdWidth,
  lgWidth,
  xlWidth,
}: PropsWithChildren<{
  width?: ColumnsWidthType;
  smWidth?: ColumnsWidthType;
  mdWidth?: ColumnsWidthType;
  lgWidth?: ColumnsWidthType;
  xlWidth?: ColumnsWidthType;
}>) {
  return (
    <div
      className={classnames(
        `col-span-${width}`,
        `sm:col-span-${smWidth}`,
        `md:col-span-${mdWidth}`,
        `lg:col-span-${lgWidth}`,
        `xl:col-span-${xlWidth}`
      )}
    >
      {children}
    </div>
  );
}

function Spacer({
  children,
  space = 4,
  smSpace,
  mdSpace,
  lgSpace,
  xlSpace,
  direction = "y",
}: PropsWithChildren<{
  space?: SpaceType;
  smSpace?: SpaceType;
  mdSpace?: SpaceType;
  lgSpace?: SpaceType;
  xlSpace?: SpaceType;
  direction?: "y" | "x";
}>) {
  return (
    <div
      className={classnames(
        `space-${direction}-${space}`,
        `sm:space-${direction}-${smSpace}`,
        `md:space-${direction}-${mdSpace}`,
        `lg:space-${direction}-${lgSpace}`,
        `lg:space-${direction}-${xlSpace}`
      )}
    >
      {children}
    </div>
  );
}

export default Object.assign({}, { Container, Column, Spacer });
