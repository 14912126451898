import { GanttConfigOptions, GanttPlugins, GanttTemplates } from "dhtmlx-gantt";
import { GanttTask, ZoomLevel } from "./GanttChart";
import { GStatic } from "./GanttSetup";

export const plugins: GanttPlugins = {
  marker: true,
  tooltip: true,
  drag_timeline: true,
};

export const config: { grid_width: number } & Partial<GanttConfigOptions> = {
  xml_date: "%Y-%m-%d %H:%i",
  autosize: false,
  show_links: true,
  readonly: true,
  link_line_width: 1,
  row_height: 40,
  grid_width: 600,
  touch_drag: 750,
  preserve_scroll: true,
  columns: [
    {
      name: "name",
      label: "TASK NAME",
      tree: true,
      width: "*",
      template: (task: GanttTask) => {
        if (!task.name || !Number(task.id)) {
          return task.id;
        }
        return `<div class="flex w-full items-center truncate">
          <button type="button" class="text-xs js-task-show text-iron-400 hover:text-black transition duration-200" data-task-id="${task.id}">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 md:h-4 w-3 md:w-4 mb-px pointer-events-none" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </button>&nbsp;<div class="truncate">${task.name}</div>
        </div>`;
      },
    },
    {
      name: "start_date",
      label: "START DATE",
      align: "right",
      width: "*",
      template: (task: GanttTask) => {
        if (task.type === "project") {
          return null;
        }

        // TODO: app locale
        return new Intl.DateTimeFormat(undefined, {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }).format(new Date(task.start_date!));
      },
    },
    {
      name: "duration",
      label: "DURATION, d",
      align: "right",
      width: "*",
    },
  ],
};

const weekendDaysClass = function (date: Date) {
  var dateToStr = GStatic.getInstance().date.date_to_str("%D");
  const currentLevel = GStatic.getInstance().ext.zoom.getCurrentLevel();
  if (currentLevel > 0) {
    if (dateToStr(date) === "Sun" || dateToStr(date) === "Sat") return "weekend";
  }

  return "";
};

export const templates: Partial<GanttTemplates> = {
  rightside_text: (start, end, task) => {
    return task.name;
  },
  grid_row_class: function (start, end, task) {
    if (task.$level >= 1) {
      return "nested-task";
    }
    return "";
  },
  tooltip_text: function (start, end, task) {
    if (!task.name) {
      return "";
    }

    return `<div class="text-base">
    <b>Task:</b> ${task.name} <br/>
    <b>Duration:</b> ${task.duration}d
    <br/>${task.taskStatus ? `<b>Status:</b> ${task.taskStatus?.name}` : ""}
    </div>`;
  },

  timeline_cell_class: function (task, date) {
    return weekendDaysClass(date);
  },
};

export const zoomLevels: ZoomLevel[] = [
  {
    name: "first",
    scale_height: 115,
    min_column_width: 30,
    scales: [
      { unit: "year", step: 1, format: "%Y" },
      { unit: "month", step: 1, format: "%M" },
    ],
  },
  {
    name: "second",
    scale_height: 115,
    min_column_width: 70,
    scales: [
      { unit: "year", step: 1, format: "%Y" },
      { unit: "month", step: 1, format: "%F, %Y" },
      { unit: "day", step: 1, format: "%j", css: weekendDaysClass },
    ],
  },
  {
    name: "third",
    scale_height: 115,
    min_column_width: 110,
    scales: [
      { unit: "year", step: 1, format: "%Y" },
      { unit: "month", step: 1, format: "%F, %Y" },
      { unit: "day", step: 1, format: "%j", css: weekendDaysClass },
    ],
  },
  {
    name: "forth",
    scale_height: 115,
    min_column_width: 150,
    scales: [
      { unit: "year", step: 1, format: "%Y" },
      { unit: "month", step: 1, format: "%F, %Y" },
      { unit: "day", step: 1, format: "%j", css: weekendDaysClass },
    ],
  },
];

export const nowMarker = {
  id: "now-marker",
  start_date: new Date(),
  css: "now-marker",
  title: "Now",
};
