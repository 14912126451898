import { Task } from "@dh-critical-path/api-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { ActionButton, Button } from "../../components/button";
import { Hash } from "../../components/common";
import {
  SlideOverContent,
  SlideOverHeader,
  SlideOverSidebar,
  SlideOverSidebarDateValue,
  SlideOverSidebarField,
  SlideOverSidebarProfileListValue,
  SlideOverSidebarProfileValue,
  SlideOverSidebarTagsValue,
  SlideOverSidebarTaskListValue,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import {
  useTaskOrTemplateTaskRemovingConfirmationDialog,
  useTaskOrTemplateTaskRemovingErrorHandling,
  useTaskOrTemplateTaskRestoringConfirmationDialog,
} from "../../components/modals";
import { Status, backgroundClassName } from "../../enums";
import { AdminOnly } from "../../hooks/useAuth";
import { useTaskRemoveAction, useTaskRestoreAction } from "../../hooks/useTask";
import { useTaskQuery } from "../../queries";
import { RouteKey, slideOverRoute } from "../SlideOvers";
import { TaskSlideOverTabs } from "../Tasks/components/TaskSlideOverTabs";
import { useNavigateToTaskEdit } from "../utils/navigation";

type TaskSlideOverProps = {
  taskId: number;
};

type TaskViewProps = {
  task: Task;
};

function TaskViewDeleteAction({ task }: TaskViewProps) {
  const { handleRemove, isLoading } = useTaskRemoveAction();

  const { modal: errorModal, handleRemoveWithErrorHandling } =
    useTaskOrTemplateTaskRemovingErrorHandling(handleRemove);

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useTaskOrTemplateTaskRemovingConfirmationDialog(handleRemoveWithErrorHandling);

  if (task.deleted_at) {
    return null;
  }

  return (
    <>
      <ActionButton
        variant="delete"
        size="lg"
        rounding="base"
        onClick={() => handleRemoveWithConfirmation(task)}
        loading={isLoading}
      />
      {confirmModal}
      {errorModal}
    </>
  );
}

function TaskViewRestoreAction({ task }: TaskViewProps) {
  const { handleRestore, isLoading } = useTaskRestoreAction();

  const { modal, handleRestoreWithConfirmation } =
    useTaskOrTemplateTaskRestoringConfirmationDialog(handleRestore);

  if (!task.deleted_at) {
    return null;
  }

  return (
    <>
      <ActionButton
        variant="restore"
        size="lg"
        rounding="base"
        onClick={() => handleRestoreWithConfirmation(task)}
        loading={isLoading}
      />
      {modal}
    </>
  );
}

function TaskViewEditAction({ task }: TaskViewProps) {
  const navigateToTaskEdit = useNavigateToTaskEdit();

  if (task.deleted_at) {
    return null;
  }

  return (
    <Button variant="secondary" onClick={() => navigateToTaskEdit(task)}>
      <FormattedMessage id="modal.edit" />
    </Button>
  );
}

function TaskViewActions({ task }: TaskViewProps) {
  return (
    <div className="flex space-x-8">
      <AdminOnly render={() => <TaskViewDeleteAction task={task} />} />
      <AdminOnly render={() => <TaskViewRestoreAction task={task} />} />
      <TaskViewEditAction task={task} />
    </div>
  );
}

function TaskViewHeader({ task }: TaskViewProps) {
  return (
    <SlideOverHeader>
      <div className="flex flex-col">
        <span className="text-sm font-bold text-iron-400 mb-1 md:mb-0">
          {task.project.name}
          &nbsp;
          <Hash>{task.project.id}</Hash>
        </span>
        <h3 className="text-black text-xl font-medium">
          {task.name}
          &nbsp;
          <Hash>
            {task.project.id}-{task.id}
          </Hash>
        </h3>
      </div>
      <TaskViewActions task={task} />
    </SlideOverHeader>
  );
}

export function SlideOverSidebarStatusField({ task }: TaskViewProps) {
  return (
    <div className="flex items-center">
      <span
        className={classNames(
          "w-2 h-2 mr-2 rounded-full",
          backgroundClassName(task.taskStatus.status_type as unknown as Status)
        )}
      />
      {task.taskStatus.name}
    </div>
  );
}

function TaskViewContentSidebar({ task }: TaskViewProps) {
  return (
    <SlideOverSidebar>
      <SlideOverSidebarField label="Status" value={<SlideOverSidebarStatusField task={task} />} />
      <SlideOverSidebarField label="Responsible Department/Team" value={task.department?.name} />
      {Boolean(task.assignedToUser || task.assignedToTitle) && (
        <SlideOverSidebarField
          label="Assigned To"
          value={
            <SlideOverSidebarProfileValue user={task.assignedToUser} title={task.assignedToTitle} />
          }
        />
      )}
      {Boolean(task.supportedByUsers.length || task.supportedByTitles.length) && (
        <SlideOverSidebarField
          label="Supporters"
          value={
            <SlideOverSidebarProfileListValue
              users={task.supportedByUsers}
              titles={task.supportedByTitles}
            />
          }
        />
      )}
      {Boolean(task.tags.length) && (
        <SlideOverSidebarField
          label="Tags"
          value={
            <SlideOverSidebarTagsValue
              tags={task.tags}
              to={(tag) => `/projects/${task.project_id}/tasks?tag=${tag.id}`}
            />
          }
        />
      )}
      <SlideOverSidebarField
        label="Start Date"
        value={task.start_date ? <SlideOverSidebarDateValue date={task.start_date} /> : null}
      />
      <SlideOverSidebarField
        label="Due Date"
        value={task.due_date ? <SlideOverSidebarDateValue date={task.due_date} /> : null}
      />
      {Boolean(task.dependsOn.length) ? (
        <SlideOverSidebarField
          label="Dependent on"
          value={
            <SlideOverSidebarTaskListValue
              tasks={task.dependsOn}
              to={(task) => slideOverRoute(RouteKey.Task, { taskId: task.id })}
            />
          }
        />
      ) : null}
      {Boolean(task.dependencyFor.length) ? (
        <SlideOverSidebarField
          label="Dependency for"
          value={
            <SlideOverSidebarTaskListValue
              tasks={task.dependencyFor}
              to={(task) => slideOverRoute(RouteKey.Task, { taskId: task.id })}
            />
          }
        />
      ) : null}
    </SlideOverSidebar>
  );
}

function TaskViewContent({ task }: TaskViewProps) {
  return (
    <SlideOverContent>
      <TaskSlideOverTabs task={task} />
      <TaskViewContentSidebar task={task} />
    </SlideOverContent>
  );
}

function TaskView({ task }: TaskViewProps) {
  return (
    <>
      <TaskViewHeader task={task} />
      <TaskViewContent task={task} />
    </>
  );
}

export function TaskSlideOver({ taskId }: TaskSlideOverProps) {
  const result = useTaskQuery(taskId);

  return <SlideOverWithLoading result={result} render={(task) => <TaskView task={task} />} />;
}
