import { TemplateDepartment } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useTemplateDepartmentUpdateForm } from "../../hooks/useTemplateDepartment";
import { useTemplateDepartmentQuery } from "../../queries";
import { useNavigateToTemplateDepartment } from "../utils/navigation";
import { TemplateDepartmentForm } from "./components/forms/TemplateDepartmentForm";

type TemplateDepartmentEditSlideOverProps = {
  templateDepartmentId: number;
};

type TemplateDepartmentEditFormProps = {
  department: TemplateDepartment;
};

function TemplateDepartmentEditForm({ department }: TemplateDepartmentEditFormProps) {
  const navigate = useNavigateToTemplateDepartment();

  const { methods, handleSubmit } = useTemplateDepartmentUpdateForm(department, navigate);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader submitText={<FormattedMessage id="modal.save" />}>
          <FormattedMessage id="departments.modal.heading.edit" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <TemplateDepartmentForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function TemplateDepartmentEditSlideOver({
  templateDepartmentId,
}: TemplateDepartmentEditSlideOverProps) {
  const result = useTemplateDepartmentQuery(templateDepartmentId);

  return (
    <SlideOverWithLoading
      result={result}
      render={(department) => <TemplateDepartmentEditForm department={department} />}
    />
  );
}
