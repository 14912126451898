import axios from "axios";

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
} else {
  axios.defaults.baseURL = "http://127.0.0.1:3000";
}

export function setBearer(token: string) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
