import {
  TemplateDepartment as TemplateDepartmentType,
  TemplateTitle as TemplateTitleType,
  User,
} from "@dh-critical-path/api-types";
import { keepTruthy, prefixTemplateTitleId } from "@dh-critical-path/shared";
import { PlusIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { ActionButton, Button } from "../../../components/button";
import { useActionWithConfirmation } from "../../../components/modals";
import { PageSectionHeader } from "../../../components/page";
import { Panel, PanelFooter } from "../../../components/panel";
import { ProfileRow } from "../../../components/profile";
import {
  useTemplateTitleMemberRemove,
  useTemplateTitleRemove,
} from "../../../hooks/useTemplateTitle";
import { useTasksQuery, useTemplateTasksQuery, useTemplateTitlesQuery } from "../../../queries";
import { fullName } from "../../../utils/text";
import {
  useNavigateToTemplateTitleCreate,
  useNavigateToTemplateTitleEdit,
} from "../../utils/navigation";
import { AffectedTasksDetails } from "./AffectedTasksDetails";

type TemplateTitlesProps = {
  department: TemplateDepartmentType;
};

type TemplateTitleProps = {
  title: TemplateTitleType;
};

type TemplateTitleMemberProps = {
  title: TemplateTitleType;
  member: User;
};

export function useTemplateTitleRemovingConfirmationDialog(
  handleRemove: (title: TemplateTitleType) => Promise<any>
) {
  const { modal, handleWithConfirmation } = useActionWithConfirmation(
    handleRemove,
    async (title) => ({
      description: <TemplateTitleRemovingConfirmationText title={title} />,
    })
  );

  return { modal, handleRemoveWithConfirmation: handleWithConfirmation };
}

function TemplateTitleRemovingConfirmationText({ title }: { title: TemplateTitleType }) {
  const params = useParams<"id" | "departmentId">();

  const projectId = params.departmentId ? Number(params.id) : null;

  const tasksQuery = useTasksQuery({
    template_department_ids: [title.template_department_id],
    assignee_ids: [prefixTemplateTitleId(title.id)],
    limit: 1,
  });

  const templateTasksQuery = useTemplateTasksQuery({
    template_department_ids: [title.template_department_id],
    template_project_ids: keepTruthy([projectId]),
    assignee_ids: [prefixTemplateTitleId(title.id)],
    limit: 1,
  });

  return (
    <div>
      <p>Do you really want to remove this title?</p>
      <AffectedTasksDetails
        totalTasks={tasksQuery.data?.meta.total ?? 0}
        totalTemplateTasks={templateTasksQuery.data?.meta.total ?? 0}
        templateDepartmentId={title.template_department_id}
        templateProjectId={projectId}
        assigneeId={prefixTemplateTitleId(title.id)}
      />
    </div>
  );
}

function TemplateTitleRemoveAction({ title }: TemplateTitleProps) {
  const { handleRemove, isLoading } = useTemplateTitleRemove();

  const { modal: confirmModal, handleRemoveWithConfirmation } =
    useTemplateTitleRemovingConfirmationDialog(handleRemove);

  return (
    <>
      <ActionButton
        variant="delete"
        onClick={() => handleRemoveWithConfirmation(title)}
        loading={isLoading}
      />
      {confirmModal}
    </>
  );
}

function TemplateTitleEditAction({ title }: TemplateTitleProps) {
  const navigateToTemplateTitleEdit = useNavigateToTemplateTitleEdit();

  return (
    <>
      <ActionButton variant="edit" onClick={() => navigateToTemplateTitleEdit(title)} />
    </>
  );
}

function TemplateTitleMemberRemoveAction({ title, member }: TemplateTitleMemberProps) {
  const { handleRemove, isLoading } = useTemplateTitleMemberRemove();

  const { modal: confirmModal, handleWithConfirmation: handleRemoveWithConfirmation } =
    useActionWithConfirmation(handleRemove, async (title, user) => ({
      description: `Do you really want to remove ${fullName(user)} from ${title.name}`,
    }));

  return (
    <>
      <ActionButton
        variant="delete"
        onClick={() => handleRemoveWithConfirmation(title, member)}
        loading={isLoading}
      />
      {confirmModal}
    </>
  );
}

function TemplateTitleActions({ title }: TemplateTitleProps) {
  return (
    <div className="flex md:invisible group-hover:visible items-center space-x-3 pl-3">
      <TemplateTitleRemoveAction title={title} />
      <TemplateTitleEditAction title={title} />
    </div>
  );
}

function TemplateTitleMemberActions({ title, member }: TemplateTitleMemberProps) {
  return (
    <div className="flex md:invisible group-hover:visible items-center space-x-3 pl-3">
      <TemplateTitleMemberRemoveAction title={title} member={member} />
    </div>
  );
}

function TemplateTitleMember({ title, member }: TemplateTitleMemberProps) {
  return (
    <div className="flex w-full justify-between items-start md:items-center relative group mb-4 last:mb-0">
      <ProfileRow user={member} />
      <TemplateTitleMemberActions title={title} member={member} />
    </div>
  );
}

function TemplateTitlesEmpty() {
  return (
    <div className="py-6 text-center">
      <FormattedMessage id="titles.empty" />
    </div>
  );
}

function TemplateTitleMembersEmpty() {
  return (
    <div className="py-6 text-center">
      <FormattedMessage id="title.members.empty" />
    </div>
  );
}

function TemplateTitleMembers({ title }: TemplateTitleProps) {
  if (!title.members.length) {
    return <TemplateTitleMembersEmpty />;
  }

  return (
    <div>
      {title.members.map((member) => (
        <TemplateTitleMember key={member.id} title={title} member={member} />
      ))}
    </div>
  );
}

function TemplateTitle({ title }: TemplateTitleProps) {
  return (
    <div className="py-6 border-t border-stone-100 first:pt-0 first:border-t-0">
      <div className="flex justify-between group">
        <h3 className="font-medium text-xl mb-3 leading-tight">
          {title.name} ({title.members.length})
        </h3>
        <TemplateTitleActions title={title} />
      </div>
      <TemplateTitleMembers title={title} />
    </div>
  );
}

function TemplateTitles({ department }: TemplateTitlesProps) {
  const { data } = useTemplateTitlesQuery({ template_department_id: department.id });

  if (!data || !data.length) {
    return <TemplateTitlesEmpty />;
  }

  return (
    <>
      {data.map((title) => (
        <TemplateTitle key={title.id} title={title} />
      ))}
    </>
  );
}

export function TemplateTitlesPanel({ department }: TemplateTitlesProps) {
  return (
    <Panel>
      <TemplateTitles department={department} />
      <TemplateTitlesPanelFooter department={department} />
    </Panel>
  );
}

function TemplateTitlesPanelFooter({ department }: TemplateTitlesProps) {
  const navigateToTitlesAdd = useNavigateToTemplateTitleCreate();

  return (
    <PanelFooter>
      <Button
        icon={() => <PlusIcon className="w-4" />}
        onClick={() => navigateToTitlesAdd(department)}
      >
        <FormattedMessage id="titles.add" />
      </Button>
    </PanelFooter>
  );
}

export function TemplateTitlesPanelHeader({ department }: TemplateTitlesProps) {
  const { data } = useTemplateTitlesQuery({ template_department_id: department.id });

  return (
    <PageSectionHeader>
      <FormattedMessage id="headings.departments.titles" values={{ count: data?.length ?? 0 }} />
    </PageSectionHeader>
  );
}
