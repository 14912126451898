import { Gantt, GanttStatic } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";

// TODO: refactor
export class GStatic {
  private static gantt: GanttStatic | null;

  public static filters = {
    isMilestonesOnly: false,
  };

  public static getInstance(): GanttStatic {
    if (!this.gantt) {
      this.gantt = Gantt.getGanttInstance();
    }

    return this.gantt;
  }

  public static destroy() {
    this.gantt?.clearAll();
    this.gantt?.destructor();
    this.gantt = null;
  }
}

let lastScrollX = 0;
let lastScrollY = 0;

GStatic.getInstance().attachEvent(
  "onBeforeGanttRender",
  () => {
    const pos = GStatic.getInstance().getScrollState() as { x: number; y: number };
    lastScrollX = pos.x;
    lastScrollY = pos.y;
  },
  null
);

GStatic.getInstance().attachEvent(
  "onGanttRender",
  () => {
    if (!GStatic.getInstance().config.preserve_scroll) {
      return;
    }

    if (lastScrollX || lastScrollY) {
      setTimeout(() => GStatic.getInstance().scrollTo(lastScrollX, lastScrollY));
    }
  },
  null
);

GStatic.getInstance().attachEvent(
  "onBeforeTaskDisplay",
  (id, task) => {
    return GStatic.filters.isMilestonesOnly ? task.type === "milestone" : true;
  },
  {}
);

GStatic.getInstance().attachEvent(
  "onTaskSelected",
  function (id) {
    GStatic.getInstance()
      .getLinks()
      .filter((link) => {
        delete link.color;

        return link.source === Number(id) || link.target === Number(id);
      })
      .forEach((link) => {
        link.color = "#be185d";
      });

    GStatic.getInstance().refreshData();
  },
  {}
);
