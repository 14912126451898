import { Request, Response } from "@dh-critical-path/api-types";
import axios from "axios";

export function getDepartment(id: number) {
  return axios
    .get<Response.Departments.GetDepartment>(`/api/departments/${id}`)
    .then(({ data }) => data);
}

export function createDepartment(params: Request.Departments.CreateDepartment) {
  return axios
    .post<Response.Departments.CreateDepartment>(`/api/departments`, params)
    .then(({ data }) => data);
}

export function updateDepartment({ id, ...params }: Request.Departments.UpdateDepartment) {
  return axios
    .patch<Response.Departments.UpdateDepartment>(`/api/departments/${id}`, params)
    .then(({ data }) => data);
}

export function removeDepartment({ id }: Request.Departments.RemoveDepartment) {
  return axios
    .delete<Response.Departments.RemoveDepartment>(`/api/departments/${id}`)
    .then(({ data }) => data);
}

export function addMembers({ id, ...params }: Request.Departments.AddMembers) {
  return axios
    .post<Response.Departments.AddMembers>(`/api/departments/${id}/members`, params)
    .then(({ data }) => data);
}

export function removeMember({ id, member_id }: Request.Departments.RemoveMember) {
  return axios
    .delete<Response.Departments.RemoveMember>(`/api/departments/${id}/members/${member_id}`)
    .then(({ data }) => data);
}

export function getDepartmentMembers(id: number) {
  return axios
    .get<Response.Departments.GetDepartmentMembers>(`/api/departments/${id}/members`)
    .then(({ data }) => data);
}

export function getDepartmentsForFilter(params: Request.Departments.GetDepartmentsForFilter) {
  return axios
    .get<Response.Departments.GetDepartmentsForFilter>("/api/departments/filter", { params })
    .then(({ data }) => data);
}

export function getDepartmentsTasksStats(params: Request.Departments.GetDepartmentsTasksStats) {
  return axios
    .get<Response.Departments.GetDepartmentsTasksStats>(`/api/stats/tasks/for-departments`, {
      params,
    })
    .then(({ data }) => data);
}
