import { Response } from "@dh-critical-path/api-types";
import { Pagination } from "../../../components/common";

type TasksPaginationProps = {
  pagination: Response.Pagination;
  onChange: (page: number) => void;
};

export function TasksPagination({ pagination, onChange }: TasksPaginationProps) {
  if (pagination.last_page < 2) {
    return null;
  }

  return (
    <div className="flex justify-end">
      <Pagination
        totalPages={pagination.last_page}
        currentPage={pagination.current_page}
        onPageChange={onChange}
      />
    </div>
  );
}
