import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useSearchParameter(key: string): string | null {
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState<string | null>(searchParams.get(key));

  useEffect(() => {
    setValue(searchParams.get(key));
  }, [key, searchParams]);

  return value;
}
