import { Task } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../components/button";
import {
  SlideOverButtons,
  SlideOverContent,
  SlideOverHeader,
  SlideOverTitle,
  SlideOverWithLoading,
} from "../../../components/form/SlideOver";
import { useTaskCreateForm, useTaskUpdateForm } from "../../../hooks/useTask";
import { useTaskQuery } from "../../../queries";
import { RouteKey, useSlideOverNavigate } from "../../SlideOvers";
import { TaskSlideOverBodyForm, TaskSlideOverSidebarForm } from "./TaskSlideOverForm";

type TaskSlideOverProps = {
  taskId: number;
};

type TaskCreateSlideOverProps = {
  projectId: number;
  departmentId?: number;
};

type TaskProps = {
  task: Task;
};

type TaskCreateProps = {
  projectId: number;
  departmentId?: number;
};

type TaskHeaderProps = {
  onDiscard: () => void;
  isSaving: boolean;
  taskExists?: boolean;
};

function TaskHeader({ taskExists, isSaving, onDiscard }: TaskHeaderProps) {
  return (
    <SlideOverHeader>
      <SlideOverTitle>
        <FormattedMessage
          id={taskExists ? "tasks.modal.heading.edit" : "tasks.modal.heading.create"}
        />
      </SlideOverTitle>
      <SlideOverButtons>
        <Button variant="secondary" onClick={onDiscard} data-cy="slide-over-task-discard-button">
          <FormattedMessage id="modal.discard" />
        </Button>
        <Button
          type="submit"
          loading={isSaving}
          disabled={isSaving}
          data-cy="slide-over-task-save-button"
        >
          <FormattedMessage id="modal.save" />
        </Button>
      </SlideOverButtons>
    </SlideOverHeader>
  );
}

function TaskEdit({ task }: TaskProps) {
  const slideOverNavigate = useSlideOverNavigate();

  const { form, isUpdating, handleUpdate } = useTaskUpdateForm(task, navigateToTaskView);

  function navigateToTaskView() {
    slideOverNavigate(RouteKey.Task, { taskId: task.id });
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleUpdate)} className="flex flex-col flex-1">
        <TaskHeader isSaving={isUpdating} onDiscard={navigateToTaskView} taskExists />
        <SlideOverContent>
          <TaskSlideOverBodyForm task={task} />
          <TaskSlideOverSidebarForm />
        </SlideOverContent>
      </form>
    </FormProvider>
  );
}

function TaskCreate(props: TaskCreateProps) {
  const slideOverNavigate = useSlideOverNavigate();

  const { form, isCreating, handleCreate } = useTaskCreateForm(props, (task) => {
    slideOverNavigate(RouteKey.Task, { taskId: task.id });
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleCreate)} className="flex flex-col flex-1">
        <TaskHeader isSaving={isCreating} onDiscard={slideOverNavigate} />
        <SlideOverContent>
          <TaskSlideOverBodyForm />
          <TaskSlideOverSidebarForm />
        </SlideOverContent>
      </form>
    </FormProvider>
  );
}

export function TaskEditSlideOver({ taskId }: TaskSlideOverProps) {
  const result = useTaskQuery(taskId);

  return <SlideOverWithLoading result={result} render={(task) => <TaskEdit task={task} />} />;
}

export function TaskCreateSlideOver(props: TaskCreateSlideOverProps) {
  return <TaskCreate {...props} />;
}
