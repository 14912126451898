import classNames from "classnames";
import { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
  type?: "base" | "grid" | "full-width";
}

const PageContainer = styled<HTMLDivElement, PageContainerProps>(
  ({ type = "base", children, ...props }) => {
    return (
      <div
        {...props}
        className={classNames("w-full", {
          "container py-8 space-y-8": type === "base",
          "grid sm:grid-cols-2 lg:grid-cols-3 gap-6": type === "grid",
        })}
      >
        {children}
      </div>
    );
  }
);

export default PageContainer;
