import { User } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  SlideOverContent,
  SlideOverForm,
  SlideOverFormHeader,
  SlideOverSidebar,
  SlideOverWithLoading,
} from "../../components/form/SlideOver";
import { useUserUpdateForm } from "../../hooks/useUser";
import { useUserQuery } from "../../queries";
import { useNavigateToUser } from "../utils/navigation";
import { SsoRoleField } from "./components/forms/UserForm";

type UserEditSlideOverProps = {
  userId: number;
};

type UserEditFormProps = {
  user: User;
};

function UserEditForm() {
  return (
    <>
      <SsoRoleField />
    </>
  );
}

function UserEditView({ user }: UserEditFormProps) {
  const navigateToView = useNavigateToUser();

  const { methods, handleSubmit } = useUserUpdateForm(user, navigateToView);

  return (
    <FormProvider {...methods}>
      <SlideOverForm onSubmit={handleSubmit}>
        <SlideOverFormHeader
          submitText={<FormattedMessage id="modal.save" />}
          onDiscard={() => navigateToView(user)}
        >
          <FormattedMessage id="users.modal.heading.edit" />
        </SlideOverFormHeader>
        <SlideOverContent>
          <SlideOverSidebar>
            <UserEditForm />
          </SlideOverSidebar>
        </SlideOverContent>
      </SlideOverForm>
    </FormProvider>
  );
}

export function UserEditSlideOver({ userId }: UserEditSlideOverProps) {
  const result = useUserQuery(userId);

  return <SlideOverWithLoading result={result} render={(user) => <UserEditView user={user} />} />;
}
