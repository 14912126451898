import classnames from "classnames";
import { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";

const isEnabled = localStorage.getItem("I want to know everything");

const Hash = styled<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
  ({ children, className, ...props }) =>
    isEnabled ? (
      <span {...props} className={classnames("text-iron-400 align-middle", className)}>
        #{children}
      </span>
    ) : null
);

export default Hash;
