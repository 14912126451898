import { Request } from "@dh-critical-path/api-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useUsersQuery } from "../queries";

export type UsersFilterFormFields = Request.Users.GetUsers;

export function useUsersFilter() {
  const [page, setPage] = useState(1);

  const [queryParams, setQueryParams] = useState<UsersFilterFormFields>({});

  const methods = useForm<Request.Users.GetUsers>({
    defaultValues: {
      order_column: "first_name",
      order_direction: "asc",
    },
  });

  const fields = methods.watch();

  useEffect(() => {
    setPage(1);
    setQueryParams({
      search: fields.search,
      sso_role: fields.sso_role,
      department_ids: fields.department_ids,
      order_column: fields.order_column,
      order_direction: fields.order_direction,
    });
  }, [
    fields.search,
    fields.sso_role,
    fields.department_ids,
    fields.order_column,
    fields.order_direction,
    //
  ]);

  const query = useUsersQuery({ ...queryParams, page });

  return { methods, query, setPage };
}
