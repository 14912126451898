import { User } from "@dh-critical-path/api-types";
import axios from "axios";

export function getMe() {
  return axios.get<User>("/api/user/me").then(({ data }) => data);
}

export function logout(redirectAfterLogoutUrl: string): Promise<{ data: string }> {
  return axios.post("/api/auth/sso/logout", { redirect_to: redirectAfterLogoutUrl });
}

export function impersonate(id: number) {
  return axios
    .post<{ token: string }>(`/api/auth/sso/impersonate/${id}`)
    .then(({ data }) => data.token);
}
