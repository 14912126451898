import { Switch as HeadlessSwitch } from "@headlessui/react";
import classnames from "classnames";
import React, { ButtonHTMLAttributes, forwardRef } from "react";
import { styled } from "../../utils/styled";

export interface SwitchProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onChange"> {
  label?: React.ReactNode;
  checked: boolean;
  onChange(checked: boolean): void;
}

const Button = styled<HTMLButtonElement, SwitchProps>(({ checked, children, ...props }) => (
  <HeadlessSwitch
    {...props}
    checked={checked}
    className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-beige"
  >
    {children}
  </HeadlessSwitch>
));

const Label: React.FC = ({ children }) => (
  <HeadlessSwitch.Label className="text-iron text-base cursor-pointer leading-tight">
    {children}
  </HeadlessSwitch.Label>
);

const Placeholder: React.VFC = () => (
  <span aria-hidden="true" className="pointer-events-none absolute w-full h-full rounded-md" />
);

const Track: React.FC<{ checked: boolean }> = ({ checked }) => (
  <span
    aria-hidden="true"
    className={classnames(
      "pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200",
      {
        "bg-beige": checked,
        "bg-stone-400": !checked,
      }
    )}
  />
);

const Toggler: React.FC<{ checked: boolean }> = ({ checked }) => (
  <span
    aria-hidden="true"
    className={classnames(
      "pointer-events-none absolute left-0 inline-block h-5 w-5 border border-stone-400 rounded-full bg-white shadow transform ring-0 group-hover:bg-beige-400 transition ease-in-out duration-200",
      {
        "translate-x-5": checked,
        "translate-x-0": !checked,
      }
    )}
  />
);

const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ label, checked, className, ...props }, ref) => (
    <HeadlessSwitch.Group>
      <div className={classnames("flex items-center space-x-2 group", className)}>
        <Button {...props} ref={ref} checked={checked}>
          <Placeholder />
          <Track checked={checked} />
          <Toggler checked={checked} />
        </Button>
        {label && <Label>{label}</Label>}
      </div>
    </HeadlessSwitch.Group>
  )
);

export default Switch;
