import { Request, TemplateTitle, User } from "@dh-critical-path/api-types";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  createTemplateTitle,
  removeMemberFromTemplateTitle,
  removeTemplateTitle,
  updateTemplateTitle,
} from "../api";
import { templateKeys } from "../queries";
import { interceptValidationErrorsAndThrow } from "../utils/form";
import { mapIds } from "../utils/misc";

export type TemplateTitleCreateFields = Request.TemplateTitles.CreateTemplateTitle;

export type TemplateTitleUpdateFields = Request.TemplateTitles.UpdateTemplateTitle;

type TemplateTitleCallback = (title: Omit<TemplateTitle, "members">) => void;

export function useTemplateTitleCreateForm(
  templateDepartmentId: number,
  onCreated: TemplateTitleCallback
) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(createTemplateTitle);

  const methods = useForm<TemplateTitleCreateFields>({
    defaultValues: {
      name: "",
      template_department_id: templateDepartmentId,
      member_ids: [],
    },
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onCreated(await mutateAsync(data));
      queryClient.invalidateQueries(templateKeys.allTemplateTitles());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useTemplateTitleUpdateForm(title: TemplateTitle, onUpdated: TemplateTitleCallback) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateTemplateTitle);

  const methods = useForm<TemplateTitleUpdateFields>({
    defaultValues: {
      template_title_id: title.id,
      name: title.name,
      template_department_id: title.template_department_id,
      member_ids: mapIds(title.members),
    },
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      onUpdated(await mutateAsync(data));
      queryClient.invalidateQueries(templateKeys.allTemplateTitles());
    } catch (e) {
      interceptValidationErrorsAndThrow(methods, e);
    }
  });

  return { methods, handleSubmit };
}

export function useTemplateTitleRemove() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(removeTemplateTitle);

  const handleRemove = useCallback(
    async (title: TemplateTitle) => {
      await mutateAsync(title.id);

      queryClient.invalidateQueries(templateKeys.allTemplateTitles());

      return title;
    },
    [queryClient, mutateAsync]
  );

  return { handleRemove, isLoading };
}

export function useTemplateTitleMemberRemove() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(removeMemberFromTemplateTitle);

  const handleRemove = useCallback(
    async (title: TemplateTitle, member: User) => {
      await mutateAsync({ template_title_id: title.id, member_id: member.id });

      queryClient.invalidateQueries(templateKeys.allTemplateTitles());

      return title;
    },
    [queryClient, mutateAsync]
  );

  return { handleRemove, isLoading };
}
