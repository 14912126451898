import classnames from "classnames";
import React, { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";
import { Hash } from "../common";

interface PageHeaderProps extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title: React.ReactNode;
  hash?: string | number;
}

const PageHeaderStyled = styled<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }) => (
    <div {...props} className={classnames("w-full py-4 bg-white", className)}>
      <div className="container flex flex-wrap items-center min-h-10">{children}</div>
    </div>
  )
);

const PageHeader = React.forwardRef<HTMLDivElement, PageHeaderProps>(function PageHeader(
  { title, hash, children },
  ref
) {
  return (
    <PageHeaderStyled ref={ref}>
      <h1 className="font-medium text-xl leading-normal flex-1 pr-6">
        <span className="pr-1">{title}</span>
        {hash && <Hash>{hash}</Hash>}
      </h1>
      {children}
    </PageHeaderStyled>
  );
});

export default PageHeader;
