import { TemplateProject } from "@dh-critical-path/api-types";
import { PropsWithChildren } from "react";
import { PageHeader } from "../../../components/page";
import HeaderHoc from "../../../hoc/header/HeaderHoc";
import { TemplateProjectTabs } from "./TemplateProjectTabs";

type TemplateProjectPageLayoutProps = {
  project: TemplateProject;
};

export function TemplateProjectPageLayout({
  project,
  children,
}: PropsWithChildren<TemplateProjectPageLayoutProps>) {
  return (
    <>
      <HeaderHoc />
      <div>
        <PageHeader title={project.name} hash={project.id}>
          <TemplateProjectTabs project={project} />
        </PageHeader>
        {children}
      </div>
    </>
  );
}
