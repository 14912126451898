import { ExclamationIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import React, { HTMLAttributes } from "react";
import { styled } from "../../utils/styled";

interface ModalIconProps extends HTMLAttributes<HTMLDivElement> {
  type: "danger" | "success";
}

const ModalIconStyled = styled<HTMLDivElement, ModalIconProps>(
  ({ type, className, children, ...props }) => (
    <div
      {...props}
      className={classnames(
        "flex-shrink-0 flex items-center justify-center rounded-full h-12 w-12 sm:h-10 sm:w-10",
        {
          "bg-red-100 text-red-600": type === "danger",
          "bg-green-100 text-green-600": type === "success",
        },
        className
      )}
    >
      {children}
    </div>
  )
);

const ModalIcon = React.forwardRef<HTMLDivElement, ModalIconProps>(function ModalIcon(
  { type, children, ...props },
  ref
) {
  return (
    <ModalIconStyled {...props} ref={ref} type={type}>
      {type === "danger" && <ExclamationIcon className="h-6 w-6" />}
      {type === "success" && <CheckIcon className="h-6 w-6" />}
    </ModalIconStyled>
  );
});

export default ModalIcon;
