import { Route, Routes } from "react-router";
import { UsersPage } from "./UsersPage";

export function UsersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<UsersPage />} />
    </Routes>
  );
}
