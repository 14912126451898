import { User } from "@dh-critical-path/api-types";
import { ProcessType } from "@dh-critical-path/shared";

export function toInitials(text: string, limit: number = 2) {
  return text
    .toUpperCase()
    .split(" ")
    .map((part) => part[0])
    .slice(0, limit)
    .join("");
}

export function fullName(user: Pick<User, "first_name" | "last_name">): string {
  return [user.first_name, user.last_name].filter(Boolean).join(" ");
}

export function pluralize(count: number, singular: string, plural: string) {
  return count === 0 || count > 1 ? plural : singular;
}

export function processTypeText(
  project: { process_type: ProcessType | string } | undefined,
  openingText: string,
  closureText: string,
  reOpeningText: string,
  transitionText: string
) {
  return project?.process_type === ProcessType.OPENING
    ? openingText
    : project?.process_type === ProcessType.RE_OPENING
    ? reOpeningText
    : project?.process_type === ProcessType.TRANSITION
    ? transitionText
    : closureText;
}
