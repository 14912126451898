import { User } from "@dh-critical-path/api-types";
import classnames from "classnames";
import { HTMLAttributes } from "react";
import { ProfileAvatar } from ".";
import { fullName } from "../../utils/text";

type ProfileRowVariant = "dark" | "light";

interface ProfileRowProps extends HTMLAttributes<HTMLDivElement> {
  user: User;
  variant?: ProfileRowVariant;
}

const ProfileRowName: React.FC<{ variant: ProfileRowVariant }> = ({ variant, children }) => (
  <p
    data-cy="profile-row-name"
    className={classnames("text-lg leading-none mb-1", {
      "text-black": variant === "dark",
      "text-white": variant === "light",
    })}
  >
    {children}
  </p>
);

const ProfileRowEmail: React.FC<{ variant: ProfileRowVariant }> = ({ variant, children }) => (
  <p
    data-cy="profile-row-email"
    className={classnames("text-sm  break-all", {
      "text-iron": variant === "dark",
      "text-white": variant === "light",
    })}
  >
    {children}
  </p>
);

export const ProfileRow: React.VFC<ProfileRowProps> = ({ user, variant = "dark", className }) => {
  return (
    <div className={classnames("flex", className)}>
      <ProfileAvatar size="lg" user={user} />
      <div className="pl-3 pt-0.5">
        <ProfileRowName variant={variant}>{fullName(user)}</ProfileRowName>
        <ProfileRowEmail variant={variant}>{user.email}</ProfileRowEmail>
      </div>
    </div>
  );
};
