import { Transition } from "@headlessui/react";
import React, { Fragment, HTMLAttributes } from "react";
import { Toast as ToastType, useToast } from "../../hooks/useToast";
import { styled } from "../../utils/styled";
import { CloseButton } from "../button";
import ModalIcon from "../icons/ModalIcon";

interface ToastProps extends HTMLAttributes<HTMLDivElement> {
  toast: ToastType;
}

const ToastStyled = styled<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, ...props }) => (
    <div
      {...props}
      className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    >
      <div className="p-4">
        <div className="flex items-center">{children}</div>
      </div>
    </div>
  )
);

const ToastRootStyled = styled<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, ...props }) => (
    <div
      {...props}
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">{children}</div>
    </div>
  )
);

const Toast: React.VFC<ToastProps> = ({ toast }) => {
  const { dismissToast, removeToast } = useToast();

  return (
    <Transition
      show={!toast.dismissed}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={() => removeToast(toast)}
    >
      <ToastStyled>
        <div className="w-0 flex-1 flex items-center justify-between">
          {toast.type && <ModalIcon className="mr-4" type={toast.type} />}
          <p className="w-0 flex-1 text-base text-black">{toast.text}</p>
        </div>
        <CloseButton className="ml-4 flex-shrink-0" onClick={() => dismissToast(toast)} />
      </ToastStyled>
    </Transition>
  );
};

export const ToastRoot = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const { toasts } = useToast();

    return (
      <ToastRootStyled {...props} ref={ref}>
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} />
        ))}
      </ToastRootStyled>
    );
  }
);
