import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/solid";
import React from "react";
import { Link } from "react-router-dom";
import { styled } from "../../utils/styled";
import HeaderLogo from "./HeaderLogo";

interface HeaderProps {
  links: React.ReactNode;
  search?: React.ReactNode;
  profileMenu?: React.ReactNode;
  notification?: React.ReactNode;
}

const Wrapper = styled<HTMLDivElement>(({ children }) => (
  <div className="container px-4 sm:px-6 lg:px-8">
    <div className="flex items-center justify-between h-16">{children}</div>
  </div>
));

const Links = styled<HTMLDivElement>(({ children }) => (
  <div className="hidden lg:block sm:ml-6">
    <nav className="flex space-x-4">{children}</nav>
  </div>
));

const LogoAndLinksWrapper = styled<HTMLDivElement>(({ children }) => (
  <div className="flex items-center">{children}</div>
));

const MobileMenu = styled<HTMLDivElement>(({ children, ...props }) => (
  <Disclosure.Panel {...props} data-cy="mobile-menu" className="lg:hidden">
    <div className="p-3 space-y-1 flex flex-col">{children} </div>
  </Disclosure.Panel>
));

const SearchAndProfileMenuWrapper = styled<HTMLDivElement>(({ children }) => (
  <div className="hidden ml-auto mr-6 lg:mr-0 lg:ml-6 sm:block">
    <div className="flex items-center space-x-5">{children}</div>
  </div>
));

const MobileMenuButton: React.FC<{ open: boolean }> = ({ open, ...props }) => (
  <Disclosure.Button
    {...props}
    data-cy="mobile-menu-button"
    className="lg:hidden p-2 rounded-md text-beige-400 hover:text-white focus:outline-none"
  >
    {open ? <XIcon className="block h-6 w-6" /> : <MenuIcon className="block h-6 w-6" />}
  </Disclosure.Button>
);

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(function Header(
  { links, search, profileMenu, notification, ...props },
  ref
) {
  return (
    <Disclosure
      {...props}
      as="div"
      ref={ref}
      data-cy="header"
      className="sticky top-0 md:relative bg-violet z-30"
    >
      {({ open }) => (
        <>
          <Wrapper>
            <LogoAndLinksWrapper>
              <Link to="/" data-cy="header-logo-link">
                <HeaderLogo className="flex-shrink-0" />
              </Link>
              <Links>{links}</Links>
            </LogoAndLinksWrapper>
            <SearchAndProfileMenuWrapper>
              {search}
              {notification}
              <div className="hidden lg:flex">{profileMenu}</div>
            </SearchAndProfileMenuWrapper>
            <MobileMenuButton open={open} />
          </Wrapper>
          <MobileMenu>
            {links}
            {profileMenu}
          </MobileMenu>
        </>
      )}
    </Disclosure>
  );
});

export default Header;
