import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LoginProcessing, LoginRedirect } from "../components/page/PageFallback";
import { useAuth } from "../hooks/useAuth";

export function LoginPage() {
  const [params] = useSearchParams();
  const { login } = useAuth();

  useEffect(() => login(params.get("token")), [login, params]);

  return params.has("token") ? <LoginProcessing /> : <LoginRedirect />;
}

export function LogoutPage() {
  const { logout } = useAuth();

  useEffect(() => logout(), [logout]);

  return <LoginRedirect />;
}
