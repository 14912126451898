import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  InsertLink,
  List,
  PlaylistAddCheck,
  StrikethroughS,
} from "@mui/icons-material";
import classNames from "classnames";
import {
  KeyboardEvent,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  BlockFormat,
  BlockType,
  CheckListItemElement as CheckListItemElementType,
  Descendant,
  LinkElement as LinkElementType,
  MarkFormat,
  ParagraphElement,
  createEditor,
} from "slate";
import { withHistory } from "slate-history";
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  useReadOnly,
  useSlate,
  useSlateStatic,
  withReact,
} from "slate-react";
import { deserialize, serialize } from "./markdown";
import {
  handleHotKey,
  isAstChanged,
  isBlockActive,
  isLinkActive,
  isMarkActive,
  toggleBlock,
  toggleMark,
  unwrapLink,
  withChecklists,
  withEnhancedLists,
  withInlines,
  wrapLink,
} from "./utils";

type EditorProps = {
  toolbar?: ReactNode;
  readOnly?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
};

type EditorToolbarButtonProps = {
  isActive: boolean;
  icon: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

type EditorToolbarMarkButtonProps = {
  icon: ReactNode;
  format: MarkFormat;
};

type EditorToolbarBlockButtonProps<T extends BlockType> = {
  icon: ReactNode;
  type: T;
  format: BlockFormat<T>;
};

export function Editor({ toolbar, readOnly, placeholder, value, onChange }: EditorProps) {
  const renderElement = useCallback((props) => <Element {...props} />, []);

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editor = useMemo(
    () => withInlines(withEnhancedLists(withChecklists(withHistory(withReact(createEditor()))))),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValue: Descendant[] = useMemo(() => deserialize(value || ""), []);

  const handleChange = useCallback(
    (value: Descendant[]) => {
      if (isAstChanged(editor)) {
        onChange(serialize(value));
      }
    },
    [editor, onChange]
  );

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => handleHotKey(editor, event),
    [editor]
  );

  return (
    <Slate editor={editor} value={initialValue} onChange={handleChange}>
      {!readOnly && toolbar}
      <EditableWrapper>
        <Editable
          placeholder={placeholder}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly={readOnly}
          onKeyDown={handleKeydown}
        />
      </EditableWrapper>
    </Slate>
  );
}

export function Renderer({ value, onChange }: Pick<EditorProps, "value" | "onChange">) {
  const renderElement = useCallback((props) => <Element {...props} />, []);

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editor = useMemo(() => withInlines(withChecklists(withReact(createEditor()))), []);

  useEffect(() => {
    editor.children = deserialize(value);
    editor.onChange();
  }, [value, editor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValue: Descendant[] = useMemo(() => deserialize(value || ""), []);

  const handleChange = useCallback(
    (value: Descendant[]) => {
      if (isAstChanged(editor)) {
        onChange(serialize(value));
      }
    },
    [editor, onChange]
  );

  return (
    <Slate editor={editor} value={initialValue} onChange={handleChange}>
      <RendererWrapper>
        <Editable renderElement={renderElement} renderLeaf={renderLeaf} readOnly={true} />
      </RendererWrapper>
    </Slate>
  );
}

export function EditorToolbar({ children }: PropsWithChildren<{}>) {
  return (
    <div className="flex px-3 py-2 space-x-4 border-b border-stone-100 rounded-t-md">
      {children}
    </div>
  );
}

export function EditorToolbarGroup({ children }: PropsWithChildren<{}>) {
  return <div className="space-x-2">{children}</div>;
}

export function EditorToolbarBase({ children }: PropsWithChildren<{}>) {
  return (
    <EditorToolbar>
      <EditorToolbarGroup>
        <EditorToolbarMarkButton icon={<FormatBold />} format="bold" />
        <EditorToolbarMarkButton icon={<FormatItalic />} format="italic" />
        <EditorToolbarMarkButton icon={<FormatUnderlined />} format="underline" />
        <EditorToolbarMarkButton icon={<StrikethroughS />} format="strikethrough" />
      </EditorToolbarGroup>
      <EditorToolbarGroup>
        <EditorToolbarBlockButton icon={<List />} type="type" format="bulleted-list" />
        <EditorToolbarBlockButton
          icon={<PlaylistAddCheck />}
          type="type"
          format="check-list-item"
        />
      </EditorToolbarGroup>
      <EditorToolbarGroup>
        <EditorToolbarLinkButton />
      </EditorToolbarGroup>
      {children}
    </EditorToolbar>
  );
}

export function EditorToolbarMarkButton({ icon, format }: EditorToolbarMarkButtonProps) {
  const editor = useSlate();

  return (
    <EditorToolbarButton
      icon={icon}
      isActive={isMarkActive(editor, format)}
      onClick={() => toggleMark(editor, format)}
    />
  );
}

export function EditorToolbarBlockButton<T extends BlockType>({
  icon,
  type,
  format,
}: EditorToolbarBlockButtonProps<T>) {
  const editor = useSlate();

  return (
    <EditorToolbarButton
      icon={icon}
      isActive={isBlockActive(editor, type, format)}
      onClick={() => toggleBlock(editor, type, format)}
    />
  );
}

export function EditorToolbarLinkButton() {
  const editor = useSlate();

  const isActive = isLinkActive(editor);

  return (
    <EditorToolbarButton
      icon={<InsertLink />}
      isActive={isActive}
      onClick={() => {
        if (isActive) {
          unwrapLink(editor);
        } else {
          wrapLink(editor, window.prompt("URL:") || "");
        }
      }}
    />
  );
}

function EditableWrapper({ children }: PropsWithChildren<{}>) {
  return <div className="content px-3 py-2">{children}</div>;
}

function RendererWrapper({ children }: PropsWithChildren<{}>) {
  return <div className="content">{children}</div>;
}

function EditorToolbarButton({ isActive, icon, onClick }: EditorToolbarButtonProps) {
  return (
    <button type="button" className={classNames({ "text-beige": isActive })} onClick={onClick}>
      {icon}
    </button>
  );
}

function Element(props: RenderElementProps) {
  const { attributes, children, element } = props;

  const style = {
    textAlign: (element as ParagraphElement).align,
  };

  switch (element.type) {
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "link":
      return <LinkElement {...props} />;
    case "check-list-item":
      return <CheckListItemElement {...props} />;
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
}

function Leaf({ attributes, children, leaf }: RenderLeafProps) {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }

  // https://github.com/ianstormtaylor/slate/blob/9892cf0ffbd741cc2880d1f0bd0d7c1b36145bbd/site/examples/inlines.tsx#L306
  return (
    <span {...attributes} style={leaf.text === "" ? { paddingLeft: "0.1px" } : {}}>
      {children}
    </span>
  );
}

function InlineChromiumBugfix() {
  return (
    <span contentEditable={false} style={{ fontSize: 0 }}>
      {/* https://github.com/ianstormtaylor/slate/blob/9892cf0ffbd741cc2880d1f0bd0d7c1b36145bbd/site/examples/inlines.tsx#L221 */}
      ${String.fromCodePoint(160)}
    </span>
  );
}

function LinkElement({ attributes, children, element }: RenderElementProps) {
  const { url } = element as LinkElementType;

  return (
    <a {...attributes} href={url} target="_blank" rel="noreferrer">
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
}

function CheckListItemElement({ attributes, children, element }: RenderElementProps) {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const { checked } = element as CheckListItemElementType;
  return (
    <div {...attributes}>
      <span contentEditable={false}>
        <input
          type="checkbox"
          checked={checked}
          onChange={(event) => toggleBlock(editor, "checked", event.target.checked, element)}
          className="checkbox h-4 w-4 rounded text-beige border-stone-400 focus:border-2 focus:border-beige bg-white hover:border-beige focus:outline-none focus:ring-offset-0 focus:ring-transparent cursor-pointer"
        />
      </span>
      <span contentEditable={!readOnly} suppressContentEditableWarning className="ml-2">
        {children}
      </span>
    </div>
  );
}
