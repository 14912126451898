import { CalendarIcon } from "@heroicons/react/outline";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import en from "date-fns/locale/en-GB";
import { ForwardedRef, forwardRef, useEffect, useRef } from "react";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";
import { FormattedDate } from "react-intl";
import { FormElementProps, Label, ValidationText } from ".";

registerLocale("eb-GB", en);

export interface DatePickerProps extends FormElementProps, ReactDatePickerProps<string> {
  //
}

interface CustomHeaderProps {
  monthDate: Date;
  date: Date;
  decreaseMonth(): void;
  increaseMonth(): void;
  changeYear(year: number): void;
  showYearDropdown?: boolean;
}

type HasInputType = {
  input: HTMLInputElement;
};

function setRef<T>(ref: ForwardedRef<T>, current: T) {
  if (ref && typeof ref === "function") {
    ref(current);
  } else if (ref) {
    ref.current = current;
  }
}

const currentYear = new Date().getFullYear();
const years = [
  ...Array(5)
    .fill(0)
    .map((_, index) => currentYear - index - 1)
    .reverse(),
  currentYear,
  ...Array(5)
    .fill(0)
    .map((_, index) => currentYear + index + 1),
];

const CustomHeader = ({
  monthDate,
  date,
  decreaseMonth,
  increaseMonth,
  showYearDropdown,
  changeYear,
}: CustomHeaderProps) => {
  return (
    <div className="flex flex-col pb-4 px-2">
      <div className="flex items-center justify-between">
        <button type="button" onClick={decreaseMonth} className="text-beige hover:text-beige-400">
          <ArrowLeftIcon className="w-4 fill-current" />
        </button>
        <span className="flex justify-center space-x-1 text-base text-black font-bold">
          <span>
            <FormattedDate value={monthDate} month="long" />
          </span>
          {showYearDropdown && (
            <select
              className="year-select"
              value={date.getFullYear()}
              onChange={({ target: { value } }) => changeYear(+value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
        </span>
        <button type="button" onClick={increaseMonth} className="text-beige hover:text-beige-400">
          <ArrowRightIcon className="w-4 fill-current" />
        </button>
      </div>
    </div>
  );
};

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    { id, disabled, required, invalid, invalidText, label, className, showYearDropdown, ...props },
    ref
  ) => {
    const innerRef = useRef(null);

    useEffect(() => {
      // https://github.com/Hacker0x01/react-datepicker/issues/1480
      // https://github.com/Hacker0x01/react-datepicker/issues/2873
      if (innerRef.current && (innerRef.current as HasInputType).input) {
        (innerRef.current as HasInputType).input.readOnly = true;
        setRef(ref, (innerRef.current as HasInputType).input);
      }
    });

    setDefaultLocale("eb-GB");

    return (
      <div className={classnames("space-y-1", className)}>
        {label && (
          <Label htmlFor={id} disabled={disabled} required={required}>
            {label}
          </Label>
        )}
        <div className="relative">
          <ReactDatePicker
            {...props}
            ref={innerRef}
            id={id}
            dateFormat="dd/MM/yyyy"
            disabled={disabled}
            required={required}
            preventOpenOnFocus={true}
            wrapperClassName={invalid ? "date-picker-error" : ""}
            popperPlacement="bottom"
            renderCustomHeader={(props) => (
              <CustomHeader {...props} showYearDropdown={showYearDropdown} />
            )}
          />
          <label
            htmlFor={id}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none cursor-pointer"
          >
            <CalendarIcon className="w-5 stroke-current text-stone-800" />
          </label>
        </div>
        {invalid && invalidText && <ValidationText className="mt-1">{invalidText}</ValidationText>}
      </div>
    );
  }
);

export default DatePicker;
