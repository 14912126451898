import { Request } from "@dh-critical-path/api-types";
import { useForm } from "react-hook-form";
import { useCompareTemplateTasks } from "../queries";

export type TasksComparisonFields = Request.TemplateTasks.CompareTemplateTasks;

type TasksComparisonFilterOptions = {
  template_project_id: number;
};

export function useTasksComparisonFilter(options: TasksComparisonFilterOptions) {
  const methods = useForm<TasksComparisonFields>({
    defaultValues: {
      template_project_id: options.template_project_id,
      ...tasksComparisonFilterDefaultValues(),
    },
  });

  const fields = methods.watch();

  const query = useCompareTemplateTasks(fields);

  return { methods, query };
}

export function tasksComparisonFilterDefaultValues(): Partial<TasksComparisonFields> {
  return {
    only_changed: true,
    only_template_tasks: false,
    only_project_tasks: false,
    only_removed_template_tasks: false,
    only_removed_project_tasks: false,
    search: "",
  };
}
