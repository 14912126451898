import { Project } from "@dh-critical-path/api-types";
import { FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Layout, PageContainer, PageSectionHeader } from "../../components/page";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useTasksFilter } from "../../hooks/useTasksFilter";
import { useTasksFilterSearchParameters } from "../../hooks/useTasksFilterSearchParameters";
import { ProjectTasksActions } from "./components/ProjectTasksActions";
import { TasksPagination } from "./components/TasksPagination";
import { TasksTable, TasksTableHeadRowSortable } from "./components/TasksTable";
import { TasksFilterForm } from "./components/forms/TasksFilterForm";

type ProjectTasksPageProps = {
  project: Project;
};

function ProjectTasksPage({ project }: ProjectTasksPageProps) {
  useDocumentTitle(`${project.name} - Tasks`);

  const { methods, query, setPage, activeFiltersCount } = useTasksFilter({
    project_ids: [project.id],
  });

  const { hasDefaultSearchParameters } = useTasksFilterSearchParameters(methods);

  return (
    <PageContainer>
      <Layout.Column>
        <Layout.Spacer space={8}>
          <PageSectionHeader
            buttons={<ProjectTasksActions project={project} query={query} methods={methods} />}
          >
            <FormattedMessage
              id="headings.projects.tasks"
              values={{ count: query.data?.meta.total ?? 0 }}
            />
          </PageSectionHeader>
          <FormProvider {...methods}>
            <TasksFilterForm
              open={hasDefaultSearchParameters}
              activeFiltersCount={activeFiltersCount}
            />
            <TasksTable
              tasks={query.data?.data ?? []}
              headRenderer={<TasksTableHeadRowSortable />}
            />
            {query.data && <TasksPagination pagination={query.data.meta} onChange={setPage} />}
          </FormProvider>
        </Layout.Spacer>
      </Layout.Column>
    </PageContainer>
  );
}

export default ProjectTasksPage;
